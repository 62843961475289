import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TopBar from "../../../shared/Navigation/TopBar";
import Dropdown from "./Dropdown";

const Settings = ({ sidebarCollapsed }) => {
  const [value, setValue] = useState(new Date());
  function onChange(nextValue) {
    setValue(nextValue);
  }
  const dropdownOptions = [
    { id: 'one', label: 'First option' },
    { id: 'two', label: 'Second option' },
    { id: 'three', label: 'Third option' },
    // Add more options as needed
  ];

  return (
    <div
      className=" main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
     <div className="create-organization">
      <label className="pt-3 pl-3">Notification</label>
      <div className="row">
        <div className="col-4 d-flex justify-content-center">
          <Calendar
            onChange={onChange}
            value={value}
            showNeighboringDecade={false}
            prev2Label={null}
            next2Label={null}
            formatShortWeekday={(locale, date) =>
              [`S`, `M`, `T`, `W`, `T`, `F`, `S`][date.getDay()]
            }
            calendarType="gregory"
            tileClassName="class1 class2"
            nextLabel={
              <EastIcon
              // style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius:'50' }}
              />
            }
            prevLabel={<WestIcon />}
          />
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Notification Type</label>
                <select className="form-control notification-input">
                  <option>System Upgrade</option>
                  <option>System Outrage</option>
                  <option>New Module info</option>
                  <option>Module On & Off</option>
                </select>
                {/* <Dropdown options={dropdownOptions}/> */}

              </div>
              <div className="form-group">
                <label>Notification Message</label>
                <textarea
                  className="form-control notification-input"
                  style={{ height: "120px", width: "100%", resize: "none" }}
                />
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label>Time</label>
                <input className="form-control" />
              </div>
              <label>Notification Source</label>
              <div className="light_shadow rounded p-2">
                <div className="form-group d-flex">
                  <label className="notification_checkbox mb-0">
                    Email Notification
                  </label>
                  <input
                    type="checkbox"
                    className="custom-checkbox ml-auto mr-3 "
                    name="vehicle2"
                    value="Car"
                  />
                </div>
                <div className=" d-flex">
                  <label className="notification_checkbox mb-0">
                    In App Notification
                  </label>
                  <input
                    type="checkbox"
                    className="custom-checkbox ml-auto mr-3"
                    name="vehicle3"
                    value="Boat"
                  />
                </div>
              </div>
              <button
                // type="submit"
                // className="button"
                style={{
                  backgroundColor: "#00a82d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  width: "90px",
                  height: "30px",
                  float: "right",
                  marginTop: "40px",
                }}
                // onClick={() => navigate("/createorganization")}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 py-3">
        <label>Notification List</label>
        <div
          className="light_shadow d-flex align-items-center"
          style={{ borderRadius: "10px", width: "90%", padding:'12px 24px' }}
        >
          <p className="font-weight-bold">
            System update is scheduled for Tuesday, 06/02/2024, 11:30 am
          </p>
          <div className="ml-auto ">
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <AccessTimeIcon />
            </button>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
        <div
          className="light_shadow mt-3 d-flex align-items-center"
          style={{ borderRadius: "10px", width: "90%",padding:'12px 24px' }}
        >
          <p className="font-weight-bold" style={{opacity:0.5}}>
            New model on Market Intelligence introduced
          </p>
          <div className="ml-auto ">
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <ArrowUpwardIcon />
            </button>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                opacity:0.5
              }}
              className="edit_icon light_shadow"
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                opacity:0.5
              }}
              className="edit_icon light_shadow"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
      </div> 
    </div>
  );
};

export default Settings;

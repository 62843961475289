const Modules = (editMode, handleUpdateButtonClick) => {
  return (
    <div className="d-flex flex-direction-row justify-content-around">
      <div className="module  bg-shadow">
        <p className="module_head text-center mb-4">Default Modules</p>
        <div className="my-2">
          <label>My Dashboard</label>
          <label className="switch">
            <input type="checkbox" disabled={true} />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Visits</label>
          <label className="switch">
            <input type="checkbox" disabled={true} />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Calendar</label>
          <label className="switch">
            <input type="checkbox" disabled={true} />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Customers</label>
          <label className="switch">
            <input type="checkbox" disabled={true} />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Team</label>
          <label className="switch">
            <input type="checkbox" disabled={true} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      <div className="module bg-shadow">
        <p className="module_head text-center mb-4">Optional Modules</p>
        <div className="my-2">
          <label>My Opportunities</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Orders</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Expenses</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Plan</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Resourses</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>Market Intelligence</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>Complaints</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="module bg-shadow">
        <p className="module_head text-center mb-4">
          External App Integrations
        </p>
        <div className="my-2">
          <label>Skilltiger</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>Mail Chimp</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>My Operator</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="my-2">
          <label>Survey Monkey</label>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      {/* {editMode && (
        <div>
          <button
            style={{
              backgroundColor: "#00a82d",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              width: "90px",
              height: "32px",
              marginTop: "10px",
            }}
            type="button"
            onClick={handleUpdateButtonClick()}
          >
            Next
          </button>
        </div>
      )} */}
    </div>
  );
};
export default Modules;

export const USER_LOGIN_REQ = 'USER_LOGIN_REQ';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const ATTRIBUTES_LIBRARY_REQUEST = 'ATTRIBUTES_LIBRARY_REQUEST';
export const ATTRIBUTES_LIBRARY_SUCCESS = 'ATTRIBUTES_LIBRARY_SUCCESS';
export const ATTRIBUTES_LIBRARY_FAIL = 'ATTRIBUTES_LIBRARY_FAIL';

export const CUSTOM_ATTRIBUTES_REQUEST = 'CUSTOM_ATTRIBUTES_REQUEST';
export const CUSTOM_ATTRIBUTES_SUCCESS = 'CUSTOM_ATTRIBUTES_SUCCESS';
export const CUSTOM_ATTRIBUTES_FAIL = 'CUSTOM_ATTRIBUTES_FAIL';

export const SETUP_STATUS_REQUEST = 'SETUP_STATUS_REQUEST';
export const SETUP_STATUS_SUCCESS = 'SETUP_STATUS_SUCCESS';
export const SETUP_STATUS_FAIL = 'SETUP_STATUS_FAIL';

export const UPDATE_SETUP_STATUS_REQUEST = 'UPDATE_SETUP_STATUS_REQUEST';
export const UPDATE_SETUP_STATUS_SUCCESS = 'UPDATE_SETUP_STATUS_SUCCESS';
export const UPDATE_SETUP_STATUS_FAIL = 'UPDATE_SETUP_STATUS_FAIL';

export const DELETE_ATTRIBUTE_REQUEST = 'DELETE_ATTRIBUTE_REQUEST';
export const DELETE_ATTRIBUTE_SUCCESS = 'DELETE_ATTRIBUTE_SUCCESS';
export const DELETE_ATTRIBUTE_FAIL = 'DELETE_ATTRIBUTE_FAIL';

export const UPDATE_SECTION_DATA = 'UPDATE_SECTION_DATA';

export const SET_ATTRIBUTES_LIST_REQUEST = 'SET_ATTRIBUTES_LIST_REQUEST';
export const SET_ATTRIBUTES_LIST_SUCCESS = 'SET_ATTRIBUTES_LIST_SUCCESS';
export const SET_ATTRIBUTES_LIST_FAIL = 'SET_ATTRIBUTES_LIST_FAIL';

export const ATTRIBUTES_REQUEST = 'ATTRIBUTES_REQUEST';
export const ATTRIBUTES_SUCCESS = 'ATTRIBUTES_SUCCESS';
export const ATTRIBUTES_FAIL = 'ATTRIBUTES_FAIL';

export const EMPTY_ATTRIBUTES = 'EMPTY_ATTRIBUTES';

export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const EMPTY_DELETE_ATTRIBUTE = 'EMPTY_DELETE_ATTRIBUTE';

export const SET_CUSTOMER_TYPES_REQUEST = 'SET_CUSTOMER_TYPES_REQUEST';
export const SET_CUSTOMER_TYPES_SUCCESS = 'SET_CUSTOMER_TYPES_SUCCESS';
export const SET_CUSTOMER_TYPES_FAIL = 'SET_CUSTOMER_TYPES_FAIL';

export const GET_CUSTOMER_TYPE_ATTRIBUTES_REQUEST = 'GET_CUSTOMER_TYPE_ATTRIBUTES_REQUEST';
export const GET_CUSTOMER_TYPE_ATTRIBUTES_SUCCESS = 'GET_CUSTOMER_TYPE_ATTRIBUTES_SUCCESS';
export const GET_CUSTOMER_TYPE_ATTRIBUTES_FAIL = 'GET_CUSTOMER_TYPE_ATTRIBUTES_FAIL';

export const DELETE_CUSTOMER_TYPES = 'DELETE_CUSTOMER_TYPES';
export const SET_CUSTOMER_TYPES = 'SET_CUSTOMER_TYPES';

export const GET_GEOGRAPHIC_NODES_REQUEST = 'GET_GEOGRAPHIC_NODES_REQUEST';
export const GET_GEOGRAPHIC_NODES_SUCCESS = 'GET_GEOGRAPHIC_NODES_SUCCESS';
export const GET_GEOGRAPHIC_NODES_FAIL = 'GET_GEOGRAPHIC_NODES_FAIL';

export const SET_GEOGRAPHIC_NODES_REQUEST = 'SET_GEOGRAPHIC_NODES_REQUEST';
export const SET_GEOGRAPHIC_NODES_SUCCESS = 'SET_GEOGRAPHIC_NODES_SUCCESS';
export const SET_GEOGRAPHIC_NODES_FAIL = 'SET_GEOGRAPHIC_NODES_FAIL';

export const EMPTY_GEOGRAPHIC_NODES = 'EMPTY_GEOGRAPHIC_NODES';

export const EDIT_GEOGRAPHIC_NODES_REQUEST = 'EDIT_GEOGRAPHIC_NODES_REQUEST';
export const EDIT_GEOGRAPHIC_NODES_SUCCESS = 'EDIT_GEOGRAPHIC_NODES_SUCCESS';
export const EDIT_GEOGRAPHIC_NODES_FAIL = 'EDIT_GEOGRAPHIC_NODES_FAIL';

export const DELETE_GEOGRAPHIC_NODES_REQUEST = 'DELETE_GEOGRAPHIC_NODES_REQUEST';
export const DELETE_GEOGRAPHIC_NODES_SUCCESS = 'DELETE_GEOGRAPHIC_NODES_SUCCESS';
export const DELETE_GEOGRAPHIC_NODES_FAIL = 'DELETE_GEOGRAPHIC_NODES_FAIL';

export const GET_USER_ATTRIBUTES_REQUEST = 'GET_USER_ATTRIBUTES_REQUEST';
export const GET_USER_ATTRIBUTES_SUCCESS = 'GET_USER_ATTRIBUTES_SUCCESS';
export const GET_USER_ATTRIBUTES_FAIL = 'GET_USER_ATTRIBUTES_FAIL';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';


export const ADD_NEW_USER_REQUEST = 'ADD_NEW_USER_REQUEST';
export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAIL = 'ADD_NEW_USER_FAIL';

export const SET_EDIT_USER_ID = 'SET_EDIT_USER_ID';
export const SET_COMBINED_LIST = 'SET_COMBINED_LIST';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const EDIT_NEW_USER_REQUEST = 'EDIT_NEW_USER_REQUEST';
export const EDIT_NEW_USER_SUCCESS = 'EDIT_NEW_USER_SUCCESS';
export const EDIT_NEW_USER_FAIL = 'EDIT_NEW_USER_FAIL';

export const TOGGLE_USER_STATUS_REQUEST = 'TOGGLE_USER_STATUS_REQUEST';
export const TOGGLE_USER_STATUS_SUCCESS = 'TOGGLE_USER_STATUS_SUCCESS';
export const TOGGLE_USER_STATUS_FAIL = 'TOGGLE_USER_STATUS_FAIL';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const EMPTY_SETUP_STATUS = 'EMPTY_SETUP_STATUS';

export const GET_OBJECTIVES_REQUEST = 'GET_OBJECTIVES_REQUEST';
export const GET_OBJECTIVES_SUCCESS = 'GET_OBJECTIVES_SUCCESS';
export const GET_OBJECTIVES_FAIL = 'GET_OBJECTIVES_FAIL';

export const SET_OBJECTIVES_REQUEST = 'SET_OBJECTIVES_REQUEST';
export const SET_OBJECTIVES_SUCCESS = 'SET_OBJECTIVES_SUCCESS';
export const SET_OBJECTIVES_FAIL = 'SET_OBJECTIVES_FAIL';

export const DELETE_OBJECTIVES_REQUEST = 'DELETE_OBJECTIVES_REQUEST';
export const DELETE_OBJECTIVES_SUCCESS = 'DELETE_OBJECTIVES_SUCCESS';
export const DELETE_OBJECTIVES_FAIL = 'DELETE_OBJECTIVES_FAIL';

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL';

export const SET_TASKS_REQUEST = 'SET_TASKS_REQUEST';
export const SET_TASKS_SUCCESS = 'SET_TASKS_SUCCESS';
export const SET_TASKS_FAIL = 'SET_TASKS_FAIL';

export const DELETE_TASKS_REQUEST = 'DELETE_TASKS_REQUEST';
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS';
export const DELETE_TASKS_FAIL = 'DELETE_TASKS_FAIL';

export const EDIT_OBJECTIVE_OPTION_REQUEST = 'EDIT_OBJECTIVE_OPTION_REQUEST';
export const EDIT_OBJECTIVE_OPTION_SUCCESS = 'EDIT_OBJECTIVE_OPTION_SUCCESS';
export const EDIT_OBJECTIVE_OPTION_FAIL = 'EDIT_OBJECTIVE_OPTION_FAIL';

export const DELETE_OBJECTIVE_OPTION_REQUEST = 'DELETE_OBJECTIVE_OPTION_REQUEST';
export const DELETE_OBJECTIVE_OPTION_SUCCESS = 'DELETE_OBJECTIVE_OPTION_SUCCESS';
export const DELETE_OBJECTIVE_OPTION_FAIL = 'DELETE_OBJECTIVE_OPTION_FAIL';

export const GET_SCHEMES_REQUEST = 'GET_SCHEMES_REQUEST';
export const GET_SCHEMES_SUCCESS = 'GET_SCHEMES_SUCCESS';
export const GET_SCHEMES_FAIL = 'GET_SCHEMES_FAIL';

export const SET_SCHEMES_REQUEST = 'SET_SCHEMES_REQUEST';
export const SET_SCHEMES_SUCCESS = 'SET_SCHEMES_SUCCESS';
export const SET_SCHEMES_FAIL = 'SET_SCHEMES_FAIL';

export const DELETE_SCHEME_REQUEST = 'DELETE_SCHEME_REQUEST';
export const DELETE_SCHEME_SUCCESS = 'DELETE_SCHEME_SUCCESS';
export const DELETE_SCHEME_FAIL = 'DELETE_SCHEMES_FAIL';

export const GET_USER_SETUP_REQUEST = 'GET_USER_SETUP_REQUEST';
export const GET_USER_SETUP_SUCCESS = 'GET_USER_SETUP_SUCCESS';
export const GET_USER_SETUP_FAIL = 'GET_USER_SETUP_FAIL';

export const SET_USER_SETUP_REQUEST = 'SET_USER_SETUP_REQUEST';
export const SET_USER_SETUP_SUCCESS = 'SET_USER_SETUP_SUCCESS';
export const SET_USER_SETUP_FAIL = 'SET_USER_SETUP_FAIL';

export const EDIT_USER_SETUP_LIST_OPTION_REQUEST = 'EDIT_USER_SETUP_LIST_OPTION_REQUEST';
export const EDIT_USER_SETUP_LIST_OPTION_SUCCESS = 'EDIT_USER_SETUP_LIST_OPTION_SUCCESS';
export const EDIT_USER_SETUP_LIST_OPTION_FAIL = 'EDIT_USER_SETUP_LIST_OPTION_FAIL';

export const DELETE_USER_SETUP_LIST_OPTION_REQUEST = 'DELETE_USER_SETUP_LIST_OPTION_REQUEST';
export const DELETE_USER_SETUP_LIST_OPTION_SUCCESS = 'DELETE_USER_SETUP_LIST_OPTION_SUCCESS';
export const DELETE_USER_SETUP_LIST_OPTION_FAIL = 'DELETE_USER_SETUP_LIST_OPTION_FAIL';

export const SET_EDIT_OBJECTIVES = 'SET_EDIT_OBJECTIVES';

export const EMPTY_EDIT_OBJECTIVES = 'EMPTY_EDIT_OBJECTIVES';

export const GET_ADMIN_PROFILE_REQUEST = 'GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_FAIL = 'GET_ADMIN_PROFILE_FAIL';
export const EMPTY_PROFILE_DETAILS = 'EMPTY_PROFILE_DETAILS';

export const SET_PRODUCT_HIERARCHY_REQUEST = 'SET_PRODUCT_HIERARCHY_REQUEST';
export const SET_PRODUCT_HIERARCHY_SUCCESS = 'SET_PRODUCT_HIERARCHY_SUCCESS';
export const SET_PRODUCT_HIERARCHY_FAIL = 'SET_PRODUCT_HIERARCHY_FAIL';

export const GET_PRODUCT_ATTRIBUTES_REQUEST = 'GET_PRODUCT_ATTRIBUTES_REQUEST';
export const GET_PRODUCT_ATTRIBUTES_SUCCESS = 'GET_PRODUCT_ATTRIBUTES_SUCCESS';
export const GET_PRODUCT_ATTRIBUTES_FAIL = 'GET_PRODUCT_ATTRIBUTES_FAIL';

export const GET_LIST_CHILD_OPTIONS_REQUEST = 'GET_LIST_CHILD_OPTIONS_REQUEST';
export const GET_LIST_CHILD_OPTIONS_SUCCESS = 'GET_LIST_CHILD_OPTIONS_SUCCESS';
export const GET_LIST_CHILD_OPTIONS_FAIL = 'GET_LIST_CHILD_OPTIONS_FAIL';

export const ADD_NEW_PRODUCT_REQUEST = 'ADD_NEW_PRODUCT_REQUEST';
export const ADD_NEW_PRODUCT_SUCCESS = 'ADD_NEW_PRODUCT_SUCCESS';
export const ADD_NEW_PRODUCT_FAIL = 'ADD_NEW_PRODUCT_FAIL';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const GET_CUSTOMER_TYPES_LIST_REQUEST = 'GET_CUSTOMER_TYPES_LIST_REQUEST';
export const GET_CUSTOMER_TYPES_LIST_SUCCESS = 'GET_CUSTOMER_TYPES_LIST_SUCCESS';
export const GET_CUSTOMER_TYPES_LIST_FAIL = 'GET_CUSTOMER_TYPES_LIST_FAIL';

export const GET_CUSTOMERS_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST';
export const GET_CUSTOMERS_LIST_SUCCESS = 'GET_CUSTOMERS_LIST_SUCCESS';
export const GET_CUSTOMERS_LIST_FAIL = 'GET_CUSTOMERS_LIST_FAIL';

export const ADD_NEW_CUSTOMER_REQUEST = 'ADD_NEW_CUSTOMER_REQUEST';
export const ADD_NEW_CUSTOMER_SUCCESS = 'ADD_NEW_CUSTOMER_SUCCESS';
export const ADD_NEW_CUSTOMER_FAIL = 'ADD_NEW_CUSTOMER_FAIL';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAIL = 'EDIT_CUSTOMER_FAIL';

export const TOGGLE_CUSTOMER_STATUS_REQUEST = 'TOGGLE_CUSTOMER_STATUS_REQUEST';
export const TOGGLE_CUSTOMER_STATUS_SUCCESS = 'TOGGLE_CUSTOMER_STATUS_SUCCESS';
export const TOGGLE_CUSTOMER_STATUS_FAIL = 'TOGGLE_CUSTOMER_STATUS_FAIL';

export const GET_CUSTOMERS_DETAILS_REQUEST = 'GET_CUSTOMERS_DETAILS_REQUEST';
export const GET_CUSTOMERS_DETAILS_SUCCESS = 'GET_CUSTOMERS_DETAILS_SUCCESS';
export const GET_CUSTOMERS_DETAILS_FAIL = 'GET_CUSTOMERS_DETAILS_FAIL';

export const GET_CUSTOMER_BY_ID_REQUEST = 'GET_CUSTOMER_BY_ID_REQUEST';
export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';
export const GET_CUSTOMER_BY_ID_FAIL = 'GET_CUSTOMER_BY_ID_FAIL';

export const GET_CUSTOMER_TYPE_ID_REQUEST = 'GET_CUSTOMER_TYPE_ID_REQUEST';
export const GET_CUSTOMER_TYPE_ID_SUCCESS = 'GET_CUSTOMER_TYPE_ID_SUCCESS';
export const GET_CUSTOMER_TYPE_ID_FAIL = 'GET_CUSTOMER_TYPE_ID_FAIL';

export const GET_LIST_ATTRIBUTES_REQUEST = 'GET_LIST_ATTRIBUTES_REQUEST';
export const GET_LIST_ATTRIBUTES_SUCCESS = 'GET_LIST_ATTRIBUTES_SUCCESS';
export const GET_LIST_ATTRIBUTES_FAIL = 'GET_LIST_ATTRIBUTES_FAIL';

export const GET_PRODUCT_CATEGORIES_REQUEST = 'GET_PRODUCT_CATEGORIES_REQUEST';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAIL = 'GET_PRODUCT_CATEGORIES_FAIL';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL';


export const CREATE_KPI_REQUEST = 'CREATE_KPI_REQUEST';
export const CREATE_KPI_SUCCESS = 'CREATE_KPI_SUCCESS';
export const CREATE_KPI_FAIL = 'CREATE_KPI_FAIL';

export const GET_KPI_REQUEST = 'GET_KPI_REQUEST';
export const GET_KPI_SUCCESS = 'GET_KPI_SUCCESS';
export const GET_KPI_FAIL = 'GET_KPI_FAIL';

export const TOGGLE_KPI_REQUEST = 'TOGGLE_KPI_REQUEST';
export const TOGGLE_KPI_SUCCESS = 'TOGGLE_KPI_SUCCESS';
export const TOGGLE_KPI_FAIL = 'TOGGLE_KPI_FAIL';

// export const LOGIN_STATUS_TRUE = 'LOGIN_STATUS_TRUE';
// export const LOGIN_STATUS_FALSE = 'LOGIN_STATUS_FALSE';


import React, { useState } from 'react';

function Dropdown({ options }) {
  const [expanded, setExpanded] = useState(false);

  function toggleDropdown() {
    setExpanded(!expanded);
  }

  // Function to handle hiding dropdown
  function hideDropdown() {
    setExpanded(false);
  }

  return (
    <div className="multiselect">
      <div className="selectBox" onClick={toggleDropdown}>
        <select>
          <option>Select an option</option>
        </select>
        <div className="overSelect"></div>
      </div>
      {expanded && (
        <div className="dropdown" id='checkboxes' onClick={hideDropdown}>
          {options.map((option, index) => (
            <label key={index}>
              {option.label}
              <input type="radio" id={option.id} name="select" />
            </label>
          ))}
        </div>
      )}
      {/* Add a listener to handle clicks outside of the dropdown */}
      {expanded && (
        <div onClick={hideDropdown} className="overlay" />
      )}
    </div>
  );
}

// function MyComponent() {
//   const dropdownOptions = [
//     { id: 'one', label: 'First option' },
//     { id: 'two', label: 'Second option' },
//     { id: 'three', label: 'Third option' },
//     // Add more options as needed
//   ];

//   return (
//     <div>
//       {Array.from({ length: 12 }, (_, index) => (
//         <Dropdown key={index} options={dropdownOptions} />
//       ))}
//     </div>
//   );
// }

export default Dropdown;

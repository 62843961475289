import React, { useCallback, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDropzone } from "react-dropzone";
import { Constants } from "../../SuperAdmin/Credentials";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../shared/Loader/Loader";
import { editExistingProduct } from '../../../../Redux/actions';

const EditProductModal = ({ editableProduct, setEditProduct, productAttributes, productLinkList }) => {
  const dispatch = useDispatch();
  const Api = Constants.api;
  const { loading } = useSelector((state) => state.adminReducer);
  const loginObject = JSON.parse(sessionStorage.user);
  const [acceptedFile, setAcceptedFile] = useState([]);
  const [imageIndex, setImageIndex] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [fieldValues, setFieldValues] = useState();
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if(editableProduct !== null) {
      setAcceptedFile(editableProduct?.imageUrls);
      setImageUrls(editableProduct?.imageUrls)
    }
  }, [editableProduct]);

  const onDrop = useCallback((files) => {
    setImageIndex();
    setAcceptedFile((acceptedFile) => [...acceptedFile, files[0]]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  });

  const handleImageClick = (e, index, image) => {
    e.stopPropagation();
    setImageIndex(index);
    if(typeof image === 'string') {
      setSelectedImage(image);
    }
  };

  const handleImageDelete = (e) => {
    e.stopPropagation();
    setImageIndex();
    const filter = acceptedFile.filter((_, index) => index !== imageIndex);
    setAcceptedFile(filter);
    if(selectedImage !== '') {
      const filter = imageUrls.filter((image) => image !== selectedImage);
      setImageUrls(filter);
      setSelectedImage('');
    }
  };

  const validate = (values, productAttributes) => {
    const errors = {};
    if(!values['productCode']) {
      errors['productCode'] = '*Product Code is mandatory'
    }
    if(!values['productName']) {
      errors['productName'] = '*Product Name is mandatory'
    }
    if(!values['baseUnit']) {
      errors['baseUnit'] = '*Base Unit is mandatory'
    }
    if(!values['billingUnit']) {
      errors['billingUnit'] = '*Billing Unit is mandatory'
    }
    if(!values['description']) {
      errors['description'] = '*Description is mandatory'
    }
    if(!values['sku']) {
      errors['sku'] = '*SKU is mandatory'
    }
    if(!values['packSize']) {
      errors['packSize'] = '*Pack size is mandatory'
    }
    let price = productAttributes?.filter((product) => product?.name === 'Price');
    if(price[0]?.isMandatory) {
      if(!values['price']) {
        errors['price'] = '*Price is mandatory'
      }
    }
    // productAttributes.forEach(attribute => {
    //     if (attribute.isMandatory && !values[attribute.name]) {
    //       errors[attribute.name] = `*${attribute.name} is mandatory`;
    //     } else if (attribute.name === 'Email' && values[attribute.name]) {
    //       // Additional validation for email format
    //       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //       if (!emailRegex.test(values[attribute.name])) {
    //         errors[attribute.name] = 'Invalid email format';
    //       }
    //     }
    //   });
    return errors;
  };

  const getListChildOptions =
    (listOptionId, setFunction, token) => async (dispatch) => {
      await fetch(`${Constants.api}/admin/list/child/${listOptionId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then(async (responseData) => {
          if (responseData.success) {
            setFunction(responseData.response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getUrl = async (file) => {
      setUploading(true);
      //get uploadUrl to upload file
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await fetch(`${Api}/file/upload-url/${file.name}`, {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
            "Content-Type": "application/json",
          }),
        });
        const data = await response.json();
        if (data.success) {
          setImageUrls((imageUrls) => [
            ...imageUrls,
            data?.response?.documentUrl,
          ]);
          uploadFile(data.response.fileUploadUrl, file);
        } else {
          setUploading(false);
        }
      } catch (error) {
        setUploading(false);
        console.error("Error getting file url:", error);
      }
    };
  
    const uploadFile = async (fileUploadUrl, file) => {
      // upload file to generated URL
      try {
        const response = await fetch(fileUploadUrl, {
          method: "PUT",
          headers: new Headers({
          }),
          body: file,
        });
        if (response?.status === 200) {
          console.log("file upload res: " + response);
          setUploading(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploading(false);
      }
    };
  
    const handleEditProduct = (values) => {
      if (acceptedFile?.length > 0) {
        setFieldValues(values);
        console.log("product values:",values)
        for (let file of acceptedFile) {
          if(typeof file !== "string") {
          getUrl(file);
          }
        }
      } else {
        editProduct(values);
      }
    };

  const editProduct = (values) => {
    let editedProduct = {
      name: values?.productName,
      code: values?.productCode,
      baseUnit: values?.baseUnit,
      packSize: values?.packSize,
      billingUnit: values?.billingUnit,
      price: values?.price,
      description: values?.description,
      sku: values?.sku,
      pictureUrls: imageUrls
    }
    console.log('edited Product: ',editedProduct);
    dispatch(editExistingProduct(editableProduct?.id, editedProduct, setEditProduct, loginObject?.token));
  }

  useEffect(() => {
    if (acceptedFile?.length > 0) {
      if (
        acceptedFile?.length === imageUrls?.length &&
        fieldValues !== undefined
      ) {
        editProduct(fieldValues);
      }
    }
  }, [acceptedFile, imageUrls, fieldValues]);
  
  return (
    <>
    <div className="custom-attribute-modal-wrapper">
        <div className="add-user-modal">
          <div>
            <h4>Edit Product</h4>
            <Formik
            initialValues={{
                productCode: editableProduct?.productCode,
                productName: editableProduct?.productName,
                baseUnit: editableProduct?.baseUnit,
                billingUnit: editableProduct?.billingUnit,
                description: editableProduct?.description,
                sku: editableProduct?.sku,
                price: editableProduct?.price,
                packSize: editableProduct?.packSize,
            }}
            validateOnChange={true}
            validate={(values) => validate(values, productAttributes)}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("Values submitted:", values);
              handleEditProduct(values);
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, errors, values }) => (
              <>
                {/* {console.log('Vals out:',values)} */}
                {/* {console.log('errors:',errors)} */}
                <Form>
                  <div className="form-fields">
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"productCode"}
                        className="required-field"
                        >
                        Product Code
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="productCode"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="productCode"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    {/* <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"productName"}
                        className="required-field"
                        >
                        Product Name
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="productName"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="productName"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div> */}
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"productName"}
                        className="required-field"
                        >
                        Product Name
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="productName"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="productName"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"baseUnit"}
                        className="required-field"
                        >
                        Base Unit
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="baseUnit"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="baseUnit"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"billingUnit"}
                        className="required-field"
                        >
                        Billing Unit
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="billingUnit"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="billingUnit"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"description"}
                        className="required-field"
                        >
                        Description
                        </label>
                        <Field
                        as="textarea"
                        type="text"
                        className="form-control"
                        name="description"
                        autoComplete="off"
                        // style={{ height: "30px" }}
                        rows="2"
                        />
                        <ErrorMessage
                        name="description"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"sku"}
                        className="required-field"
                        >
                        SKU
                        </label>
                        <Field
                        type="text"
                        className="form-control"
                        name="sku"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="sku"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"packSize"}
                        className="required-field"
                        >
                        Pack Size
                        </label>
                        <Field
                        type="number"
                        className="form-control"
                        name="packSize"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="packSize"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div
                    className="form-group"
                    style={{ marginBottom: "10px", padding: "5px" }}
                    >
                        <label
                        htmlFor={"price"}
                        className={productAttributes?.filter((product) => product?.name === 'Price')[0]?.isMandatory ? 
                          "required-field" 
                          : ""}
                        >
                        Price
                        </label>
                        <Field
                        type="number"
                        className="form-control"
                        name="price"
                        autoComplete="off"
                        style={{ height: "30px" }}
                        />
                        <ErrorMessage
                        name="price"
                        component="div"
                        style={{ color: "red" }}
                        />
                    </div>
                    <div className="d-flex justify-content-between" style={{ paddingLeft: '5px' }}>
                      <label>Upload Photos</label>
                      <div {...getRootProps()}>
                        <button
                          style={{ backgroundColor: "inherit", border: "none" }}
                          type="button"
                          // onClick={handleAddClick}
                        >
                          <img
                            src={"/assets/add.png"}
                            alt=""
                            style={{
                              height: "17px",
                              objectFit: "cover",
                              margin: "0 5px 5px 0",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      {...getRootProps()}
                      style={{
                        height: acceptedFile?.length > 0 ? "auto" : "30%",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "space-between",
                        textAlign: "center",
                        // cursor: "pointer",
                        maxWidth: "95%",
                        overflowX: "auto",
                        marginLeft: "5px",
                        marginBottom: "5px",
                      }}
                      className="form-control"
                    >
                      <input {...getInputProps()} />
                      {acceptedFile?.length > 0 ? (
                        <>
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              gap: "10px",
                              height: "80%",
                              position: "relative",
                              width: "100%",
                              overflowX: "auto",
                              marginBottom: "5px",
                              padding: "5px",
                            }}
                          >
                            {acceptedFile?.map((image, index) => (
                              <>
                                <div
                                  onClick={(e) => handleImageClick(e, index, image)}
                                >
                                  <img
                                    className=""
                                    src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                                    alt="logo"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      border:
                                        index === imageIndex
                                          ? "2px solid #00a82d"
                                          : "",
                                    }}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                          <div
                            style={{ height: "20%" }}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              {imageIndex !== undefined &&
                                `Selected ${imageIndex + 1}/${
                                  acceptedFile?.length
                                }`}
                            </div>
                            <button
                              type="button"
                              className="next_button"
                              disabled={imageIndex !== undefined ? false : true}
                              style={{
                                width: "60px",
                                opacity:
                                  imageIndex !== undefined ? "1.0" : "0.6",
                              }}
                              onClick={(e) => handleImageDelete(e)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{ height: "100%" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <a
                            style={{
                              // textDecoration: "underline",
                              color: "#00a82d",
                            }}
                          >
                            Click on + button or Drag and Drop to upload photos
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                    <div
                    className="mr-3 float-left"
                    style={{ paddingTop: "2rem", height: "10%" }}
                    >
                    <button
                        type="submit"
                        // className="button"
                        style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                        }}
                        onClick={() => setEditProduct(false)}
                    >
                        Back
                    </button>
                    </div>
                    <div className="float-right" style={{ paddingTop: "2rem" }}>
                    <button
                      type="submit"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                    >
                      Submit
                    </button>
                    </div>
                </Form>
              </>
            )}
            </Formik>
          </div>
        </div>
    </div>
    {(uploading || loading) && <Loader />}
    </>
  )
}

export default EditProductModal

import React, { useEffect, useState } from 'react';
import { useDrag } from "react-dnd";

const AttributesLibrary = ({ attribute, isMandatory, mandatoryAttributes, attributesLibrary, onChange, nonMandatoryAttributes, func }) => {
    
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "attribute",
        item: { name: attribute },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            console.log('monitor:',dropResult);
            if(item.name.name === attribute.name && dropResult) {
                // alert(`You threw ${item.name.name} into ${dropResult.name}`);
                // let addedObj = {name: item.name.name, isMandatory: false, isEditable: true}
                if(dropResult.name === 'mandatory attributes') {
                    // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: true, inputType: item.name.inputType, owner: item.name.owner}
                    // mandatoryAttributes.push(addedObj);
                    mandatoryAttributes.push(item.name);
                } else if(dropResult.name === 'non mandatory attributes') {
                    // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: false, inputType: item.name.inputType, owner: item.name.owner}
                    // nonMandatoryAttributes.push(addedObj);
                    nonMandatoryAttributes.push(item.name);
                }
                const filtered = attributesLibrary.filter(cItem => {
                    return !mandatoryAttributes.some(aItem => aItem.name === cItem.name) && !nonMandatoryAttributes.some(bItem => bItem.name === cItem.name);
                  });
                onChange(filtered, "attributes library")
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }), [attribute, attributesLibrary, mandatoryAttributes, nonMandatoryAttributes]);

    const handleDeleteClick = (orgAttributeId) => {
        console.log('orgAttributeId:',orgAttributeId);
    }

    // const [{ canDrop, isOver }, drop] = useDrop(() => ({
    //     accept: "attribute",
    //     drop: () => ({ name: "attributes library" }),
    //     collect: (monitor) => ({
    //         isOver: !!monitor.isOver(),
    //         canDrop: !!monitor.canDrop(),
    //     })
    //   }), []);

  return (
    <>
        <div className='wrapper' ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
        <div className='logo'>
            <img src={"/assets/drag.png"} />
        </div>
        <div> 
        <input 
        // ref={drag}
        value={attribute.name} 
        // disabled={isMandatory ? true : false}
        disabled
        className="attribute"
        style={{ border: attribute.owner === "BLOOMR" ? "2px solid rgb(63, 228, 63)" : "", 
        boxShadow: !attribute.owner === "BLOOMR" ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "",
         cursor: "pointer" }}
        />
        </div>
        {attribute.owner !== "BLOOMR" && (
            <button 
            className='logo-delete'
            // onClick={() => handleDeleteClick(attribute.id)}
            onClick={() => func(attribute?.id, "attributesLibrary")}
            >
                <img src={"/assets/delete.png"} />
            </button>
        )}
        </div>
    </>
  )
}

export default AttributesLibrary
import React from 'react';
import './CreateSectionModal.css';

const CreateSectionModalCustomer = ({ setCreateSectionFlag, mandatoryAttributes, nonMandatoryAttributes, navigate, customer, count, customerTypeId }) => {

  return (
    <div className='create-section-modal-wrapper'>
        <div className='section-modal-popup'>
        <h3>Do you want to create sections?</h3>
        <div className='section-modal-buttons'>
        <button 
            className='next_button ml-3'
            onClick={() => {
                navigate(`/admin/create-sections-customer`, {
                  state: {
                    mandatoryAttributes: mandatoryAttributes,
                    nonMandatoryAttributes: nonMandatoryAttributes,
                    customer: customer,
                    count: count,
                    customerTypeId: customerTypeId
                  }
                });
                setCreateSectionFlag(false);
              }}
            >Yes</button>
        <button 
            className='next_button ml-3'
            onClick={() => {
                navigate(`/admin/attributes-list-review-customer`, {
                  state: {
                    mandatoryAttributes: mandatoryAttributes,
                    nonMandatoryAttributes: nonMandatoryAttributes,
                    customer: customer,
                    count: count,
                    customerTypeId: customerTypeId
                  }
                });
                setCreateSectionFlag(false);
              }}
            >No</button>
        </div>
        </div>
    </div>
  )
}

export default CreateSectionModalCustomer
import React, { useEffect, useState } from 'react';
import TopBar from '../../../../shared/Navigation/TopBar';
import { useDrop } from 'react-dnd';
import SearchIcon from "@mui/icons-material/Search";
import { attributesLibraryRequest, attributesRequest, createCustomAttribute, setParentChildConnection } from '../../../../Redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../shared/Loader/Loader';
import CustomerTypesLibrary from './CustomerTypesLibrary';
import CustomAttributeModal from '../../../../shared/CustomAttribute/CustomAttributeModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ConnectionsBox from '../../../../shared/ConnectionsBox/ConnectionsBox';
import { SET_CUSTOMER_TYPES, EMPTY_ATTRIBUTES } from '../../../../Redux/actions/actionTypes';

const ParentChildConnection = ({ sidebarCollapsed }) => {
    const loginObject = JSON.parse(sessionStorage.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { loading, attributes } = useSelector((state) => state.adminReducer); 
    const { orgId } = useSelector((state) => state.loginReducer); 

    const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([]);
    const [customerTypesLibrary, setCustomerTypesLibrary] = useState([]);
    const [customerTypesLibraryCopy, setCustomerTypesLibraryCopy] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [customAttributeModal, setCustomAttributeModal] = useState(false);
    const [parentChildModal, setParentChildModal] = useState(false);
    const [parentChildValue, setParentChildValue] = useState('');
    const [parentChildRelation, setParentChildRelation] = useState([]);
    const [dropdownOptions, setDropDownOptions] = useState([]);

    // useEffect(() => {
    //     if(state != null) {
    //         setSelectedCustomerTypes(state?.selectedCustomerTypes);
    //         // setCustomerTypesLibrary(state?.customerTypesLibrary);
    //     }        
    // }, [state, state?.selectedCustomerTypes, state?.customerTypesLibrary]);

    useEffect(() => {
      if(!loading && attributes?.length > 0) {
        setSelectedCustomerTypes(attributes);
      }
    }, [attributes, loading]);

    // useEffect(() => {
    //   if(state !== null && !loading && attributesLibraryList?.length > 0) {
    //     const filteredLibraryList = attributesLibraryList?.filter((attribute) => {
    //       return !state?.selectedCustomerTypes?.some(customer => customer.id === attribute.id);
    //     });
    //     setCustomerTypesLibrary(filteredLibraryList);
    //   }
    // }, [loading, attributesLibraryList, state])

    useEffect(() => {
      // dispatch(attributesLibraryRequest(orgId, "CUST_TYPE", loginObject.token));
      setTimeout(() => {
        dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token));
      }, [100]);
    }, []);

    useEffect(() => {
      // Save original customerTypesLibrary when it's first loaded
      if (customerTypesLibrary.length > 0 && customerTypesLibraryCopy.length === 0) {
        setCustomerTypesLibraryCopy(customerTypesLibrary);
      }
    }, [customerTypesLibrary, customerTypesLibraryCopy]);

    const [{ canDropCustomerType, isOverCustomerType }, dropCustomerTypes] = useDrop(() => ({
        accept: "attribute",
        drop: () => ({ name: "selected customer types" }),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        })
      }), []);

      const [{ canDropCustomerLibrary, isOverCustomerLibrary }, dropCustomerLibrary] = useDrop(() => ({
        accept: "attribute",
        drop: () => ({ name: "customer type library" }),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        })
      }), []);

      useEffect(() => {
        dispatch(attributesLibraryRequest(orgId, "CUST_TYPE", loginObject.token));
      }, []);

      const retrieve_attribute_id = (value1, value2) => {
        if(value2 === 'link') {
            setParentChildModal(true);
            setParentChildValue(value1);
            if(parentChildRelation?.length > 0) {
              let childId = null;
            for (let i = 0; i < parentChildRelation?.length; i++) {
              if (parentChildRelation[i]?.parentId === value1?.orgAttributeId) {
                childId = parentChildRelation[i]?.childId;
              }
            }
            const options = attributes?.filter(
              (attri) =>
                attri.orgAttributeId !== value1?.orgAttributeId &&
                attri?.orgAttributeId !== childId
            );
            setDropDownOptions(options);
          } else {
            const options = attributes?.filter(
              (attri) =>
                attri.orgAttributeId !== value1?.orgAttributeId);
            setDropDownOptions(options);
          }
        }
      }

      const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
      }
    
      useEffect(() => {
        if(searchTerm !== "") {
          let filter =  customerTypesLibrary?.filter((attribute) => {
            return attribute.name.toLowerCase().includes(searchTerm?.toLowerCase());
          });
          setCustomerTypesLibraryCopy(filter);
          // setCustomerTypesLibrary(filter);
        } else if(searchTerm === "") {
          setCustomerTypesLibraryCopy(customerTypesLibrary);
          // setCustomerTypesLibrary(customerTypesLibraryCopy);
        }
      }, [searchTerm, customerTypesLibrary, customerTypesLibraryCopy]);

      const handleAddClick = () => {
        setCustomAttributeModal(true);
      }

      const retrieve_custom_attribute = (value) => {
        // setCustomAttributeModal(false);
        dispatch(createCustomAttribute(orgId, "CUST_TYPE", value, loginObject?.token));
        // setCustomAttributeFlag(true);
      }

      const retrieve_parent_Child = (value) => {
        setParentChildModal(false);
        let obj = {
            parentId: parseInt(value?.split(",")[0]),
            parent: value?.split(",")[1],
            childId: parentChildValue.orgAttributeId,
            child: parentChildValue.name
        }
        const filter = parentChildRelation?.findIndex((parent) => parent.child === obj.child);
        // if(filter?.length === 0) {
        // setParentChildRelation(prevState => [...prevState, obj]);
        if(filter !== -1) {
        parentChildRelation.splice(filter, 1, obj);
        } else {
          setParentChildRelation(prevState => [...prevState, obj]);
        }
        // }
    }

    const handleSubmit = () => {
        const parentChildMapping = selectedCustomerTypes?.map((customer) => {
          const parentID = parentChildRelation?.filter((parent) => parent?.childId === customer?.orgAttributeId);
          return {
            attributeId: customer?.orgAttributeId,
            parentCustomerTypeOrgAttributeId: parentID[0]?.parentId !== undefined ? parentID[0]?.parentId : null
          }
        });
        dispatch({ type: EMPTY_ATTRIBUTES })
        dispatch(setParentChildConnection(orgId, 'CUST_TYPE', parentChildMapping, loginObject.token, true, navigate));
        dispatch({ type: SET_CUSTOMER_TYPES, payload: selectedCustomerTypes })
    }

  return (
    <>
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
    <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>Establish Parent Child Connection</p>
      <div className="d-flex flex-direction-row justify-content-center">
        
        <div className="usermaster_box bg-shadow" style={{ height: '70vh', padding: '20px 20px 10px 20px' }} ref={dropCustomerTypes}>
          <p className="module_head mb-2">Selected Customer Types</p>
          {selectedCustomerTypes?.map(attribute => 
            <CustomerTypesLibrary
            key={new Date().getTime() + Math.floor(Math.random * 10000)}
            attribute={attribute}
            customerTypesLibrary={customerTypesLibrary}
            setCustomerTypesLibrary={setCustomerTypesLibrary}
            customerTypesLibraryCopy={customerTypesLibraryCopy}
            setCustomerTypesLibraryCopy={setCustomerTypesLibraryCopy}
            selectedCustomerTypes={selectedCustomerTypes}
            setSelectedCustomerTypes={setSelectedCustomerTypes}
            func={retrieve_attribute_id}
            setSearchTerm={setSearchTerm}
            comesFrom={'parentChild'}
            />
          )}
        </div>

        {/* <div className="usermaster_box bg-shadow" style={{ height: '70vh', marginRight: "15%", padding: '20px 20px 10px 20px' }} ref={dropCustomerLibrary}>
          <p className="module_head mb-2">Customer Types Library</p>
          <div className="search-wrapper-icon">
            <div className="search-icon-attributes">
              <SearchIcon />
            </div>
            <input
              className="search-input form-control pl-5"
              id="search-library"
              placeholder="Search"
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearchTerm(e)}
            />
          </div>
          <div className="attributes-library">
            {customerTypesLibraryCopy?.map(attribute => 
              <CustomerTypesLibrary
              key={new Date().getTime() + Math.floor(Math.random * 10000)}
              attribute={attribute}
              customerTypesLibrary={customerTypesLibrary}
              setCustomerTypesLibrary={setCustomerTypesLibrary}
              customerTypesLibraryCopy={customerTypesLibraryCopy}
              setCustomerTypesLibraryCopy={setCustomerTypesLibraryCopy}
              selectedCustomerTypes={selectedCustomerTypes}
              setSelectedCustomerTypes={setSelectedCustomerTypes}
              func={retrieve_attribute_id}
              setSearchTerm={setSearchTerm}
               />
          )}
          </div>
          <div className="add-custom-attribute">
              <button
              className="custom-attribute1"
              onClick={handleAddClick}
              >
              Add Customer Type
              <img 
                src={'/assets/add.png'} 
                />
              </button>
          </div>
        </div> */}
      </div>
        {/* <div className='connections'> 
            <h4>Connections</h4>
            <div className='connections-container'>
                <div className='connections-container-green'>Child</div>
                <div className='connections-container-white bg-shadow'>Retailer</div>
            </div>
            <div className='connections-container'>
                <div className='connections-container-green parent-box'>Parent</div>
                <div className='connections-container-white bg-shadow parent-box'>Distributor</div>
            </div>
        </div> */}
        {parentChildRelation?.length > 0 && 
        <ConnectionsBox
        parentChildRelation={parentChildRelation}
        />}
      <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            navigate('/admin/customer-master-setup',  {
              state: {
                selectedCustomerTypes: selectedCustomerTypes,
                customerTypesLibrary: customerTypesLibrary
              }
            })
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 mb-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            handleSubmit();
             {!loading && navigate('/admin/customer-master-attributes', {
              // state: {
              //   selectedCustomerTypes: selectedCustomerTypes,
              //   customerTypesLibrary: customerTypesLibrary
              // }
            })}
          }}
        >
          Save & Create
        </button>
      </div>
    </div>
    {loading && <Loader />}
    {customAttributeModal && 
    <CustomAttributeModal 
    setCustomAttributeModal={setCustomAttributeModal} 
    func={retrieve_custom_attribute} 
    comesFrom={"customerMaster"}
    />}
    {parentChildModal && 
    <CustomAttributeModal 
    setCustomAttributeModal={setParentChildModal} 
    func={retrieve_parent_Child} 
    comesFrom={"customerParentChild"}
    parentChildValue={parentChildValue}
    dropdownOptions={dropdownOptions}
    />
    }
    </>
  )
}

export default ParentChildConnection
import React, { useEffect, useState } from 'react';
import { EMPTY_GEOGRAPHIC_NODES } from '../../Redux/actions/actionTypes';

const AddNodeModal = ({ 
  setAddNodeModal, 
  initialNodes,
  func,
  dispatch
 }) => {
  const [nodeName, setNodeName] = useState('');
  const [parent, setParent] = useState('');
  const [emptyName, setemptyName] = useState(false);
  const [emptyType, setEmptyType] = useState(false);

  // Helper function to filter out duplicate objects based on 'id'
  const filterDuplicates = (arr) => {
    const uniqueIds = new Set();
    return arr.filter(item => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
  };

  // Filter out duplicates
  const filteredNodes = filterDuplicates(initialNodes);
  console.log('filteredNodes in add modal:',filteredNodes);

  const handleChange = (e) => {
    if(e.target.name === 'nodeName') {
        setNodeName(e.target.value);
      setemptyName(false);
    }
    if(e.target.name === 'parent') {
      setParent(e.target.value);
      setEmptyType(false);
    }
  }

  const handleSave = () => {
    if (filteredNodes?.length > 0 && nodeName === "" && parent === "") {
      console.log('C1')
      setemptyName(true);
      setEmptyType(true);
    } else if(nodeName === "") {
      console.log('C2')
      setemptyName(true);
    } else if (filteredNodes?.length > 0 && parent === "") {
      setEmptyType(true);
    } else {
    const newNode = {
      node: nodeName,
      parent: filteredNodes?.length === 0 ? null : parent
    }
    dispatch({ type: EMPTY_GEOGRAPHIC_NODES })
    func(newNode);
    setAddNodeModal(false)
  }
  }

  return (
    <div className='custom-attribute-modal-wrapper'>
        <div className='custom-modal-popup' style={{ height: emptyType ? '45%' : '40%' }}>
            <h3>{`Add New Node`}</h3>
            <div className='input-group'>
                <input 
                placeholder="Name"
                name="nodeName" 
                style={{ marginBottom: emptyName ? "0" : "20px" }} 
                onChange={(e) => handleChange(e)} 
                />
                {emptyName && <p style={{ color: 'red', fontSize: '5px', marginTop: "5px", marginBottom: '5px' }}>*Required</p>}
                <br/>
                {filteredNodes?.length > 0 ? (
                <select
                  className="input-group-select"
                  name="parent"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="default" hidden>
                    <span style={{ fontWeight: '100'}}>{`Parent`}</span>
                  </option>
                  {filteredNodes?.map((node) => (
                    <option value={node.id}>{node.data.label}</option>
                  ))}
                </select>) : (
                    <div>This node will be added as the Parent node.</div>
                )}
                {emptyType && <p style={{ color: 'red', fontSize: '5px', marginTop: "5px", marginBottom: '5px' }}>*Required</p>}
            </div>
            <div className='mt-3 float-right'>
                <button
                 className="cancel_button"
                 onClick={() => setAddNodeModal(false)}
                 >
                  Cancel
                 </button>
                <button 
                className='next_button ml-3'
                onClick={handleSave}
                >Save</button>
            </div>
        </div>
    </div>
  )
}

export default AddNodeModal
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// Components
import LoginWithMobileNumber from "../components/authPages/LoginWithMobileNumber";
import VerifyOtp from "../components/authPages/VerifyOtp";
import LoginWithEmail from "../components/authPages/LoginWithEmail";
import HomePage from "../components/mainPages/SuperAdmin/HomePage";
import Dashboard from "../components/mainPages/SuperAdmin/Dashboard";
import Settings from "../components/mainPages/SuperAdmin/Settings";
import PromoCode from "../components/mainPages/SuperAdmin/PromoCode";
import ForgotPassword from "../components/authPages/ForgotPassword";
import OrganizationProfile from "../components/mainPages/SuperAdmin/OrganizationProfile";
import Reminder from "../components/mainPages/SuperAdmin/Reminder";
import CreateOrg from "../components/mainPages/SuperAdmin/CreateOrg";
import EditOrganization from "../components/mainPages/SuperAdmin/EditOrganization";
import { useAuth } from "../shared/Context/AuthContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrganizationSetup from "../components/mainPages/Admin/OrganizationSetup";
import ModuleSelection from "../components/mainPages/Admin/ModuleSelection";
import UserMaster from "../components/mainPages/Admin/UserMasterSetup/UserMaster";
import AttributesListReview from "../components/mainPages/Admin/UserMasterSetup/AttributesListReview";
import CreateSections from "../components/mainPages/Admin/UserMasterSetup/CreateSections";
import SectionsReview from "../components/mainPages/Admin/UserMasterSetup/SectionsReview";
import CustomerMaster from "../components/mainPages/Admin/CustomerMasterSetup/CustomerMaster";
import ParentChildConnection from "../components/mainPages/Admin/CustomerMasterSetup/ParentChildConnection";
import ViewSections from "../components/mainPages/Admin/UserMasterSetup/ViewSections";
import CustomerMasterParent from "../components/mainPages/Admin/CustomerMasterSetup/CustomerMasterParent";
import CustomerMasterNew from "../components/mainPages/Admin/CustomerMasterSetup/CustomerMasterNew";
import CustomerAttributes from "../components/mainPages/Admin/CustomerMasterSetup/MasterSetup/CustomerAttributes";
import AttributesListReviewCustomer from "../components/mainPages/Admin/CustomerMasterSetup/MasterSetup/AttributesListReview";
import CreateSectionsCustomer from "../components/mainPages/Admin/CustomerMasterSetup/MasterSetup/CreateSections";
import SectionsReviewCustomer from "../components/mainPages/Admin/CustomerMasterSetup/MasterSetup/SectionsReview";
import Users from "../components/mainPages/Admin/Users/Users";
import OrganizationStructure from "../components/mainPages/Admin/GeographyMasterSetup/OrganizationStructure";
import OrganizationStructureNew from "../components/mainPages/Admin/GeographyMasterSetup/OrganizationStructureNew";
import ViewSectionsCustomer from "../components/mainPages/Admin/CustomerMasterSetup/MasterSetup/ViewSections"; 
import FunctionalitySetupHome from "../components/mainPages/Admin/FunctionalitySetup/FunctionalitySetupHome";
import ObjectiveAchievements from "../components/mainPages/Admin/FunctionalitySetup/VisitsSetup/ObjectiveAchievements";
import Tasks from "../components/mainPages/Admin/FunctionalitySetup/VisitsSetup/Tasks";
import ParentChildEdit from "../components/mainPages/Admin/FunctionalitySetup/CustomerSetup/ParentChildEdit";
import UserSetup from "../components/mainPages/Admin/FunctionalitySetup/UserSetup/UserSetup";
import CustomerCodeSetup from "../components/mainPages/Admin/FunctionalitySetup/CustomerSetup/CustomerCodeSetUp";
import ProspectStatus from "../components/mainPages/Admin/FunctionalitySetup/CustomerSetup/ProspectStatus";
import ProductMaster from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster";
import Profile from "../components/mainPages/Admin/Profile/Profile";
import EditProfile from "../components/mainPages/Admin/Profile/EditProfile";
import ProductMasterAttributes from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster/UserMaster";
import ViewSectionsProduct from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster/ViewSections1";
import SectionsReviewProduct from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster/SectionsReview";
import CreateSectionsProduct from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster/CreateSections1";
import AttributesListReviewProduct from "../components/mainPages/Admin/ProductMasterSetup/ProductMaster/AttributesListReview";
import Products from "../components/mainPages/Admin/Products/Products";
import ProductSetup from "../components/mainPages/Admin/FunctionalitySetup/ProductSetup/ProductSetup";
import Customers from "../components/mainPages/Admin/Customers/Customers";
import SetDelivery from "../components/mainPages/Admin/FunctionalitySetup/ProductSetup/SetDelivery";
import { toast } from "react-toastify";
import SchemesAndPromos from "../components/mainPages/Admin/FunctionalitySetup/ProductSetup/SchemesaAndPromos";
import FocusedProducts from "../components/mainPages/Admin/FunctionalitySetup/ProductSetup/FocusedProducts";
import KpiSetup from "../components/mainPages/Admin/KpiSetup/KpiSetup";
import KpiDetails from "../components/mainPages/Admin/KpiSetup/KpiDetails";

function AppRoutes({ sidebarCollapsed }) {
  // const user = sessionStorage.getItem('user');
  // const loginObject = user ? JSON.parse(user) : null;
  const { updateToken } = useAuth();
  const { token } = useAuth();
  const { role } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (token && role === "SUPER_ADMIN") {
  //     navigate("/home");
  //   } else if (token && role === "ORG_ADMIN") {
  //     navigate("admin/organization-setup");
  //   } else if (role === '') {
  //     console.log('3rd condition')
  //     updateToken("");
  //     navigate("/");
  //   }
  // }, [token, role]);

  // useEffect(() => {
  //   if(!token) {
  //     navigate("/");
  //   }
  // }, [token, location.pathname])

  useEffect(() => {
    if (token && role === "SUPER_ADMIN") {
      navigate("/home");
    } else if (token && role === "ORG_ADMIN" || role === "ORG_USER") {// temporary fix, change later
      if(role === "ORG_ADMIN"){
      navigate("admin/organization-setup");
      }
      else{
        navigate("admin/customers");
      }
    }


    //  else if (token && role === "ORG_USER") {
    //   console.log('3rd condition')
    //   toast.error("Action Not Allowed");
    // }
  }, [token, role]);

  useEffect(() => {
    if(!token) {
      navigate("/");
    }
  }, [token, location.pathname]);

  return (
    <Routes>
      {!token ? (
        <>
          <Route
            path="/"
            element={token ? <Navigate to="/home" /> : <LoginWithEmail />}
          />
          <Route
            path="/loginwithmobilenumber"
            element={<LoginWithMobileNumber />}
          />
          <Route path="/verifyotp" element={<VerifyOtp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
        </>
      ) : token && role === "SUPER_ADMIN" ? (
        <>
          <Route
            path="/home"
            element={<HomePage sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/createorganization"
            element={<CreateOrg sidebarCollapsed={sidebarCollapsed} />}
          />
          {/* <Route path="/package" element={<CreateOrganization sidebarCollapsed={sidebarCollapsed} />} /> */}
          <Route
            path="/promocode"
            element={<PromoCode sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/settings"
            element={<Settings sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/reminder"
            element={<Reminder sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/editorganization/:id"
            element={<EditOrganization sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/organizationprofile/:id"
            element={
              <OrganizationProfile sidebarCollapsed={sidebarCollapsed} />
            }
          />
        </>
      ) : token && role === "ORG_ADMIN" || role === "ORG_USER" ? ( //temporary fix, change later
        <>
          <Route
            path="/admin/organization-setup"
            element={<OrganizationSetup sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/module-selection"
            element={<ModuleSelection sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/user-master-setup"
            element={<UserMaster sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/attributes-list-review"
            element={<AttributesListReview sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/create-sections"
            element={<CreateSections sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/review-sections"
            element={<SectionsReview sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/customer-master-setup"
            element={<CustomerMasterNew sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/customer-master-parent-child-connection"
            element={<ParentChildConnection sidebarCollapsed={sidebarCollapsed} />}
          />
           <Route
            path="/admin/view-created-sections"
            element={<ViewSections sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/view-created-sections-customer"
            element={<ViewSectionsCustomer sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/customer-master-attributes"
            element={<CustomerAttributes sidebarCollapsed={sidebarCollapsed} />}
          />
           <Route
            path="/admin/attributes-list-review-customer"
            element={<AttributesListReviewCustomer sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/create-sections-customer"
            element={<CreateSectionsCustomer sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/review-sections-customer"
            element={<SectionsReviewCustomer sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/users"
            element={<Users sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/organization-structure"
            element={<OrganizationStructureNew sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup"
            element={<FunctionalitySetupHome sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/objective-and-achievements"
            element={<ObjectiveAchievements sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/add-tasks"
            element={<Tasks sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/customer-setup/customer-code-setup"
            element={<CustomerCodeSetup sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/customer-setup/prospect-status"
            element={<ProspectStatus sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/customer-setup/edit-parent-child-connection"
            element={<ParentChildEdit sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/user-functionality/setup/:id"
            element={<UserSetup sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/product-master-setup"
            element={<ProductMaster sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/company-profile"
            element={<Profile sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/edit-company-profile"
            element={<EditProfile sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/product-master-setup-attributes"
            element={<ProductMasterAttributes sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/view-created-sections-product"
            element={<ViewSectionsProduct sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/review-sections-product"
            element={<SectionsReviewProduct sidebarCollapsed={sidebarCollapsed} />}
          />
           <Route
            path="/admin/create-sections-product"
            element={<CreateSectionsProduct sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/attributes-list-review-product"
            element={<AttributesListReviewProduct sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/products"
            element={<Products sidebarCollapsed={sidebarCollapsed} />}
          />
            <Route
            path="/admin/kpi-setup"
            element={<KpiSetup sidebarCollapsed={sidebarCollapsed} />}
          />
            <Route
            path="/admin/kpi-details"
            element={<KpiDetails sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/product-functionality/setup/:id"
            element={<ProductSetup sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/customers"
            element={<Customers sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/product-functionality/set-delivery"
            element={<SetDelivery sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/product-functionality/schemes-and-promos"
            element={<SchemesAndPromos sidebarCollapsed={sidebarCollapsed} />}
          />
          <Route
            path="/admin/functionality-setup/product-functionality/focused-products"
            element={<FocusedProducts sidebarCollapsed={sidebarCollapsed} />}
          />
        </>
      ) : (
        <Route
            path="/"
            element={<LoginWithEmail />}
          />
      )}
    </Routes>
  );
}

export default AppRoutes;

import React, { useState } from 'react';
import TopBar from '../../../../shared/Navigation/TopBar';
import { useLocation } from 'react-router-dom';
import Package from '../../SuperAdmin/Package';
import CompanyDetails from './CompanyDetails';
import { Formik, Form, Field } from 'formik';

const EditProfile = ({ sidebarCollapsed }) => {
    const { state } = useLocation();
    const [activeTab, setActiveTab] = useState(state?.tab);

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

  return (
    <>
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
      <div className="create-organization" style={{ height: '110%' }}>
        <p className="heading pt-4 pl-4">Company Profile</p>
        <nav className="d-flex justify-content-center my-4">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-companydetails" && "active"
              } ${activeTab === "nav-packagedetails" ? "disabled-tab" : ""}`}
              id="nav-companydetails-tab"
              data-toggle="tab"
              href="#nav-companydetails"
              role="tab"
              aria-controls="nav-companydetails"
              aria-selected="true"
              style={{ opacity : activeTab === "nav-companydetails" && "active" ? '1' : '0.7' }}
              onClick={(e) => {
                if(activeTab === "nav-packagedetails") {
                  e.preventDefault();
                } else {
                handleTabChange("nav-companydetails")
                }
              }}
            >
              Company Details
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-packagedetails" && "active"
              } ${activeTab === "nav-companydetails" ? "disabled-tab" : ""}`}
              id="nav-packagedetails-tab"
              data-toggle="tab"
              href="#nav-packagedetails"
              role="tab"
              aria-controls="nav-packagedetails"
              aria-selected="false"
              style={{ opacity : activeTab === "nav-packagedetails" && "active" ? '1' : '0.7' }}
              onClick={(e) => {
                if(activeTab === "nav-companydetails") {
                  e.preventDefault();
                } else {
                handleTabChange("nav-packagedetails")
                }
              }}
            >
              Package Details
            </a>
          </div>
        </nav>
        
        <div className='profile-tab-content' id="nav-tabContent" style={{ padding: activeTab === "nav-packagedetails" ? '10px' : '' }}>
          {activeTab === "nav-companydetails" ? <div
            // className={`tab-pane fade ${
            //   activeTab === "nav-companydetails" && "show active"
            // }`}
            id="nav-companydetails"
            role="tabpanel"
            aria-labelledby="nav-companydetails-tab"
            style={{ height: '100%' }}
          >
            <CompanyDetails profile={state.profile} />
          </div> :
          <div
            // className={`tab-pane fade ${
            //   activeTab === "nav-companydetails" && "show active"
            // }`}
            id="nav-packagedetails"
            role="tabpanel"
            aria-labelledby="nav-packagedetails-tab"
            style={{ height: '100%' }}
          >
            <Package
              editMode={true}
              organizationDetails={state.profile}
              // handleUpdateButtonClick={handleUpdateButtonClick}
            />
          </div>}
        </div>
      </div>
    </div>
    </>
  )
}

export default EditProfile
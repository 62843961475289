import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { addNewUser, clearUserDetails, editUserById, getUserById, setEditUserId } from "../../../../Redux/actions";
import Loader from "../../../../shared/Loader/Loader";
import { Constants } from "../../SuperAdmin/Credentials";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const AddUserModal = ({
  setAddUserModal,
  attributes,
  isEdit,
  setIsEdit,
  users,
  editUser
}) => {
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const { loading, userDetails, } = useSelector((state) => state.adminReducer);
  const [pincodeLoader, setpincodeLoader] = useState(false);
  const [businesstypes, setBusinessTypes] = useState([]);
  const [businessSegments, setBusinessSegments] = useState([]);
  const [edituserdetails, setEditUserDetails] = useState(userDetails);
  const [initialValues, setInitValues] = useState({})
  const dispatch = useDispatch();

  console.log("edituserdetails", edituserdetails);
  console.log("userDetails", userDetails);

  console.log("edit id:",editUser?.id);
  useEffect(() => {
    if (isEdit && editUser?.id) {
      dispatch(getUserById(loginObject?.token, editUser?.id));
    } else {
      setEditUserDetails(null);
      setInitValues({});
    }
  }, [editUser, isEdit, dispatch, loginObject?.token]);

  useEffect(() => {
    if (isEdit && userDetails) {
      setEditUserDetails(userDetails);
      setInitValues(getInitialValues(userDetails));
    }
  }, [isEdit, userDetails]);

  const handleModalClick = (e) => {
    // Prevent click propagation to children
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    // Close modal when clicking outside of it
    setAddUserModal(false);
  };

  // useEffect(() => {
  //     if(attributes?.length > 0) {
  //         const object = {};
  //         attributes?.map((attribute) => (
  //             object.push({ attribute.name : ''})
  //         ))
  //     }
  // }, [attributes]);
  const mapValueToOptionId = (userDetail, options) => {
    const option = options.find(opt => opt.id === userDetail?.valueId ); // Adjust as needed
    console.log("found option",option)
    return option ? option.id : "";  // Default to empty string if not found
  };

  const getInitialValues = (userDetails = null) => {
    console.log("Getting initial values for userDetails:", userDetails);
    const initialValues = {};
    attributes.forEach((attribute) => {
      const userDetail = userDetails?.find(
        (detail) => detail.orgAttributeId === attribute.orgAttributeId
      );
      console.log(`Processing attribute: ${attribute.name}, userDetail:`, userDetail);
      
      if (isEdit && userDetail) {
        if (attribute.inputType === "LIST" || attribute.inputType === "GEO_NODE") {
          const mappedValue = mapValueToOptionId(userDetail, attribute.options);
          initialValues[attribute.name] = mappedValue?.toString() || "";
        } else if (attribute.inputType === "ADDRESS") {
          const addressValue = userDetail.addressValue || {};
          initialValues[`line1${attribute.orgAttributeId}`] = addressValue.line1 || "";
          initialValues[`line2${attribute.orgAttributeId}`] = addressValue.line2 || "";
          initialValues[`city${attribute.orgAttributeId}`] = addressValue.city || "";
          initialValues[`state${attribute.orgAttributeId}`] = addressValue.state || "";
          initialValues[`country${attribute.orgAttributeId}`] = addressValue.country || "";
          initialValues[`pincode${attribute.orgAttributeId}`] = addressValue.pincode || "";
        } else if (attribute.inputType === "CONTACT") {
          const contactValue = userDetail.contactValue || {};
          initialValues[`firstName${attribute.orgAttributeId}`] = contactValue.firstName || "";
          initialValues[`lastName${attribute.orgAttributeId}`] = contactValue.lastName || "";
          initialValues[`phone${attribute.orgAttributeId}`] = contactValue.phone || "";
          initialValues[`email${attribute.orgAttributeId}`] = contactValue.email || "";
          initialValues[`designation${attribute.orgAttributeId}`] = contactValue.designation || "";
        } else {
          initialValues[attribute.name] = userDetail.value || "";
        }
      } else {
        initialValues[attribute.name] = "";
        if (attribute.inputType === "ADDRESS") {
          initialValues[`line1${attribute.orgAttributeId}`] = "";
          initialValues[`line2${attribute.orgAttributeId}`] = "";
          initialValues[`city${attribute.orgAttributeId}`] = "";
          initialValues[`state${attribute.orgAttributeId}`] = "";
          initialValues[`country${attribute.orgAttributeId}`] = "";
          initialValues[`pincode${attribute.orgAttributeId}`] = "";
        } else if (attribute.inputType === "CONTACT") {
          initialValues[`firstName${attribute.orgAttributeId}`] = "";
          initialValues[`lastName${attribute.orgAttributeId}`] = "";
          initialValues[`phone${attribute.orgAttributeId}`] = "";
          initialValues[`email${attribute.orgAttributeId}`] = "";
          initialValues[`designation${attribute.orgAttributeId}`] = "";
        }
      }
    });
    return initialValues;
  };

  const validate = (values, attributes) => {
    const errors = {};
    attributes.forEach((attribute) => {
      if (
        attribute?.name !== "Manager" &&
        attribute.isMandatory &&
        !values[attribute.name]
      ) {
        errors[attribute.name] = `*${attribute.name} is mandatory`;
      }
      if (attribute.inputType === "ADDRESS") {
        // if (!values["line1"]) {
        //   errors["line1"] = `*Address Line 1 is mandatory`;
        // }
        // // if (!values["line2"]) {
        // //   errors["line2"] = `*Address Line 2 is mandatory`;
        // // }
        // if (!values["city"]) {
        //   errors["city"] = `*City is mandatory`;
        // }
        // if (!values["state"]) {
        //   errors["state"] = `*State is mandatory`;
        // }
        // if (!values["country"]) {
        //   errors["country"] = `*Country is mandatory`;
        // }
        // if (!values["pincode"]) {
        //   errors["pincode"] = `*Pincode is mandatory`;
        // }
        if (!values[`line1${attribute?.orgAttributeId}`]) {
          errors[
            `line1${attribute?.orgAttributeId}`
          ] = `*Address Line 1 is mandatory`;
        }
        // if (!values["line2"]) {
        //   errors["line2"] = `*Address Line 2 is mandatory`;
        // }
        if (!values[`city${attribute?.orgAttributeId}`]) {
          errors[`city${attribute?.orgAttributeId}`] = `*City is mandatory`;
        }
        if (!values[`state${attribute?.orgAttributeId}`]) {
          errors[`state${attribute?.orgAttributeId}`] = `*State is mandatory`;
        }
        if (!values[`country${attribute?.orgAttributeId}`]) {
          errors[
            `country${attribute?.orgAttributeId}`
          ] = `*Country is mandatory`;
        }
        if (!values[`pincode${attribute?.orgAttributeId}`]) {
          errors[
            `pincode${attribute?.orgAttributeId}`
          ] = `*Pincode is mandatory`;
        }
      }
      if (attribute?.isMandatory && attribute.inputType === "CONTACT") {
        if (!values[`firstName${attribute?.orgAttributeId}`]) {
          errors[
            `firstName${attribute?.orgAttributeId}`
          ] = `*First Name is mandatory`;
        }
        if (!values[`lastName${attribute?.orgAttributeId}`]) {
          errors[
            `lastName${attribute?.orgAttributeId}`
          ] = `*Last Name is mandatory`;
        }
        if (!values[`phone${attribute?.orgAttributeId}`]) {
          errors[
            `phone${attribute?.orgAttributeId}`
          ] = `*Phone Number is mandatory`;
        }
        if (!values[`email${attribute?.orgAttributeId}`]) {
          errors[`email${attribute?.orgAttributeId}`] = `*Email is mandatory`;
        }
        if (!values[`designation${attribute?.orgAttributeId}`]) {
          errors[
            `designation${attribute?.orgAttributeId}`
          ] = `*Designation is mandatory`;
        }
      }
    });
    //Email Validation
    // attributes.forEach(attribute => {
    //     if (attribute.isMandatory && !values[attribute.name]) {
    //       errors[attribute.name] = `*${attribute.name} is mandatory`;
    //     } else if (attribute.name === 'Email' && values[attribute.name]) {
    //       // Additional validation for email format
    //       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //       if (!emailRegex.test(values[attribute.name])) {
    //         errors[attribute.name] = 'Invalid email format';
    //       }
    //     }
    //   });
    return errors;
  };

  const handleAddUser = (values) => {
    console.log("Values:", values);
    const data = [];
    attributes?.map((attribute) => {
      const obj = {
        orgAttributeId: attribute?.orgAttributeId,
        [attribute?.inputType !== "ADDRESS" &&
        attribute?.inputType !== "CONTACT"
          ? "value"
          : attribute?.inputType === "ADDRESS"
          ? "addressValue"
          : "contactValue"]:
          attribute?.inputType !== "ADDRESS" &&
          attribute?.inputType !== "CONTACT"
            ? values[attribute.name] !== ""
              ? values[attribute.name]
              : null
            : attribute?.inputType === "ADDRESS"
            ? {
                // line1: values["line1"],
                // line2: values["line2"],
                // city: values["city"],
                // state: values["state"],
                // country: values["country"],
                // pincode: values["pincode"],
                line1: values[`line1${attribute?.orgAttributeId}`],
                line2: values[`line2${attribute?.orgAttributeId}`],
                city: values[`city${attribute?.orgAttributeId}`],
                state: values[`state${attribute?.orgAttributeId}`],
                country: values[`country${attribute?.orgAttributeId}`],
                pincode: values[`pincode${attribute?.orgAttributeId}`],
              }
            : {
                firstName: values[`firstName${attribute?.orgAttributeId}`],
                lastName: values[`lastName${attribute?.orgAttributeId}`],
                phone: values[`phone${attribute?.orgAttributeId}`]?.toString(),
                email: values[`email${attribute?.orgAttributeId}`],
                designation: values[`designation${attribute?.orgAttributeId}`],
              },
      };
      data.push(obj);
    });
    const finalData = data?.filter((d) => d.value !== null);
    console.log("Filtered data:", finalData);
    console.log("isEdit:",isEdit);
    isEdit ?
    dispatch(editUserById(finalData,setIsEdit, loginObject?.token,editUser?.id)) :
    dispatch(addNewUser(finalData, setAddUserModal, loginObject?.token));
  };

  const getLocation = async (pincode, setFieldValue, orgAttributeId) => {
    if (pincode?.length === 6) {
      setpincodeLoader(true);
      const resp = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
        {
          method: "GET",
          headers: new Headers({}),
        }
      ).catch((error) => console.log(error));
      const respJson = await resp.json();
      // Update form fields with fetched data
      if (respJson && respJson.length > 0) {
        setpincodeLoader(false);
        const { State, Country, PostOffice } = respJson[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, Country, Region } = PostOffice[0];
          // formik.setFieldValue("communicationAddress.country", Country);
          // formik.setFieldValue("communicationAddress.state", State);
          // formik.setFieldValue("communicationAddress.city", Region);
          setFieldValue(`country${orgAttributeId}`, Country);
          setFieldValue(`state${orgAttributeId}`, State);
          setFieldValue(`city${orgAttributeId}`, Region);
          // formik.setFieldValue("billingAddress.country", Country);
          // formik.setFieldValue("billingAddress.state", State);
          // formik.setFieldValue("billingAddress.city", Name);
        }
      }
    }
  };

  useEffect(() => {
    const getDropdowns = async () => {
      const resp = await fetch(`${Api}/data/business_type/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const responseData = await resp.json();
      if (responseData.success) {
        setBusinessTypes(responseData.response);
      } else {
        console.log("failed to fetch business types");
      }

      const res = await fetch(`${Api}/data/business_segment/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const respJson = await res.json();
      if (respJson.success) {
        setBusinessSegments(respJson.response);
      } else {
        console.log("failed to fetch business segments");
      }
    };
    getDropdowns();

    // if (editMode) {
    //   formik.setValues({
    //     ...formik.values,
    //     name: organizationDetails.name || "",
    //     businessTypeId: organizationDetails.businessTypeId || "",
    //     channelPartner: organizationDetails.channelPartner || "",
    //     logoUrl: organizationDetails.logoUrl || "",
    //     businessSegmentId: organizationDetails.businessSegmentId || "",
    //     gst: organizationDetails.gst || "",
    //     mobileCode: organizationDetails.mobileCode || "+91",
    //     mobileNumber: organizationDetails.mobileNumber || "",
    //     accountManager: organizationDetails.accountManager || ""
    //   });
    // }
  }, []);

  return (
    <>
      <div className="custom-attribute-modal-wrapper">
        <div className="add-user-modal">
          <div>
            <h4>{isEdit ? "Edit User" : "Add New User"}</h4>
          </div>
          {/* <div className='add-user-details'> */}
          <Formik
            initialValues={initialValues}
            validateOnChange={true}
            enableReinitialize={true}
            validate={(values) => validate(values, attributes)}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Values submitted:", values);
              handleAddUser(values);
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, setFieldValue, touched, errors, values }) => (
              <>
                {console.log("Values:", values)}
                {/* {console.log('errors:',errors)} */}
                <Form>
                  <div className="form-fields">
                    {attributes?.map((attribute, index) => (
                      <>
                        <div
                          key={index}
                          className="form-group"
                          style={{ marginBottom: "10px", padding: "5px" }}
                        >
                          <label
                            htmlFor={attribute?.name}
                            className={
                              attribute?.inputType !== "ADDRESS" &&
                              attribute?.inputType !== "CONTACT" &&
                              attribute?.isMandatory
                                ? "required-field"
                                : ""
                            }
                          >
                            {attribute?.inputType !== "ADDRESS" &&
                              attribute?.inputType !== "CONTACT" &&
                              attribute?.name}
                          </label>
                          {attribute?.inputType !== "ADDRESS" &&
                          attribute?.inputType !== "LIST" &&
                          attribute?.inputType !== "GEO_NODE" &&
                          attribute?.inputType !== "CONTACT" &&
                          attribute?.inputType !== "BUSINESS_SEG" &&
                          attribute?.inputType !== "BUSINESS_TYPE" ? (
                            <>
                              <Field
                                type="text"
                                className="form-control"
                                name={attribute?.name}
                                autoComplete="off"
                                style={{ height: "30px" }}
                              />
                              <ErrorMessage
                                name={attribute.name}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </>
                          ) : attribute?.inputType === "ADDRESS" ? (
                            <>
                              <label className="required-field">
                                {attribute?.name}
                              </label>
                              <br />
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`line1${attribute?.orgAttributeId}`}
                                  className="required-field"
                                >{`Address Line 1`}</label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="line1"
                                  name={`line1${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              {/* <ErrorMessage name={"line1"} component="div" style={{ color: 'red' }} /> */}
                              <ErrorMessage
                                name={`line1${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`line2${attribute?.orgAttributeId}`}
                                >
                                  {"Address Line 2"}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="line2"
                                  name={`line2${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`pincode${attribute?.orgAttributeId}`}
                                  className="required-field"
                                >
                                  {"Pincode"}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="pincode"
                                  name={`pincode${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                  onChange={(e) => {
                                    const inputValue = e?.target?.value;
                                    // Check if the input value has more than 6 digits, if yes, trim it to 6 digits
                                    const trimmedValue = inputValue.slice(0, 6);
                                    getLocation(
                                      trimmedValue,
                                      setFieldValue,
                                      attribute?.orgAttributeId
                                    );
                                    setFieldValue(
                                      `pincode${attribute?.orgAttributeId}`,
                                      trimmedValue
                                    );
                                    // getLocation(e?.target?.value, setFieldValue)
                                    // setFieldValue(`communicationAddress.pincode`, e?.target?.value);
                                  }}
                                />
                                <ErrorMessage
                                  name={`pincode${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`city${attribute?.orgAttributeId}`}
                                  className="required-field"
                                >
                                  {"City"}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="city"
                                  name={`city${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                                <ErrorMessage
                                  name={`city${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`state${attribute?.orgAttributeId}`}
                                  className="required-field"
                                >
                                  {"State"}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="state"
                                  name={`state${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                                <ErrorMessage
                                  name={`state${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </div>
                              <div
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`country${attribute?.orgAttributeId}`}
                                  className="required-field"
                                >
                                  {"Country"}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  // name="country"
                                  name={`country${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                                <ErrorMessage
                                  name={`country${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </>
                          ) : attribute?.inputType === "CONTACT" ? (
                            <>
                              <label
                                className={
                                  attribute?.isMandatory ? "required-field" : ""
                                }
                              >
                                {attribute?.name}
                              </label>
                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`firstName${attribute?.orgAttributeId}`}
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {`First Name`}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`firstName${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <ErrorMessage
                                name={`firstName${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />

                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`lastName${attribute?.orgAttributeId}`}
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {`Last Name`}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`lastName${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <ErrorMessage
                                name={`lastName${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />

                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`phone${attribute?.orgAttributeId}`}
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {`Phone Number`}
                                </label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  name={`phone${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <ErrorMessage
                                name={`phone${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />

                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`email${attribute?.orgAttributeId}`}
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {`Email`}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`email${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <ErrorMessage
                                name={`email${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />

                              <div
                                className="form-group"
                                style={{ marginBottom: "10px", padding: "5px" }}
                              >
                                <label
                                  htmlFor={`designation${attribute?.orgAttributeId}`}
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {`Designation`}
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`designation${attribute?.orgAttributeId}`}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                />
                              </div>
                              <ErrorMessage
                                name={`designation${attribute?.orgAttributeId}`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </>
                          ) : attribute?.inputType === "BUSINESS_SEG" ? (
                            <>
                              <select
                                className="input-group-select"
                                name={attribute?.name}
                                onChange={(e) =>
                                  setFieldValue(
                                    `${attribute?.name}`,
                                    e?.target?.value
                                  )
                                }
                              >
                                <option
                                  value=""
                                  defaultValue=""
                                >{`Select ${attribute?.name}`}</option>
                                {businessSegments?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                ))}
                              </select>
                              <ErrorMessage
                                name={attribute.name}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </>
                          ) : attribute?.inputType === "BUSINESS_TYPE" ? (
                            <>
                              <select
                                className="input-group-select"
                                name={attribute?.name}
                                onChange={(e) =>
                                  setFieldValue(
                                    `${attribute?.name}`,
                                    e?.target?.value
                                  )
                                }
                              >
                                <option
                                  value=""
                                  defaultValue=""
                                >{`Select ${attribute?.name}`}</option>
                                {businesstypes?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                ))}
                              </select>
                              <ErrorMessage
                                name={attribute.name}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </>
                          ) : (
                            <>
                              {(attribute?.inputType === "LIST" ||
                                attribute?.inputType === "GEO_NODE") && (
                                <>
                                  <select
                                    className="input-group-select"
                                    name={attribute?.name}
                                    value={values[attribute.name]}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `${attribute?.name}`,
                                        e?.target?.value
                                      )
                                    }
                                  >
                                    <option
                                      value=""
                                      defaultValue=""
                                      hidden
                                    >{`Select ${attribute?.name}`}</option>
                                    {attribute?.options?.map((opt) => (
                                      <option value={opt?.id}>
                                        {opt?.name}
                                      </option>
                                    ))}
                                    {/* {attribute.name === "Manager" ?
                    (<>
                      {users?.result?.length === 0 ?
                      (<>
                        {attribute?.options?.map((opt) => (
                          <option value={opt?.id}>{opt?.name}</option>
                        ))}
                      </>) : 
                      (<>
                      {users?.result?.map((user) => (
                        <option value={user?.id}>{user?.firstName + " " + user?.lastName}</option>
                      ))}
                      <option value='0'>NA</option>
                      </>)}
                    </>) : (<>
                    {attribute?.options?.map((opt) => (
                    <option value={opt?.id}>{opt?.name}</option>
                    ))}
                    </>)} */}
                                  </select>
                                  <ErrorMessage
                                    name={attribute.name}
                                    component="div"
                                    style={{ color: "red" }}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                  <div
                    className="mr-3 float-left"
                    style={{ paddingTop: "2rem" }}
                  >
                    <button
                      type="button"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setAddUserModal(false);
                        setIsEdit(false);
                        // dispatch(clearUserDetails());
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="float-right" style={{ paddingTop: "2rem" }}>
                    <button
                      type="submit"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
      {/*</div> */}
      {(loading || pincodeLoader) && <Loader />}
    </>
  );
};

export default AddUserModal;

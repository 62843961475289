import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

export default function OrderEmailModal() {
  const [isOpen, setIsOpen] = useState(true);
  const [timeSlots, setTimeSlots] = useState([
    { id: 1, time: '10:30 am', enabled: true },
    { id: 2, time: '2:00 pm', enabled: true },
    { id: 3, time: '4:45 am', enabled: false }
  ]);
  const [newTimeSlot, setNewTimeSlot] = useState('10:30 am');
  const [emailOption, setEmailOption] = useState('timeSlot');

  const handleAddTimeSlot = () => {
    const newId = timeSlots.length + 1;
    setTimeSlots([...timeSlots, { id: newId, time: newTimeSlot, enabled: false }]);
  };

  const toggleTimeSlot = (id) => {
    setTimeSlots(timeSlots.map(slot =>
      slot.id === id ? { ...slot, enabled: !slot.enabled } : slot
    ));
  };

  const deleteTimeSlot = (id) => {
    setTimeSlots(timeSlots.filter(slot => slot.id !== id));
  };

  if (!isOpen) return null;

  return (
    <div className="custom-attribute-modal-wrapper">
      <div className="order-email-modal">
        <div className="modal-header">
          <p className='order_email_heading'>Order Email</p>
          <button className="close-button" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="modal-content">
          <div className='w-50 mt-4'>
          <div className="radio-group">
            <label className='light_shadow py-3 px-1 m-2'>
              <input
                type="radio"
                value="everyOrder"
                checked={emailOption === 'everyOrder'}
                onChange={(e) => setEmailOption(e.target.value)}
              />
              Send Email on every order
            </label>
          </div>
          <div className='light_shadow p-1 m-2'>
            <label>
              <input
                type="radio"
                value="timeSlot"
                checked={emailOption === 'timeSlot'}
                onChange={(e) => setEmailOption(e.target.value)}
              />
              Enable Time Slot Email
            </label>
            <p>Define Time Slot</p>
              <div className="add-time-slot">
                <div className="time-input-wrapper">
                  <input
                    type="text"
                    value={newTimeSlot}
                    onChange={(e) => setNewTimeSlot(e.target.value)}
                  />
                      <img
                        src={"/assets/clock.png"}
                        className="clock-icon"
                      />
                </div>
                <button onClick={handleAddTimeSlot} className="add-button">Add</button>
              </div>
              </div>
              </div>
          {emailOption === 'timeSlot' && (
            <div className="time-slot-section w-50 px-3">
              <div className="time-slots-list">
                <p className='order_email_heading'>Created Time slots</p>
                {timeSlots.map((slot) => (
                  <div key={slot.id} className="time-slot-item light_shadow">
                    <p className="slot-number">Time slot {slot.id}</p>
                    <div className="slot-controls">
                      <p className="slot-time">{slot.time}</p>
                      <button
                        onClick={() => toggleTimeSlot(slot.id)}
                        className={`toggle-button ${slot.enabled ? 'enabled' : ''}`}
                      >
                        ✓
                      </button>
                      <button
                        onClick={() => deleteTimeSlot(slot.id)}
                        className="delete-button"
                      >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import TopBar from '../../../../../shared/Navigation/TopBar';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DisabledBlock from './DisabledBlock';
import EditIcon from '@mui/icons-material/Edit';

const SetDelivery = ({ sidebarCollapsed }) => {
    const navigate = useNavigate();
    const [deliveryDateEnable, setDeliveryDateEnable] = useState(false);
    console.log('deliveryDateEnable:',deliveryDateEnable);
  return (
    <>
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px", position: 'relative' }}
    >
      <TopBar />
      <button
        className="d-flex align-items-center"
        style={{ backgroundColor: "#fff", border: "none" }}
        onClick={() => navigate("/admin/functionality-setup")}
      >
        <KeyboardBackspaceIcon />
        <p>Back</p>
      </button>
      <p className="heading pt-1 pl-4 upper-index">Delivery Date Settings</p>
      {/* <div className={`delivery-container ${!deliveryDateEnable ? "blur-body" : ''}`}> */}
      <div className={`delivery-container`}>
        <div className="delivery-product-table bg-shadow lower-index">
          <table className='w-100'>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Delivery Estimate</th>
                <th>Delivery Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Arc Motot Oil</td>
                <td>Yes</td>
                <td>4:00 Hrs</td>
                  <td> <button style={{
                          padding: 0,
                          margin: 0,
                          border: "none",
                          backgroundColor: "#fff",
                        }} className='light_shadow edit_icon'>
                     <img
                          src={"/assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        /> </button> </td>
              </tr>

              <tr>
                <td>Active Shield Oil</td>
                <td>Yes</td>
                <td>4:00 Hrs</td>
                  <td> <button
                  style={{
                    padding: 0,
                    margin: 0,
                    border: "none",
                    backgroundColor: "#fff",
                  }}
                  className='edit_icon light_shadow'>
                     <img
                          src={"/assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        /> </button> </td>
              </tr>
              <tr>
                <td>Gold Super Shield</td>
                <td>Yes</td>
                <td>4:00 Hrs</td>
                  <td> <button
                  style={{
                    padding: 0,
                    margin: 0,
                    border: "none",
                    backgroundColor: "#fff",
                  }}
                   className='edit_icon light_shadow'>
                     <img
                          src={"/assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        /> </button> </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='enable-delivery-date lower-index'>
            {deliveryDateEnable && <div className='enable-delivery-date-heading upper-index'>
                <label htmlFor='enable-radio' className="heading delivery-heading">Enable Delivery Date</label>
                <input 
                id='enable-radio' 
                type='radio' 
                className='custom-radio' 
                checked={deliveryDateEnable} 
                // onChange={() => setDeliveryDateEnable(!deliveryDateEnable)}
                />
            </div>}
        </div>
      </div>
    </div>
    {!deliveryDateEnable && <DisabledBlock sidebarCollapsed={sidebarCollapsed}  setDeliveryDateEnable={setDeliveryDateEnable} />}
    </>
  )
}

export default SetDelivery

import React from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const DisabledBlock = ({ sidebarCollapsed, setDeliveryDateEnable }) => {
    const navigate = useNavigate();
    const handleClick = (e) => {
        e.stopPropagation();
    }
  return (
    <>
    <div
      className={`main blur-body ${sidebarCollapsed ? "shrinked-blur" : ""}`}
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <button
        className="d-flex align-items-center"
        style={{ backgroundColor: "inherit", border: "none" }}
        onClick={() => navigate("/admin/functionality-setup")}
      >
        <KeyboardBackspaceIcon />
        <p>Back</p>
      </button>
      <p className="heading pt-1 pl-4 upper-index">Delivery Date Settings</p>
      {/* <div className={`delivery-container ${!deliveryDateEnable ? "blur-body" : ''}`}> */}
      <div className={`delivery-container`}>
        <div className="delivery-product-table" style={{ width: '44%' }}>
        </div>
        <div className='enable-delivery-date lower-index'>
            <div className='enable-delivery-date-heading upper-index'>
                <label htmlFor='enable-radio1' className="heading delivery-heading" style={{ cursor: 'pointer' }}>Enable Delivery Date</label>
                <input 
                id='enable-radio1' 
                type='radio' 
                className='custom-radio' 
                checked={false} 
                onChange={() => setDeliveryDateEnable(true)}
                style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default DisabledBlock

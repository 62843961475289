import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Constants } from "./Credentials";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Loader from "../../../shared/Loader/Loader";

const PurchaseHistory = ({ organizationDetails }) => {
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [fileTypes, setFileTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const loginObject = JSON.parse(sessionStorage.user);
  const Api = Constants.api;
  // const orgId = organizationDetails?.id;

  const onDrop = useCallback((files) => {
    setAcceptedFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const initialValues = {
    documentTypeId: "",
    documentUrl: "",
  };
  const validationSchema = Yup.object().shape({
    documentTypeId: Yup.string().required("*Required"),
    // documentUrl: Yup.string().required("*Required"),
  });
  useEffect(() => {
    getData();
  }, [organizationDetails, loading]);

  const getData = async () => {
    if (organizationDetails && organizationDetails.id) {
      const resp = await fetch(`${Api}/data/document_type/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const responseData = await resp.json();
      if (responseData.success) {
        setFileTypes(responseData.response);
      } else {
        console.log("failed to fetch business types");
      }

      const getFiles = await fetch(
        `${Api}/org/${organizationDetails?.id}/document/list`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
          }),
        }
      );
      const getFilesResponse = await getFiles.json();
      // console.log('files respones;',getFilesResponse)
      if (getFilesResponse?.success) {
        setFiles(getFilesResponse?.response);
      } else {
        console.log("failed to fetch files");
      }
    }
  };

  //   const getFiles = await fetch(`${Api}/org/${organizationDetails?.id}/document/list`, {
  //     method: "GET",
  //     headers: new Headers({
  //       Authorization: `Bearer ${loginObject.token}`,
  //     }),
  //   });
  //   const getFilesResponse = await getFiles.json();
  //   // console.log('files respones;',getFilesResponse)
  //   if (getFilesResponse?.success) {
  //     setFiles(getFilesResponse?.response);

  //   } else {
  //     console.log("failed to fetch files");
  //   }
  // }

  async function handleSubmit(values) {
    if (acceptedFile) {
      await getUrl(values);
    }
    addDocument(values);
  }

  const getUrl = async (values) => {
    //get uploadUrl to upload file
    const formData = new FormData();
    formData.append("file", acceptedFile);
    try {
      const response = await fetch(
        `${Api}/file/upload-url/${acceptedFile.name}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
            "Content-Type": "application/json",
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        // setLogoUrl(data.response.fileUploadUrl); // Set the obtained URL in
        values.documentUrl = data.response.documentUrl;
        uploadFile(data.response.fileUploadUrl);
      }
    } catch (error) {
      console.error("Error getting file url:", error);
    }
  };

  const uploadFile = async (fileUploadUrl) => {
    // upload file to generated URL
    try {
      const response = await fetch(fileUploadUrl, {
        method: "PUT",
        headers: new Headers({}),
        body: acceptedFile,
      });
      // console.log(response);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  function addDocument(values) {
    setLoading(true);
    console.log("purchase history details;", values);
    fetch(`${Api}/org/${organizationDetails?.id}/document/add`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        setLoading(false);
        if (responseData.success) {
          getData();
          setTimeout(() => {
            alert(responseData?.message);
          }, 100);
        }
        console.log("update basic details response:", responseData);
        // console.log("api:", Api);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const downloadFile = async (documentUrl, fileName) => {
    try {
      const response = await fetch(documentUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginObject.token}`,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // console.log("file type:", acceptedFile);
  return (
    <div className="container">
      <div className="d-flex flex-direction-row">
        <div className="purchase-left light_shadow">
          {typeof files !== "undefined" && files.length > 0 ? (
            <table className="w-100 purchase_history_table">
              <thead>
                <tr>
                  <td>File Uploaded</td>
                  <td>Upload Date</td>
                  <td className="">File Type</td>
                  <td className="d-flex justify-content-center">Actions</td>
                </tr>
              </thead>
              <tbody>
                {files
                  // ?.sort((a, b) => a.id - b.id)
                  ?.map((file) => (
                    <tr>
                      <td>{file.name}</td>
                      <td>{file.uploadedDate}</td>
                      <td>{file.documentType}</td>
                      <td className="d-flex justify-content-center">
                        <button
                          style={{ border: "none", backgroundColor: "#fff" }}
                          onClick={() =>
                            downloadFile(file.documentUrl, file.name)
                          }
                        >
                          <img
                            style={{ width: "20px" }}
                            src={"/assets/download.png"}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ): <div className="d-flex align-items-center justify-content-center h-100 "> <p>No files uploaded till now</p></div>}
        </div>
        <div className="purchase-right light_shadow">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={(values) => {
              console.log("Values:", values);
              handleSubmit(values);
            }}
          >
            {({ handleSubmit, touched, errors }) => (
              <form className="" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Select File Type</label>

                  <Field
                    as="select"
                    className="form-control"
                    id="documentTypeId"
                    name="documentTypeId"
                  >
                    <option
                      value="default"
                      // disabled
                      selected
                      hidden
                      style={{ fontSize: "16px !important" }}
                    >
                      ----
                    </option>
                    {!fileTypes.length !== 0 &&
                      fileTypes?.map((type) => {
                        return (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </Field>
                  {touched.documentTypeId && errors.documentTypeId && (
                    <span className="error-msg">{errors.documentTypeId}</span>
                  )}
                </div>
                <div className="form-group">
                  {/* <label>Logo</label> */}
                  {acceptedFile ? (
                    <div className="d-flex justify-content-center">
                      {acceptedFile.type === "image/*" ? (
                        <img
                          src={URL.createObjectURL(acceptedFile)}
                          alt="logo"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      ) : (
                        <p>{acceptedFile.name}</p>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        {...getRootProps()}
                        style={{
                          height: "140px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        className="form-control"
                      >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop the file here ...</p>
                        ) : (
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#00a82d",
                            }}
                          >
                            Drag and Drop logo of Organization <br /> or Click
                            here to select
                          </a>
                        )}
                      </div>
                      <p className="small-text mt-2">
                        * File size should be less than 10 mb
                      </p>
                    </>
                  )}
                </div>
                <button
                  style={{
                    width: "100px",
                    backgroundColor: "#00a82d",
                    border: "none",
                    borderRadius: "5px",
                    color: "#ffffff",
                    height: "30px",
                    float: "right",
                  }}
                  type="submit"
                >
                  Add
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};
export default PurchaseHistory;

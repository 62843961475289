import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; //date picker styles
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useFormik, Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SubPackage from "./SubPackage";
import { Constants } from "./Credentials";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Package = ({ onPackageChange, editMode,
  organizationDetails,
  handleUpdateButtonClick,
  orgName, name, navigateToBackStep }) => {
  const navigate = useNavigate();
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTo, setValidTo] = useState(new Date());
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const orgId = organizationDetails?.id;
  const [flatDiscountFlag, setFlagdiscountFlag] = useState(false);
  const [percentDiscountFlag, setPercentDiscountFlag] = useState(false);
  const [validToFlag, setValidToFlag] = useState(false);

  const { role } = useSelector((state) => state.loginReducer);
  console.log('role:',role);

  // console.log("organizationDetails:",organizationDetails);

  const initialValues = {
      organizationName: name, //auto populate from previous tab
      packageName: "BLOOMR",
      numberOfLicenses: 0,
      pricePerLicense: 0,
      packagePrice: 0,
      discountType: "PERCENTAGE",
      discountValue: 0,
      finalPrice: 0,
      currency: "INR",
      // validFrom: moment(new Date).format('DD/MM/YYYY'),
      // validTo: moment(new Date).format('DD/MM/YYYY'),
      validFrom: "",
      validTo: "",
      billingCycle: "MONTHLY",
      discountValueType: 'PER_LICENSE'
  };

  const validationSchema = Yup.object().shape({
      numberOfLicenses: Yup.number().required("Number of Licenses is required")
      .min(1, "Value must be greater than 0"),
      pricePerLicense: Yup.number().required("Price Per License is required")
      .min(1, "Value must be greater than 0"),
      // mobile: Yup.string().required("Mobile is required"),
      // designation: Yup.string().required("Designation is required"),
      // email: Yup.string().required("Email is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
  });
  // console.log('license:',organizationDetails?.license)
  const handleSubmit = async (values) => {
    console.log('valid from before formatting:',validFrom.toISOString().split('T')[0]);
    values.validFrom = moment(validFrom).format("YYYY-MM-DD");
    values.validTo = moment(validTo).format("YYYY-MM-DD");
    values.pricePerLicense = parseInt(values.pricePerLicense);
    values.discountValue = parseInt(values.discountValue);
    values.numberOfLicenses = parseInt(values.numberOfLicenses);
    console.log("package values:", values);

    editMode ? updatePackageDetails(values) : onPackageChange(values);
    // !editMode && navigate("/home");
  };
  
  function updatePackageDetails(values) {
  values.packageId = 1
  console.log('values before updating:',values);
  fetch(
    `${Api}/org/${orgId}/update/package`,
    {
     method: "POST",
     headers: new Headers({
       Authorization: `Bearer ${loginObject.token}`,
       "Content-Type": "application/json",
     }),
     body: JSON.stringify(values),
    }
  ) 
  .then((response) => response.json())
  .then(async (responseData) => {
    if (responseData.success){
      // handleUpdateButtonClick();
      // alert('Package details updated')
      toast.success('Package details updated');
      if(role === 'ORG_ADMIN') {
        navigate('/admin/company-profile');
      }
    }
    console.log("update package details response:", responseData);
   // console.log("api:", Api);
  })
  .catch((error) => console.log(error));
}

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnChange={true}
    onSubmit={(values) => {
      if(!flatDiscountFlag && !percentDiscountFlag && !validToFlag) {
      {console.log("Values in final submit:",values)}
      handleSubmit(values);
      }
    }}
    >
    {({ handleSubmit, touched, errors, values, setValues, setFieldValue }) => (
    <form className="row" onSubmit={handleSubmit}>
      {/* {console.log("Values in Form:",values)} */}
      {/* {console.log("Errors in Form:",errors)}
      {console.log("touched in Form:",touched)} */}
      <SubPackage
      name={name} 
      setFieldValue={setFieldValue}
      values={values}
      errors={errors}
      touched={touched}
      validFrom={validFrom}
      setValidFrom={setValidFrom}
      validTo={validTo}
      setValidTo={setValidTo}
      navigate={navigate}
      flatDiscountFlag={flatDiscountFlag}
      setFlagdiscountFlag={setFlagdiscountFlag}
      percentDiscountFlag={percentDiscountFlag}
      setPercentDiscountFlag={setPercentDiscountFlag}
      validToFlag={validToFlag}
      setValidToFlag={setValidToFlag}
      organizationDetails={organizationDetails}
      editMode={editMode}
      setValues={setValues}
      />
      {/* <div className="package-one col-4">
        <div className="form-group">
          <label htmlFor="organizationName">Organization Name*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="organizationName"
            onChange={(e) => {
              setFieldValue(`organizationName`, e.target.value)
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="packageName">Package Name*</label>
          <Field
            as="select"
            className="form-control dropdown"
            id="package-name-dropdown"
            name="packageName"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={values?.packageName}
          >
            <option value='BLOOMR' className="package-name-option">
              Bloomr
            </option>
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="numberOfLicenses">Number of Licenses*</label>
          <Field
            type="number"
            className="form-control"
            autoComplete="off"
            name="numberOfLicenses"
            onChange={(e) => {
              setFieldValue(`numberOfLicenses`, e.target.value);
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={values?.numberOfLicenses}
          />
          {touched.numberOfLicenses && errors.numberOfLicenses && (
                <span className="error-msg">{errors.numberOfLicenses}</span>
              )}
        </div>
        <div className="form-group">
          <label htmlFor="pricePerLicense">Price Per License*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="pricePerLicense"
            onChange={(e) => {
              setFieldValue(`pricePerLicense`, e.target.value);
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={values?.pricePerLicense}
          />
          {touched.pricePerLicense && errors.pricePerLicense && (
                <span className="error-msg">{errors.pricePerLicense}</span>
              )}
        </div>
      </div>

      <div className="package-two col-4 ">
        <div className="form-group">
          <label htmlFor="packagePrice">Package Price*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="packagePrice"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.packagePrice}
            value={values?.pricePerLicense * values?.numberOfLicenses}
          />
        </div>
        <div className="form-group">
          <label htmlFor="discountType">Discount Type</label>
          <Field
            as="select"
            className="form-control dropdown"
            id="discountType"
            name="discountType"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.discountType}
          >
            <option value='PERCENTAGE'>Percentage</option>
            <option value='FLAT'>Value</option>
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="discountValue">Discount Value</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="discountValue"
            onChange={(e) => {
              setFieldValue(`discountValue`, e.target.value);
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.discountValue}
          />
          {values?.discountType == 'FLAT' ? 
          (values?.discountValue > values?.packagePrice && (
            <span className="error-msg">{"Discount value cannot be more than Package price"}</span>
          )) : ''}
        </div>
        <div className="form-group">
          <label>Final Price*</label>
          <input
            type="text"
            className="form-control"
            autoComplete="off"
            name="finalPrice"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.finalPrice}
          />
        </div>
      </div>
      <div className="package-three col-4">
        <div className="form-group">
          <label>Currency*</label>
          <select
            className="form-control dropdown"
            id="currency"
            name="currency"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currency}
          >
            <option value='INR'>INR</option>
            <option value='USD'>USD</option>
            <option value='AUD'>AUD</option>
            <option value='EUR'>EUR</option>
          </select>
        </div>

        <div className="form-group">
          <label>Valid From*</label>
          <div className="datepicker-input">
            <div className="datepicker-icon">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className=" form-control "
              selected={validFrom}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setValidFrom(date)}
              name="validFrom"
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.package.validFrom}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Valid To*</label>
          <div className="datepicker-input">
            <div className="datepicker-icon">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className="form-control"
              selected={validTo}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setValidTo(date)}
              name="validTo"
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.package.validTo}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Billing Cycle*</label>
          <select
            className="dropdown form-control"
            name="billingCycle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // value={formik.values.billingCycle}
          >
            <option value='MONTHLY'>Monthly</option>
            <option value='QUARTERLY'>Quarterly</option>
            <option value='HALF-YEARLY'>Half-Yearly</option>
            <option value='YEARLY'>Yearly</option>
          </select>
        </div>
      </div> */}
      {role === 'ORG_ADMIN' ? 
      (<>
        <div className="ml-auto mr-3 mt-5 float-right">
          <button
            style={{
              backgroundColor: "#00a82d",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              width: "90px",
              height: "32px",
              marginRight: "20px",
              marginTop: "10px",
            }}
            onClick={() => navigate('/admin/company-profile')}
          >
            Back
          </button>
          <button
            style={{
              backgroundColor: "#00a82d",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              width: "90px",
              height: "32px",
              marginRight: "20px",
              marginTop: "10px",
            }}
            type="submit"
          //   onClick={() => handleUpdateButtonClick()}
          >
            Update
          </button>
        </div>
      </>) : editMode ? (
        <div className="ml-auto mr-4">
                   <button
                   style={{
                     backgroundColor: "#00a82d",
                     color: "#fff",
                     border: "none",
                     borderRadius: "7px",
                     width: "90px",
                     height: "32px",
                     marginRight: "20px",
                     marginTop: "10px",
                   }}
                   onClick={() => navigate("/reminder")}
                 >
                   Reminder
                 </button>

                 <button
                    style={{
                      backgroundColor: "#00a82d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      width: "90px",
                      height: "32px",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                    type="button"
                    onClick={() => handleSubmit(formik.values)}
                  >
                    Save
                  </button>
                  <button
                    style={{
                      backgroundColor: "#00a82d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      width: "90px",
                      height: "32px",
                      marginTop: "10px",
                    }}
                    type="button"
                    onClick={() => handleUpdateButtonClick()}
                  >
                    Next
                  </button>
              {/* <button
                style={{
                  backgroundColor: "#00a82d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  width: "90px",
                  height: "32px",
                  // float: "right",
                  marginLeft:'auto',
                  marginRight: "20px",
                  marginTop: "10px",
                }}
                type="button"
                onClick={() => handleSubmit(formik.values)}
              >
                Update
              </button> */}
              </div>
            ) : (
              <>
      <div className="mt-3 ml-3 float-left">
        <button
          className="back_button"
          type="button"
          onClick={() => navigateToBackStep(4)}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 ml-auto">
        <button
          className="cancel_button"
          type="button"
          onClick={() => navigate("/home")}
        >
          Cancel
        </button>
        <button
          className="next_button ml-3"
          type="submit"
          // onClick={() => handleSubmit(formik.values)}
        >
          Create
        </button>
      </div>
      </>)}
    </form>
    )}
    </Formik>
  );
};
export default Package;

import React, { useEffect, useState, useRef } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import {
  setProductHierarchy,
  attributesLibraryRequest,
  productCategoryRequest,
  editProductHierarchy
} from "../../../../Redux/actions/userLoginAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../shared/Loader/Loader";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import QuickGuide from "../../../../shared/QuickGuide/QuickGuide";

const ProductMaster = ({ sidebarCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginObject = JSON.parse(sessionStorage.user);
  const { orgId } = useSelector((state) => state.loginReducer);
  const { loading, productCategory,setupStatus } = useSelector(
    (state) => state.adminReducer
  );
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [level3, setLevel3] = useState("");
  const [level1Flag, setLevel1Flag] = useState(false);
  const [level2Flag, setLevel2Flag] = useState(false);
  const [level3Flag, setLevel3Flag] = useState(false);
  const [level1Disable, setLevel1Disable] = useState(false);
  const [level2Disable, setLevel2Disable] = useState(false);
  const [level3Disable, setLevel3Disable] = useState(false);
  const [level1AttributeId, setLevel1AttributeId] = useState('');
  const [level2AttributeId, setLevel2AttributeId] = useState('');
  const [level3AttributeId, setLevel3AttributeId] = useState('');
  const [attributesArray, setAttributesArray] = useState([]);
  let quicktipFlagUser = localStorage.getItem("quickFlagGuideUser");
  const [quickFlagGuideUser, setQuickFlagGuideUser] = useState();

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);

  // console.log('productCategory:',productCategory);

  useEffect(() => {
    if(!loading) {
      if(setupStatus.userMasterSetup) {
        setQuickFlagGuideUser(false);
      } else if(quicktipFlagUser !== null && quicktipFlagUser) {
        setQuickFlagGuideUser(false);
      } else {
        setQuickFlagGuideUser(true);
      }
    }
  }, [setupStatus, loading, quicktipFlagUser]);

  useEffect(() => {
    if(quickFlagGuideUser != undefined && !quickFlagGuideUser) {
      localStorage.setItem("quickFlagGuideUser", true);
    }
  }, [quickFlagGuideUser]);

  const handleSubmit = () => {
    if (level1 === "") {
      setLevel1Flag(true);
    } else if (level2 === "") {
      setLevel2Flag(true);
    } else if (level3 === "") {
      setLevel3Flag(true);
    } else {
      const levels = {
        level1: level1,
        level2: level2,
        level3: level3,
      };
      dispatch(setProductHierarchy(levels, navigate, loginObject?.token));
    }
  };

  const handleEditClick = (index) => {
    if(index === 1 && level1 === "") {
      setLevel1Flag(true);
    } else if (index === 2 && level2 === "") {
      setLevel2Flag(true);
    } else if (index === 3 && level3 === "") {
      setLevel3Flag(true);
    } else {
      if (index === 1) {
        dispatch(editProductHierarchy(level1AttributeId, level1, setLevel1Disable, loginObject?.token))
      } else if (index === 2) {
        dispatch(editProductHierarchy(level2AttributeId, level2, setLevel2Disable, loginObject?.token))
      } if (index === 3) {
        dispatch(editProductHierarchy(level3AttributeId, level3, setLevel3Disable, loginObject?.token))
      }
    }
  }

  useEffect(() => {
    dispatch(productCategoryRequest(loginObject.token));
  }, [orgId]);

  useEffect(() => {
    if (
      !loading &&
      productCategory?.length > 0
    ) {
      setLevel1Disable(true);
      setLevel2Disable(true);
      setLevel3Disable(true);
      let latestAttributes = [];
      for(let i = 3; i >= 1; i--) {
        let attribute = productCategory?.filter((_, index) => index === productCategory?.length - i);
        latestAttributes.push(attribute[0]);
      }
      setAttributesArray(latestAttributes);
      setLevel1(latestAttributes[0]?.name);
      setLevel1AttributeId(latestAttributes[0]?.orgAttributeId);
      setLevel2(latestAttributes[1]?.name);
      setLevel2AttributeId(latestAttributes[1]?.orgAttributeId);
      setLevel3(latestAttributes[2]?.name);
      setLevel3AttributeId(latestAttributes[2]?.orgAttributeId);
    }
  }, [loading, productCategory]);

  useEffect(() => {
    if (!level2Disable && inputRef2.current) {
      const len = inputRef2.current.value.length;
      inputRef2.current.focus();
      inputRef2.current.setSelectionRange(len, len); 
    }
  }, [level2Disable]);

  useEffect(() => {
    if (!level3Disable && inputRef3.current) {
      const len = inputRef3.current.value.length;
      inputRef3.current.focus();
      inputRef3.current.setSelectionRange(len, len); 
    }
  }, [level3Disable]);

  useEffect(() => {
    if (!level1Disable && inputRef1.current) {
      const len = inputRef1.current.value.length;
      inputRef1.current.focus();
      inputRef1.current.setSelectionRange(len, len); 
    }
  }, [level1Disable]);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <button onClick={() => {if (!quickFlagGuideUser) {setQuickFlagGuideUser(true)}}} style={{float:'right', marginRight:'40px',backgroundColor:'#00a82d',padding:'0px 7px',borderRadius:'50%',border:'none'}}>
        <p style={{color:'#fff'}}>?</p>
        </button>
        <p className="heading pt-2 pl-2 m-2" style={{ textAlign: "center" }}>
          Define Product Hierarchy
        </p>
        <p className="heading pt-1 pl-1 m-2" style={{ textAlign: "center" }}>
          Mention three levels of Product Hierarchy
        </p>
        <div className="product-hierarchy-container">
          <div className="product-level-input bg-shadow" 
          style={{ width: productCategory?.length === 0 ? "40%" : ((!level1Disable || !level2Disable || !level3Disable) ? '50%' : '40%') }}
          >
            <div className="product-input-container">
              <div className="product-input-box-container" style={{ width: productCategory?.length > 0 ? "80%" : '100%' }}>
                <div className="product-num">1.</div>
                <div className="product-input-wrapper">
                  <div style={{ width: '100%' }}>
                  <input
                    ref={inputRef1}
                    autoFocus={!level1Disable}
                    className="product-option-input bg-shadow"
                    placeholder="Level 1"
                    value={level1}
                    disabled={level1Disable}
                    onChange={(e) => {
                        setLevel1(e?.target?.value);
                        setLevel1Flag(false);
                    }}
                  />
                  {level1Flag && (
                    <div className="empty-error-msg">
                      *Please add level 1 hierarchy
                    </div>
                  )}
                  </div>
                </div>
              </div>
              {productCategory?.length >= 3 && (<>
              <div className="product-option-edit">
                {level1Disable ? (<>
                  <button
                    className='section-edit-btn'
                    onClick={() => {
                      setLevel1Disable(false);
                      setLevel2Disable(true);
                      setLevel3Disable(true);
                    }}
                  >
                    <EditIcon style={{ width: '20px' }}/>
                  </button>
                </>) : (<>
                  <button
                  className='next_button'
                  style={{ width: '40px' }}
                  onClick={() => handleEditClick(1)}
                  >
                  Save
                  </button>
                  <button 
                  className='next_button'
                  style={{ width: '50px' }}
                  // className='section-edit-btn'
                  onClick={() => {
                    setLevel1Disable(true);
                    setLevel1Flag(false);
                    setLevel1(attributesArray[0]?.name);
                    }}>
                    Cancel
                  </button>
                </>)}
              </div>
              </>)}
            </div>

            <div className="product-input-container">
              <div className="product-input-box-container" style={{ width: productCategory?.length >= 3 ? "80%" : '100%' }}>
                <div className="product-num">2.</div>
                <div className="product-input-wrapper">
                  <div style={{ width: '100%' }}>
                  <input
                    ref={inputRef2}
                    autoFocus={!level2Disable}
                    className="product-option-input bg-shadow"
                    placeholder="Level 2"
                    value={level2}
                    disabled={level2Disable}
                    onChange={(e) => {  
                      setLevel2(e?.target?.value);
                      setLevel2Flag(false);
                    }}
                  />
                  {level2Flag && (
                    <div className="empty-error-msg">
                      *Please add level 2 hierarchy
                    </div>
                  )}
                  </div>
                </div>
              </div>
              {productCategory?.length >= 3 && (<>
              {/* <div className="product-option-edit"> */}
                {level2Disable ? (<>
                  <div className="product-option-edit">
                  <button
                    className='section-edit-btn'
                    onClick={() => {
                      setLevel2Disable(false);
                      setLevel1Disable(true);
                      setLevel3Disable(true);
                    }}
                  >
                    <EditIcon style={{ width: '20px' }}/>
                  </button>
                  </div>
                </>) : (<>
                  <div className="product-option-edit product-option-save">
                  <button
                  className='next_button'
                  style={{ width: '40px' }}
                  onClick={() => handleEditClick(2)}
                  >
                  Save
                  </button>
                  <button 
                  className='next_button'
                  style={{ width: '50px' }}
                  // className='section-edit-btn'
                  onClick={() => {
                    setLevel2Disable(true);
                    setLevel2Flag(false);
                    setLevel2(attributesArray[1]?.name);
                    }}>
                    Cancel
                  </button>
                  </div>
                </>)}
              {/* </div> */}
              </>)}
            </div>

            <div className="product-input-container">
              <div className="product-input-box-container" style={{ width: productCategory?.length >= 3 ? "80%" : '100%' }}>
                <div className="product-num">3.</div>
                <div className="product-input-wrapper">
                  <div style={{ width: '100%' }}>
                  <input
                    ref={inputRef3}
                    autoFocus={!level3Disable}
                    className="product-option-input bg-shadow"
                    placeholder="Level 3"
                    value={level3}
                    disabled={level3Disable}
                    onChange={(e) => {
                      setLevel3(e?.target?.value);
                      setLevel3Flag(false);
                    }}
                  />
                  {level3Flag && (
                    <div className="empty-error-msg">
                      *Please add level 3 hierarchy
                    </div>
                  )}
                  </div>
                </div>
              </div>
              {productCategory?.length >= 3 && (<>
              <div className="product-option-edit">
                {level3Disable ? (<>
                  <button
                    className='section-edit-btn'
                    onClick={() => {
                      setLevel3Disable(false);
                      setLevel1Disable(true);
                      setLevel2Disable(true);

                    }}
                  >
                    <EditIcon style={{ width: '20px' }}/>
                  </button>
                </>) : (<>
                  <button
                  className='next_button'
                  style={{ width: '40px' }}
                  onClick={() => handleEditClick(3)}
                  >
                  Save
                  </button>
                  <button 
                  className='next_button'
                  style={{ width: '50px' }}
                  // className='section-edit-btn'
                  onClick={() => {
                    setLevel3Disable(true);
                    setLevel3Flag(false);
                    setLevel3(attributesArray[2]?.name);
                    }}>
                    Cancel
                  </button>
                </>)}
              </div>
              </>)}
            </div>
            

            {/* <div>
            2.
            <input
              className="product-option-input bg-shadow"
              placeholder="Level 2"
              value={level2}
              disabled={level2Disable}
              style={{ width: productCategory?.length >= 3 ? "70%" : '' }}
              onChange={(e) => {
                setLevel2(e.target.value);
                setLevel2Flag(false);
              }}
            />
            {level2Flag && (
              <div className="empty-error-msg" style={{ marginLeft: "25px" }}>
                *Please add level 2 hierarchy
              </div>
            )}
            </div>
            <div>
            3.
            <input
              className="product-option-input bg-shadow"
              placeholder="Level 3"
              value={level3}
              disabled={level3Disable}
              style={{ width: productCategory?.length >= 3 ? "70%" : '' }}
              onChange={(e) => {
                setLevel3(e.target.value);
                setLevel3Flag(false);
              }}
            />
            {level3Flag && (
              <div className="empty-error-msg" style={{ marginLeft: "25px" }}>
                *Please add level 3 hierarchy
              </div>
            )}
            </div> */}

            <div className="mt-3 mr-3 float-right">
            {productCategory?.length >= 3 && <button
                className="next_button ml-3"
                style={{ width: '70px' }}
                type="button"
                onClick={() => navigate('/admin/product-master-setup-attributes')}
              >
                Skip
              </button>}
              {productCategory?.length === 0 && <button
                className="next_button ml-3"
                type="button"
                onClick={() => handleSubmit()}
              >
                Save & Next
              </button>}
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {quickFlagGuideUser && <QuickGuide setQuickFlagGuideUser={setQuickFlagGuideUser} />}
    </>
  );
};

export default ProductMaster;

import LoginTopBar from "./LoginTopBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="login-container">
      <div className="login-box bg-shadow">
        <LoginTopBar />
        <div className="login-form">
          <label>Create new password</label>
          <p className="small-text"> Create and save your new password </p>
          <input
            type="text"
            className="form-control"
            placeholder="Enter new password"
            name="email"
            autoComplete="off"
          />
          <div className="search-wrapper">
            <img className="eye-icon" src={'./assets/show.png'} />
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Re-enter new password"
              name="password"
              autoComplete="off"
            />
          </div>
          <div className="d-flex">
            <button
              type="submit"
              onClick={() => navigate("/")}
              //   disabled={formik.errors.passcode || formik.errors.mobileNumber}
              className="button mx-auto"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

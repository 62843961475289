// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(() => {
    const user = sessionStorage.getItem('user');
    return user ? JSON.parse(user).token : '';
  });
  const [isSessionClear, setIsSessionClear] = useState(false);

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    if (user) {
      setToken(JSON.parse(user).token);
    }
  }, []);

  const updateToken = (newToken) => {
    setToken(newToken);
    sessionStorage.setItem('user', JSON.stringify({ token: newToken }));
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const user = sessionStorage.getItem('user');
      setToken(user);
      setIsSessionClear(true);
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if(!token) {
      navigate("/");
    } 
    if(!token && isSessionClear) {
      // toast.error("Session Expired");
    }
    if(token && isSessionClear) {
      setIsSessionClear(false);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, updateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

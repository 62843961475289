import React, { useState } from 'react';
import './KpiDetails.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadIcon from '@mui/icons-material/Download';

const KpiDetails = ({sidebarCollapsed}) => {
  const [unit, setUnit] = useState('USD');
  const [period, setPeriod] = useState('Yearly');
  const [year, setYear] = useState('');
  const [entries, setEntries] = useState(10);
  const [count, setCount] = useState(0);


  const months = ['April', 'May', 'June', 'August'];
  const increment = () => {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 5);
    });
  };

  const decrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 5);
      } else {
        return (prevCount = 0);
      }
    });
  };

  return (
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    
  <div className='d-flex annual-sales-plan' style={{ width: '100%'}}>
  <div style={{borderRight:'1px solid grey',paddingRight:'30px',marginRight:'30px'}}>
       <header>
        <button className="back-button">
          <KeyboardArrowLeftIcon />
        </button>
        <h1>Annual Sales plan</h1>
      </header>

      <div className="filters mr-3">
        <div className="filter-group">
          <label>Unit:</label>
          <p className="light_shadow ml-2 p-1 px-3" style={{backgroundColor:'#f2f2f2'}}>USD</p>
        </div>
        <div className="filter-group">
          <label>Period:</label>
          <p className="light_shadow ml-2 p-1 px-3" style={{backgroundColor:'#f2f2f2'}}>Yearly</p>
        </div>
      </div>
      </div>

      <div className="duration-filter">
        <div>
        <label>Duration:</label>
        <div>
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="">Select Year</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
        <select>
          <option value="All">All</option>
        </select>
        <select>
          <option value="All">All</option>
        </select>
        <button className="submit-button">Submit</button>
        <input type="text" placeholder="Type Keyword" className="search-input" />
        {/* <div className="search-wrapper">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              className="search-input form-control pl-3"
              placeholder="Search"
              type="text"
              // onChange={(e) => handleSearchTerm(e)}
            />
          </div> */}
        <button className="download-button">
        <img
          style={{ width: "20px" }}
          src={"/assets/download.png"}
        />
        </button>
        </div>
      </div>
      </div>
</div>

      <table className="sales-table">
        <thead>
          <tr>
            <th colSpan={2}>
            <div className="pl-3">
              <p className="d-flex align-items-center mt-3">
                Show
                <p className="count light_shadow ml-2">{count}</p>
                <div className="counters mr-2">
                  <button onClick={increment}>
                    {" "}
                    <KeyboardArrowUpIcon fontSize="5px" />
                  </button>
                  <button onClick={decrement}>
                    <KeyboardArrowDownIcon fontSize="5px" />
                  </button>
                </div>
                entries
              </p>
            </div>
            </th>
            {months?.map((month)=>
            <th colSpan={3} style={{textAlign:'center'}}>{month}</th>
            )}
          </tr>
          <tr>
            <th>Employee Name</th>
            <th>Employee ID</th>
            {months.flatMap(month => [
              <th key={`${month}-target`}>Target</th>,
              <th key={`${month}-achievement`}>Achievement</th>,
              <th key={`${month}-percentage`}>% Achievement</th>
            ])}
          </tr>
        </thead>
        <tbody>
          {/* Table rows would be dynamically generated here */}
        </tbody>
      </table>
    </div>

  );
};

export default KpiDetails;
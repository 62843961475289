import React, { useState, useEffect } from "react";

const EditOption = ({ option, setEditRole, func }) => {
  const [optionName, setOptionName] = useState();
  const [emptyOptionFlag, setEmptyOptionFlag] = useState(false);

  useEffect(() => {
    if (option !== undefined) {
      setOptionName(option?.name);
    }
  }, [option]);

  const handleSubmit = () => {
    if (optionName === "") {
      setEmptyOptionFlag(true);
    } else {
      func(optionName, option?.id);
    }
  };

  return (
    <div className="custom-attribute-modal-wrapper" style={{ zIndex: "10" }}>
      <div className="edit-option-wrapper bg-shadow">
        <p className="heading pt-2 pl-2">Edit Option</p>
        <div className="edit-option">
          <p className="edit-option-heading" style={{ fontWeight: "bold" }}>
            Option
          </p>
          <input
            className="objective-input bg-shadow"
            placeholder="Edit Option"
            value={optionName}
            onChange={(e) => {
              setEmptyOptionFlag(false);
              setOptionName(e.target.value);
            }}
          />
          {emptyOptionFlag && (
            <div className="empty-error-msg">*Please add an option name</div>
          )}
          <div className="mt-3 mr-3 float-right">
            <button
              className="next_button ml-3"
              type="button"
              onClick={() => handleSubmit()}
            >
              Save
            </button>
          </div>
          <div className="mt-3 mr-3 float-right">
            <button
              className="next_button ml-3"
              type="button"
              onClick={() => setEditRole(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOption;

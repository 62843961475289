import React, { useEffect, useState } from 'react';
import { EMPTY_GEOGRAPHIC_NODES } from '../../Redux/actions/actionTypes';

const EditNodeModalNew = ({ 
  setEditNodeModal, 
  initialNodes,
  node,
  func,
  edges,
  dispatch
 }) => {
    console.log('initialNodes:',initialNodes);
    console.log('node in modal:',node);
    console.log('edges in modal:',edges);
  const [nodeName, setNodeName] = useState('');
  const [emptyName, setemptyName] = useState(false);

  // Helper function to filter out duplicate objects based on 'id'
  const filterDuplicates = (arr) => {
    const uniqueIds = new Set();
    return arr.filter(item => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
  };

  // Filter out duplicates
  const filteredNodes = filterDuplicates(initialNodes);

  console.log('filteredNodes:',filteredNodes);

  const handleChange = (e) => {
    if(e.target.name === 'nodeName') {
        setNodeName(e.target.value);
      setemptyName(false);
    }
  }

  const handleSave = () => {
    if (nodeName === "") {
      console.log('C1')
      setemptyName(true);
    } else {
    const parentEdge = edges?.filter((edge) => edge?.target === (node?.id)?.toString());
    console.log('Parent Edge:',parentEdge);
    let parentNode = [];
    if(parentEdge?.length > 0) {
    parentNode = filteredNodes?.filter((node) => node?.id === parentEdge[0]?.source);
    console.log('Parent Node:',parentNode);
    }
    const editNode = {
      name: nodeName, 
      parents: parentEdge?.length > 0 ? [{
        nodeId: parseInt(parentNode[0]?.id),
        relation: 'WRITE'
      }] : []
    }
    dispatch({ type: EMPTY_GEOGRAPHIC_NODES })
    func(editNode, node?.id);
    setEditNodeModal(false)
  }
  }

  useEffect(() => {
    setNodeName(node?.name);
  }, []);

  return (
    <div className='custom-attribute-modal-wrapper'>
        <div className='custom-modal-popup' 
        style={{ height: '30%' }}
        >
            <h3>{`Edit Node`}</h3>
            <div className='input-group'>
                <input 
                placeholder="Name"
                name="nodeName" 
                value={nodeName}
                style={{ marginBottom: emptyName ? "0" : "20px" }} 
                onChange={(e) => handleChange(e)} 
                />
                {emptyName && <p style={{ color: 'red', fontSize: '5px', marginTop: "5px", marginBottom: '5px' }}>*Required</p>}
                <br/>
            </div>
            <div className='mt-3 float-right'>
                <button
                 className="cancel_button"
                 onClick={() => setEditNodeModal(false)}
                 >
                  Cancel
                 </button>
                <button 
                className='next_button ml-3'
                onClick={handleSave}
                >Save</button>
            </div>
        </div>
    </div>
  )
}

export default EditNodeModalNew
import * as actionType from "../actions/actionTypes";

const initialState = {
  attributesLibraryList: [],
  setupStatus: [],
  sectionData: [],
  attributes: [],
  deleteAttribute: {},
  customerTypes: [],
  geographicNodes: null,
  customerTypesAttributes: [],
  users: [],
  objectives: [],
  tasks: [],
  editObjectives: [],
  userSetup: [],
  loading: false,
  profile: {},
  productAttributes: [],
  productListChildOptions: [],
  products: [],
  customerTypesList: [],
  allAttributes: [],
  productCategory: [],
  // loginStatus: false,
  userDetails: [],
  combinedList: [],
  customersList:[],
  customersDetails:[],
  customerById:{},
  customerData:{},
  schemes: [],
  kpiList:[],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ATTRIBUTES_LIBRARY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATTRIBUTES_LIBRARY_SUCCESS:
      return {
        ...state,
        attributesLibraryList: action.payload,
        loading: false,
      };
    case actionType.ATTRIBUTES_LIBRARY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.CUSTOM_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.CUSTOM_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.CUSTOM_ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SETUP_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SETUP_STATUS_SUCCESS:
      return {
        ...state,
        setupStatus: action.payload,
        loading: false,
      };
    case actionType.SETUP_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_SETUP_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_SETUP_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_SETUP_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_ATTRIBUTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_SECTION_DATA:
      return {
        ...state,
        sectionData: action.payload,
      };
    case actionType.SET_ATTRIBUTES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_ATTRIBUTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_ATTRIBUTES_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        attributes: action.payload,
      };
    case actionType.ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EMPTY_ATTRIBUTES:
      return {
        ...state,
        attributes: [],
      };
    case actionType.DELETE_ATTRIBUTE:
      return {
        ...state,
        deleteAttribute: action.payload,
      };
    case actionType.EMPTY_DELETE_ATTRIBUTE:
      return {
        ...state,
        deleteAttribute: undefined,
      };
    case actionType.SET_CUSTOMER_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_CUSTOMER_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_CUSTOMER_TYPES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_CUSTOMER_TYPES:
      return {
        ...state,
        customerTypes: action.payload,
      };
    case actionType.DELETE_CUSTOMER_TYPES:
      return {
        ...state,
        customerTypes: [],
      };
    case actionType.GET_GEOGRAPHIC_NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_GEOGRAPHIC_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
        geographicNodes: action.payload,
      };
    case actionType.GET_GEOGRAPHIC_NODES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_GEOGRAPHIC_NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_GEOGRAPHIC_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_GEOGRAPHIC_NODES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EMPTY_GEOGRAPHIC_NODES:
      return {
        ...state,
        geographicNodes: null,
      };
    case actionType.EDIT_GEOGRAPHIC_NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.EDIT_GEOGRAPHIC_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_GEOGRAPHIC_NODES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_GEOGRAPHIC_NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_GEOGRAPHIC_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_GEOGRAPHIC_NODES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_CUSTOMER_TYPE_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_CUSTOMER_TYPE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        customerTypesAttributes: action.payload,
      };
    case actionType.GET_CUSTOMER_TYPE_ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_USER_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_USER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        attributes: action.payload,
      };
    case actionType.GET_USER_ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionType.GET_USER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    case actionType.GET_USER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.TOGGLE_USER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.TOGGLE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.TOGGLE_USER_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_NEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_COMBINED_LIST:
      return {
        ...state,
        combinedList: action.payload,
      };
    case actionType.CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: [],
      };

    case actionType.EDIT_NEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.EDIT_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case actionType.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EMPTY_SETUP_STATUS:
      return {
        ...state,
        setupStatus: [],
      };
    case actionType.GET_OBJECTIVES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_OBJECTIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        objectives: action.payload,
      };
    case actionType.GET_OBJECTIVES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_OBJECTIVES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_OBJECTIVES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_OBJECTIVES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload,
      };
    case actionType.GET_TASKS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_TASKS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_OBJECTIVES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_OBJECTIVES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_OBJECTIVES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_TASKS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_OBJECTIVE_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.EDIT_OBJECTIVE_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_OBJECTIVE_OPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_OBJECTIVE_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_OBJECTIVE_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_OBJECTIVE_OPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
      case actionType.GET_SCHEMES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_SCHEMES_SUCCESS:
      return {
        ...state,
        loading: false,
        schemes: action.payload,
      };
    case actionType.GET_SCHEMES_FAIL:
      return {
        ...state,
        loading: false,
      };
      case actionType.SET_SCHEMES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionType.SET_SCHEMES_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case actionType.SET_SCHEMES_FAIL:
        return {
          ...state,
          loading: false,
        };
        case actionType.DELETE_SCHEME_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionType.DELETE_SCHEME_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case actionType.DELETE_SCHEME_FAIL:
          return {
            ...state,
            loading: false,
          };
    case actionType.GET_USER_SETUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_USER_SETUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userSetup: action.payload,
      };
    case actionType.GET_USER_SETUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_USER_SETUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_USER_SETUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_USER_SETUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_USER_SETUP_LIST_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.EDIT_USER_SETUP_LIST_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_USER_SETUP_LIST_OPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_USER_SETUP_LIST_OPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.DELETE_USER_SETUP_LIST_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.DELETE_USER_SETUP_LIST_OPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case actionType.GET_ADMIN_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EMPTY_PROFILE_DETAILS:
      return {
        ...state,
        profile: {},
      };
    case actionType.SET_PRODUCT_HIERARCHY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_PRODUCT_HIERARCHY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.SET_PRODUCT_HIERARCHY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_PRODUCT_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_PRODUCT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        productAttributes: action.payload,
      };
    case actionType.GET_PRODUCT_ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_LIST_CHILD_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_LIST_CHILD_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        productListChildOptions: action.payload,
      };
    case actionType.GET_LIST_CHILD_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_NEW_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.ADD_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.ADD_NEW_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case actionType.GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_CUSTOMER_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_CUSTOMER_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerTypesList: action.payload,
      };
    case actionType.GET_CUSTOMER_TYPES_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
      case actionType.GET_CUSTOMERS_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
        case actionType.GET_CUSTOMERS_LIST_SUCCESS:
          return {
              ...state,
              loading: false,
              customersList: [
                  ...state.customersList.filter(
                      (customer) =>
                          !action.payload.some(
                              (newCustomer) => newCustomer.id === customer.id
                          )
                  ),
                  ...action.payload,
              ],
          };
      
case 'UPDATE_CUSTOMERS_LIST':
  return {
      ...state,
      customersList: action.payload,
  };

      case actionType.GET_CUSTOMERS_LIST_FAIL:
        return {
          ...state,
          loading: false,
        };
        case actionType.EDIT_CUSTOMER_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionType.EDIT_CUSTOMER_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case actionType.EDIT_CUSTOMER_FAIL:
          return {
            ...state,
            loading: false,
          };
        
        case actionType.TOGGLE_CUSTOMER_STATUS_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionType.TOGGLE_CUSTOMER_STATUS_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case actionType.TOGGLE_CUSTOMER_STATUS_FAIL:
          return {
            ...state,
            loading: false,
          };
        case actionType.GET_CUSTOMERS_DETAILS_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionType.GET_CUSTOMERS_DETAILS_SUCCESS:
          return {
            ...state,
            loading: false,
            customersDetails: action.payload,
          };
        case actionType.GET_CUSTOMERS_DETAILS_FAIL:
          return {
            ...state,
            loading: false,
          };
          case actionType.GET_CUSTOMER_BY_ID_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case actionType.GET_CUSTOMER_BY_ID_SUCCESS:
            return {
              ...state,
              loading: false,
              customerById: action.payload,
            };
          case actionType.GET_CUSTOMER_BY_ID_FAIL:
            return {
              ...state,
              loading: false,
            };
            case actionType.GET_CUSTOMER_TYPE_ID_REQUEST:
              return {
                ...state,
                loading: true,
              };
            case actionType.GET_CUSTOMER_TYPE_ID_SUCCESS:
              return {
                ...state,
                loading: false,
                customerData: action.payload,
              };
            case actionType.GET_CUSTOMER_TYPE_ID_FAIL:
              return {
                ...state,
                loading: false,
              };
    case actionType.GET_LIST_ATTRIBUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_LIST_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        allAttributes: action.payload,
      };
    case actionType.GET_LIST_ATTRIBUTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.GET_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        productCategory: action.payload,
      };
    case actionType.GET_PRODUCT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.EDIT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
      };
      case actionType.GET_KPI_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionType.GET_KPI_SUCCESS:
        return {
          ...state,
          loading: false,
          kpiList: action.payload,
        };
      case actionType.GET_KPI_FAIL:
        return {
          ...state,
          loading: false,
        };
        case actionType.CREATE_KPI_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case actionType.CREATE_KPI_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case actionType.CREATE_KPI_FAIL:
          return {
            ...state,
            loading: false,
          };
          case actionType.TOGGLE_KPI_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case actionType.TOGGLE_KPI_SUCCESS:
            return {
              ...state,
              loading: false,
            };
          case actionType.TOGGLE_KPI_FAIL:
            return {
              ...state,
              loading: false,
            };
    // case actionType.LOGIN_STATUS_TRUE:
    //     return {
    //         ...state,
    //         loginStatus: true
    //     }
    // case actionType.LOGIN_STATUS_FALSE:
    //     return {
    //         ...state,
    //         loginStatus: false
    //     }
    default:
      return state;
  }
};

export default adminReducer;

import React, { useState } from 'react';

const VisitRestrictions = ({ SetVisitRestrictions }) => {
  const [restrictionDays, setRestrictionDays] = useState('');
  const [emptyRestrictionDays, setEmptyRestrictionDays] = useState(false);

  console.log('restrictionDays:',restrictionDays);

  const handleSubmit = () => {
    if(restrictionDays === '') {
      setEmptyRestrictionDays(true)
    }
  }

  return (
    <>
      <div className='custom-attribute-modal-wrapper'>
        <div className='visit-restrictions-box bg-shadow'>
        <p className="heading pt-2 pl-2">Visit Restrictions</p>
        <div className='visit-restrictions-input-wrapper'>
        <input
        type='number'
        className="visit-restrictions-input bg-shadow"
        placeholder="Enter Number of working days"
        value={restrictionDays}
        onChange={(e) => {
          setRestrictionDays(e.target.value);
          setEmptyRestrictionDays(false);
        }}
        />
        {emptyRestrictionDays && <div className="empty-error-msg">*Required</div>}
        </div>
        <div className='visit-restrictions-btn-wrapper float-right' style={{ marginTop: emptyRestrictionDays ? '' : '1rem' }}>
        <button 
          className="next_button ml-3" 
          type="button"
          onClick={() => SetVisitRestrictions(false)}
          >
            Cancel
        </button>
        <button 
          className="next_button ml-3" 
          type="button"
          onClick={() => handleSubmit()}
          >
            Submit
        </button>
        </div>
        </div>
      </div>
    </>
  )
}

export default VisitRestrictions
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import UserMaster from './UserMaster';
import { useSelector, useDispatch } from 'react-redux';
import { SET_CUSTOMER_TYPES } from '../../../../../Redux/actions/actionTypes';
import { updateSetupStatus, attributesRequest } from '../../../../../Redux/actions';
import { Constants } from '../../../SuperAdmin/Credentials';

const CustomerAttributes = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const { customerTypes, attributes, loading } = useSelector((state) => state.adminReducer);
  const { orgId } = useSelector((state) => state.loginReducer); 
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerSelected, setCustomerSelected] = useState(null);  
  const [count, setCount] = useState(0);
  const [customerSelectedAttributes, setCustomerSelectedAttributes] = useState([]);
  // const [customerTypeId, setCustomerTypeId] = useState(null);
  // let customerTypeId = null;
  console.log('customerSelectedAttributes:',customerSelectedAttributes);

  useEffect(() => {
    if (customerTypes?.length > 0) {
      setCustomerSelected([...customerTypes]); 
    }
  }, [customerTypes]);

  const attributesRequest1 = (orgId, key, token) => async () => {
    console.log('attributesRequest1 CALLED');
      await fetch(
         `${Constants.api}/admin/${orgId}/${key}/attributes`,
         {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          })
         }
       ).then((response) => response.json())
       .then(async (responseData) => {
         if (responseData.success) {
          setCustomerSelectedAttributes(responseData.response);
         }
       })
       .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // attributesRequest1(orgId, "CUST_TYPE", loginObject?.token);
    const fetchData = attributesRequest1(orgId, "CUST_TYPE", loginObject?.token);
    fetchData();
  },[]);

  useEffect(() => {
    dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject?.token));
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(!loading && attributes?.length > 0 && customerSelected !== null) {
  //       console.log('Attributes inside:',attributes);
  //       console.log('customerSelected inside:',customerSelected);
  //       let customerType = attributes?.filter((attribute) => (attribute?.baseAttributeId === customerSelected[0]?.id));
  //       setCustomerTypeId(customerType[0]);
  //       console.log('customerTypeId inside:',customerTypeId);
  //     }
  //   }, [100]);
  // }, [loading, attributes, customerSelected]);

  // console.log('customerTypeId outside:',customerTypeId);

  // useEffect(() => {
  //   if(state !== null && customerSelected !== null) {
  //     const filtered = customerSelected?.filter((c) => c.name !== state.name);
  //     if (filtered.length !== customerSelected.length || !filtered.every((c, index) => c === customerSelected[index])) {
  //       setCustomerSelected(filtered);
  //       dispatch({ type: SET_CUSTOMER_TYPES, payload: filtered })
  //   }
  //   }
  // }, [state, customerSelected]);

  useEffect(() => {
    // if(count === 0 && customerSelected !== null) {
        if(count >= 0 && count < customerTypes?.length && customerTypes !== null) {
          const filtered = customerTypes?.filter((c, index) => index === count);
          // if (filtered.length !== customerSelected?.length || !filtered.every((c, index) => c === customerSelected[index])) {
            setCustomerSelected(filtered);
            // dispatch({ type: SET_CUSTOMER_TYPES, payload: filtered })
          // }
        } else  if (count === customerTypes?.length) {
          dispatch(updateSetupStatus(orgId, 'CUSTOMER_MASTER_SETUP', 'Customer Setup', navigate, loginObject?.token));
          // navigate('/admin/organization-setup');
        }
  }, [count, customerTypes]);

  useEffect(() => {
    if(state !== null) {
      const counter = state.count + 1;
      setCount(counter);
    }
  }, [state]);

  return (
    <>
    <div style={{ backgroundColor: 'white' }}>
    {customerSelected?.map((customer) => (
<     UserMaster
      customer={customer}
      sidebarCollapsed={sidebarCollapsed}
      count={count}
      custTypeAttributes={customerSelectedAttributes}
      // customerTypeId={customerTypeId}
      />
    ))}
    </div>
    </>   
  )
}

export default CustomerAttributes;
import React from 'react';
import { useDrag } from 'react-dnd';

const NonMandatoryAttributesSection = ({ attribute, mandatoryAttributes, nonMandatoryAttributes, onChange }) => {
    // console.log('attribute:',attribute)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "attribute",
    item: { name: attribute },
    end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        // console.log('item:',item);
        // console.log('monitor:',dropResult);
        if(item && dropResult) {
            // console.log('Entered 1');
            // alert(`You threw ${item.name.name} into ${dropResult.name}`);
            // let addedObj = {id: item.name.id, name: item.name.name, owner: item.name.owner, inputType: item.name.inputType, isMandatory: false}
            let addedObj = {id: item.name.id !== undefined ? item.name.id : item.name.baseAttributeId, name: item.name.name, owner: item.name.owner, inputType: item.name.inputType, isMandatory: false}
            // if(dropResult.name === 'non mandatory attributes') {
            //   // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: false, inputType: item.name.inputType, owner: item.name.owner}
            //   // nonMandatoryAttributes.push(addedObj);
            //   nonMandatoryAttributes.push(item.name);
            // }
            const filtered = nonMandatoryAttributes.filter(cItem => {
                // return !nonMandatoryAttributes.some(aItem => aItem.name === cItem.name);
                return cItem.name !== item.name.name;
              });
            onChange(dropResult.name, addedObj, filtered, "fromNonMandatory");
        }
    },
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    })
}), [attribute, mandatoryAttributes, nonMandatoryAttributes, onChange]);

  return (
    <>
    <div className='wrapper' ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='logo'>
        <img src={"/assets/drag.png"} />
      </div>
    <div> 
      <input 
      // ref={drag}
      value={attribute.name} 
      disabled
      className="attribute"
      style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
      boxShadow: attribute.owner === 'ORG' ? "1px 0.5px 2px 0px" : "", cursor: "pointer", minHeight: '40px' }}
      />
    </div>
    </div>
    </>
  )
}

export default NonMandatoryAttributesSection
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "./Credentials";
import Loader from "../../../shared/Loader/Loader";
import "./BasicDetails.css";

const Communication = ({
  onCommunicationDataChange,
  setFormData,
  navigateToFormStep,
  editMode,
  organizationDetails,
  handleUpdateButtonClick,
  navigateToBackStep
}) => {
  const Api = Constants.api;
  const navigate = useNavigate();
  const loginObject = JSON.parse(sessionStorage.user);
  const orgId = organizationDetails?.id;
  const [locationDetails, setLocationDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [communicationFlag, setCommunicationFlag] = useState(false);
  const [billingFlag, setBillingFlag] = useState(false);
  const initialValues = {
    communicationAddress: {
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      country: "",
      state: "",
      city: "",
    },
    billingAddress: {
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      country: "",
      state: "",
      city: "",
    },
    billingSameAsCommunication: false,
  };

  const validationSchema = Yup.object().shape({
    communicationAddress: Yup.object().shape({
      // addressLine1: Yup.string().required("Address Line 1 is required"),
      pincode: Yup.number()
        .required("PIN code is required")
        .test("len", "Pin code should be 6 digits", (val) => {
          if (val) return val.toString().length === 6;
        }),
      // country: Yup.string().required("Country is required"),
      // state: Yup.string().required("State is required"),
      // city: Yup.string().required("City is required"),
    }),
    billingAddress: Yup.object().shape({
      // addressLine1: Yup.string().required("Address Line 1 is required"),
      pincode: Yup.number()
        .required("PIN code is required")
        .test("len", "Pin code should be 6 digits", (val) => {
          if (val) return val.toString().length === 6;
        }),
      // country: Yup.string().required("Country is required"),
      // state: Yup.string().required("State is required"),
      // city: Yup.string().required("City is required"),
    }),
    billingSameAsCommunication: Yup.boolean(),
  });

  const handleSubmit = (values) => {
    // Update the form data in the parent component
    console.log("values:", values);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   communication: values
    // }));
    // Call the callback function to handle the submission

    editMode ? updateAddressDetails(values) : onCommunicationDataChange(values);
    !editMode && navigateToFormStep(3);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });

  // useEffect(() => {
  //   getLocation(formik?.values?.communicationAddress?.pincode);
  // }, [formik?.values?.communicationAddress?.pincode]);

  const getLocation = async (pincode, setFieldValue) => {
    if (pincode?.length === 6) {
      setLoading(true);
      const resp = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
        {
          method: "GET",
          headers: new Headers({}),
        }
      ).catch((error) => console.log(error));
      const respJson = await resp.json();
      setLocationDetails(respJson);
      // Update form fields with fetched data
      if (respJson && respJson.length > 0) {
        setLoading(false);
        const { State, Country, PostOffice } = respJson[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, Country, Region } = PostOffice[0];
          // formik.setFieldValue("communicationAddress.country", Country);
          // formik.setFieldValue("communicationAddress.state", State);
          // formik.setFieldValue("communicationAddress.city", Region);
          setFieldValue("communicationAddress.country", Country);
          setFieldValue("communicationAddress.state", State);
          setFieldValue("communicationAddress.city", Region);
          // formik.setFieldValue("billingAddress.country", Country);
          // formik.setFieldValue("billingAddress.state", State);
          // formik.setFieldValue("billingAddress.city", Name);
        }
      }
    }
  };

  const getLocationForBilling = async (pincode, setFieldValue) => {
    if (pincode?.length === 6) {
      setLoading(true);
      const resp = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
        {
          method: "GET",
          headers: new Headers({}),
        }
      ).catch((error) => console.log(error));
      const respJson = await resp.json();
      // Update form fields with fetched data for billing address
      if (respJson && respJson.length > 0) {
        setLoading(false);
        const { State, Country, Region } = respJson[0]?.PostOffice[0] || {};
        setFieldValue("billingAddress.country", Country || "");
        setFieldValue("billingAddress.state", State || "");
        setFieldValue("billingAddress.city", Region || "");
      }
    }
  };

  //response
  //[{"Message":"Number of pincode(s) found:3","Status":"Success","PostOffice":[{"Name":"Chaithanyapuri Colony","Description":null,"BranchType":"Sub Post Office","DeliveryStatus":"Non-Delivery",
  //"Circle":"Andhra Pradesh","District":"K.V.Rangareddy","Division":"Hyderabad South East","Region":"Hyderabad City","Block":"Saroornagar","State":"Telangana","Country":"India","Pincode":"500060"},
  //{"Name":"Dilsukhnagar Colony","Description":null,"BranchType":"Sub Post Office","DeliveryStatus":"Non-Delivery","Circle":"Andhra Pradesh","District":"K.V.Rangareddy","Division":"Hyderabad South East","Region":"Hyderabad City","Block":"Saroornagar","State":"Telangana","Country":"India","Pincode":"500060"},
  //{"Name":"P & T Colony (K.V.Rangareddy)","Description":null,"BranchType":"Sub Post Office","DeliveryStatus":"Delivery","Circle":"Andhra Pradesh","District":"Hyderabad","Division":"Hyderabad South East","Region":"Hyderabad City","Block":"Saroornagar","State":"Telangana","Country":"India","Pincode":"500060"}]}]

  // const handleCheckboxChange = (event) => {
  //   const { checked } = event.target;
  //   formik.setFieldValue("billingSameAsCommunication", checked);

  //   if (checked) {
  //     const communicationAddress = formik.values.communicationAddress;
  //     formik.setValues({
  //       ...formik.values,
  //       billingAddress: { ...communicationAddress },
  //     });
  //   }
  //   else {
  //     formik.setValues({
  //       ...formik.values,
  //       billingAddress: {
  //         addressLine1: "",
  //         addressLine2: "",
  //         pincode: "",
  //         country: "",
  //         state: "",
  //         city: "",
  //       },
  //     });
  //   }
  // };

  const handleCheckboxChange = (event, values, setFieldValue) => {
    const { checked } = event.target;
    setFieldValue("billingSameAsCommunication", checked);

    if (checked) {
      const communicationAddress = values.communicationAddress;
      setFieldValue(`billingAddress`, communicationAddress);
    } else {
      setFieldValue(`billingAddress.addressLine1`, "");
      setFieldValue(`billingAddress.addressLine2`, "");
      setFieldValue(`billingAddress.pincode`, "");
      setFieldValue(`billingAddress.country`, "");
      setFieldValue(`billingAddress.state`, "");
      setFieldValue(`billingAddress.city`, "");
    }
  };

  function updateAddressDetails(values) {
    console.log("communication id:", organizationDetails);

    const communicationPromise = fetch(
      `${Api}/org/${orgId}/update/address/${organizationDetails?.communicationAddress?.id}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          line1: values?.communicationAddress?.addressLine1,
          line2: values?.communicationAddress?.addressLine2,
          city: values?.communicationAddress?.city,
          state: values?.communicationAddress?.state,
          country: values?.communicationAddress?.country,
          pinCode: values?.communicationAddress?.pincode,
        }),
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log(
          "error occured while updating communication address:",
          error
        );
        alert("Error while updating communication address, please try again later");
      });
    const billingPromise = fetch(
      `${Api}/org/${orgId}/update/address/${organizationDetails?.billingAddress?.id}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          line1: values?.billingAddress?.addressLine1,
          line2: values?.billingAddress?.addressLine2,
          city: values?.billingAddress?.city,
          state: values?.billingAddress?.state,
          country: values?.billingAddress?.country,
          pinCode: values?.billingAddress?.pincode,
        }),
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log(
          "error occured while updating billing address:",
          error
        );
        alert("Error while updating billing address, please try again later");
      });
    Promise.all([communicationPromise, billingPromise])
      .then(([communicationResponse, billingResponse]) => {
        console.log(
          "condition:",
          communicationResponse.success && billingResponse.success
        );
        if (communicationResponse.success && billingResponse.success) {
          alert(communicationResponse.message);
        } else {
          alert("Something went wrong, please try again later");
        }
      })
      .catch((error) => console.log(error));
  }

  // console.log('org details from communication:',organizationDetails?.communicationAddress)

  return (
    <>
      <Formik
        initialValues={
          editMode
            ? {
                communicationAddress: {
                  addressLine1:
                    organizationDetails?.communicationAddress?.line1,
                  addressLine2:
                    organizationDetails?.communicationAddress?.line2,
                  pincode: organizationDetails?.communicationAddress?.pinCode,
                  country: organizationDetails?.communicationAddress?.country,
                  state: organizationDetails?.communicationAddress?.state,
                  city: organizationDetails?.communicationAddress?.city,
                },
                billingAddress: {
                  addressLine1: organizationDetails?.billingAddress?.line1,
                  addressLine2: organizationDetails?.billingAddress?.line2,
                  pincode: organizationDetails?.billingAddress?.pinCode,
                  country: organizationDetails?.billingAddress?.country,
                  state: organizationDetails?.billingAddress?.state,
                  city: organizationDetails?.billingAddress?.city,
                },
                billingSameAsCommunication: false,
              }
            : initialValues
        }
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ handleSubmit, errors, touched, values, setFieldValue }) => (
          <form
            className="row d-flex justify-content-center"
            onSubmit={handleSubmit}
          >
            {/* {console.log("Errors:",formik.errors)}
      {console.log("Touched:",formik.touched)} */}
            <div className="col-5 communication-column-left">
              <p className=" font-weight-bold" style={{ marginBottom: "36px" }}>
                Communication Address
              </p>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="communicationAddress.addressLine1"
                  placeholder="Address Line 1"
                  autoComplete="off"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.addressLine1}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="communicationAddress.addressLine2"
                  placeholder="Address Line 2"
                  autoComplete="off"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.addressLine2}
                />
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  className="form-control"
                  name="communicationAddress.pincode"
                  placeholder="PIN code*"
                  onWheel={(e) => e.currentTarget.blur()}
                  autoComplete="off"
                  onChange={(e) => {
                    const inputValue = e?.target?.value;
                    // Check if the input value has more than 6 digits, if yes, trim it to 6 digits
                    const trimmedValue = inputValue.slice(0, 6);
                    getLocation(trimmedValue, setFieldValue);
                    setFieldValue(`communicationAddress.pincode`, trimmedValue);
                    // getLocation(e?.target?.value, setFieldValue)
                    // setFieldValue(`communicationAddress.pincode`, e?.target?.value);
                  }}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.pincode}
                />
                {touched.communicationAddress?.pincode &&
                  errors.communicationAddress?.pincode && (
                    <span className="error-msg">
                      {errors.communicationAddress?.pincode}
                    </span>
                  )}
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="communicationAddress.country"
                  placeholder="Country"
                  autoComplete="off"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.country}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="communicationAddress.state"
                  placeholder="State"
                  autoComplete="off"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.state}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="communicationAddress.city"
                  placeholder="City"
                  autoComplete="off"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.communicationAddress.city}
                />
              </div>
              {!editMode && (
                <>
                  <div className="mt-3 ml-3 float-left">
                  <button
                    className="back_button"
                    type="button"
                    onClick={() => navigateToBackStep(1)}
                  >
                    Back
                  </button>
                </div>
                </>
              )}
            </div>

            <div className="col-5 communication-column-right">
              <p className=" font-weight-bold">Billing Address</p>
              <div className="d-flex my-2">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleCheckboxChange(e, values, setFieldValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.billingSameAsCommunication}
                  name="billingSameAsCommunication"
                />
                <p className="ml-2 small-text">
                  Billing address same as communication address
                </p>
              </div>

              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="billingAddress.addressLine1"
                  placeholder="Address Line 1"
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.addressLine1}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="billingAddress.addressLine2"
                  placeholder="Address Line 2"
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.addressLine2}
                />
              </div>
              <div className="form-group">
                <Field
                  type="number"
                  className="form-control"
                  name="billingAddress.pincode"
                  placeholder="PIN code*"
                  onWheel={(e) => e.currentTarget.blur()}
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}
                  onChange={(e) => {
                    getLocationForBilling(e?.target?.value, setFieldValue);
                    const inputValue = e?.target?.value;
                    // Check if the input value has more than 6 digits, if yes, trim it to 6 digits
                    const trimmedValue = inputValue.slice(0, 6);
                    setFieldValue(`billingAddress.pincode`, trimmedValue);
                    // setFieldValue(`billingAddress.pincode`, e.target.value);
                  }}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.pincode}
                />
                {touched.billingAddress?.pincode &&
                  errors.billingAddress?.pincode && (
                    <span className="error-msg">
                      {errors.billingAddress?.pincode}
                    </span>
                  )}
              </div>
              <div className="form-group">
                {/* <select
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.billingAddress.country}
          >
            <option value="default" disabled selected hidden>
              Country
            </option>
            <option value="india">India</option>
          </select> */}

                <Field
                  type="text"
                  className="form-control"
                  name="billingAddress.country"
                  placeholder="Country"
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.country}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="billingAddress.state"
                  placeholder="State"
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}

                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.state}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  name="billingAddress.city"
                  placeholder="City"
                  autoComplete="off"
                  disabled={values.billingSameAsCommunication}

                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.billingAddress.city}
                />
              </div>
              {editMode ? (
                <div className="float-right">
                  {/* <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    float: "right",
                    marginLeft: "auto",
                    marginTop: "10px",
                  }}
                  type="submit"
                >
                  Update
                </button> */}

                  <button
                    style={{
                      backgroundColor: "#00a82d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      width: "90px",
                      height: "32px",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    style={{
                      backgroundColor: "#00a82d",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      width: "90px",
                      height: "32px",
                      marginTop: "10px",
                    }}
                    type="button"
                    onClick={() => handleUpdateButtonClick()}
                  >
                    Next
                  </button>
                </div>
              ) : (
                <div className="mt-3 mr-3 float-right">
                  <button
                    className="cancel_button"
                    type="button"
                    onClick={() => navigate("/home")}
                  >
                    Cancel
                  </button>
                  <button className="next_button ml-3" type="submit">
                    Save & Next
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
      {loading && <Loader />}
    </>
  );
};

export default Communication;

import { useEffect, useState } from "react";
import { useFormik, Field, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Constants } from "./Credentials";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const KeyContacts = ({
  onKeyContactsChange,
  navigateToFormStep,
  editMode,
  organizationDetails,
  handleUpdateButtonClick,
  getOrganizationDetails,
  navigateToBackStep
}) => {
  const [keyContacts, setKeyContacts] = useState([]);
  const [mobileFlag, setMobileFlag] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const orgId = organizationDetails?.id;

  const navigate = useNavigate();
  const initialValues = {
    firstName: "",
    lastName: "",
    mobile: "+91",
    designation: "",
    email: "",
    isAdmin: false,
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("*First Name is required"),
    // lastName: Yup.string().required("Last Name is required"),
    // designation: Yup.string().required("Designation is required"),
    email: Yup.string().email("Invalid Email").required("*Email is required"),
  });

  const handleCheckboxChange = (contact) => {
    const updatedContacts = [...allContacts];
    contact.isAdmin = !contact.isAdmin;
    console.log("isAdmin?: ", contact.isAdmin);
    // updatedContacts[contact.id].isAdmin = !updatedContacts[contact.id].isAdmin;
    // setKeyContacts(updatedContacts);
    if (contact?.id) {
    fetch(`${Api}/org/setAdmin/${orgId}/${contact?.id}/${contact.isAdmin}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject?.token}`,
        "Content-Type": "application/json",
      }),
      //  body: JSON.stringify(orgData),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          console.log("toggle organization status response:", responseData);
          getOrganizationDetails();
        }
      })
      .catch((error) => console.log(error));
    }
    setAllContacts(updatedContacts);
  };

  const DeleteContact = (contact) => {
    const updatedContacts = [...keyContacts];
    updatedContacts.splice(contact, 1);
    if (contact?.id) {
      fetch(
        `${Api}/org/${orgId}/contacts/${contact?.id}`,

        {
          method: "DELETE",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then(async (responseData) => {
          if (responseData.success) {
            getOrganizationDetails();
          }
          console.log("deleted contact response:", responseData);
          // console.log("api:", Api);
        })
        .catch((error) => console.log(error));
    }
    setKeyContacts(updatedContacts);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // onSubmit: (values, { resetForm }) => {
    //   const newContact = {
    //     firstName: values.firstName,
    //     lastName: values.lastName,
    //     mobile: values.mobile,
    //     designation: values.designation,
    //     email: values.email,
    //     isAdmin: values.isAdmin,
    //   };
    //   setKeyContacts([...keyContacts, newContact]);
    //   resetForm();
    //   console.log("list of contacts:", keyContacts);
    // },
  });

  const AddContact = () => {
    const newContact = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      mobile: formik.values.mobile,
      designation: formik.values.designation,
      email: formik.values.email,
      isAdmin: formik.values.isAdmin,
    };
    setKeyContacts([...keyContacts, newContact]);
    formik.resetForm();
    console.log("list of contacts:", keyContacts);
  };
// console.log('keycontacts:',organizationDetails?.keyContacts)
  var fetchetdContacts = organizationDetails?.keyContacts;
  useEffect(() => {
    setAllContacts([
      ...keyContacts,
      ...(organizationDetails?.keyContacts || []),
    ]);
    // allContacts = [...fetchetdContacts, ...keyContacts];
  }, [organizationDetails, keyContacts]);

  function handleInvite(contact) {
    fetch(`${Api}/org/invite/resend/${orgId}/${contact.id}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject.token}`,
        "Content-Type": "application/json",
      }),
      // body: JSON.stringify({ keyContacts: allContacts }),
    })
      // .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.ok) {
          alert("invitation sent successfully", JSON.stringify(contact?.email));
        } else {
          alert("Key Contact is not an Admin");
        }
        // else{
        //   alert("something went wrong, please try again");
        // }
        console.log("invitef response:", responseData);
        // console.log("api:", Api);
      })
      .catch((error) => console.log(error));
  }

  const handleSubmitForm = async (values) => {
    console.log("key contacts values:", allContacts);

    editMode ? updateContacts(allContacts) : onKeyContactsChange(allContacts);

    !editMode && navigateToFormStep(4);
  };

  function updateContacts(allContacts) {
    fetch(`${Api}/org/${orgId}/update/contacts`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ keyContacts: allContacts }),
    })
      // .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData?.ok) {
          setKeyContacts([]);
          getOrganizationDetails();
          // navigate(`/editorganization/${orgId}`, {
          //   state: { id: orgId },
          // });
          alert('Key contacts updated')
        }
        console.log("update basic details response:", responseData);
        // console.log("api:", Api);
      })
      .catch((error) => console.log(error));
  }
  // console.log('allcontacts', allContacts)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={(values, { resetForm }) => {
        // if (values.mobile != "" && values.mobile.length != 10) {
        //   setMobileFlag(true);
        // } else {
          const newContact = {
            firstName: values.firstName,
            lastName: values.lastName,
            mobile: values.mobile,
            designation: values.designation,
            email: values.email,
            isAdmin: values.isAdmin,
          };
          setKeyContacts([...keyContacts, newContact]);
          resetForm();

          console.log("list of contacts:", allContacts);
        // }
      }}
    >
      {({ handleSubmit, touched, values, errors, setFieldValue }) => (
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-7">
            {allContacts?.map((contact, index) => (
              <div
                className="bg-shadow"
                style={{ margin: "31px 60px", padding: "20px 20px 20px 40px" }}
                key={index}
              >
                <p style={{ fontSize: "16px" }}>
                  {contact?.firstName} {contact?.lastName} <br />
                  {contact?.designation} <br />
                  {contact?.email} &nbsp; &nbsp; {"+"+contact?.mobile}
                </p>
                <div className="d-flex align-items-center">
                  <p style={{ fontSize: "16px" }}>Is Admin?</p>
                  <input
                    type="checkbox"
                    className="ml-2"
                    name="isAdmin"
                    onChange={() => handleCheckboxChange(contact)}
                    checked={contact?.isAdmin}
                    onBlur={formik.handleBlur}
                    // checked={formik.values.isAdmin}
                  />
                  {contact?.isAdmin && (
                    <button
                      onClick={() => handleInvite(contact)}
                      type="button"
                      disabled={!contact.id}
                      style={{
                        marginLeft: "15px",
                        border: contact.id ? "1px solid #00a82d" : '1px solid #ddd',
                        backgroundColor: "#fff",
                        borderRadius: "3px",
                        color: contact.id ?  "#00a82d" : "#ddd",
                      }}
                    >
                      Invite
                    </button>
                  )}
                  <img
                    src={"./../assets/bin.png"}
                    style={{
                      width: "25px",
                      height: "25px",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => DeleteContact(contact)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="col-5">
            <p className="small-text ml-4">Add more key contacts here</p>
            <div className="key-contact">
              <p className="mb-3 font-weight-bold">Add Key Contact</p>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder="First Name*"
                  autoComplete="off"
                  name="firstName"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.firstName}
                />
                {/* {formik.touched.firstName && formik.errors.firstName && (
              <div className="form-error text-danger">
                {formik.errors.firstName}
              </div>
            )} */}
                {touched.firstName && errors.firstName && (
                  <div className="form-error text-danger">
                    {errors.firstName}
                  </div>
                )}
                {/* {firstNameFlag && (
              <div className="form-error text-danger">
                {"*First Name is Required"}
              </div>
            )} */}
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  autoComplete="off"
                  name="lastName"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.lastName}
                />
                {/* {formik.touched.lastName && formik.errors.lastName && (
              <div className="form-error text-danger">
                {formik.errors.lastName}
              </div>
            )} */}
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Email Address*"
                  autoComplete="off"
                  name="email"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.email}
                />
                {touched.email && errors.email && (
                  <div className="form-error text-danger">{errors.email}</div>
                )}
              </div>
              <div className="form-group">
                {/* <Field
                  type="number"
                  className="form-control"
                  placeholder="Phone Number"
                  autoComplete="off"
                  name="mobile"
                  onChange={(event) => {
                    setMobileFlag(false);
                    setFieldValue(`mobile`, event.target.value);
                  }}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.mobile}
                />
                {mobileFlag && (
                  <div className="form-error text-danger">
                    {"Phone Number should be 10 digits"}
                  </div>
                )} */}
                <PhoneInput
                  country={'in'}
                  countryCodeEditable={true}
                  value={values?.mobile}
                  name="mobile"
                  placeholder="Phone Number"
                  onChange={(e, value) => {
                    setFieldValue('mobile', e);
                  }}
                  style={{ height: '45px', width: '100%' }}
                  inputStyle={{ height: '45px', width: '100%', border: 'none' }}
                  // className="phone-input"
                  enableSearch={true}
                  dropdownStyle={{ position: 'relative', margin: '1px 0 10px -1px' }}
                />
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Designation"
                  autoComplete="off"
                  name="designation"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.designation}
                />
              </div>
              <button
                style={{
                  width: "100px",
                  backgroundColor: "#00a82d",
                  border: "none",
                  borderRadius: "5px",
                  color: "#ffffff",
                  height: "30px",
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  marginRight: "10px",
                }}
                // type="button"
                type="submit"
                // onClick={AddContact}
              >
                Add
              </button>
            </div>
          </div>

          {editMode ? (              <div className="ml-auto mr-3">
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  type="button"
                  onClick={() => handleSubmitForm(values)}
                >
                  Save
                </button>
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  type="button"
                  onClick={() => handleUpdateButtonClick()}
                >
                  Next
                </button>
            {/* <button
              style={{
                backgroundColor: "#00a82d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                width: "90px",
                height: "32px",
                marginLeft: "auto",
                marginRight: "35px",
                marginTop: "10px",
              }}
              type="button"
              onClick={() => handleSubmitForm(values)}
            >
              Update
            </button> */}
              </div>
          ) : (
            <>
            <div className="mt-3 ml-5 float-left">
              <button
                className="back_button"
                type="button"
                onClick={() => navigateToBackStep(2)}
              >
                Back
              </button>
            </div>
            <div className="mt-3 mr-5 ml-auto">
              <button
                className="cancel_button"
                type="button"
                onClick={() => navigate("/home")}
              >
                Cancel
              </button>
              <button
                className="next_button ml-3"
                type="button"
                onClick={() => handleSubmitForm(values)}
              >
                Save & Next
              </button>
            </div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default KeyContacts;

import React, { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";

const CustomerTypesLibrary = ({
  attribute,
  customerTypesLibrary,
  setCustomerTypesLibrary,
  customerTypesLibraryCopy,
  setCustomerTypesLibraryCopy,
  selectedCustomerTypes,
  setSelectedCustomerTypes,
  func,
  setSearchTerm,
  comesFrom,
  index
}) => {
  const [dragStart, setDragStart] = useState();
  const [dragEnter, setDragEnter] = useState();
  const [isDrag, setIsDrag] = useState(false);
  const [, forceUpdate] = useState();

  const dragItem = useRef(0);
  const dragOverItem = useRef(0);

  // console.log('dragItem out:',dragItem.current);
  // console.log('dragOverItem out:',dragOverItem.current);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "attribute",
      item: { name: attribute },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item?.name?.name === attribute?.name && dropResult?.name !== 'customer type library') {
          // console.log("Drop:", dropResult.name);
          // alert(`You threw ${item.name.name} into ${dropResult.name}`);
          // if (dropResult.name === "selected customer types") {
          //   const index = selectedCustomerTypes?.findIndex((attribute) => attribute.name === item.name.name)
          //   if(index !== -1) {
          //     selectedCustomerTypes.splice(index, 1);
          //   }
            // selectedCustomerTypes.push(item.name);
            //start
            // if(dragItem.current !== null && dragItem.current !== undefined && dragOverItem.current !== null && dragOverItem.current !== undefined) {
            //   console.log('C1');
            // console.log('dragStart:',dragStart);
            // console.log('dragEnter:',dragEnter);
            // let removedElement = selectedCustomerTypes.splice(dragStart, 1)[0];
            // console.log('removedElement:',removedElement);
            // selectedCustomerTypes.splice(dragEnter, 0, removedElement); 
            // console.log('selectedCustomerTypes:',selectedCustomerTypes);
            // } else {
            // if(!isDrag) {
            //   console.log('C2');
            // selectedCustomerTypes.push(item.name);
            // // }
            // //End
            // const filtered = customerTypesLibrary.filter(
            //   (cItem) => cItem !== item.name
            // );
            // setCustomerTypesLibrary(filtered);
            // // setCustomerTypesLibraryCopy(filtered);
            // setSearchTerm('');
          // } else {
            selectedCustomerTypes.push(item?.name);
            const filtered = customerTypesLibrary.filter(
              (cItem) => cItem !== item?.name
            );
            // const index = customerTypesLibrary?.findIndex((attribute) => attribute.name === item.name.name)
            // if(index !== -1) {
            //   customerTypesLibrary.splice(index, 1);
            // }
            // customerTypesLibrary.push(item.name);
            // customerTypesLibraryCopy.push(item.name);
            // const filtered = selectedCustomerTypes.filter(
            //   (cItem) => cItem !== item.name
            // );
            setCustomerTypesLibrary(filtered);
            setSearchTerm('');
          }
        // }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [
      attribute,
      customerTypesLibrary,
    //   customerTypesLibraryCopy,
      selectedCustomerTypes,
      // dragStart,
      // dragEnter,
      // dragItem.current,
      // dragOverItem.current
    ]
  );
  // console.log('isDragging:',isDragging);

  // console.log('dragStart out:',dragStart);
  // console.log('dragEnter out:',dragEnter);

  // const handleDragEnd = (result) => {
  //   // if (!result.destination) return;
  //   console.log('Result:',result);
  //   console.log('Destiny:',result.destination);
  //   const { source, destination } = result;
  //   console.log('source:',source);
  //   console.log('destination:',destination);

  //   // const newItems = Array.from(items);
  //   // const [reorderedItem] = newItems.splice(result.source.index, 1);
  //   // newItems.splice(result.destination.index, 0, reorderedItem);

  //   // setItems(newItems);
  // };

  // const opacity = isDragging ? 0 : 1

  // console.log('DS:',dragStart);
  // console.log('DE State:',dragEnter);

  // const onDragEnd = (e) => {
  // }

  // const handleSort = () => {
  //   console.log('sorted');
  //   console.log('dragItem.current:',dragItem.current);
  //   console.log('dragOverItem.current:',dragOverItem.current);
  //   let _selectedCustomerTypes = [...selectedCustomerTypes];
  //   const draggedItemContent = _selectedCustomerTypes.splice(dragItem.current, 1)[0];
  //   _selectedCustomerTypes.splice(dragOverItem.current, 0, draggedItemContent);
  //   console.log('_selectedCustomerTypes:',_selectedCustomerTypes);
  //   dragItem.current = null;
  //   dragOverItem.current = null;
  //   setSelectedCustomerTypes(_selectedCustomerTypes);
  // }

  const onDragStart = (index) => {
    // console.log('Drag started', index);
    setDragStart(index);
  }

  const onDragEnter = (index) => {
    console.log('isDragging', isDragging);
    if(!isDragging) {
      console.log('entered')
      console.log('Ind value:',index);
    setDragEnter(dragEnter => index);
    console.log('DE State:',dragEnter);
    }
  }

  const handleSort = () => {
    // console.log('Entered handle sort function...');
    // console.log('dragItem.current:',dragItem.current);
    // console.log('dragOverItem.current:',dragOverItem.current);
    // // forceUpdate(Math.random());
    // // if(dragStart !== undefined && dragEnter !== undefined) {
    // let removedElement = selectedCustomerTypes.splice(dragItem.current, 1)[0];
    // // console.log('removedElement:',removedElement);
    // selectedCustomerTypes.splice(dragOverItem.current, 0, removedElement); 
    // console.log('selectedCustomerTypes:',selectedCustomerTypes);
    // }

    console.log('Entered handle sort function...');
    const peopleClone = [...selectedCustomerTypes]
    const temp = peopleClone[dragItem.current]
    peopleClone[dragItem.current] = peopleClone[dragOverItem.current]
    peopleClone[dragOverItem.current] = temp
    setSelectedCustomerTypes(peopleClone);
  }

  return (
    <>
      <div className="wrapper" 
      // style={{ opacity: opacity }}
      key={index}
      // draggable
      // onDragStart={() => (dragItem.current = index)}
      // onDragEnter={() => (dragOverItem.current = index)}
      // onDragStart={(e) => onDragStart(index)}
      // onDragEnter={(e) => onDragEnter(index)}
      // onDragEnd={handleSort}
      // onDragOver={(e) => e.preventDefault()}
      // onDragEnd={handleDragEnd}
      ref={drag} style={{ opacity: isDragging ? 0 : 1 }}
      >
        <div className="logo">
          <img src={"/assets/drag.png"} />
        </div>
        <div>
          <input
            ref={drag}
            value={attribute?.name}
            disabled
            className="attribute"
            style={{
              border:
                attribute?.owner === "BLOOMR"
                  ? "2px solid rgb(63, 228, 63)"
                  : "",
              boxShadow:
                !attribute?.owner === "BLOOMR"
                  ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                  : "",
              // cursor: "pointer",
            }}
          />
        </div>
        {comesFrom === 'parentChild' ? (<>
            <button
            className="logo-delete"
            // onClick={() => handleDeleteClick(attribute.id)}
            onClick={() => func(attribute, "link")}
          >
            <img src={"/assets/link.png"} alt="link" />
          </button>
        </>) : (
            <>
            {attribute?.owner !== "BLOOMR" && (
          <button
            className="logo-delete"
            // onClick={() => handleDeleteClick(attribute.id)}
            onClick={() => func(attribute?.id, "attributesLibrary")}
          >
            <img src={"/assets/delete.png"} alt="delete" />
          </button>)}
          </>
        )}
      </div>
    </>
  );
};

export default CustomerTypesLibrary;

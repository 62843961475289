import React, { useState, useEffect, useCallback } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { getGeographicNodes } from "../../../../Redux/actions";
import AddNodeModal from "../../../../shared/AddNodeModal/AddNodeModal";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  Panel,
  Background,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from '@mui/material/styles';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import EditNodeModal from "../../../../shared/AddNodeModal/EditNodeModal";
import { useNavigate  } from "react-router-dom";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Flow = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { geographicNodes } = useSelector((state) => state.adminReducer);

  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const [addNodeModal, setAddNodeModal] = useState(false);
  const [nodesList, setNodesList] = useState([]);
  const [nodesId, setNodesId] = useState([]);
  const [editNodeModal, setEditNodeModal] = useState(false);
  const [editNode, setEditNode] = useState();

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;
  const nodeHeight = 36;

  const { fitView } = useReactFlow();

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "top";
      node.sourcePosition = "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    // window.requestAnimationFrame(() => {
    fitView();
    // });

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges,
    "TB"
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const position = { x: 0, y: 0 };

  useEffect(() => {
    dispatch(getGeographicNodes(loginObject?.token));
  }, []);

  const retrieve_node_name = (value) => {
    const newNode = {
      id: (nodes.length + 1)?.toString(),
      data: { label: value.node },
      position,
    };
    setInitialNodes([...initialNodes, newNode]);
    setNodes([...nodes, newNode]);
    if (value?.parent !== null) {
      const newEdge = {
        id: `e${value?.parent}${nodes?.length + 1}`,
        source: value?.parent,
        target: (nodes?.length + 1)?.toString(),
        animated: true,
      };
      setInitialEdges([...initialEdges, newEdge]);
      setEdges([...edges, newEdge]);
    }
    setTimeout(() => {
      document?.getElementById("vertical-button")?.click();
    }, [10]);
  };

  const edit_node1 = (value) => {
    console.log('Value:',value);
    const nodesCopy = [...nodes];
    const nodeById = nodesCopy?.filter((node) => node.id === value.id);
    const nodeIndex = initialNodes?.findIndex((node) => node.id === value.id)
    if (nodeById) {
      nodeById[0].data.label = value.node;
    }
    initialNodes.splice(nodeIndex, 1, nodeById[0]);
    setTimeout(() => {
      setInitialNodes(initialNodes);
    }, [10]);
    nodes.splice(nodeIndex, 1, nodeById[0]);
    setNodes(nodes);
    setTimeout(() => {
      document?.getElementById("vertical-button")?.click();
    }, [10]);
  }

  const edit_node = (value) => {
    setNodes((nodes) => 
    nodes.map((node) => {
      if(node.id === value.id) {
        node.data = {
          ...node.data,
          label: value.node
        };
      }
      return node;
    }
  ));
  setInitialNodes((nodes) => 
    nodes.map((node) => {
      if(node.id === value.id) {
        node.data = {
          ...node.data,
          label: value.node
        };
      }
      return node;
    }
  ));
  if(value?.parent  !== null) {
    const index = edges?.findIndex((edge) => edge.target === (value?.id)?.toString());
    edges.splice(index, 1);
    const newEdge = {
      id: `e${value?.parent}${value?.id}`,
      source: value?.parent,
      target: (value?.id)?.toString(),
      animated: true,
    };
    setInitialEdges([...initialEdges, newEdge]);
    setEdges([...edges, newEdge]);
  }   
  setTimeout(() => {
    document?.getElementById("vertical-button")?.click();
  }, [10]);
  }

  const handleDelete = (selectedNode) => {
    // console.log('selectedNode:',selectedNode);
    const newNodes = nodes?.filter((node) => node.id !== selectedNode.id);
    // console.log('newNodes:',newNodes);
    setNodes(newNodes);
    setInitialNodes(newNodes);
    if(edges?.length > 0) {
      const newEdges = edges?.filter((edge) => edge.target !== selectedNode?.id);
      // console.log('newEdges:',newEdges);
      setEdges(newEdges);
      setInitialEdges(newEdges);
    }
  }

  // console.log('Nodes:',nodes);
  // console.log('Edges:',edges);
  // console.log('nodesList:',nodesList);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges]
  );

  // console.log('Nodes:',nodes);
  // console.log('Edges:',edges);

  // useEffect(() => {
  //   const createHierarchy = (nodes, edges, parentId) => {
  //     const parent = nodes.find(node => node.id === parentId);
  //     const children = edges
  //       .filter(edge => edge.source === parentId)
  //       .map(edge => ({
  //         id: edge.target,
  //         data: nodes.find(node => node.id === edge.target).data,
  //         child: createHierarchy(nodes, edges, edge.target)
  //       }));
  //     return { parent, child: children };
  //   };

  //   // Find the root node(s) (nodes without a source)
  //   const rootNodeIds = nodes1.filter(node => !edges1.some(edge => edge.target === node.id)).map(node => node.id);

  //   // Create hierarchy for each root node
  //   const result = rootNodeIds.map(rootNodeId => createHierarchy(nodes1, edges1, rootNodeId));

  //   console.log('Result:',result);

  // }, [edges]);

  // useEffect(() => {
  // const obje = [];
  // for(let i = 0; i < edges?.length; i++) {
  //   const parentNode = nodes?.filter((node) => node.id === edges[i].source);
  //   const childNode = nodes?.filter((node) => node.id === edges[i].target);

  //   // Check if the parent already exists in obje
  //   const existingObj = obje.find(obj => obj.parent.id === parentNode[0].id);

  //   if (existingObj) {
  //       // If parent exists, add child to its children array
  //       existingObj.child.push(childNode[0]);
  //   } else {
  //       // If parent doesn't exist, create a new object and push to obje
  //       obje.push({
  //           parent: parentNode[0],
  //           child: [childNode[0]]
  //       });
  //   }
  //   setNodesList(obje);
  //   // const obj = {
  //   //   parent: node,
  //   //   child: [nodeChild]
  //   // }
  //   // if(i === 0) {
  //   //   obje.push(obj);
  //   // }
  //   // if(i > 0) {
  //   //   for(let j = 0; j < obje?.length; j++) {
  //   //     if(obje[j]?.parent?.id === node?.id) {
  //   //       obje[j]?.child.push(nodeChild)
  //   //     } else {
  //   //       obje.push(obj);
  //   //     }
  //   //   }
  //   // }
  // }
  // }, [edges]);

  // console.log("nodesList:", nodesList);

  useEffect(() => {
    //   const nodes = [
    //     { id: '1', data: { label: 'HQ' } },
    //     { id: '2', data: { label: 'North' } },
    //     { id: '3', data: { label: 'South' } },
    //     { id: '4', data: { label: 'Delhi' } },
    //     { id: '5', data: { label: 'Kerela' } }
    // ];

    // const edges = [
    //     { id: 'e12', source: '1', target: '2' },
    //     { id: 'e13', source: '1', target: '3' },
    //     { id: 'e24', source: '2', target: '4' },
    //     { id: 'e35', source: '3', target: '5' }
    // ];

    // Function to recursively build the hierarchical structure Woeking
    const buildHierarchy = (parentId) => {
      const parentNode = nodes.find((node) => node.id === parentId);
      const children = edges
        .filter((edge) => edge.source === parentId)
        .map((edge) => ({
          ...nodes.find((node) => node.id === edge.target),
          child: buildHierarchy(edge.target),
        }));
      return children.length > 0
        ? { ...parentNode, child: children }
        : parentNode;
    };

    // Starting with the root node (HQ)
    const root = buildHierarchy("1");
    setNodesList(root);

    // console.log('Root:',root);
  }, [edges]);

  const handleEdit = (node) => {
    setEditNode(node);
  }

  const renderTreeItems = (node) => {
    return (
      <>
        {/* <TreeItem itemId={node?.id * Math.floor(Math.random() * 54239)} label={node?.data?.label}> */}
        <TreeItem
          // itemId={Math.floor(Date.now() * Math.random())}
          itemId={node?.id}
          label={node?.data?.label}
        >
          <button className="btn-edit" 
          onClick={() => {
            setEditNodeModal(true);
            handleEdit(node)
            }}
          >
            <img src={"/assets/pencil-tool.png"} alt="" style={{ width: "20px"}}/>
          </button>
          {!node?.child && <button className="btn-delete" onClick={() => handleDelete(node)}>
          <img src={"/assets/delete.png"} alt="" style={{ width: "20px"}}/>
          </button>}
          {node?.child &&
            Array.isArray(node?.child) &&
            node?.child?.map((childNode) => renderTreeItems(childNode?.child))}
        </TreeItem>
      </>
    );
  };

  const getAllIds = (node, idSet) => {
    if (!node) return;
    
    idSet.add(node.id);
    
    if (Array.isArray(node.child)) {
      node.child.forEach(child => {
        getAllIds(child.child, idSet);
      });
    }
  };

  useEffect(() => {
    const idSet = new Set();
    getAllIds(nodesList, idSet);

    const uniqueIdsArray = Array.from(idSet);
    // console.log('uniqueIdsArray:',uniqueIdsArray);
      setNodesId(uniqueIdsArray);
  }, [nodesList]);

  function ExpandIcon(props) {
    return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
  }
  
  function CollapseIcon(props) {
    return <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
  }
  
  function EndIcon(props) {
    return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
  }

  console.log('Nodes list:',nodesList);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="org-structure-container">
          <div
            style={{
              height: "500px",
              width: "600px",
              padding: "10px",
              margin: "5px",
              border: "1px solid black",
            }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              // onConnect={onConnect}
              fitView
            >
              <Background />
              <Controls />
              <Panel position="top-right">
                <button id="vertical-button" onClick={() => onLayout("TB")}>
                  Re-order
                </button>
                {/* <button onClick={() => onLayout('LR')}>horizontal layout</button> */}
              </Panel>
            </ReactFlow>
          </div>
          <div className="nodes">
            <div className="org-structure-child child-2">
              <p className="heading pt-1">Current Nodes</p>
              <button
                style={{
                  backgroundColor: "#00a82d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  width: "90px",
                  height: "30px",
                  padding: "2px 8px",
                  marginRight: "10px",
                }}
                onClick={() => setAddNodeModal(true)}
              >
                + Add Node
              </button>
            </div>
            <div className="nodes-list-tree">
              {nodesList !== undefined && nodesId?.length > 0 && (
              <SimpleTreeView
              defaultExpandedItems={nodesId}
              aria-label="customized"
              // slots={{
              //   expandIcon: ExpandIcon,
              //   collapseIcon: CollapseIcon,
              //   endIcon: EndIcon,
              // }}
              sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}
              >
                {/* <TreeItem itemId={nodesList?.id} label={nodesList?.data?.label}> */}
                {/* {nodesList?.child?.length > 0 && nodesList?.child?.map((node, index) => (
                <TreeItem itemId={node?.id} label={node?.data?.label} />
              ))} */}
                {renderTreeItems(nodesList)}
                {/* </TreeItem> */}
              </SimpleTreeView>)}
            </div>
          </div>
        </div>
        <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
          //   setShowTab(false);
          //   navigateToFormStep(3);
          navigate('/admin/organization-setup');
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            // setShowTab(false);
            // navigateToFormStep(3);
            // navigate(`/admin/attributes-list-review`, {
            //   state: {
            //     mandatoryAttributes: mandatoryAttributes,
            //     nonMandatoryAttributes: nonMandatoryAttributes
            //   }
            // });
          }}
        >
          Save & Create
        </button>
      </div>
      </div>
      {addNodeModal && (
        <AddNodeModal
          setAddNodeModal={setAddNodeModal}
          initialNodes={nodes}
          func={retrieve_node_name}
        />
      )}
      {editNodeModal && (
        <EditNodeModal
        setEditNodeModal={setEditNodeModal}
        initialNodes={nodes}
        node={editNode}
        func={edit_node}
        edges={edges}
        />
      )}
    </>
  );
};

function OrganizationStructure({ sidebarCollapsed }) {
  return (
    <ReactFlowProvider>
      <Flow sidebarCollapsed={sidebarCollapsed} />
    </ReactFlowProvider>
  );
}

export default OrganizationStructure;

import React from 'react';
import { useDrop, useDrag } from 'react-dnd';

// const MandatoryAttributes = ({ attributes }) => {
const MandatoryAttributes = ({ attribute, isMandatory, mandatoryAttributes, attributesLibrary, nonMandatoryAttributes, onChange, func, setupStatus }) => {

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "attribute",
    item: { name: attribute },
    end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if(item.name.name === attribute.name && dropResult) {
            // alert(`You threw ${item.name.name} into ${dropResult.name}`);
            // let addedObj = {name: item.name.name, isMandatory: false, isEditable: true}
            if(dropResult.name === 'non mandatory attributes') {
              // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: false, inputType: item.name.inputType, owner: item.name.owner}
              // nonMandatoryAttributes.push(addedObj);
              nonMandatoryAttributes.push(item.name);
            } else if(dropResult.name === 'attributes library') {
              // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: false, inputType: item.name.inputType, owner: item.name.owner}
              // attributesLibrary.push(addedObj);
              attributesLibrary.push(item.name);
            }
            const filtered = mandatoryAttributes.filter(cItem => {
                return !nonMandatoryAttributes.some(aItem => aItem.name === cItem.name) && !attributesLibrary.some(bItem => bItem.name === cItem.name);
              });
            onChange(filtered, "mandatory attributes")
        }
    },
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    })
}), [attribute, attributesLibrary, mandatoryAttributes, nonMandatoryAttributes]);

  return (
    <>
    <div className='wrapper' ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='logo'>
        <img src={"/assets/drag.png"} />
      </div>
    <div> 
      <input 
      // ref={drag}
      value={attribute.name} 
      // disabled={mandatoryAttributes ? true : false}
      disabled
      className="attribute"
      style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
      boxShadow: attribute.owner === 'ORG' ? "1px 0.5px 2px 0px" : "", cursor: "pointer", minHeight: '40px' }}
      />
    </div>
    {attribute.owner !== "BLOOMR" && (
        <button 
        className='logo-delete'
        // onClick={() => handleDeleteClick(attribute.id)}
        //Working line
        // onClick={() => func(attribute?.id, "mandatoryAttributes")}
        onClick={() => {
          if(setupStatus?.userMasterSetup) {
            if(attribute?.orgAttributeId != undefined) {
            func(attribute?.orgAttributeId, "mandatoryAttributes", "link2")
            } else {
              func(attribute?.id, "mandatoryAttributes", "link1")
            }
          } else {
            func(attribute?.id, "mandatoryAttributes", "link1")
          }
        }}
        >
            <img src={"/assets/delete.png"} />
        </button>
    )}
    </div>
    </>
  )

  // return (
  //   <>
  //   <div className="usermaster_box bg-shadow" style={{ marginLeft: "30px", marginRight: "0" }} id="style-3" ref={drop}>
  //           <p className="module_head mb-2">Mandatory Attributes</p>
  //           {attributes?.map((attribute) => (
  //               // <div className="attribute" 
  //               // style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
  //               // boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}> 
  //               // {attribute.name}
  //               // </div>
              
  //               <div>
  //                 <input 
  //                 value={attribute.name} 
  //                 disabled={attribute.isMandatory ? true : false}
  //                 className="attribute"
  //                 style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
  //                 boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}
  //                 />
  //               </div>
  //           ))}
  //       </div>
  //   </>
  // )
}

export default MandatoryAttributes
import React, { useState, useCallback, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { addNewProduct } from "../../../../Redux/actions";
import Loader from "../../../../shared/Loader/Loader";
import { useDropzone } from "react-dropzone";
import { Constants } from "../../SuperAdmin/Credentials";
import { toast } from "react-toastify";

const AddProductModal = ({ setAddProductModal, productAttributes }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const { loading } = useSelector((state) => state.adminReducer);
  const [acceptedFile, setAcceptedFile] = useState([]);
  const [pincodeLoader, setpincodeLoader] = useState(false);
  const [imageIndex, setImageIndex] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [linkListIndex1, setLinkListIndex1] = useState();
  const [linkListIndex2, setLinkListIndex2] = useState();
  const [linkListOptions1, setLinkListOptions1] = useState();
  const [linkListOptions2, setLinkListOptions2] = useState();
  const [fieldValues, setFieldValues] = useState();
  const [businesstypes, setBusinessTypes] = useState([]);
  const [businessSegments, setBusinessSegments] = useState([]);

  const dispatch = useDispatch();
  const Api = Constants.api;

  const onDrop = useCallback((files) => {
    setImageIndex();
    setAcceptedFile((acceptedFile) => [...acceptedFile, files[0]]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  });

  const handleAddClick = () => {
    // const props = getInputProps();
    // setAddButtonClick(true);
  };

  const handleImageClick = (e, index) => {
    e.stopPropagation();
    setImageIndex(index);
  };

  const handleImageDelete = (e) => {
    e.stopPropagation();
    setImageIndex();
    const filter = acceptedFile.filter((_, index) => index !== imageIndex);
    setAcceptedFile(filter);
  };

  useEffect(() => {
    if (linkListIndex1 !== undefined) {
      dispatch(
        getListChildOptions(
          linkListIndex1,
          setLinkListOptions1,
          loginObject?.token
        )
      );
    }
  }, [linkListIndex1]);

  useEffect(() => {
    if (linkListIndex2 !== undefined) {
      dispatch(
        getListChildOptions(
          linkListIndex2,
          setLinkListOptions2,
          loginObject?.token
        )
      );
    }
  }, [linkListIndex2]);

  const getListChildOptions =
    (listOptionId, setFunction, token) => async (dispatch) => {
      await fetch(`${Constants.api}/admin/list/child/${listOptionId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then(async (responseData) => {
          if (responseData.success) {
            setFunction(responseData.response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

  const getInitialValues = () => {
    const initialValues = {};
    productAttributes.forEach((attribute) => {
      initialValues[attribute.name] = ""; // Set initial value as empty string
      if (attribute.inputType === "ADDRESS") {
        // initialValues["line1"] = '';
        // initialValues["line2"] = '';
        // initialValues["city"] = '';
        // initialValues["state"] = '';
        // initialValues["country"] = '';
        // initialValues["pincode"] = '';
        initialValues[`line1${attribute?.orgAttributeId}`] = "";
        initialValues[`line2${attribute?.orgAttributeId}`] = "";
        initialValues[`city${attribute?.orgAttributeId}`] = "";
        initialValues[`state${attribute?.orgAttributeId}`] = "";
        initialValues[`country${attribute?.orgAttributeId}`] = "";
        initialValues[`pincode${attribute?.orgAttributeId}`] = "";
      }
    });
    return initialValues;
  };

  const validate = (values, productAttributes) => {
    const errors = {};
    productAttributes.forEach((attribute) => {
      if (attribute.isMandatory && !values[attribute.name]) {
        errors[attribute.name] = `*${attribute.name} is mandatory`;
      }
      if (attribute.inputType === "ADDRESS") {
        // if (!values["line1"]) {
        //   errors["line1"] = `*Address Line 1 is mandatory`;
        // }
        // // if (!values["line2"]) {
        // //   errors["line2"] = `*Address Line 2 is mandatory`;
        // // }
        // if (!values["city"]) {
        //   errors["city"] = `*City is mandatory`;
        // }
        // if (!values["state"]) {
        //   errors["state"] = `*State is mandatory`;
        // }
        // if (!values["country"]) {
        //   errors["country"] = `*Country is mandatory`;
        // }
        // if (!values["pincode"]) {
        //   errors["pincode"] = `*Pincode is mandatory`;
        // }
        if (!values[`line1${attribute?.orgAttributeId}`]) {
          errors[
            `line1${attribute?.orgAttributeId}`
          ] = `*Address Line 1 is mandatory`;
        }
        // if (!values["line2"]) {
        //   errors["line2"] = `*Address Line 2 is mandatory`;
        // }
        if (!values[`city${attribute?.orgAttributeId}`]) {
          errors[`city${attribute?.orgAttributeId}`] = `*City is mandatory`;
        }
        if (!values[`state${attribute?.orgAttributeId}`]) {
          errors[`state${attribute?.orgAttributeId}`] = `*State is mandatory`;
        }
        if (!values[`country${attribute?.orgAttributeId}`]) {
          errors[
            `country${attribute?.orgAttributeId}`
          ] = `*Country is mandatory`;
        }
        if (!values[`pincode${attribute?.orgAttributeId}`]) {
          errors[
            `pincode${attribute?.orgAttributeId}`
          ] = `*Pincode is mandatory`;
        }
      }
      if(attribute?.isMandatory && attribute.inputType === 'CONTACT') {
        if (!values[`firstName${attribute?.orgAttributeId}`]) {
          errors[`firstName${attribute?.orgAttributeId}`] = `*First Name is mandatory`;
        }
        if (!values[`lastName${attribute?.orgAttributeId}`]) {
          errors[`lastName${attribute?.orgAttributeId}`] = `*Last Name is mandatory`;
        }
        if (!values[`phone${attribute?.orgAttributeId}`]) {
          errors[`phone${attribute?.orgAttributeId}`] = `*Phone Number is mandatory`;
        }
        if (!values[`email${attribute?.orgAttributeId}`]) {
          errors[`email${attribute?.orgAttributeId}`] = `*Email is mandatory`;
        }
        if (!values[`designation${attribute?.orgAttributeId}`]) {
          errors[`designation${attribute?.orgAttributeId}`] = `*Designation is mandatory`;
        }
      }
    });
    //Email Validation
    // productAttributes.forEach(attribute => {
    //     if (attribute.isMandatory && !values[attribute.name]) {
    //       errors[attribute.name] = `*${attribute.name} is mandatory`;
    //     } else if (attribute.name === 'Email' && values[attribute.name]) {
    //       // Additional validation for email format
    //       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //       if (!emailRegex.test(values[attribute.name])) {
    //         errors[attribute.name] = 'Invalid email format';
    //       }
    //     }
    //   });
    return errors;
  };

  const getUrl = async (file) => {
    setUploading(true);
    //get uploadUrl to upload file
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(`${Api}/file/upload-url/${file.name}`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
          "Content-Type": "application/json",
        }),
      });
      const data = await response.json();
      if (data.success) {
        setImageUrls((imageUrls) => [
          ...imageUrls,
          data?.response?.documentUrl,
        ]);
        uploadFile(data.response.fileUploadUrl, file);
      } else {
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
      console.error("Error getting file url:", error);
    }
  };

  const uploadFile = async (fileUploadUrl, file) => {
    // upload file to generated URL
    try {
      const response = await fetch(fileUploadUrl, {
        method: "PUT",
        headers: new Headers({
        }),
        body: file,
      });
      if (response?.status === 200) {
        console.log("file upload res: " + response);
        setUploading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  const handleAddProduct = (values) => {
    if (acceptedFile?.length > 0) {
      setFieldValues(values);
      for (let file of acceptedFile) {
        getUrl(file);
      }
    } else {
      addProduct(values);
    }
  };

  const addProduct = (values) => {
    const data = [];
    productAttributes?.map((attribute) => {
      const obj = {
        orgAttributeId: attribute?.orgAttributeId,
        [attribute?.inputType !== "ADDRESS" && attribute?.inputType !== 'CONTACT' ? "value" : attribute?.inputType === 'ADDRESS' ? 'addressValue' : 'contactValue']:
          attribute?.inputType !== "ADDRESS" && attribute?.inputType !== 'CONTACT'
            ? values[attribute.name] !== ""
              ? values[attribute.name]
              : null
            : attribute?.inputType === 'ADDRESS' ? {
                // line1: values["line1"],
                // line2: values["line2"],
                // city: values["city"],
                // state: values["state"],
                // country: values["country"],
                // pincode: values["pincode"],
                line1: values[`line1${attribute?.orgAttributeId}`],
                line2: values[`line2${attribute?.orgAttributeId}`],
                city: values[`city${attribute?.orgAttributeId}`],
                state: values[`state${attribute?.orgAttributeId}`],
                country: values[`country${attribute?.orgAttributeId}`],
                pincode: values[`pincode${attribute?.orgAttributeId}`],
              } : {
                firstName : values[`firstName${attribute?.orgAttributeId}`],
                lastName : values[`lastName${attribute?.orgAttributeId}`],
                phone : values[`phone${attribute?.orgAttributeId}`]?.toString(),
                email : values[`email${attribute?.orgAttributeId}`],
                designation : values[`designation${attribute?.orgAttributeId}`]
              }
      };
      data.push(obj);
    });
    const finalData = data?.filter((d) => d.value !== null);
    dispatch(
      addNewProduct(
        finalData,
        imageUrls,
        setAddProductModal,
        loginObject?.token
      )
    );
  };

  const getLocation = async (pincode, setFieldValue, orgAttributeId) => {
    if (pincode?.length === 6) {
      setpincodeLoader(true);
      const resp = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
        {
          method: "GET",
          headers: new Headers({}),
        }
      ).catch((error) => console.log(error));
      const respJson = await resp.json();
      // Update form fields with fetched data
      if (respJson && respJson.length > 0) {
        setpincodeLoader(false);
        const { State, Country, PostOffice } = respJson[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, Country, Region } = PostOffice[0];
          // formik.setFieldValue("communicationAddress.country", Country);
          // formik.setFieldValue("communicationAddress.state", State);
          // formik.setFieldValue("communicationAddress.city", Region);
          setFieldValue(`country${orgAttributeId}`, Country);
          setFieldValue(`state${orgAttributeId}`, State);
          setFieldValue(`city${orgAttributeId}`, Region);
          // formik.setFieldValue("billingAddress.country", Country);
          // formik.setFieldValue("billingAddress.state", State);
          // formik.setFieldValue("billingAddress.city", Name);
        }
      }
    }
  };

  useEffect(() => {
    if (acceptedFile?.length > 0) {
      if (
        acceptedFile?.length === imageUrls?.length &&
        fieldValues !== undefined
      ) {
        addProduct(fieldValues);
      }
    }
  }, [acceptedFile, imageUrls, fieldValues]);

  useEffect(() => {
    const getDropdowns = async () => {
      const resp = await fetch(`${Api}/data/business_type/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const responseData = await resp.json();
      if (responseData.success) {
        setBusinessTypes(responseData.response);
      } else {
        console.log("failed to fetch business types");
      }

      const res = await fetch(`${Api}/data/business_segment/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const respJson = await res.json();
      if (respJson.success) {
        setBusinessSegments(respJson.response);
      } else {
        console.log("failed to fetch business segments");
      }
    };
    getDropdowns();

    // if (editMode) {
    //   formik.setValues({
    //     ...formik.values,
    //     name: organizationDetails.name || "",
    //     businessTypeId: organizationDetails.businessTypeId || "",
    //     channelPartner: organizationDetails.channelPartner || "",
    //     logoUrl: organizationDetails.logoUrl || "",
    //     businessSegmentId: organizationDetails.businessSegmentId || "",
    //     gst: organizationDetails.gst || "",
    //     mobileCode: organizationDetails.mobileCode || "+91",
    //     mobileNumber: organizationDetails.mobileNumber || "",
    //     accountManager: organizationDetails.accountManager || ""
    //   });
    // }
  }, []);

  return (
    <>
      <div className="custom-attribute-modal-wrapper">
        <div className="add-user-modal">
          <div>
            <h4>Add New Product</h4>
          </div>
          {/* <div className='add-user-details'> */}
          <Formik
            initialValues={getInitialValues()}
            validateOnChange={true}
            validate={(values) => validate(values, productAttributes)}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Values submitted:", values);
              handleAddProduct(values);
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, setFieldValue, touched, errors, values }) => (
              <>
                {/* {console.log('Values:',values)} */}
                {/* {console.log('errors:',errors)} */}
                <Form>
                  <div className="form-fields">
                    {productAttributes
                      ?.filter(
                        (attribute) => attribute.inputType === "LIST_LINK"
                      ).map((attribute, index) => (
                        <>
                          <div
                            key={index}
                            className="form-group"
                            style={{ marginBottom: "10px", padding: "5px" }}
                          >
                            <label
                              htmlFor={attribute?.name}
                              className="required-field"
                            >
                              {attribute?.name}
                            </label>
                            <select
                              className="input-group-select"
                              name={attribute?.name}
                              onChange={(e) => {
                                setFieldValue(
                                  `${attribute?.name}`,
                                  e?.target?.value
                                );
                                if (index === 0) {
                                  setLinkListIndex1(e?.target?.value);
                                  setLinkListOptions1([]);
                                  setFieldValue(
                                    `${productAttributes[1]?.name}`,
                                    ""
                                  );
                                  setLinkListOptions2([]);
                                  setFieldValue(
                                    `${productAttributes[2]?.name}`,
                                    ""
                                  );
                                } else if (index === 1) {
                                  setLinkListIndex2(e?.target?.value);
                                  setLinkListOptions2([]);
                                  setFieldValue(
                                    `${productAttributes[2]?.name}`,
                                    ""
                                  );
                                }
                              }}
                            >
                              <option
                                value=""
                                defaultValue=""
                                hidden
                              >{`Select ${attribute?.name}`}</option>
                              {index === 0 &&
                                attribute?.options?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                ))}
                              {index === 1 &&
                                linkListOptions1?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                ))}
                              {index === 2 &&
                                linkListOptions2?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                ))}
                            </select>
                            <ErrorMessage
                              name={attribute.name}
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        </>
                      ))}
                    {productAttributes
                      ?.filter(
                        (attribute) => attribute.inputType !== "LIST_LINK"
                      )
                      ?.map((attribute, index) => (
                        <>
                          <div
                            key={index}
                            className="form-group"
                            style={{ marginBottom: "10px", padding: "5px" }}
                          >
                            <label
                              htmlFor={attribute?.name}
                              className={
                                attribute?.inputType !== "ADDRESS" &&
                                attribute?.inputType !== 'CONTACT' &&
                                attribute?.isMandatory
                                  ? "required-field"
                                  : ""
                              }
                            >
                              {attribute?.inputType !== "ADDRESS" &&
                              attribute?.inputType !== 'CONTACT' &&
                                attribute?.name}
                            </label>
                            {
                              attribute?.inputType !== "ADDRESS" &&
                              attribute?.inputType !== "LIST" &&
                              attribute?.inputType !== "GEO_NODE" &&
                              attribute?.inputType !== "LIST_LINK" &&
                              attribute?.inputType !== 'CONTACT' && 
                              attribute?.inputType !== 'BUSINESS_SEG' && 
                              attribute?.inputType !== 'BUSINESS_TYPE' ? (
                                <>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name={attribute?.name}
                                    autoComplete="off"
                                    style={{ height: attribute?.name === 'Description' ? "auto" : "30px" }}
                                    as={attribute?.name === 'Description' ? "textarea" : "input"}
                                  />
                                  <ErrorMessage
                                    name={attribute.name}
                                    component="div"
                                    style={{ color: "red" }}
                                  />
                                </>
                              ) : attribute?.inputType === "ADDRESS" ? (
                                <>
                                  <label className="required-field">
                                    {attribute?.name}
                                  </label>
                                  <br />
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`line1${attribute?.orgAttributeId}`}
                                      className="required-field"
                                    >{`Address Line 1`}</label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="line1"
                                      name={`line1${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                    />
                                  </div>
                                  {/* <ErrorMessage name={"line1"} component="div" style={{ color: 'red' }} /> */}
                                  <ErrorMessage
                                    name={`line1${attribute?.orgAttributeId}`}
                                    component="div"
                                    style={{ color: "red" }}
                                  />
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`line2${attribute?.orgAttributeId}`}
                                    >
                                      {"Address Line 2"}
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="line2"
                                      name={`line2${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`pincode${attribute?.orgAttributeId}`}
                                      className="required-field"
                                    >
                                      {"Pincode"}
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="pincode"
                                      name={`pincode${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                      onChange={(e) => {
                                        const inputValue = e?.target?.value;
                                        // Check if the input value has more than 6 digits, if yes, trim it to 6 digits
                                        const trimmedValue = inputValue.slice(
                                          0,
                                          6
                                        );
                                        getLocation(
                                          trimmedValue,
                                          setFieldValue,
                                          attribute?.orgAttributeId
                                        );
                                        setFieldValue(
                                          `pincode${attribute?.orgAttributeId}`,
                                          trimmedValue
                                        );
                                        // getLocation(e?.target?.value, setFieldValue)
                                        // setFieldValue(`communicationAddress.pincode`, e?.target?.value);
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`pincode${attribute?.orgAttributeId}`}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`city${attribute?.orgAttributeId}`}
                                      className="required-field"
                                    >
                                      {"City"}
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="city"
                                      name={`city${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                    />
                                    <ErrorMessage
                                      name={`city${attribute?.orgAttributeId}`}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`state${attribute?.orgAttributeId}`}
                                      className="required-field"
                                    >
                                      {"State"}
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="state"
                                      name={`state${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                    />
                                    <ErrorMessage
                                      name={`state${attribute?.orgAttributeId}`}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      padding: "5px",
                                    }}
                                  >
                                    <label
                                      htmlFor={`country${attribute?.orgAttributeId}`}
                                      className="required-field"
                                    >
                                      {"Country"}
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      // name="country"
                                      name={`country${attribute?.orgAttributeId}`}
                                      autoComplete="off"
                                      style={{ height: "30px" }}
                                    />
                                    <ErrorMessage
                                      name={`country${attribute?.orgAttributeId}`}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </>
                              ) : attribute?.inputType === 'CONTACT' ? (<>
                                <label className={attribute?.isMandatory ? 'required-field' : ''}>{attribute?.name}</label>
                                   <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                                    <label htmlFor={`firstName${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                    {`First Name`}
                                    </label>
                                    <Field
                                    type="text"
                                    className="form-control"
                                    name={`firstName${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: '30px' }}
                                    />
                                  </div>
                                  <ErrorMessage name={`firstName${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
            
                                  <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                                    <label htmlFor={`lastName${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                    {`Last Name`}
                                    </label>
                                    <Field
                                    type="text"
                                    className="form-control"
                                    name={`lastName${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: '30px' }}
                                    />
                                  </div>
                                  <ErrorMessage name={`lastName${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
            
                                  <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                                    <label htmlFor={`phone${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                    {`Phone Number`}
                                    </label>
                                    <Field
                                    type="number"
                                    className="form-control"
                                    name={`phone${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: '30px' }}
                                    />
                                  </div>
                                  <ErrorMessage name={`phone${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
            
                                  <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                                    <label htmlFor={`email${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                    {`Email`}
                                    </label>
                                    <Field
                                    type="text"
                                    className="form-control"
                                    name={`email${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: '30px' }}
                                    />
                                  </div>
                                  <ErrorMessage name={`email${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
            
                                  <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                                    <label htmlFor={`designation${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                    {`Designation`}
                                    </label>
                                    <Field
                                    type="text"
                                    className="form-control"
                                    name={`designation${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: '30px' }}
                                    />
                                  </div>
                                  <ErrorMessage name={`designation${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                                  
                              </>) : attribute?.inputType === 'BUSINESS_SEG' ? (<>
                                <select
                                className="input-group-select"
                                name={attribute?.name}
                                onChange={(e) => setFieldValue(`${attribute?.name}`, e?.target?.value)}
                                >
                                  <option value="" defaultValue="">{`Select ${attribute?.name}`}</option>
                                  {businessSegments?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                  ))}
                              </select>
                              <ErrorMessage name={attribute.name} component="div" style={{ color: 'red' }} />
                              </>) : attribute?.inputType === 'BUSINESS_TYPE' ? (<>
                                <select
                                className="input-group-select"
                                name={attribute?.name}
                                onChange={(e) => setFieldValue(`${attribute?.name}`, e?.target?.value)}
                                >
                                  <option value="" defaultValue="">{`Select ${attribute?.name}`}</option>
                                  {businesstypes?.map((opt) => (
                                  <option value={opt?.id}>{opt?.name}</option>
                                  ))}
                              </select>
                              <ErrorMessage name={attribute.name} component="div" style={{ color: 'red' }} />
                              </>) : (
                                attribute?.inputType === "LIST" && (
                                  <>
                                    <select
                                      className="input-group-select"
                                      name={attribute?.name}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `${attribute?.name}`,
                                          e?.target?.value
                                        )
                                      }
                                    >
                                      <option
                                        value=""
                                        defaultValue=""
                                      >{`Select ${attribute?.name}`}</option>
                                      {attribute?.options?.map((opt) => (
                                        <option value={opt?.id}>
                                          {opt?.name}
                                        </option>
                                      ))}
                                    </select>
                                    <ErrorMessage
                                      name={attribute.name}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </>
                                )
                              )
                              // : (<>
                              // <select
                              //   className="input-group-select"
                              //   name={attribute?.name}
                              //   onChange={(e) => setFieldValue(`${attribute?.name}`, e?.target?.value)}
                              // >
                              //   <option value="" defaultValue="">{`Select ${attribute?.name}`}</option>
                              //   {attribute?.options?.map((opt) => (
                              //   <option value={opt?.id}>{opt?.name}</option>
                              //   ))}
                              // </select>
                              // <ErrorMessage name={attribute.name} component="div" style={{ color: 'red' }} />
                              // </>)
                            }
                          </div>
                        </>
                      ))}
                    <div className="d-flex justify-content-between" style={{ paddingLeft: '5px' }}>
                      <label>Upload Photos</label>
                      <div {...getRootProps()}>
                        <button
                          style={{ backgroundColor: "inherit", border: "none" }}
                          type="button"
                          onClick={handleAddClick}
                        >
                          <img
                            src={"/assets/add.png"}
                            alt=""
                            style={{
                              height: "17px",
                              objectFit: "cover",
                              margin: "0 5px 5px 0",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      {...getRootProps()}
                      style={{
                        height: acceptedFile?.length > 0 ? "auto" : "30%",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "space-between",
                        textAlign: "center",
                        // cursor: "pointer",
                        maxWidth: "95%",
                        overflowX: "auto",
                        marginLeft: "5px",
                        marginBottom: "5px",
                      }}
                      className="form-control"
                    >
                      <input {...getInputProps()} />
                      {acceptedFile?.length > 0 ? (
                        <>
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              gap: "10px",
                              height: "80%",
                              position: "relative",
                              width: "100%",
                              overflowX: "auto",
                              marginBottom: "5px",
                              padding: "5px",
                            }}
                          >
                            {acceptedFile?.map((image, index) => (
                              <>
                                <div
                                  onClick={(e) => handleImageClick(e, index)}
                                >
                                  <img
                                    className=""
                                    src={URL.createObjectURL(image)}
                                    alt="logo"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      border:
                                        index === imageIndex
                                          ? "2px solid #00a82d"
                                          : "",
                                    }}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                          <div
                            style={{ height: "20%" }}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              {imageIndex !== undefined &&
                                `Selected ${imageIndex + 1}/${
                                  acceptedFile?.length
                                }`}
                            </div>
                            <button
                              type="button"
                              className="next_button"
                              disabled={imageIndex !== undefined ? false : true}
                              style={{
                                width: "60px",
                                opacity:
                                  imageIndex !== undefined ? "1.0" : "0.6",
                              }}
                              onClick={(e) => handleImageDelete(e)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{ height: "100%" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <a
                            style={{
                              // textDecoration: "underline",
                              color: "#00a82d",
                            }}
                          >
                            Click on + button or Drag and Drop to upload photos
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="mr-3 float-left"
                    style={{ paddingTop: "2rem", height: "10%" }}
                  >
                    <button
                      type="submit"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                      onClick={() => setAddProductModal(false)}
                    >
                      Back
                    </button>
                  </div>
                  <div className="float-right" style={{ paddingTop: "2rem" }}>
                    <button
                      type="submit"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
      {/*</div> */}
      {(loading || pincodeLoader || uploading) && <Loader />}
    </>
  );
};

export default AddProductModal;

import React, { useEffect, useState } from 'react';
import TopBar from '../../../../shared/Navigation/TopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAttributesList } from '../../../../Redux/actions';
import Loader from '../../../../shared/Loader/Loader';

const SectionsReview = ({ sidebarCollapsed }) => {
    const loginObject = JSON.parse(sessionStorage.user);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { orgId } = useSelector((state) => state.loginReducer); 
    const { loading } = useSelector((state) => state.adminReducer); 
    const [dispatchAction, setdispatchAction] = useState(false);
    const [attributes, setAttributes] = useState([]);

    const handleNextClick = () => {
        if(state?.sectionData?.length > 0) {
            for(let i = 0; i < state?.sectionData?.length; i++) {
                for (let j = 0; j < state?.sectionData[i]?.attributesList?.length; j++){
                    const obj = {
                        // attributeId : state?.sectionData[i]?.attributesList[j]?.id,
                        attributeId : state?.sectionData[i]?.attributesList[j]?.id !== undefined ? state?.sectionData[i]?.attributesList[j]?.id : state?.sectionData[i]?.attributesList[j]?.baseAttributeId,
                        section: state?.sectionData[i]?.sectionName,
                        isMandatory: state?.sectionData[i]?.attributesList[j]?.isMandatory
                    }
                    setAttributes(prevAttributes => [...prevAttributes, obj]);
                } 
            }
            setdispatchAction(true);
        }
    }

    useEffect(() => {
        if(dispatchAction && attributes?.length > 0) {
          dispatch(setAttributesList(orgId, "USER", "USER_MASTER_SETUP", "User Master", attributes, loginObject.token, navigate));
        }
      }, [dispatchAction]);

    console.log('attributes:',attributes);
    console.log('State:',state);

  return (
    <>
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
      <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>Review Sections & Create Master</p>
      <div style={{ height: '75vh', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="d-flex flex-direction-row flex-wrap" style={{ height: 'auto', overflowY: 'auto' }}>
        {state?.sectionData?.map((section, index) => (
            <>
            <div className="usermaster_box" style={{ height: 'fit-content', width: '30%', margin: '10px', boxShadow: "0 5px 10px rgba(100, 100, 111, 0.2)" }}>
                {`Section ${index + 1}:${section.sectionName}`}
                {section.attributesList.map((attribute, attrIndex) => (
                    <>
                        <input 
                        value={attribute.name} 
                        disabled
                        className="attribute"
                        style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
                        boxShadow: attribute.owner === 'ORG' ? "1px 0.5px 2px 0px" : "", cursor: "pointer", height: 'auto' }}
                        />
                    </>
                ))}
            </div>
            </>
        ))}
      </div>
      {/* Button */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="mt-3 mr-3 float-left" style={{ alignSelf: 'flex-end '}}>
    <button
      className="next_button ml-3"
      type="button"
      onClick={() => {
      //   setShowTab(false);
      //   navigateToFormStep(3);
    //   navigate("/admin/organization-setup");
    //  navigate(-1); 
    navigate("/admin/create-sections", {
      state: {
        mandatoryAttributes: state?.mandatoryAttributes,
        nonMandatoryAttributes: state?.nonMandatoryAttributes,
        sectionsList: state?.sectionsList,
        sectionData: state?.sectionData,
        isBack: true,
      }
    });
      }}
    >
      Back
    </button>
  </div>
  <div className="mt-3 mr-3 float-right" style={{ alignSelf: 'flex-end '}}>
    <button
      className="next_button ml-3"
      type="button"
      onClick={handleNextClick}
    >
      Save & Create
    </button>
  </div>
    </div>
    </div>
    </div>
    {loading && <Loader />}
  </>
  )
}

export default SectionsReview
import React, { useEffect, useState } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { attributesRequest, getUsersList, getCustomerTypes, getCustomersList, getCustomersDetails, getUserById, getCustomerById, toggleCustomerStatus, SetCombinedList, getCustomerTypeId } from "../../../../Redux/actions";
import { useSelector, useDispatch } from "react-redux";
import AddCustomerModal from "./AddCustomerModal";
import Loader from "../../../../shared/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Constants } from "../../SuperAdmin/Credentials";
import * as actionType from "./../../../../Redux/actions/actionTypes";

const Customers = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orgId, } = useSelector((state) => state.loginReducer);
  const { attributes, loading, setupStatus, customerTypesList, customersList ,customersDetails ,combinedList, customerData } = useSelector((state) => state.adminReducer);
  const [count, setCount] = useState(0);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [cusDetails,setCusDetails] = useState([]);
  const [cuslist, setCusList] = useState(customersList);
  const [isEdit, setIsEdit] = useState(false);
  const [allcustomers,setAllCustomers] = useState(combinedList);
  const [editcustomer, setEditCustomer] = useState();


// console.log('cuslist',cuslist)
console.log('/custlist',customersList)
useEffect(() => {
  fetchAllCustomerDetails();
},[cuslist])//removed allcustomers (31-08)

const fetchAllCustomerDetails = async () => {
const detailsArray = [];
for (const customer of cuslist) {
  // const { phoneNumber, customerCode } = extractCustomerDetails(customersDetails[customer?.id]);
  // details[customer?.id] = { phoneNumber, customerCode };
  fetch(`${Constants.api}/v1/customer/${customer?.id}/profile`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${loginObject?.token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        console.log("customerdetails res;",responseData.response?.attributeValues)
        // dispatch({
        //   type: actionTypes.GET_CUSTOMERS_DETAILS_SUCCESS,
        //   payload: responseData.response?.attributeValues,
        // });
        detailsArray.push(responseData);
      }
    })
    .catch((error) => {
      console.log(error);
      // dispatch({ type: actionTypes.GET_CUSTOMERS_DETAILS_FAIL });
    });
}
console.log("All customer details:", detailsArray);
setCusDetails(detailsArray);
}
  useEffect(() => {
    fetchAllCustomers();
}, []);

const fetchAllCustomers = async () => {
  const customerMap = new Map();
  for (const type of customerTypesList) {
      const customers = await dispatch(getCustomersList(loginObject?.token, type.orgAttributeId));
      customers.forEach(customer => {
          if (!customerMap.has(customer.id)) {
              customerMap.set(customer.id, customer);
          }
      });
  }
  dispatch({
      type: 'UPDATE_CUSTOMERS_LIST',
      payload: [...customerMap.values()]
  });
};

console.log("custdetails:",customersDetails);
console.log("custypeslist:",customerTypesList);

    const getCustomerDetails = (customerId, cusDetails) => {
      const customerDetail = cusDetails.find(detail => detail.response.id === customerId);
    
      if (!customerDetail) {
        return {
          phoneNumber: "",
          billingAddress: {
            line1: "----",
            line2: "----",
            country: "----",
            state: "----",
          }
        };
      }
    
      const attributeValues = customerDetail.response.attributeValues;
    
      const phoneNumberObject = attributeValues.find(item => item.label === "Phone Number");
      const billingAddressObject = attributeValues.find(item => item.label === "Billing Address");
    
      return {
        phoneNumber: phoneNumberObject?.value || "",
        billingAddress: {
          line1: billingAddressObject?.addressValue?.line1 || "----",
          line2: billingAddressObject?.addressValue?.line2 || "----",
          country: billingAddressObject?.addressValue?.country || "----",
          state: billingAddressObject?.addressValue?.state || "----",
        }
      };
    };

    // const customerDetails = useMemo(() => getCustomerDetails(customer?.id, cusDetails), [cusDetails, customer?.id]);

  useEffect(() => {
    if(setupStatus?.customerMasterSetup) {
        dispatch(getCustomerTypes(orgId, loginObject?.token));
    }
  }, [setupStatus?.customerMasterSetup]);

  useEffect(() => {
    if(!loading && attributes?.length > 0) {
        const uniqueSections = [
          ...new Set(attributes.map((item) => item.section !== null && item.section)),
        ];
        if(uniqueSections[0]) {
        const newData = uniqueSections?.map((section) => {
          return {
            sectionName: section,
            attributesList: attributes?.filter(
              (attr) => attr.section === section
            ),
          };
        });
        setSectionData(newData);
      } else {
        setSectionData(null);
      }
    }
  }, [loading, attributes]);

  const increment = () => {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 5);
    });
  };

  const decrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 5);
      } else {
        return (prevCount = 0);
      }
    });
  };

  function handleEditClick (customer) {
    console.log("customered;",customer);
    // dispatch(getCustomerById(loginObject?.token,customer?.id));
    setIsEdit(true);
  }

  function handleCheckboxChange(customer) {
    // Toggle the active status
    const updatedCustomer = { ...customer, active: !customer.active };

    const toggleObj = {
        customerId: updatedCustomer.id,
        active: updatedCustomer.active
    };

    // Dispatch the action to toggle customer status
    dispatch(toggleCustomerStatus(loginObject?.token, toggleObj, customerData?.customerTypeId))
        .then(() => {
            // Fetch the updated customers list after the status change
            fetchAllCustomers();
        })
        .catch((error) => {
            console.error("Failed to toggle customer status:", error);
        });

    console.log("Changed customer status:", updatedCustomer.active, updatedCustomer.id);
}

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="add-user user-table">
          <div className="p-4">
            <div className="d-flex gap-3">
              <div className="ml-auto">
                {setupStatus?.customerMasterSetup && <button
                  // type="submit"
                  // className="button"
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "100px",
                    height: "30px",
                    padding: "2px 8px",
                    marginRight: "10px",
                  }}
                    onClick={() => setAddCustomerModal(true)}
                >
                  Add Customer
                </button>}
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <TuneIcon />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <img
                    src={"/assets/download-file.png"}
                    alt="download"
                    style={{ width: "25px", padding: "2px" }}
                  />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    borderRadius: "5px",
                    marginRight: "20px",
                    height: "30px",
                  }}
                  onClick={() => setBulkUploadModal(true)}
                >
                  <UploadFileIcon />
                </button>
              </div>
            </div>

            <div className="pl-3">
              <p className="d-flex align-items-center mt-3">
                Show
                <p className="count light_shadow ml-2">{count}</p>
                <div className="counters mr-2">
                  <button onClick={increment}>
                    {" "}
                    <KeyboardArrowUpIcon fontSize="5px" />
                  </button>
                  <button onClick={decrement}>
                    <KeyboardArrowDownIcon fontSize="5px" />
                  </button>
                </div>
                entries
              </p>
            </div>
            {setupStatus?.customerMasterSetup ? (
              <>
            <div style={{ width: '100%', overflowX: "auto" }}>
            <table className="table mt-3">
          <thead style={{ padding: 20 }}>
            <tr>
                <th>Customer Code</th>
                <th>Customer Name</th>
                <th>Category</th>
                <th>Mobile Number</th>
                <th>Address Line 1</th>
                <th>Address Line 2</th>
                <th>Country</th>
                <th>State</th>
                <th>Action</th>
            </tr>
          </thead>
          {customersList?.map(customer =>{
              const phoneNumber = cusDetails
              ?.find(detail => detail?.response?.id === customer?.id)
              ?.response?.attributeValues?.find(item => item?.label === "Phone Number")
              ?.value || "";
          return(
          <tbody className="add-user-body">
              <tr>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {cusDetails?.find(detail => detail.response.id === customer?.id)
      ?.response?.attributeValues?.find(item => item.label === "Customer Code")?.value || "----"}
              </td>
              {/* <Tooltip title={user.firstName + ' ' + user.lastName} placement="bottom"> */}
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {customer?.name}
              </td>
              {/* </Tooltip> */}
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                Customer Success Executive
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
                style={{ maxWidth: '50px', overflowX: 'hidden', textOverflow: 'ellipsis' }}
              >
                {phoneNumber}
           </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
               {cusDetails?.find(detail => detail.response.id === customer?.id)
      ?.response?.attributeValues?.find(item => item.label === "Billing Address")?.addressValue?.line1 || "----"} 
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {cusDetails?.find(detail => detail.response.id === customer?.id)
      ?.response?.attributeValues?.find(item => item.label === "Billing Address")?.addressValue?.line2 || "----"} 
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {cusDetails?.find(detail => detail.response.id === customer?.id)
      ?.response?.attributeValues?.find(item => item.label === "Billing Address")?.addressValue?.country || "----"} 
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {cusDetails?.find(detail => detail.response.id === customer?.id)
      ?.response?.attributeValues?.find(item => item.label === "Billing Address")?.addressValue?.state || "----"} 
              </td>
              <td className="d-flex px-0">
                      <button
                        style={{
                          padding: 0,
                          margin: 0,
                          border: "none",
                          backgroundColor: "#fff",
                        }}
                        onClick={() =>{
                          handleEditClick(customer)
                          setEditCustomer(customer)
                        }
                        }
                        className="edit_icon light_shadow"
                      >
                        <img
                          src={"/assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        />
                      </button>
                      <label className="table_switch" style={{ right: '30px' }}>
                        <input
                          type="checkbox"
                          checked={customer?.active}
                          onChange={() => handleCheckboxChange(customer)}
                        />
                        <span className="table_slider round"></span>
                      </label>
                    </td>
            </tr>
            {/* ))} */}
          </tbody>
              )})}
          </table>
          </div>
          </>) : 
        //   <span className="add-user-span">Please setup Customer Master to add a new customer</span>
        <>
                <span className="add-user-span" style={{ left: "70%" }}>
                  Please setup Customer Master to add a new product.
                </span>
                <p className="add-user-span product-font">
                  <button
                    className="link-btn"
                    onClick={() => navigate("/admin/customer-master-setup")}
                  >
                    Click here
                  </button>
                  to setup customer master
                </p>
              </>
          }
          </div>
        </div>
      </div>
      {(addCustomerModal || isEdit) && <AddCustomerModal setAddCustomerModal={setAddCustomerModal} customerTypesList={customerTypesList}  isEdit={isEdit} setIsEdit={setIsEdit} editcustomer={editcustomer}/>}
      {loading && <Loader />}
    </>
  );
};

export default Customers;

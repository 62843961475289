import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const TopBar = (props) => {

  const [searchTerm, setSearchTerm] = useState('');

  if(props?.search != '' && props?.search != undefined && props?.search != null) {
  props?.search(searchTerm);
 }

  const handleSearchTerm = (e) => {
    setSearchTerm(e?.target?.value);
  }

  return (
    <div className="top-bar">
      <div className="row w-100">
        <div className="col-4 d-flex justify-content-center">
          <div className="search-wrapper">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              className="search-input form-control pl-3"
              placeholder="Search"
              type="text"
              onChange={(e) => handleSearchTerm(e)}
            />
          </div>
        </div>
        <div className="col-4"></div>
        {/* <div className="col-4 d-flex justify-content-end"> */}
          {/* <p>Goal Seek Solutions</p> */}
          {/* <img
            src={"/assets/Goalseek.gif"}
            style={{
              width: "20%",
              height: "13px",
              margin: "auto 0",
              marginRight: "40px",
            }}
          />
        </div> */}
      </div>
    </div>
  );
};
export default TopBar;

import React from 'react';
import { useDrag } from 'react-dnd';

const SectionDrag = ({ attribute, attrIndex, index , sectionData, setSectionData, sectionIndex }) => {
    //Drag compo
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "attribute",
        item: { name: attribute },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            // console.log('item:',monitor.getItem());
            // console.log('Name:',dropResult.name);
            if(item && dropResult) {
                // console.log('Entered 1');
                // alert(`You threw ${item.name.name} into ${dropResult.name}`);
                let currentIndex = dropResult.name.split(" ")[1];
                console.log('currentIndex:',currentIndex);
                console.log('sectionIndex=',sectionIndex);
                const updatedSectionsList = [...sectionData];
                console.log('sectionData in:',sectionData)
                const itemIndexInSection = updatedSectionsList[sectionIndex]?.attributesList?.findIndex(attribute => attribute.name === item.name.name);
                console.log('updatedSectionsList:',updatedSectionsList);
                console.log('itemIndexInSection:',itemIndexInSection);
                updatedSectionsList[currentIndex]?.attributesList?.push(item.name);
                updatedSectionsList[sectionIndex]?.attributesList?.splice(itemIndexInSection, 1);
                setSectionData(updatedSectionsList);
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }), [attribute, sectionData]);



    return (
        <div className='wrapper' key={attrIndex} ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
            <div className='logo' key={index}>
              <img src={"/assets/drag.png"} />
            </div>
            <div> 
              <input 
              // ref={drag}
              value={attribute.name} 
              disabled
              className="attribute"
              style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
              boxShadow: attribute.owner === 'ORG' ? "1px 0.5px 2px 0px" : "", cursor: "pointer" }}
              />
            </div>
          </div>
  )
}

export default SectionDrag
import React, { useEffect, useState } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getUserAttributes, getUserById, getUsersList, setEditUserId, toggleUserStatus } from "../../../../Redux/actions";
import { useSelector, useDispatch } from "react-redux";
import AddUserModal from "./AddUserModal";
import Loader from "../../../../shared/Loader/Loader";

const Users = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const { orgId } = useSelector((state) => state.loginReducer);
  const { attributes, loading, setupStatus, users } = useSelector((state) => state.adminReducer);
  const [count, setCount] = useState(0);
  const [addUserModal, setAddUserModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editUser, setEditUser] = useState();
  const [userList,setUserList] = useState(users);
 
  useEffect(() => {
    dispatch(getUserAttributes(loginObject?.token));
    dispatch(getUsersList(loginObject?.token));
  }, []);
  
  console.log("userLIst:",users)
  useEffect(() => {
    setUserList(users)
  },[users])
  
  useEffect(() => {//
    if(!loading && attributes?.length > 0) {
        const uniqueSections = [
          ...new Set(attributes.map((item) => item.section !== null && item.section)),
        ];
        if(uniqueSections[0]) {
        const newData = uniqueSections?.map((section) => {
          return {
            sectionName: section,
            attributesList: attributes?.filter(
              (attr) => attr.section === section
            ),
          };
        });
        setSectionData(newData);
      } else {
        setSectionData(null);
      }
    }
  }, [loading, attributes]);

  const increment = () => {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 5);
    });
  };

  const decrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 5);
      } else {
        return (prevCount = 0);
      }
    });
  };

  function handleEditClick (user) {
    console.log("userid;",user);
    // dispatch(getUserById(loginObject?.token,user?.id));
    setIsEdit(true);
    // dispatch(setEditUserId(user?.id))
  }

  function handleCheckboxChange(user) {
    const updatedUser = { ...user, active: !user.active };
    const toggleObj = {
      userId: updatedUser?.id,
      active: updatedUser?.active
    };
    dispatch(toggleUserStatus(loginObject?.token, toggleObj));
  }
  
  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="add-user user-table">
          <div className="p-4">
            <div className="d-flex gap-3">
              <div className="ml-auto">
                {setupStatus?.userMasterSetup && <button
                  // type="submit"
                  // className="button"
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "30px",
                    padding: "2px 8px",
                    marginRight: "10px",
                  }}
                    onClick={() => setAddUserModal(true)}
                >
                  Add User
                </button>}
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <TuneIcon />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <img
                    src={"/assets/download-file.png"}
                    alt="download"
                    style={{ width: "25px", padding: "2px" }}
                  />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    borderRadius: "5px",
                    marginRight: "20px",
                    height: "30px",
                  }}
                  onClick={() => setBulkUploadModal(true)}
                >
                  <UploadFileIcon />
                </button>
              </div>
            </div>

            <div className="pl-3">
              <p className="d-flex align-items-center mt-3">
                Show
                <p className="count light_shadow ml-2">{count}</p>
                <div className="counters mr-2">
                  <button onClick={increment}>
                    {" "}
                    <KeyboardArrowUpIcon fontSize="5px" />
                  </button>
                  <button onClick={decrement}>
                    <KeyboardArrowDownIcon fontSize="5px" />
                  </button>
                </div>
                entries
              </p>
            </div>
            {setupStatus?.userMasterSetup && setupStatus?.orgStructure ? (
              <>
            <div style={{ width: '100%', overflowX: "auto" }}>
            <table className="table mt-3">
          <thead style={{ padding: 20 }}>
            <tr>
              {/* {attributes?.map((attribute, index) => (
                <th key={index} style={{ width: '50px' }}>{attribute?.name}</th>
              ))} */}
                <th>Employee Code</th>
                <th>Employee Name</th>
                <th>Designation</th>
                <th>Email Id</th>
                <th>Mobile Number</th>
                <th>Base Location</th>
                <th>User Role in Bloomr</th>
                <th>Action</th>
            </tr>
          </thead>
          <tbody className="add-user-body">
            {!loading && userList?.result?.map((user) => (
              <tr key={user.id}>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.employeeCode}
              </td>
              {/* <Tooltip title={user.firstName + ' ' + user.lastName} placement="bottom"> */}
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                { user?.firstName?  user?.firstName+ ' ' + user.lastName  : user?.fullName
                // user?.firstName+ ' ' + user.lastName changed (23-08)
                 }
              </td>
              {/* </Tooltip> */}
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.designation}
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.emailAddress}
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.phoneNumber}
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.baseLocation !== null ? user.baseLocation : ""}
              </td>
              <td
                // style={{ opacity: org.isActive ? 1 : 0.5 }}
                // className={org.isActive ? "" : "deleted_organizations"}
              >
                {user.roleInBloomr !== null ? user.roleInBloomr : ""} 
              </td>
              <td className="d-flex px-0">
                      <button
                        style={{
                          padding: 0,
                          margin: 0,
                          border: "none",
                          backgroundColor: "#fff",
                        }}
                        onClick={() =>{
                          console.log("user::",user);
                          setEditUser(user)
                          handleEditClick(user);
                        }}
                        className="edit_icon light_shadow"
                      >
                        <img
                          src={"/assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        />
                      </button>
                      <label className="table_switch" style={{ right: '30px' }}>
                        <input
                          type="checkbox"
                          checked={user?.active}
                          onChange={() => handleCheckboxChange(user)}
                        />
                        <span className="table_slider round"></span>
                      </label>
                    </td>
            </tr>
            ))}
          </tbody>
          </table>
          </div>
          </>) : <span className="add-user-span">Please setup Organization Structure & User Master to add a new user</span>
          }
          </div>
        </div>
      </div>
      {/* {(addUserModal && sectionData === null) && <AddUserModal setAddUserModal={setAddUserModal} attributes={attributes} />} */}
      {/* {(addUserModal && sectionData !== null) && <AddUserSectionModal setAddUserModal={setAddUserModal} sectionData={sectionData} />} */}
      {(addUserModal || isEdit) && <AddUserModal setAddUserModal={setAddUserModal} attributes={attributes} isEdit={isEdit} setIsEdit={setIsEdit} editUser={editUser} users={userList} />}
      {/* {bulkUploadModal && <BulkUploadModal setBulkUploadModal={setBulkUploadModal}/>} */}
      {loading && <Loader />}
    </>
  );
};

export default Users;

import React, { useEffect, useState, useRef } from "react";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { useDispatch, useSelector } from 'react-redux';
import { getObjectivesList, addNewObjective, deleteObjective, editObjectiveOption, deleteObjectiveOption } from "../../../../../Redux/actions";
import Loader from "../../../../../shared/Loader/Loader";
import EditObjective from "./EditObjective";
import { useNavigate } from 'react-router-dom';

const ObjectiveAchievements = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const navigate = useNavigate();

  const { loading, objectives } = useSelector((state) => state.adminReducer);

  const [objectiveName, setObjectiveName] = useState('');
  const [objectiveFieldType, setObjectiveFieldType] = useState('default');
  const [emptyObjectiveName, setEmptyObjectiveName] = useState(false);
  const [emptyObjectiveType, setEmptyObjectiveType] = useState(false);
  const [achievementName, setAchievementName] = useState([{ option: '', isEmpty: false }]);
  const [editObjectiveDetails, setEditObjectiveDetails] = useState();
  const [editObjectiveModal, setEditObjectiveModal] = useState(false);
  const [isAchievementEmpty, setIsAchievementEmpty] = useState(false);

  useEffect(() => {
    dispatch(getObjectivesList(loginObject?.token));
  }, []);

  const handleSubmit = () => {
    if(objectiveName === '' && objectiveFieldType === 'default') {
      console.log('C1')
      setEmptyObjectiveName(true);
      setEmptyObjectiveType(true);
    } else if(objectiveName === '') {
      console.log('C2')
      setEmptyObjectiveName(true);
    } else if(objectiveFieldType === 'default') {
      console.log('C3');
      setEmptyObjectiveType(true);
    } else {
      console.log('C4');
      if(objectiveFieldType === 'OPTIONS_MULTI_SELECT' || objectiveFieldType === 'OPTIONS_SINGLE_SELECT') {
      let isEmptyAchievementFound = false;
      console.log('achievementName:',achievementName);
      for(let i = 0; i < achievementName?.length; i++) {
        isEmptyAchievementFound = true;
        if(achievementName[i]?.option === '') {
          const dummy = [...achievementName];
          dummy[i].isEmpty = true;
          setAchievementName(dummy);
          setIsAchievementEmpty(true);
          isEmptyAchievementFound = false;
          break;
        }
      }
      console.log('isEmptyAchievementFound:',isEmptyAchievementFound);
      console.log('isAchievementEmpty:',isAchievementEmpty);
      if(isEmptyAchievementFound && isAchievementEmpty !== undefined && !isAchievementEmpty) {
        console.log('C5');
      setObjectiveName('');
      setObjectiveFieldType('default');
      const objectiveObj = {
        objective: objectiveName,
        achievementFieldType: objectiveFieldType,
        options: achievementName?.map((name) => name?.option)
      }
      setAchievementName([{ option: '', isEmpty: false }]);
      setIsAchievementEmpty(false);
      dispatch(addNewObjective(objectiveObj, loginObject?.token));
    }
     } else {
      console.log('C6');
      setObjectiveName('');
      setObjectiveFieldType('default');
      const objectiveObj = {
        objective: objectiveName,
        achievementFieldType: objectiveFieldType,
        options: []
      }
      dispatch(addNewObjective(objectiveObj, loginObject?.token));
     }
    }
  }

  const handleDeleteClick = (objectiveId) => {
    dispatch(deleteObjective(objectiveId, loginObject?.token));
  }

  const handleAchievementDelete = (index) => {
    const acheievementsCopy = [...achievementName];
    acheievementsCopy.splice(index, 1);
    setAchievementName(acheievementsCopy);
  }

  const handleEditObjective = (objective) => {
    setEditObjectiveDetails(objective);
    setEditObjectiveModal(true);
  }

  const handleEditVisitObjective = (objectives, visitObjectiveId) => {
    const newObjectives = objectives?.filter((objective) => objective?.id === null)?.map((objective) => {
      const newTask = {
        task : objective?.option
      } 
      return newTask;
    });
    if(newObjectives?.length > 0) {
      dispatch(editObjectiveOption(newObjectives[0], visitObjectiveId, setEditObjectiveModal, loginObject?.token));
    }
  }

  const deleteVisitOption = (visitObjectiveId, vistObjOptionId) => {
    dispatch(deleteObjectiveOption(visitObjectiveId, vistObjOptionId, loginObject?.token));
  }

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [achievementName?.length]);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container bg-shadow">
          <div className="objective-body">
          <div className="objective-left-content">
          <p className="heading pt-2 pl-2">Add Objectives & Achievements</p>
          <div className="add-objectives-container">
            <p className="objectives-headings">Objectives & Achievements</p>
            <div className="objectives-parent">
              <div className="objectives-body-container">
                <div className="objectives-component-left" style={{ height: objectives?.length > 0 ? 'fit-content' : '100%' }}>
                  {objectives?.length > 0 ? (<>
                  {objectives?.map((objective) => (
                    <div className="objectives-list-container bg-shadow">
                    <div className="objective-achievement-wrapper">
                      <div className="objective-heading">
                        <div className="heading-text">Objective</div>
                        <div className="add-objective-heading">{objective?.objective}</div>
                      </div>
                      <div className="objective-heading">
                        <div className="heading-text">Achievement</div>
                        <div className="add-objective-heading">{objective?.achievementFieldType === 'NUMERIC' ? 'Numeric' 
                        : objective?.achievementFieldType === 'OPTIONS_MULTI_SELECT' ? 'Multiple choice, multi-select' 
                        : objective?.achievementFieldType === 'OPTIONS_SINGLE_SELECT' ? 'Multiple choice, single-select'
                        : 'Free Text'
                      }</div>
                      </div>
                    </div>
                    <div className="mr-1 mt-2 float-right">
                      {(objective?.achievementFieldType === 'OPTIONS_MULTI_SELECT' 
                      || objective?.achievementFieldType === 'OPTIONS_SINGLE_SELECT') 
                      && 
                      <button className="edit-delete-btn" style={{ left: '15px' }} onClick={() => handleEditObjective(objective)}><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>}
                      <button className="edit-delete-btn" style={{ left: '10px' }} onClick={() => handleDeleteClick(objective?.id)}><img src={"/assets/bin.png"} alt="" className="edit-delete-icon" /></button>
                    </div>
                  </div>
                  ))}
                  </>) : (
                  <div className="empty-objectives-container bg-shadow">
                    <span className="empty-objectives">No Objectives & Achievements present right now. Please add a new one.</span>
                  </div>)
                  }
                </div>
                {/* <div className="objectives-component-right bg-shadow">
                  <p className="heading-text">Add Objective & Achievement</p>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">Add Objective</p>
                    <input
                      className="objective-input bg-shadow"
                      placeholder="Add Objective"
                      value={objectiveName}
                      onChange={(e) => {
                        setObjectiveName(e.target.value);
                        setEmptyObjectiveName(false);
                      }}
                    />
                    {emptyObjectiveName && <div className="empty-error-msg">*Please add an objective</div>}
                  </div>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">
                      Select Achievement Field Type
                    </p>
                    <select
                      className="objective-input bg-shadow"
                      name="attributeType"
                      value={objectiveFieldType}
                      onChange={(e) => {
                        setObjectiveFieldType(e.target.value);
                        setEmptyObjectiveType(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Achievement Field Type
                      </option>
                      <option value="NUMERIC">Numeric</option>
                      <option value="OPTIONS_MULTI_SELECT">
                        Multiple choice, multi-select
                      </option>
                      <option value="OPTIONS_SINGLE_SELECT">
                        Multiple choice, single-select
                      </option>
                      <option value="FREE_TEXT">Free Text</option>
                    </select>
                    {emptyObjectiveType && <div className="empty-error-msg">*Please select achievement field type</div>}
                  </div>
                  <div className="mt-3 mr-3 float-right">
                    <button 
                    className="next_button ml-3" 
                    type="button"
                    onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          </div>
          {(objectiveFieldType !== 'OPTIONS_MULTI_SELECT' && objectiveFieldType !== 'OPTIONS_SINGLE_SELECT') ? <div className="objectives-component-right bg-shadow">
                  <p className="heading-text">Add Objective & Achievement</p>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">Add Objective</p>
                    <input
                      className="objective-input bg-shadow"
                      placeholder="Add Objective"
                      value={objectiveName}
                      onChange={(e) => {
                        setObjectiveName(e.target.value);
                        setEmptyObjectiveName(false);
                      }}
                    />
                    {emptyObjectiveName && <div className="empty-error-msg">*Please add an objective</div>}
                  </div>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">
                      Select Achievement Field Type
                    </p>
                    <select
                      className="objective-input bg-shadow"
                      name="attributeType"
                      value={objectiveFieldType}
                      onChange={(e) => {
                        setObjectiveFieldType(e.target.value);
                        setEmptyObjectiveType(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Achievement Field Type
                      </option>
                      <option value="NUMERIC">Numeric</option>
                      <option value="OPTIONS_MULTI_SELECT">
                        Multiple choice, multi-select
                      </option>
                      <option value="OPTIONS_SINGLE_SELECT">
                        Multiple choice, single-select
                      </option>
                      <option value="FREE_TEXT">Free Text</option>
                    </select>
                    {emptyObjectiveType && <div className="empty-error-msg">*Please select achievement field type</div>}
                  </div>
                  <div className="mt-3 mr-3 float-right">
                    <button 
                    className="next_button ml-3" 
                    type="button"
                    onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
          </div> 
          :
          <div className="objectives-component-right bg-shadow" style={{ marginTop: '10px', height: '100%' }}>
            <p className="heading-text">Add Objective & Achievement</p>
            <div className="add-new-objective multi-select">
              <p className="add-objective-heading">Add Objective</p>
              <input
                className="objective-input bg-shadow"
                placeholder="Add Objective"
                value={objectiveName}
                onChange={(e) => {
                  setObjectiveName(e.target.value);
                  setEmptyObjectiveName(false);
                }}
              />
              {emptyObjectiveName && <div className="empty-error-msg">*Please add an objective</div>}
            </div>
            <div className="add-new-objective multi-select">
              <p className="add-objective-heading">
                Select Achievement Field Type
              </p>
              <select
                className="objective-input bg-shadow"
                name="attributeType"
                value={objectiveFieldType}
                onChange={(e) => {
                  setObjectiveFieldType(e.target.value);
                  setEmptyObjectiveType(false);
                }}
              >
                <option value="default" hidden>
                  Select Achievement Field Type
                </option>
                <option value="NUMERIC">Numeric</option>
                <option value="OPTIONS_MULTI_SELECT">
                  Multiple choice, multi-select
                </option>
                <option value="OPTIONS_SINGLE_SELECT">
                  Multiple choice, single-select
                </option>
                <option value="FREE_TEXT">Free Text</option>
              </select>
              {emptyObjectiveType && <div className="empty-error-msg">*Please select achievement field type</div>}
            </div>
            <div className="add-new-objective multi-select add-achievement">
            <div className="achievement-line">
            <p className="add-objective-heading" style={{ width: '60%' }}>Add Achievements</p>
            <button className="add-btn" onClick={() => setAchievementName([...achievementName, { option: '', isEmpty: false }])}>
              <img src={'/assets/add.png'} alt="" className="edit-delete-icon" />
            </button>
            </div>
            <div className="extra-acheievements bg-shadow">
              {achievementName?.map((achievement, index) => (
                <>
                <div className="extra-achievements-wrapper">
                <div className="delete-achievement">
                  <button className="edit-delete-btn" onClick={() => handleAchievementDelete(index)}>
                    <img src={"/assets/bin.png"} alt="" className="edit-delete-icon" />
                  </button>
                </div>
                <input
                key={index}
                className="objective-input bg-shadow"
                placeholder="Add Achievement"
                value={achievement?.option}
                onChange={(e) => {
                  const updatedAchievementName = [...achievementName]; 
                  updatedAchievementName[index].option = e.target.value; 
                  updatedAchievementName[index].isEmpty = false; 
                  setAchievementName(updatedAchievementName); 
                  if(isAchievementEmpty) {
                    setIsAchievementEmpty(false);
                  }
                }}
              />
              {(achievement?.isEmpty) && <div className="empty-error-msg" style={{ marginBottom: '5px' }}>*Required</div>}
              <div ref={bottomRef}></div>
              </div>
              </>
              ))}
            </div>
            </div>
            <div className="mt-3 mr-3 float-right">
              <button 
              className="next_button ml-3" 
              type="button"
              onClick={() => handleSubmit()}
              >
                Add
              </button>
            </div>
          </div> }
        </div>
        <div className="mr-3 mt-4 float-right">
          <button 
          className="next_button ml-3" 
          type="button"
          onClick={() => navigate("/admin/functionality-setup")}>
            Save & Exit
          </button>
        </div>
        </div>
      </div>
      {loading && <Loader />}
      {editObjectiveModal && 
      <EditObjective 
      editObjectiveDetails={editObjectiveDetails}
      setEditObjectiveModal={setEditObjectiveModal}
      func={handleEditVisitObjective}
      deleteVisitOption={deleteVisitOption}
       />}
    </>
  );
};

export default ObjectiveAchievements;

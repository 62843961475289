import { useCallback, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { Constants } from "./Credentials";
import { useNavigate } from "react-router-dom";
import Loader from "../../../shared/Loader/Loader";
import "./BasicDetails.css";

const BasicDetails = ({
  onBasicDetailsChange,
  navigateToFormStep,
  editMode,
  organizationDetails,
  handleUpdateButtonClick,
}) => {
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [businesstypes, setBusinessTypes] = useState([]);
  const [businessSegments, setBusinessSegments] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const orgId = organizationDetails?.id;
  const onDrop = useCallback((files) => {
    setAcceptedFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const initialValues = {
    name: "",
    businessTypeId: "",
    channelPartner: "",
    logoUrl: "",
    businessSegmentId: "",
    gst: "",
    mobileCode: "+91",
    mobileNumber: "",
    accountManager: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Organization name must be at least 2 characters")
      .required("*Required"),
    // logoUrl: Yup.string().required("PIN code is required"),
    // mobileCode: Yup.string().required("Country is required"),
    mobileNumber: Yup.number()
      .required("*Required")
      .test("len", "Phone number should be 10 digits", (val) => {
        if (val) return val.toString().length === 10;
      }),
    channelPartner: Yup.string().required("*Required"),
    businessTypeId: Yup.number().required("*Required"),
    businessSegmentId: Yup.number().required("*Required"),
    accountManager: Yup.string().required("*Required"),
  });

  const handleSubmit = async (values) => {
    // Convert business type and business segment IDs to integers
    values.businessTypeId = parseInt(values.businessTypeId);
    values.businessSegmentId = parseInt(values.businessSegmentId);

    // console.log("basic details values:", values);
    if (acceptedFile) {
      await getUrl(values); // Upload the file to get the URL
    } else {
      editMode ? updateBasicDetails(values) : onBasicDetailsChange(values);
    }
    !editMode && navigateToFormStep(2);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
  });

  const getUrl = async (values) => {
    setLoading(true);
    //get uploadUrl to upload file
    const formData = new FormData();
    formData.append("file", acceptedFile);
    try {
      const response = await fetch(
        `${Api}/file/upload-url/${acceptedFile.name}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
            "Content-Type": "application/json",
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        // setLogoUrl(data.response.fileUploadUrl); // Set the obtained URL in
        values.logoUrl = data.response.documentUrl;
        uploadFile(data.response.fileUploadUrl);
        editMode ? updateBasicDetails(values) : onBasicDetailsChange(values);
        // console.log('response from getUrl: ',data,"values: " ,formik.values);
      }
    } catch (error) {
      console.error("Error getting file url:", error);
    }
  };
  const uploadFile = async (fileUploadUrl) => {
    // upload file to generated URL
    try {
      const response = await fetch(fileUploadUrl, {
        method: "PUT",
        headers: new Headers({
          // "Content-Type": acceptedFile.type,
          // "Content-Length": acceptedFile.length,
          // "Connection": "keep-alive",
          // "Host": "www.localhost:3000",
          // "Host" : "bloomr-2.0-s3.s3.ap-south-1.amazonaws.com",
          // "Content-Length" : 0
          // "Access-Control-Allow-Origin" : "*"
        }),
        body: acceptedFile,
      });
      if (response?.status === 200) {
        console.log(response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getDropdowns = async () => {
      const resp = await fetch(`${Api}/data/business_type/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const responseData = await resp.json();
      if (responseData.success) {
        setBusinessTypes(responseData.response);
      } else {
        console.log("failed to fetch business types");
      }

      const res = await fetch(`${Api}/data/business_segment/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const respJson = await res.json();
      if (respJson.success) {
        setBusinessSegments(respJson.response);
      } else {
        console.log("failed to fetch business segments");
      }
    };
    getDropdowns();

    // if (editMode) {
    //   formik.setValues({
    //     ...formik.values,
    //     name: organizationDetails.name || "",
    //     businessTypeId: organizationDetails.businessTypeId || "",
    //     channelPartner: organizationDetails.channelPartner || "",
    //     logoUrl: organizationDetails.logoUrl || "",
    //     businessSegmentId: organizationDetails.businessSegmentId || "",
    //     gst: organizationDetails.gst || "",
    //     mobileCode: organizationDetails.mobileCode || "+91",
    //     mobileNumber: organizationDetails.mobileNumber || "",
    //     accountManager: organizationDetails.accountManager || ""
    //   });
    // }
  }, []);

  //update basic details
  function updateBasicDetails(values) {
    setLoading(true);
    fetch(`${Api}/org/${orgId}/update/details`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          setLoading(false);
    alert('Basic details updated');
        }
        console.log("update basic details response:", responseData);
        // console.log("api:", Api);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <>
      <Formik
        initialValues={
          editMode
            ? {
                name: organizationDetails.name,
                businessTypeId: organizationDetails.businessTypeId,
                channelPartner: organizationDetails.channelPartner,
                logoUrl: organizationDetails.logoUrl,
                businessSegmentId: organizationDetails.businessSegmentId,
                gst: organizationDetails.gst,
                mobileCode: organizationDetails.mobileCode,
                mobileNumber: organizationDetails.mobileNumber,
                accountManager: organizationDetails.accountManager,
              }
            : initialValues
        }
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values) => {
          console.log("Values:", values);
          handleSubmit(values);
        }}
      >
        {({ handleSubmit, touched, errors }) => (
          <form
            // className="row mr-5"
            className="row d-flex justify-content-center"
            onSubmit={handleSubmit}
          >
            <div className="col-6 form-column1">
              <div className="form-group">
                <label htmlFor="name">Organization Name*</label>
                <Field
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Organization Name"
                  autoComplete="off"
                />
                {touched.name && errors.name && (
                  <span className="error-msg">{errors.name}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="businessTypeId">Business type*</label>
                {/* <select
              className="form-control"
              id="businesstype"
              name="businessTypeId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessTypeId}
            > */}
                <Field
                  as="select"
                  className="form-control"
                  id="businesstype"
                  name="businessTypeId"
                >
                  <option
                    value="default"
                    // disabled
                    selected
                    hidden
                    style={{ fontSize: "16px !important" }}
                  >
                    Select Business Type
                  </option>
                  {!businesstypes.length !== 0 &&
                    businesstypes?.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      );
                    })}
                </Field>
                {touched.businessTypeId && errors.businessTypeId && (
                  <span className="error-msg">{errors.businessTypeId}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="channelPartner">Channel Partner*</label>
                <Field
                  type="text"
                  placeholder="Enter Channel Partner Name"
                  className="form-control"
                  name="channelPartner"
                  autoComplete="off"
                />
                {touched.channelPartner && errors.channelPartner && (
                  <span className="error-msg">{errors.channelPartner}</span>
                )}
              </div>
              {/* <div className="form-group">
          <label>Logo</label>
          <div
            id="div2"
            onDorop="drop(event)"
            onDragOver="allowDrop(event)"
            className="form-control"
            style={{
              height: "140px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign:'center'
            }}
          >
            <a style={{ textDecoration: "underline", color: "#00a82d", }}>
              Drag and Drop logo of Organization <br /> or Click here to select
            </a>
          </div>
          <p className="small-text mt-2">* Logo should be 250px * 250px and less than 5 mb</p>
        </div> */}
              {/* <MyDropzone /> */}
              <div className="form-group">
                <label>Logo</label>

                <>
                  <div
                    {...getRootProps()}
                    style={{
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    className="form-control"
                  >
                    <input {...getInputProps()} />
                    {/* {isDragActive ? (
                        <p>Drop the file here ...</p>
                      ) : ( */}
                    {acceptedFile || editMode ? (
                      <div className="d-flex justify-content-center">
                        <img
                          className=""
                          src={
                            editMode
                              ? acceptedFile
                                ? URL.createObjectURL(acceptedFile)
                                : organizationDetails.logoUrl
                              : URL.createObjectURL(acceptedFile)
                          }
                          alt="logo"
                          style={{ maxWidth: "100%", maxHeight: "140px" }}
                        />
                      </div>
                    ) : (
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "#00a82d",
                        }}
                      >
                        Drag and Drop logo of Organization <br /> or Click here
                        to select
                      </a>
                    )}

                    {/* )} */}
                  </div>
                  <p className="small-text mt-2">
                    * Logo should be 250px * 250px and less than 5 mb
                  </p>
                </>
              </div>
            </div>
            <div className="col-6 form-column2">
              <div className="form-group">
                <label htmlFor="businessSegmentId">Business Segment*</label>
                <Field
                  as="select"
                  className="form-control"
                  id="businesstype"
                  name="businessSegmentId"
                >
                  <option
                    value="default"
                    // disabled
                    selected
                    hidden
                  >
                    Select Business Segment
                  </option>
                  {!businessSegments.length !== 0 &&
                    businessSegments?.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      );
                    })}
                </Field>
                {touched.businessSegmentId && errors.businessSegmentId && (
                  <span className="error-msg">{errors.businessSegmentId}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="gst">GST No./TAX Number</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Enter GST/Tax Number"
                  autoComplete="off"
                  name="gst"
                />
              </div>
              <div className="form-group">
                <label htmlFor="mobileNumber">Phone Number*</label>
                <div className="d-flex">
                  {/* <input className="form-control" style={{width:'50px'}}/>
          <input
            type="text"
            placeholder="Enter Phone Number"
            className="form-control"
            name="phoneNo"
            autoComplete="off"
          /> */}
                  <input
                    type="text"
                    value="+91 "
                    id="subdomain"
                    disabled
                    className="form-control"
                  />
                  <Field
                    type="number"
                    inputMode="numeric"
                    id="subdomaintwo"
                    placeholder="Enter Phone Number"
                    className="form-control"
                    autoComplete="off"
                    name="mobileNumber"
                  />
                </div>
                <div>
                  {touched.mobileNumber && errors.mobileNumber && (
                    <span className="error-msg">{errors.mobileNumber}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="accountManager">Bloomr Account Manager*</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Choose Bloomr Account Manager"
                  autoComplete="off"
                  name="accountManager"
                />
                {touched.accountManager && errors.accountManager && (
                  <span className="error-msg">{errors.accountManager}</span>
                )}
              </div>
            </div>
            {editMode ? (
              <div className="ml-auto mr-3">
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  type="submit"
                >
                  Save
                </button>
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  type="button"
                  onClick={() => handleUpdateButtonClick()}
                >
                  Next
                </button>
              </div>
            ) : (
              <div className="mt-3 mr-3 ml-auto">
                <button
                  className="cancel_button"
                  type="button"
                  onClick={() => navigate("/home")}
                >
                  Cancel
                </button>
                <button className="next_button ml-3" type="submit">
                  Save & Next
                </button>
              </div>
            )}
          </form>
        )}
      </Formik>
      {loading && <Loader />}
    </>
  );
};

export default BasicDetails;

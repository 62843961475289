import React, { useState } from "react";
import TopBar from "../../../shared/Navigation/TopBar";
import BasicDetails from "./BasicDetails";
import Communication from "./Communication";
import KeyContacts from "./KeyContacts";
import Modules from "./Modules";
import Package from "./Package";
import { useNavigate } from "react-router-dom";
import { Constants } from "./Credentials";
import { Form } from "formik";
import Loader from "../../../shared/Loader/Loader";

const CreateOrg = ({ sidebarCollapsed }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const editMode = false;
  const [orgName, setOrgName] = useState([]);
  const [formData, setFormData] = useState({
    basicDetails: {},
    communication: {},
    keyContacts: {},
    // modules: {},
    license: {},
  });
  const [loading, setLoading] = useState(false);

  const handleBasicDetails = (basicDetailsData) => {
    setOrgName(basicDetailsData.name);
    setFormData((prevFormData) => ({
      ...prevFormData,
      basicDetails: basicDetailsData,
    }));
  };

  const handleCommunicationDataChange = (communicationData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      communication: communicationData,
    }));
  };
  const handleKeyContacts = (keyContactsData) => {
    console.log("keycontacts from create org:", keyContactsData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      keyContacts: keyContactsData,
    }));
  };
  const handlePackage = (packageData) => {
    console.log("form data before submission:", formData);
    setLoading(true);
    const orgData = {
      name: formData.basicDetails.name,
      logoUrl: formData.basicDetails.logoUrl,
      mobileCode: formData.basicDetails.mobileCode,
      mobileNumber: formData.basicDetails.mobileNumber,
      gst: formData.basicDetails.gst,
      channelPartner: formData.basicDetails.channelPartner,
      businessTypeId: formData.basicDetails.businessTypeId,
      businessSegmentId: formData.basicDetails.businessSegmentId,
      accountManager: formData.basicDetails.accountManager,
      communicationAddress: {
        line1: formData.communication.communicationAddress.addressLine1,
        line2: formData.communication.communicationAddress.addressLine2,
        city: formData.communication.communicationAddress.city,
        state: formData.communication.communicationAddress.state,
        country: formData.communication.communicationAddress.country,
        pinCode: formData.communication.communicationAddress.pincode,
      },
      billingAddress: {
        line1: formData.communication.billingAddress.addressLine1,
        line2: formData.communication.billingAddress.addressLine2,
        city: formData.communication.billingAddress.city,
        state: formData.communication.billingAddress.state,
        country: formData.communication.billingAddress.country,
        pinCode: formData.communication.billingAddress.pincode,
      },
      keyContacts: formData.keyContacts.map((contact) => ({
        firstName: contact.firstName,
        lastName: contact.lastName,
        mobile: contact.mobile,
        designation: contact.designation,
        email: contact.email,
        isAdmin: contact.isAdmin,
      })),
      license: {
        packageId: 1,
        numberOfLicenses: packageData.numberOfLicenses,
        pricePerLicense: packageData.pricePerLicense,
        discountType: packageData.discountType,
        discountValue: packageData.discountValue,
        discountValueType: packageData.discountValueType,
        currency: packageData.currency,
        validFrom: packageData.validFrom,
        validTo: packageData.validTo,
        billingCycle: packageData.billingCycle,
      },
    };

    // const orgData ={
    //   "name": "Blink Enterprises",
    //   "logoUrl": "",
    //   "mobileCode": "+91",
    //   "mobileNumber": "9382746352",
    //   "gst": "392837263839",
    //   "channelPartner": "blink partner",
    //   "businessTypeId": 4,
    //   "businessSegmentId": 3,
    //   "accountManager": "blink manager",
    //   "communicationAddress": {
    //     "line1": "street1",
    //     "line2": "Avenue",
    //     "city": "Hyderabad",
    //     "state": "Telangana",
    //     "country": "India",
    //     "pinCode": "500090"
    //   },
    //   "billingAddress": {
    // "line1": "street1",
    //     "line2": "Avenue",
    //     "city": "Hyderabad",
    //     "state": "Telangana",
    //     "country": "India",
    //     "pinCode": "500090"
    //   },
    //   "keyContacts": [
    //     {
    //       "firstName": "bob",
    //       "lastName": "pen",
    //       "mobile": "8372639483",
    //       "designation": "head",
    //       "email": "bobpen@gmail.com",
    //       "isAdmin": true
    //     }
    //   ],
    //   "license": {
    //     "packageId": 1,
    //     "numberOfLicenses": 10,
    //     "pricePerLicense": 10,
    //     "discountType": "PERCENTAGE",
    //     "discountValue": 20,
    //     "currency": "INR",
    //     "validFrom": "2024-03-09",
    //     "validTo": "2024-03-09",
    //     "billingCycle": "MONTHLY"
    //   }
    // }

    console.log("license object:", packageData);
    console.log("token inside:", loginObject.token);
    setFormData((prevFormData) => ({
      ...prevFormData,
      license: packageData,
    }));
    console.log("OrgData before submission:", orgData);
    // handleCreate();
    fetch(`${Api}/org/create`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(orgData),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          alert("New Organization Created");
        } else {
          alert("Something went wrong, please try again later");
        }
        console.log("create org response:", responseData);
        setLoading(false);
        navigate("/home");
        // console.log("api:", Api);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        navigate("/home");
      });
  };

  const handleCreate = () => {
    console.log("Form submitted:", formData);
    navigate("/home");
  };

  const navigateToFormStep = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  const navigateToBackStep = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  return (
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
      <div className="create-organization">
        <p className="heading pt-4 pl-4">Create Organization</p>
        <div>
          <div id="multi-step-form-container">
            <ul className="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
              <li
                className={`form-stepper-${
                  currentStep === 1
                    ? "active"
                    : currentStep > 1
                    ? "completed"
                    : "unfinished"
                } text-center form-stepper-list`}
                step="1"
              >
                <a
                  className="mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep > 1) {
                      navigateToFormStep(1);
                    }
                  }}
                >
                  <div className="label">Basic Details</div>
                  <span className="form-stepper-circle">
                    {currentStep > 1 ? (
                      <p className="tick">✓</p>
                    ) : (
                      <span>{/* 1 */}</span>
                    )}
                  </span>
                </a>
              </li>
              <li
                className={`form-stepper-${
                  currentStep === 2
                    ? "active"
                    : currentStep > 2
                    ? "completed"
                    : "unfinished"
                } text-center form-stepper-list`}
                step="2"
              >
                <a
                  className="mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep > 2) {
                      navigateToFormStep(2);
                    }
                  }}
                >
                  <div className="label">Address</div>
                  <span className="form-stepper-circle">
                    {currentStep > 2 ? (
                      //   <i className="fas fa-check"></i>

                      <p className="tick">✓</p>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </a>
              </li>
              <li
                className={`form-stepper-${
                  currentStep === 3
                    ? "active"
                    : currentStep > 3
                    ? "completed"
                    : "unfinished"
                } text-center form-stepper-list`}
                step="3"
              >
                <a
                  className="mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep > 3) {
                      navigateToFormStep(3);
                    }
                  }}
                >
                  <div className="label">Key Contacts</div>
                  <span className="form-stepper-circle">
                    {currentStep > 3 ? (
                      <p className="tick">✓</p>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </a>
              </li>
              <li
                className={`form-stepper-${
                  currentStep === 4
                    ? "active"
                    : currentStep > 4
                    ? "completed"
                    : "unfinished"
                } text-center form-stepper-list`}
                step="3"
              >
                <a
                  className="mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentStep > 4) {
                      navigateToFormStep(4);
                    }
                  }}
                >
                  <div className="label">Modules </div>
                  <span className="form-stepper-circle">
                    {currentStep > 4 ? (
                      <p className="tick">✓</p>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </a>
              </li>
              <li
                className={`form-stepper-${
                  currentStep === 5
                    ? "active"
                    : currentStep > 5
                    ? "completed"
                    : "unfinished"
                } text-center form-stepper-list`}
                step="3"
              >
                <a className="mx-2">
                  <div className="label">Package</div>
                  <span className="form-stepper-circle">
                    {currentStep > 5 ? (
                      <p className="tick">✓</p>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </a>
              </li>
            </ul>
            {/* <form
              id="userAccountSetupForm"
              name="userAccountSetupForm"
              encType="multipart/form-data"
              method="POST"
            > */}
            <section
              id="step-1"
              className={`form-step ${currentStep === 1 ? "" : "d-none"}`}
            >
              {/* <h2 className="font-normal">Basic Details</h2> */}
              <div className="mt-3">
                <BasicDetails
                  onBasicDetailsChange={handleBasicDetails}
                  navigateToFormStep={navigateToFormStep}
                  editMode={editMode}
                />
              </div>
            </section>
            <section
              id="step-2"
              className={`form-step ${currentStep === 2 ? "" : "d-none"}`}
            >
              {/* <h2 className="font-normal">Social Profiles</h2> */}
              <div className="mt-3">
                <Communication
                  onCommunicationDataChange={handleCommunicationDataChange}
                  setFormData={setFormData} // Pass setFormData to Communication component
                  navigateToFormStep={navigateToFormStep}
                  navigateToBackStep={navigateToBackStep}
                />
              </div>
            </section>
            <section
              id="step-3"
              className={`form-step ${currentStep === 3 ? "" : "d-none"}`}
            >
              {/* <h2 className="font-normal">key contacts</h2> */}
              <div className="mt-3">
                <KeyContacts
                  onKeyContactsChange={handleKeyContacts}
                  navigateToFormStep={navigateToFormStep}
                  navigateToBackStep={navigateToBackStep}
                />
              </div>
              {/* <div className="mt-3 float-right">
                  <button
                    className="next_button ml-3"
                    type="button"
                    onClick={() => navigateToFormStep(4)}
                  >
                    Save & Next1
                  </button>
                </div>  */}
            </section>
            <section
              id="step-4"
              className={`form-step ${currentStep === 4 ? "" : "d-none"}`}
            >
              {/* <h2 className="font-normal">Modules</h2> */}
              <div className="mt-3">
                <Modules />
              </div>
              <div className="mt-3 float-left">
                <button
                  className="back_button"
                  type="button"
                  onClick={() => navigateToBackStep(3)}
                >
                  Back
                </button>
              </div>
              <div className="mt-3 float-right">
                <button
                  className="cancel_button"
                  type="button"
                  onClick={() => navigate("/home")}
                >
                  Cancel
                </button>
                <button
                  className="next_button ml-3"
                  type="button"
                  onClick={() => navigateToFormStep(5)}
                >
                  Save & Next
                </button>
              </div>
            </section>
            <section
              id="step-5"
              className={`form-step ${currentStep === 5 ? "" : "d-none"}`}
            >
              {/* <h2 className="font-normal">Package</h2> */}
              <div className="mt-3">
                <Package
                  onPackageChange={handlePackage}
                  orgName={orgName}
                  name={formData?.basicDetails?.name}
                  navigateToBackStep={navigateToBackStep}
                />
              </div>
            </section>
            {/* </form> */}
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateOrg;

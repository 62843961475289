import { useState, useEffect, useInsertionEffect } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../shared/Navigation/TopBar";
import FilterModal from "./FilterModal";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Constants, Countries, billingCycle } from "./Credentials";
import Loader from "../../../shared/Loader/Loader";

const HomePage = ({ sidebarCollapsed }) => {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [count, setCount] = useState(0);
  const [organizations, setOrganizations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [organizationsCopy, setOrganizationsCopy] = useState([]);
  const [filterDropdowns, setFilterDropdowns] = useState([]);
  const [loading, setLoading] = useState(false);

  var page = 0;
  const Api = Constants.api;
  const user = sessionStorage.getItem("user");
  const loginObject = user ? JSON.parse(user) : null;

  const defaultCountry = "Country";
  const defaultBusinessType = "Business Type";
  const defaultYear = "Creation Year";
  const defaultBilling = "Billing Cycle";
  const defaultPackageName = "Package Name";
  const defaultAccountManager = "Account Manager";
  const defaultChannelPartner = "Channel Partner";
  const defaultBusinessSegment = "Business Segment";

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const parts = selectedOption.split("-");
    const filterType = event.target.name; // Assuming you have set the name attribute for each select element

    const existingFilterIndex = selectedFilters.findIndex(
      (filter) => filter.type === filterType
    );

    if (existingFilterIndex !== -1) {
      // If there's already a filter of the same type, replace it
      const updatedFilters = [...selectedFilters];
      updatedFilters[existingFilterIndex] = {
        type: filterType,
        value: parts[0],
        id: parts[1],
      };
      setSelectedFilters(updatedFilters);
    } else {
      // If there's no filter of the same type, add the new filter
      setSelectedFilters([
        ...selectedFilters,
        // { type: filterType, value: selectedOption },
        { type: filterType, value: parts[0], id: parts[1] },
      ]);
    }
  };

  const removeFilter = (index) => {
    const newFilters = [...selectedFilters];
    newFilters.splice(index, 1);
    setSelectedFilters(newFilters);
  };

  const handleClose = () => {
    setOpen(false);
    getFilterResults();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function increment() {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 5);
    });
  }

  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 5);
      } else {
        return (prevCount = 0);
      }
    });
  }

  const navigate = useNavigate();

  useEffect(() => {
    getOrganizations();
    getFilterDropDowns();
  }, []);

  const getOrganizations = () => {
    setLoading(true);
    fetch(`${Api}/org/list/${page}`, {
      method: "GET",
      //Request Type
      headers: {
        Authorization: `Bearer ${loginObject?.token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          setLoading(false);
          setOrganizations(responseJson.response.result);
          setOrganizationsCopy(responseJson.response.result);
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        setLoading(false);
        // return error;
      });
  };

  const getFilterDropDowns = () => {
    fetch(`${Api}/org/filters`, {
      method: "GET",
      //Request Type
      headers: {
        Authorization: `Bearer ${loginObject?.token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          setFilterDropdowns(responseJson.response);
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        // return error;
      });
  };

  const handleCheckboxChange = (org) => {
    org.isActive = !org.isActive;
    console.log("changed org status:", org.isActive, org.id);
    fetch(`${Api}/org/${org.id}/${org.isActive}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${loginObject?.token}`,
        "Content-Type": "application/json",
      }),
      //  body: JSON.stringify(orgData),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          console.log("toggle organization status response:", responseData);
          getOrganizations();
        }
      })
      .catch((error) => console.log(error));
  };

  const pullDataFromSearch = (data) => {
    setSearchTerm(data);
  };

  const getSearchedItems = (list) => {
    return list?.filter((org) => {
      return Object.values(org)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm?.toLowerCase());
    });
  };

  useEffect(() => {
    // if(selectedFilters?.length == 0 && organizations?.length > 0) {
    if (selectedFilters?.length == 0) {
      // setOrganizations(organizations);
      getOrganizations();
    }
  }, [selectedFilters]);

  const getFilterResults = () => {
    if (selectedFilters?.length > 0) {
      const requestBody = {
        country: null,
        businessType: null,
        businessSegment: null,
        channelPartner: null,
        creationYear: null,
        packageId: null,
        billingCycle: null,
        accountManager: null,
      };
      selectedFilters.forEach((item) => {
        requestBody[item.type] = item.value;
      });
      setLoading(true);
      fetch(`${Api}/org/list/filter`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginObject?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.success) {
            setLoading(false);
            setOrganizations(responseJson.response);
            setOrganizationsCopy(responseJson.response);
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          setLoading(false);
          // return error;
        });
    }
  };

  useEffect(() => {
    if (searchTerm != "") {
      console.log("C1");
      const filteredresult = getSearchedItems(organizations);
      setOrganizationsCopy(filteredresult);
    } else if (searchTerm == "") {
      console.log("C2");
      setOrganizationsCopy(organizations);
    }
  }, [searchTerm, selectedFilters]);

  return (
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar search={pullDataFromSearch} />
      <div className="create-organization">
        <div className="pl-4 pt-4">
          <p className="heading ">Organizations</p>
          <p className="d-flex align-items-center mt-3">
            Show
            <p className="count light_shadow ml-2">{count}</p>
            <div className="counters mr-2">
              <button onClick={increment}>
                {" "}
                <KeyboardArrowUpIcon fontSize="5px" />
              </button>
              <button onClick={decrement}>
                <KeyboardArrowDownIcon fontSize="5px" />
              </button>
            </div>
            entries
          </p>
        </div>

        <div className="d-flex">
          <div className="ml-auto">
            <button
              style={{
                border: "none",
                padding: "2px 8px",
                marginRight: "10px",
                borderRadius: "5px",
              }}
              onClick={handleOpen}
            >
              <TuneIcon />
            </button>
            <button
              // type="submit"
              // className="button"
              style={{
                backgroundColor: "#00a82d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                width: "90px",
                height: "30px",
                marginRight: "20px",
              }}
              onClick={() => navigate("/createorganization")}
            >
              + Add New
            </button>
          </div>
        </div>
        {/* <input
      type='text'
      className="form-control"
      placeholder="search"
      name="search"
      autoComplete="off"
      style={{width:'20%'}}
      /> */}
        <FilterModal isOpen={open} onClose={handleClose}>
          <label className="ml-3">Select Filters</label>
          <div
            className="row light_shadow mx-3 px-1 pt-4 select_filters"
            style={{ borderRadius: 8 }}
          >
            <div className="col-4">
              <div className="form-group">
                <select
                  className="form-control"
                  name="country"
                  onChange={handleSelectChange}
                  value={defaultCountry}
                >
                  {/* <option value="default" disabled selected hidden>
                    Country
                  </option> */}
                  <option value="default" hidden>
                    Country
                  </option>
                  {Countries?.map((country) => (
                    <option value={`${country}-${country}`}>{country}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="creationYear"
                  onChange={handleSelectChange}
                  value={defaultYear}
                >
                  <option value="default" hidden>
                    Creation Year
                  </option>
                  {/* <option value="2024">2023-24</option> */}
                  <option value={`${2023}-${2023}`}>2023</option>
                  <option value={`${2024}-${2024}`}>2024</option>
                </select>
              </div>
              <div className="form-group">
                <select
                  className="dropdown form-control"
                  name="billingCycle"
                  onChange={handleSelectChange}
                  value={defaultBilling}
                >
                  <option value="default" hidden>
                    Billing Cycle
                  </option>
                  {billingCycle?.map((bill) => (
                    <option value={`${bill?.id}-${bill?.name}`}>
                      {bill?.name}
                    </option>
                  ))}
                  {/* <option value="MONTHLY">Monthly</option>
                  <option value="QUATERLY">Quarterly</option>
                  <option value="HALFYEARLY">Half-Yearly</option>
                  <option value="YEARLY">Yearly</option> */}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <select
                  className="form-control"
                  id="businesstype"
                  name="businessType"
                  onChange={handleSelectChange}
                  value={defaultBusinessType}
                >
                  {/* <option
                    value="default"
                    disabled
                    selected
                    hidden
                    style={{ fontSize: "16px !important" }}
                  >
                    Business Type
                  </option> */}
                  <option value="default" hidden>
                    Business Type
                  </option>
                  {filterDropdowns?.businessType?.map((type) => (
                    <option value={`${type?.id}-${type?.name}`}>
                      {type?.name}
                    </option>
                  ))}
                  {/* <option value="default" hidden>Business Type</option>
                  <option value="b2b">B2B</option>
                  <option value="b2c">B2C</option>
                  <option value="retail">Retail</option>
                  <option value="ecommerce">Ecommerce</option>
                  <option value="d2c">D2C</option>
                  <option value="b2b2c">B2B2C</option>
                  <option value="institutional">Institutional</option>
                  <option value="industrial">Industrial</option>
                  <option value="subscription">Subscription</option>
                  <option value="service">Service</option>
                  <option value="project">Project</option>
                  <option value="govt">Govt.</option>
                  <option value="modernretail">Modern Retail</option>
                  <option value="exports">Exports</option>
                  <option value="others">Others</option> */}
                </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control dropdown"
                  id="package-name-dropdown"
                  name="packageId"
                  onChange={handleSelectChange}
                  value={defaultPackageName}
                >
                  <option value="default" hidden>
                    Package Name
                  </option>
                  {filterDropdowns?.packageName?.map((p) => (
                    <option
                      value={`${p?.id}-${p?.name}`}
                      className="package-name-option"
                    >
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <select
                  className="form-control"
                  name="accountManager"
                  onChange={handleSelectChange}
                  value={defaultAccountManager}
                >
                  <option value="default" hidden>
                    Account Manager
                  </option>
                  {/* <option>Sahil Singh</option> */}
                  {filterDropdowns?.accountManager?.map((manager, index) => {
                    if (manager !== "") {
                      return (
                        <option value={`${manager}-${manager}`}>
                          {manager}
                        </option>
                      );
                    }
                    return null; // If empty string, return null to exclude it from rendering
                  })}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <select
                  className="form-control"
                  name="channelPartner"
                  onChange={handleSelectChange}
                  value={defaultChannelPartner}
                >
                  <option value="default" hidden>
                    Channel Partner
                  </option>
                  {/* <option>Rishikesh Shevde</option> */}
                  {filterDropdowns?.channelPartner?.map((partner, index) => {
                    if (partner !== "") {
                      return (
                        <option value={`${partner}-${partner}`}>
                          {partner}
                        </option>
                      );
                    }
                    return null; // If empty string, return null to exclude it from rendering
                  })}
                </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  id="businesssegment"
                  name="businessSegment"
                  onChange={handleSelectChange}
                  value={defaultBusinessSegment}
                >
                  <option value="default" hidden>
                    Business Segment
                  </option>
                  {filterDropdowns?.businessSegment?.map((segment) => (
                    <option value={`${segment?.id}-${segment?.name}`}>
                      {segment?.name}
                    </option>
                  ))}
                  {/* <option value="road&constructions">
                    Road & Constructions
                  </option>
                  <option value="mining">Mining</option>
                  <option value="auto&machinery">Auto & Machinery</option>
                  <option value="chemicals">Chemicals</option>
                  <option value="metals">Metals</option>
                  <option value="paper">Paper</option>
                  <option value="plastics">Plastics</option>
                  <option value="power">Power</option>
                  <option value="steel">Steel</option>
                  <option value="others">Others</option> */}
                </select>
              </div>
            </div>
          </div>
          <label className="ml-3 mt-3">Selected Filters</label>
          <div
            className="selected_filters row light_shadow mx-3 px-1 pt-4"
            style={{ display: "flex", flexWrap: "wrap", height: "35%" }}
          >
            {selectedFilters.map((filter, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 17px 20px 17px",
                  padding: "5px 10px",
                  width: "28%",
                  borderRadius: "8px",
                  height: "35px",
                }}
                className="light_shadow"
              >
                <p style={{ color: "black", marginRight: "5px" }}>
                  {filter.id}
                </p>
                <button
                  style={{
                    border: "1px solid black",
                    borderRadius: "50%",
                    width: "14px",
                    height: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // float: "right",
                    marginLeft: "auto",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => removeFilter(index)}
                >
                  <CloseIcon fontSize="13px" />
                </button>
              </div>
            ))}
          </div>
          <div className="float-right mr-3">
            <button
              onClick={handleClose}
              className="modal_button modal_cancel_button "
            >
              Cancel
            </button>
            <button
              onClick={handleClose}
              className="modal_button modal_apply_button ml-2"
            >
              Apply
            </button>
          </div>
        </FilterModal>

        <table className="table mt-3">
          <thead style={{ padding: 20, backgroundcolor: "red" }}>
            <tr>
              {/* {headings.map((head, headID) => (
              <th key={headID}>{head}</th>
            ))} */}
              <th>ID</th>
              <th>Organization Name</th>
              <th>Creation Date</th>
              <th>Admin Name</th>
              <th>Mobile Number</th>
              <th>Package Name</th>
              <th>Billing Cycle</th>
              <th>Renewal Date</th>
              <th>Country</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* {typeof organizationdetails !== "undefined" &&
            organizationdetails.length > 0 &&
            organizationdetails?.map((organization) => (
              <tr key={organization.couponId}>
                <td className="organization">{organization.organizationId}</td>
                <td className="organization">
                  {organization.organizationName}
                </td>
              </tr>
            ))} */}
            {typeof organizationsCopy !== "undefined" &&
              organizationsCopy.length > 0 &&
              organizationsCopy
                ?.sort((a, b) => a.id - b.id)
                ?.map((org) => (
                  <tr key={org.id}>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.id}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "#fff",
                          padding: "0px",
                        }}
                        onClick={() =>
                          navigate(`/editorganization/${org.id}`, {
                            state: { id: org.id },
                          })
                        }
                      >
                        {org.name}
                      </button>
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.creationDate}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.adminName}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.mobileNumber}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.billingPackage}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.billingCycle
                        .split("_")
                        .map(
                          (part) =>
                            part.charAt(0).toUpperCase() +
                            part.slice(1).toLowerCase()
                        )
                        .join("-")}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.renewalDate}
                    </td>
                    <td
                      style={{ opacity: org.isActive ? 1 : 0.5 }}
                      className={org.isActive ? "" : "deleted_organizations"}
                    >
                      {org.country}
                    </td>
                    <td className="d-flex px-0">
                      <button
                        style={{
                          padding: 0,
                          margin: 0,
                          border: "none",
                          backgroundColor: "#fff",
                        }}
                        onClick={() =>
                          navigate(`/editorganization/${org.id}`, {
                            state: { id: org.id },
                          })
                        }
                        className="edit_icon light_shadow"
                      >
                        <img
                          src={"./assets/pencil-tool.png"}
                          style={{ width: "25px", padding: "5px" }}
                        />
                      </button>
                      <label className="table_switch">
                        <input
                          type="checkbox"
                          defaultChecked={org.isActive}
                          onChange={() => handleCheckboxChange(org)}
                        />
                        <span className="table_slider round"></span>
                      </label>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default HomePage;

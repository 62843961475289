import React, { useEffect } from 'react';
import TopBar from '../../../../shared/Navigation/TopBar';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminProfile } from '../../../../Redux/actions';

const Profile = ({ sidebarCollapsed }) => {
    const loginObject = JSON.parse(sessionStorage.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orgId } = useSelector((state) => state.loginReducer);
    const { profile } = useSelector((state) => state.adminReducer);
    
    useEffect(() => {
        dispatch(getAdminProfile(orgId, loginObject?.token));
    }, []);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <p className="heading pt-2 pl-4 m-2">Company Profile</p>
        <div className='profile-container'>
            <div className='profile-details bg-shadow'>
                <div className='details-heading'>
                    <div className='basic-details'>
                        Basic Details
                    </div>
                    <div className='profile-edit-btn'>
                        <button className='edit-profile-btn light_shadow' onClick={() => navigate('/admin/edit-company-profile', {
                            state: {
                                tab: 'nav-companydetails',
                                profile: profile
                            }
                        })}>
                            <img src={'/assets/pencil-tool.png'} alt='' style={{ width: '15px', objectFit: 'cover' }} />
                        </button>
                    </div>
                </div>
                <div className='org-name-logo'>
                    <div className='org-logo'>
                        <img src={'/assets/jsw.png'} alt='' />
                    </div>
                    <div className='org-address'>
                        <h3 className='org-name'>{profile?.name}</h3>
                        <p style={{ marginTop: '5px' }}>
                            {/* 510 Ghanshyam Enclave, New Link Road, near Dhanukarvadi metro station, Kandivali(W), 400061,
                            Mumbai, Maharashtra, India */}
                            {`${profile?.communicationAddress?.line1}, ${profile?.communicationAddress?.line2}, ${profile?.communicationAddress?.city}, 
                            ${profile?.communicationAddress?.state}, ${profile?.communicationAddress?.country}, ${profile?.communicationAddress?.pinCode}`}
                        </p>
                    </div>
                </div>
                <div className='org-specifics'>
                    <div className='org-data'>
                        <p className='org-data-heading'>Company Name</p>
                        <p>{profile?.name}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Business</p>
                        <p>{profile?.businessTypeName}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Email Id</p>
                        <p>{profile?.keyContacts !== undefined && profile?.keyContacts[0]?.email}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Phone No.</p>
                        <p>{profile?.keyContacts !== undefined && `+${profile?.keyContacts[0]?.mobile}`}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Company Location</p>
                        <p>Lumen Steels</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Admin Name</p>
                        <p>{profile?.keyContacts !== undefined && `${profile?.keyContacts[0]?.firstName} ${profile?.keyContacts[0]?.lastName}`}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Employee Id</p>
                        <p>{profile?.keyContacts !== undefined && profile?.keyContacts[0]?.id}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Designation / Role</p>
                        <p>{profile?.keyContacts !== undefined && profile?.keyContacts[0]?.designation}</p>
                    </div>
                    <div className='org-data'>
                        <p className='org-data-heading'>Division</p>
                        <p>Lumen Steels</p>
                    </div>
                </div>
            </div>
            <div className='profile-details bg-shadow' style={{ height: '44%' }}>
                <div className='details-heading package'>
                    <div className='basic-details'>
                        Package Details
                    </div>
                    <div className='profile-edit-btn'>
                        <button className='edit-profile-btn light_shadow' onClick={() => navigate('/admin/edit-company-profile', {
                            state: {
                                tab: 'nav-packagedetails',
                                profile: profile
                            }
                        })}>
                            <img src={'/assets/pencil-tool.png'} alt='' style={{ width: '15px', objectFit: 'cover' }} />
                        </button>
                    </div>
                </div>
                <div className='org-specifics package-specifics'>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Package Name</p>
                        <p>{profile?.license?.packageName}</p>
                    </div>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Number of Licenses*</p>
                        <p>{profile?.license?.numberOfLicenses}</p>
                    </div>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Price per License*</p>
                        <p>{`${profile?.license?.pricePerLicense}/-`}</p>
                    </div>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Valid From</p>
                        <p>{profile?.license?.validFrom}</p>
                    </div>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Valid To</p>
                        <p>{profile?.license?.validTo}</p>
                    </div>
                    <div className='org-data package-data'>
                        <p className='org-data-heading'>Billing Cycle</p>
                        <p>
                        {profile?.license?.billingCycle === 'MONTHLY' ? 'Monthly' : 
                        profile?.license?.billingCycle === 'QUATERLY' ? 'Quaterly' :
                        profile?.license?.billingCycle === 'HALY-YEARLY' ? 'Half-Yearly' :
                        'Yearly'
                        }</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-3 mr-3 mb-3 float-right">
            <button
            className="next_button ml-3"
            type="button"
            >
            Back
            </button>
            <button
            className="next_button ml-3"
            type="button"
            style={{ width: 'fit-content'}}
            >
            Change Password
            </button>
        </div>
     </div>
    </>
  )
}

export default Profile
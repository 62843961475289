import { useNavigate } from "react-router-dom";
import LoginTopBar from "./LoginTopBar";
const VerifyOtp = () => {
  const navigate = useNavigate();
  return (
    <div className="login-container">
      <div className="login-box bg-shadow">
        <LoginTopBar />
        <div className="login-form">
          <label>Verify OTP</label>
          <p className="small-text"> OTP has been sent </p>
          <input
            type="text"
            className="form-control"
            placeholder="Enter new password"
            name="email"
            autoComplete="off"
          />
          <div className="d-flex flex-direction-row">
            <a className="small-text text-secondary" href="/verifyotp">
              (00:50)
            </a>
            <a
              className="ml-auto"
              style={{ color: "#00a82d", fontSize: "14px" }}
              href="/forgotpassword"
            >
              (Resend OTP)
            </a>
          </div>
          <div className="d-flex">
            <button
              type="submit"
              onClick={() => navigate("/home")}
              //   disabled={formik.errors.passcode || formik.errors.mobileNumber}
              className="button mx-auto"
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;

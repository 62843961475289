import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import Select from "react-select";
import TopBar from "../../../../shared/Navigation/TopBar";
import Loader from "../../../../shared/Loader/Loader";
import { addNewScheme, createKpi, deleteScheme, getKpiList, toggleKpiStatus,  } from "../../../../Redux/actions";
import { Constants } from "../../SuperAdmin/Credentials";
import UploadTargetModal from "./UploadTargetModal";

const KpiSetup = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const Api = Constants.api;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, kpiList, products } = useSelector(
    (state) => state.adminReducer
  );

  const [kpiname, setKpiName] = useState("");
  const [emptykpiname, setEmptyKpiName] = useState(false);
  const [kpitype,setKpiType] = useState("");
  const [emptykpitype, setEmptyKpiType] = useState(false);
  const [unit,setUnit] = useState("");
  const [emptyunit, setEmptyUnit] = useState(false);
  const [period, setPeriod] = useState("");
  const [emptyperiod, setEmptyPeriod] = useState(false);
  const [showtargetModal, setShowTargetModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [editkpiid,setEditKpiId] = useState(0);
  const [kpis,setKpis] = useState(kpiList)
  
  console.log("kpis:", kpis);
  useEffect(() => {
    dispatch(getKpiList(loginObject?.token));
  }, []);

  useEffect(()=>{
    setKpis(kpiList)
  },[kpiList]);


  const handleSubmit = () => {
    if (kpiname === "") {
      setEmptyKpiName(true);
    }
    if (kpitype === "") {
      setEmptyKpiType(true);
    }
    if (period === "") {
      setEmptyPeriod(true);
    }
    if (unit === "") {
      setEmptyUnit(true);
    } else {
      setKpiName("");
      setUnit("");
      setKpiType("");
      setPeriod("");
      const kpiObj = {
        name: kpiname,
        kpiType: kpitype,
        period: period,
        targetName: "",
        achievementName: "",
        unit:unit,
      };
       dispatch(createKpi(kpiObj, loginObject?.token, isEdit,editkpiid,));
    }
  };

  const handleEditClick = (kpi) => {
    setEditKpiId(kpi?.id);
    setKpiType(kpi?.type);
    setKpiName(kpi?.name);
    setUnit(kpi?.unit);
    setPeriod(kpi?.period);
    setisEdit(true);
  };
  
  function handleCheckboxChange(kpi) {
    const updatedKpi = { ...kpi, active: !kpi.active };
    const toggleObj = {
      kpiId: updatedKpi?.id,
      enable: updatedKpi?.active
    };
    dispatch(toggleKpiStatus(loginObject?.token, toggleObj));
  }

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container ">
          <p className="heading pt-2 pl-2">KPI Setup</p>
          <div className="add-schemes-container">
            <div className="schemes-body-container h-100">
              <div
                className="schemes-container-left"
                style={{ height: kpis?.length > 0 ? "fit-content" : "100%" }}
              >
                {/* <p className="objectives-headings">Active Schemes & Promos</p> */}
                {kpis?.length > 0 ? (
                  <>
                    {kpis?.map((kpi) => (
                      <>
                        <div className="scheme-wrapper light_shadow p-2">
                          <div
                            className="d-flex"
                          >
                            <div className="font-weight-bold">
                              {kpi?.name}
                            </div>
                            <div className="ml-auto d-flex position-relative">
                              {/* <p>{`scheme?.startDate`}</p>
                              <p>{`scheme?.endDate`}</p> */}
                            <button className="edit-delete-btn position-absolute"
                              onClick={() => navigate("/admin/kpi-details")}
                             >
                                <img
                                  src={"/assets/show.png"}
                                  alt=""
                                  style={{right:'85px',top:'10px',zIndex:-1}}
                                  className="eye-icon position"
                                />
                              </button>
                              <button className="edit-delete-btn position-absolute"
                              onClick={() => handleEditClick(kpi)}
                              >
                                <img
                                  src={"/assets/pencil-tool.png"}
                                  alt=""
                                  style={{right:'64px',}}
                                  className="edit-delete-icon position-absolute align-top"
                                />
                              </button>
                              <label className="table_switch mt-0">
                        <input
                          type="checkbox"
                          checked={kpi?.active}
                          onChange={() => handleCheckboxChange(kpi)}
                        />
                        <span className="table_slider round"></span>
                      </label>
                            </div>
                          </div>
                          <div className="scheme-content">
                            {`KPI Type: ${kpi?.type}`}
                          </div>
                          <div className="d-flex align-items-center mt-1">
                          <div className="scheme-content d-flex align-items-center">
                            {`Unit:`}
                            <p className="light_shadow ml-2 p-1 px-3">{kpi?.unit}</p>
                          </div>
                          <div className="scheme-content d-flex align-items-center ml-2">
                            {`Period:`}
                            <p className="light_shadow ml-2 p-1 px-3">{kpi?.period}</p>
                          </div>
                            <div className="ml-auto">
                              
                            </div>
                          </div>
                        </div>
                        <div style={{width:'90%'}}> 
                        <button
                              className="upload_target_button m-0"
                              type="button"
                              onClick={() => setShowTargetModal(true)}
                            >
                             <p style={{color:"#000"}}> + Upload Target </p>
                            </button>
                          <button
                              className="assign_button float-right"
                              type="button"
                              // onClick={() => setShowProducts(true)}
                            >
                              + Upload Achievement
                            </button>
                            </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div className="empty-objectives-container light_shadow">
                    <span className="empty-objectives">
                      No Kpis present right now. Please add a new one.
                    </span>
                  </div>
                )}
              </div>
              <div className="schemes-container-right p-1 light_shadow" >
                <div className="add-schemes-box p-1" style={{height:'65%'}}>
                  <p className="objectives-headings ml-2">Add KPI</p>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">
                      KPI Type
                    </p>
                    <select
                      className="objective-input light_shadow"
                      name="attributeType"
                      value={kpitype}
                      onChange={(e) => {
                        setKpiType(e.target.value);
                        setEmptyKpiType(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Kpi Type
                      </option>
                      <option value="TARGET_VS_ACHIEVEMENT">Target Vs Achievement</option>
                      <option value="CONTRIBUTION">
                        Contribution
                      </option>
                      <option value="NUMBER">Number</option>
                    </select>
                    {emptykpitype && <div className="empty-error-msg">*Please select kpi type</div>}
                  </div>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">KPI Name</p>
                    <input
                      className="objective-input light_shadow"
                      placeholder=""
                      value={kpiname}
                      onChange={(e) => {
                        setKpiName(e.target.value);
                        setEmptyKpiName(false);
                      }}
                    />
                    {emptykpiname && (
                      <div className="empty-error-msg">
                        *KPI Name is required
                      </div>
                    )}
                  </div>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">
                      Select Unit
                    </p>
                    <select
                      className="objective-input light_shadow"
                      name="attributeType"
                      value={unit}
                      onChange={(e) => {
                        setUnit(e.target.value);
                        setEmptyUnit(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Unit
                      </option>
                      <option value="PERCENTAGE">Percentage</option>
                      <option value="KG">Kg</option>
                      <option value="LTR">Ltr</option>
                      <option value="GRAM">Gram</option>
                      <option value="NUMBER">Number</option>
                      <option value="CURRENCY">Currency</option>
                      <option value="DOZEN">Dozen</option>
                      <option value="TON">Ton</option>
                    </select>
                    {emptyunit && <div className="empty-error-msg">*Please select unit</div>}
                  </div>
                  <div className="add-new-objective">
                    <p className="add-objective-heading">
                      Select Period
                    </p>
                    <select
                      className="objective-input light_shadow"
                      name="attributeType"
                      value={period}
                      onChange={(e) => {
                        setPeriod(e.target.value);
                        setEmptyPeriod(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Period
                      </option>
                      <option value="MONTHLY">Monthly</option>
                      <option value="QUARTERLY">Quarterly</option>
                      <option value="YEARLY">Yearly</option>
                    </select>
                    {emptyperiod && <div className="empty-error-msg">*Please select period</div>}
                  </div>
                  <div className="mr-3 float-right">
                    <button
                      className="next_button ml-3"
                      type="button"
                      style={{ zIndex: 1 }}
                      onClick={() => handleSubmit()}
                    >
                      {isEdit ? `Save`:`Add`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}

      {showtargetModal && <UploadTargetModal showtargetModal={showtargetModal} setShowTargetModal={setShowTargetModal} />}
    </>
  );
};

export default KpiSetup;

import React from 'react';
import './CreateSectionModal.css';

const CreateSectionModal = ({ setCreateSectionFlag, mandatoryAttributes, nonMandatoryAttributes, navigate }) => {

  return (
    <div className='create-section-modal-wrapper'>
        <div className='section-modal-popup'>
        <h3>Do you want to create sections?</h3>
        <div className='section-modal-buttons'>
        <button 
            className='next_button ml-3'
            onClick={() => {
                navigate(`/admin/create-sections`, {
                  state: {
                    mandatoryAttributes: mandatoryAttributes,
                    nonMandatoryAttributes: nonMandatoryAttributes
                  }
                });
                setCreateSectionFlag(false);
              }}
            >Yes</button>
        <button 
            className='next_button ml-3'
            onClick={() => {
                navigate(`/admin/attributes-list-review`, {
                  state: {
                    mandatoryAttributes: mandatoryAttributes,
                    nonMandatoryAttributes: nonMandatoryAttributes
                  }
                });
                setCreateSectionFlag(false);
              }}
            >No</button>
        </div>
        </div>
    </div>
  )
}

export default CreateSectionModal
import { useState, useEffect } from "react";
import BasicDetails from "./BasicDetails";
import Communication from "./Communication";
import KeyContacts from "./KeyContacts";
import Modules from "./Modules";
import Package from "./Package";
import PurchaseHistory from "./PurchaseHistory";
import TopBar from "../../../shared/Navigation/TopBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Constants } from "./Credentials";
import Loader from "../../../shared/Loader/Loader";

const OrganizationProfile = ({ sidebarCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("nav-basicdetails");
  const orgId = location.state.id;
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const editMode = true;

  const [orgName, setOrgName] = useState("");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleUpdateButtonClick = () => {
    // Logic to find the index of the current active tab
    const tabs = [
      "nav-basicdetails",
      "nav-addressdetails",
      "nav-keycontacts",
      "nav-moduleselection",
      "nav-packageselection",
      "nav-purchasehistory",
    ];
    const currentIndex = tabs.indexOf(activeTab);
    // If the current tab is not the last one, switch to the next tab
    if (currentIndex < tabs.length - 1) {
      const nextTab = tabs[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };

  function getOrganizationDetails() {
    setLoading(true);
    fetch(`${Api}/org/${orgId}`, {
      method: "GET",
      //Request Type
      headers: {
        Authorization: `Bearer ${loginObject.token}`,
      },
    })
      .then((response) => response.json())
      .then((respJson) => {
        if (respJson.success) {
          setOrganizationDetails(respJson.response);
          setLoading(false);
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        // return error;
        setLoading(false);
      });
  }
  useEffect(() => {
    getOrganizationDetails();
    setActiveTab(location.state.tab);
    setOrgName(organizationDetails.name);
  }, []);
  // console.log("org details:", organizationDetails);

  return (
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
      <div className="create-organization">
        <p className="heading pt-4 pl-4">Organization Profile</p>
        <nav className="d-flex justify-content-center my-4">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-basicdetails" && "active"
              }`}
              id="nav-basicdetails-tab"
              data-toggle="tab"
              href="#nav-basicdetails"
              role="tab"
              aria-controls="nav-basicdetails"
              aria-selected="true"
              onClick={() => handleTabChange("nav-basicdetails")}
            >
              Basic Details
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-addressdetails" && "active"
              }`}
              id="nav-addressdetails-tab"
              data-toggle="tab"
              href="#nav-addressdetails"
              role="tab"
              aria-controls="nav-addressdetails"
              aria-selected="false"
              onClick={() => handleTabChange("nav-addressdetails")}
            >
              Address Details
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-keycontacts" && "active"
              }`}
              id="nav-keycontacts-tab"
              data-toggle="tab"
              href="#nav-keycontacts"
              role="tab"
              aria-controls="nav-keycontacts"
              aria-selected="false"
              onClick={() => handleTabChange("nav-keycontacts")}
            >
              Key Contacts
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-moduleselection" && "active"
              }`}
              id="nav-moduleselection-tab"
              data-toggle="tab"
              href="#nav-moduleselection"
              role="tab"
              aria-controls="nav-moduleselection"
              aria-selected="false"
              onClick={() => handleTabChange("nav-moduleselection")}
            >
              Module Details
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-packageselection" && "active"
              }`}
              id="nav-packageselection-tab"
              data-toggle="tab"
              href="#nav-packageselection"
              role="tab"
              aria-controls="nav-packageselection"
              aria-selected="false"
              onClick={() => handleTabChange("nav-packageselection")}
            >
              Package Details
            </a>
            <a
              className={`nav-item nav-link ${
                activeTab === "nav-purchasehistory" && "active"
              }`}
              id="nav-purchasehistory-tab"
              data-toggle="tab"
              href="#nav-purchasehistory"
              role="tab"
              aria-controls="nav-purchasehistory"
              aria-selected="false"
              onClick={() => handleTabChange("nav-purchasehistory")}
            >
              Purchase History
            </a>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
          <div
            className={`tab-pane fade ${
              activeTab === "nav-basicdetails" && "show active"
            }`}
            id="nav-basicdetails"
            role="tabpanel"
            aria-labelledby="nav-basicdetails-tab"
          >
            <BasicDetails
              editMode={editMode}
              organizationDetails={organizationDetails}
              handleUpdateButtonClick={handleUpdateButtonClick}
            />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "nav-addressdetails" && "show active"
            }`}
            id="nav-addressdetails"
            role="tabpanel"
            aria-labelledby="nav-addressdetails-tab"
          >
            <Communication
              editMode={editMode}
              organizationDetails={organizationDetails}
              handleUpdateButtonClick={handleUpdateButtonClick}
            />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "nav-keycontacts" && "show active"
            }`}
            id="nav-keycontacts"
            role="tabpanel"
            aria-labelledby="nav-keycontacts-tab"
          >
            <KeyContacts
              editMode={editMode}
              organizationDetails={organizationDetails}
              handleUpdateButtonClick={handleUpdateButtonClick}
              getOrganizationDetails={getOrganizationDetails}
            />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "nav-moduleselection" && "show active"
            }`}
            id="nav-moduleselection"
            role="tabpanel"
            aria-labelledby="nav-moduleselection-tab"
          >
            <Modules 
                          editMode={editMode}
                          organizationDetails={organizationDetails}
                          handleUpdateButtonClick={handleUpdateButtonClick}
/>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "nav-packageselection" && "show active"
            }`}
            id="nav-packageselection"
            role="tabpanel"
            aria-labelledby="nav-packageselection-tab"
          >
            <Package
              editMode={editMode}
              organizationDetails={organizationDetails}
              handleUpdateButtonClick={handleUpdateButtonClick}
            />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "nav-purchasehistory" && "show active"
            }`}
            id="nav-purchasehistory"
            role="tabpanel"
            aria-labelledby="nav-purchasehistory-tab"
          >
            <PurchaseHistory
              editMode={editMode}
              organizationDetails={organizationDetails}
              handleUpdateButtonClick={handleUpdateButtonClick}
            />
          </div>

          {/* {activeTab !== "nav-purchasehistory" && (

          )} */}
          {/* {activeTab === "nav-packageselection" && (
 
          )} */}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default OrganizationProfile;

import React, { useState, useEffect } from 'react'
import { Field } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; //date picker styles
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from 'moment';

const SubPackage = ({ name, setFieldValue, values, errors, touched, 
    validFrom, setValidFrom, validTo, setValidTo, navigate, flatDiscountFlag, 
    setFlagdiscountFlag, percentDiscountFlag, setPercentDiscountFlag, validToFlag, setValidToFlag, organizationDetails, editMode, setValues }) => {
      const [minValidTo, setMinValidTo] = useState(new Date());
      console.log('Values:',values);
      // console.log("validFrom:",validFrom);
      // console.log("validTo:",validTo);

    useEffect(() => {
      if(editMode && organizationDetails != '') {
        console.log('C1');
        setValues({
          organizationName: organizationDetails?.name,
          billingCycle: organizationDetails?.license?.billingCycle,
          currency: organizationDetails?.license?.currency,
          discountType: organizationDetails?.license?.discountType,
          discountValue: organizationDetails?.license?.discountValue,
          discountValueType: organizationDetails?.license?.discountValueType,
          // finalPrice: organizationDetails?.license?.finalPrice,
          numberOfLicenses: organizationDetails?.license?.numberOfLicenses,
          packageName: organizationDetails?.license?.packageName,
          pricePerLicense: organizationDetails?.license?.pricePerLicense,
          packagePrice: organizationDetails?.license?.numberOfLicenses * organizationDetails?.license?.pricePerLicense,
          validFrom: moment(organizationDetails?.license?.validFrom).format('DD-MM-YYYY'),
          validTo: moment(organizationDetails?.license?.validTo).format('DD-MM-YYYY')
          // validFrom: new Date(organizationDetails?.license?.validFrom),
          // validTo: new Date(organizationDetails?.license?.validTo),
          // validFrom: organizationDetails?.license?.validFrom,
          // validTo: organizationDetails?.license?.validTo
        });
        // console.log("Highlisht:",moment(values?.validFrom).format('DD/MM/YYYY'));
      }
    }, [editMode, organizationDetails]);

    // console.log("Values:",values);
    // console.log("Flag:",flatDiscountFlag);

    useEffect(() => {
      console.log('C2');
        setFieldValue(`organizationName`, name);
    }, [name]);

    useEffect(() => {
        if(values?.pricePerLicense!= 0 && values?.pricePerLicense != undefined  && values?.numberOfLicenses != 0 && values?.numberOfLicenses != undefined) {
          console.log('C3');
            setFieldValue(`packagePrice`, values?.pricePerLicense * values?.numberOfLicenses);
        }
    }, [values?.pricePerLicense, values?.numberOfLicenses, editMode]);

    useEffect(() => {
        if(values?.discountType == 'FLAT' && values?.packagePrice != undefined && values?.discountValue > values?.packagePrice && values?.discountValue > values?.finalPrice) {
          console.log('C4');
            setFlagdiscountFlag(true);
        } else if (values?.discountType == 'PERCENTAGE' && parseInt(values?.discountValue) > 100) {
          console.log('C5');
            setPercentDiscountFlag(true);
        } 
        if(values?.discountType == 'FLAT' && values?.packagePrice != undefined && values?.finalPrice < 0) {
          console.log('C6');
          setFlagdiscountFlag(true);
        }
    }, [values?.pricePerLicense, values?.discountType, values?.discountValue, values?.finalPrice]);

    useEffect(() => {
        if(flatDiscountFlag) {
          console.log('C7');
            setFieldValue(`finalPrice`, 0);
        }
        else if(!flatDiscountFlag && values?.discountType == 'FLAT' && values?.packagePrice != undefined && values?.discountValueType === 'ON_TOTAL_PRICE') {
          console.log('C8');
            setFieldValue(`finalPrice`, values?.packagePrice - values?.discountValue);
        } else if(!flatDiscountFlag && values?.discountType == 'FLAT' && values?.packagePrice != undefined && values?.discountValueType === 'PER_LICENSE') {
          console.log('C9');
          setFieldValue(`finalPrice`, values?.packagePrice - (values?.discountValue * values?.numberOfLicenses));
        } 
        if(percentDiscountFlag) {
          console.log('C10');
            setFieldValue(`finalPrice`, 0);
        } else if (!percentDiscountFlag && values?.discountType == 'PERCENTAGE' && values?.packagePrice != undefined) {
          console.log('C11');
            const percentValue = (values?.discountValue/100) * values?.packagePrice
            setFieldValue(`finalPrice`, values?.packagePrice - percentValue);
        }
    }, [flatDiscountFlag, percentDiscountFlag, values?.packagePrice, values?.discountType, values?.discountValue, values?.discountValueType]);

    useEffect(() => {
        if(values?.discountType == 'FLAT' && values?.packagePrice != undefined && values?.discountValue <= values?.packagePrice) {
          console.log('C12');
            setFlagdiscountFlag(false);
        }
        if(values?.discountType == 'PERCENTAGE' && parseInt(values?.discountValue) <= 100) {
          console.log('C13');
            setPercentDiscountFlag(false);
        }
    }, [values?.discountType, values?.discountValue, values?.packagePrice, values?.discountValue]);


    useEffect(() => {
      if(editMode && values?.validFrom != '') {
        const minValidTo = moment(values?.validTo, "DD-MM-YYYY").toDate();
      setMinValidTo(minValidTo);
      setValidTo(minValidTo);
      setFieldValue(`validTo`,minValidTo);
      } else {
      const minValidTo = moment(validFrom, "DD-MM-YYYY").toDate();
      setMinValidTo(minValidTo);
      setValidTo(minValidTo);
      setFieldValue(`validTo`,minValidTo);
    }
    }, [values?.validFrom, editMode, validFrom]);
    
    useEffect(() => {
      if (values?.validFrom !== '') {
        const validFromDate = moment(values?.validFrom, "DD-MM-YYYY").toDate();
        setValidFrom(validFromDate);
      }
    }, [values?.validFrom]);

    // console.log('Values:',values);

    useEffect(() => {
      const validFrom = moment(values?.validFrom);
      const validTo = moment(values?.validTo);
      if (validTo.isBefore(validFrom)) {
        console.log("Invalid date");
        setValidToFlag(true);
      } else {
        setValidToFlag(false);
      }
    },[values?.validFrom, values?.validTo]);

    // useEffect(() => {
    //   const fromDate = moment(validFrom, "DD/MM/YYYY");
    //   console.log("fromDate:",fromDate);
    //   const toDate = moment(validTo, "DD/MM/YYYY");
    //   console.log("toDate:",toDate);
    //   if(toDate.isBefore(fromDate)) {
    //     console.log("True condition");
    //     setValidToFlag(true);
    //   }
    //   if(toDate.isAfter(fromDate)) {
    //     console.log("False condition");
    //     setValidToFlag(false);
    //   }
    // }, [values?.validFrom, values?.validTo]);

  return (
    <>
         <div className="package-one col-4">
        <div className="form-group">
          <label htmlFor="organizationName">Organization Name*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="organizationName"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={editMode ? organizationDetails?.name : name}
            disabled
          />
        </div>
        <div className="form-group">
          <label htmlFor="packageName">Package Name*</label>
          <Field
            as="select"
            className="form-control dropdown"
            id="package-name-dropdown"
            name="packageName"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={editMode ? organizationDetails?.license?.packageName : values?.packageName}
          >
            <option value='BLOOMR' className="package-name-option">
              Bloomr
            </option>
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="numberOfLicenses">Number of Licenses*</label>
          <Field
            type="number"
            className="form-control"
            autoComplete="off"
            name="numberOfLicenses"
            onWheel={() => document.activeElement.blur()}
            onChange={(e) => {
              setFieldValue(`numberOfLicenses`, e.target.value);
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={editMode? organizationDetails?.license?.numberOfLicenses : values?.numberOfLicenses}
          />
          {touched?.numberOfLicenses && errors?.numberOfLicenses && (
                <span className="error-msg">{errors?.numberOfLicenses}</span>
              )}
        </div>
        <div className="form-group">
          <label htmlFor="pricePerLicense">Price Per License*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="pricePerLicense"
            onChange={(e) => {
              setFieldValue(`pricePerLicense`, e.target.value);
            }}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={editMode ? organizationDetails?.license?.pricePerLicense : values?.pricePerLicense}
          />
          {touched?.pricePerLicense && errors?.pricePerLicense && (
                <span className="error-msg">{errors?.pricePerLicense}</span>
              )}
        </div>
      </div>

      <div className="package-two col-4 ">
        <div className="form-group">
          <label htmlFor="packagePrice">Package Price*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="packagePrice"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.packagePrice}
            // value={values?.pricePerLicense * values?.numberOfLicenses}
            disabled
          />
        </div>
        <div className="form-group">
          <label htmlFor="discountType">Discount Type</label>
          <Field
            as="select"
            className="form-control dropdown"
            id="discountType"
            name="discountType"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.discountType}
            // value={editMode ? organizationDetails?.license?.discountType : ''}
          >
            <option value='PERCENTAGE'>Percentage</option>
            <option value='FLAT'>Value</option>
          </Field>
        </div>
          {/* <div className="form-group"> */}
            {values?.discountType === 'PERCENTAGE' ? (
              <>
              <div className="form-group">
            <label htmlFor="discountValue">Discount Value</label>
            <Field
              type="number"
              className="form-control"
              autoComplete="off"
              name="discountValue"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                  setFieldValue(`discountValue`, e?.target?.value);
              }}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.discountValue}
              // value={editMode ? organizationDetails?.license?.discountValue : ''}
            />
            {(flatDiscountFlag && (
              <span className="error-msg">{"Discount value cannot be more than Package price"}</span>
            ))}
            {(percentDiscountFlag && (
              <span className="error-msg">{"Invalid discount percent"}</span>
            ))}
            </div>
          </>) : (<>
            <div className="form-group form-group-discount">
              <div className='discount-container'>
            <label htmlFor="discountValue">Discount Value</label>
            <Field
              type="number"
              className="form-control form-control-discount"
              autoComplete="off"
              name="discountValue"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                  setFieldValue(`discountValue`, e?.target?.value);
              }}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.discountValue}
              // value={editMode ? organizationDetails?.license?.discountValue : ''}
            />
            {(flatDiscountFlag && (
              <span className="error-msg">{"Invalid Discount value"}</span>
            ))}
            {(percentDiscountFlag && (
              <span className="error-msg">{"Invalid discount percent"}</span>
            ))}
            </div>

          <div className='discount-container'>
          <label htmlFor="discountValueType" style={{ color: 'white' }}>Discount Type 2</label>
            <Field
              as="select"
              className="form-control dropdown form-control-discount"
              autoComplete="off"
              name="discountValueType"
            >
            <option value='PER_LICENSE'>Per license</option>
            <option value='ON_TOTAL_PRICE'>On total package</option>
            </Field>
            </div>
            </div>
          </>)}
      {/* </div> */}
        <div className="form-group">
          <label htmlFor='finalPrice'>Final Price*</label>
          <Field
            type="text"
            className="form-control"
            autoComplete="off"
            name="finalPrice"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.finalPrice}
            disabled
          />
        </div>
      </div>
      <div className="package-three col-4">
        <div className="form-group">
          <label htmlFor='currency'>Currency*</label>
          <Field
            as="select"
            className="form-control dropdown"
            id="currency"
            name="currency"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.currency}
            // value={editMode ? organizationDetails?.license?.currency : ''}
          >
            <option value='INR'>INR</option>
            <option value='USD'>USD</option>
            <option value='AED'>AED</option>
            {/* <option value='EUR'>EUR</option> */}
          </Field>
        </div>

        <div className="form-group">
          <label>Valid From*</label>
          <div className="datepicker-input">
            <div className="datepicker-icon">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className=" form-control "
              selected={validFrom}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setValidFrom(date);
                setFieldValue(`validFrom`,date);
                // setFieldValue(`validFrom`,date);
              }}
              name="validFrom"
              minDate={editMode ? validFrom : new Date()}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.package.validFrom}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Valid To*</label>
          <div className="datepicker-input">
            <div className="datepicker-icon">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className="form-control"
              selected={validTo}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setValidTo(date);
                setFieldValue(`validTo`,date);
              }}
              name="validTo"
              minDate={minValidTo}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // value={formik.values.package.validTo}
            />
            {/* {validToFlag && (
              <span className="error-msg">{""}</span>
            )} */}
          </div>
          {editMode && validToFlag && (
                <span className="error-msg">{"Valid to date cannot be before Valid from date"}</span>
              )}
        </div>

        <div className="form-group">
          <label htmlFor='billingCycle'>Billing Cycle*</label>
          <Field
           as='select'
            className="dropdown form-control"
            name="billingCycle"
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            // value={formik.values.billingCycle}
            // value={editMode ? organizationDetails?.license?.billingCycle : ''}
          >
            <option value='MONTHLY'>Monthly</option>
            <option value='QUARTERLY'>Quarterly</option>
            <option value='HALF_YEARLY'>Half-Yearly</option>
            <option value='YEARLY'>Yearly</option>
          </Field>
        </div>
      </div>
      {/* <div className="mt-3 mr-3 ml-auto">
        <button
          className="cancel_button"
          type="button"
          onClick={() => navigate("/home")}
        >
          Cancel
        </button>
        <button
          className="next_button ml-3"
          type="button"
        //   onClick={() => handleSubmit(formik.values)}
        >
          Create
        </button>
      </div> */}
    </>
  )
}

export default SubPackage
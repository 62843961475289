import TopBar from "../../../../../shared/Navigation/TopBar";
import { useNavigate } from "react-router-dom";

const ProspectStatus = ({ sidebarCollapsed }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container bg-shadow">
          <p className="heading pt-2 pl-2">Add Prospect Status</p>
          <div className="add-tasks-container">
            <p className="objectives-headings">Status</p>
            <div className="tasks-body-container">
              <div
                className="tasks-container-left"
                style={{
                  // height:
                    // tasks?.length > 0 ? 'fit-content' :
                    // "100%",
                }}
              >
                {/* {tasks?.length > 0 ? ( */}
                {/* <>  */}
                {/* {tasks?.map((task) => (<> */}
                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Suspect</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>


                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Prospect</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>
                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Lead</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>
                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Proposal</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>
                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Negotiation</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>
                <div className="task-wrapper bg-shadow">
                  <div className="task-content">
                    Closure</div>
                  <div className="mr-1 float-right task-edit-delete-btn">
                    <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button>
                    <button
                      className="edit-delete-btn"
                      // onClick={() => handleDeleteClick(task?.id)}
                    >
                      <img
                        src={"/assets/bin.png"}
                        alt=""
                        className="edit-delete-icon"
                      />
                    </button>
                  </div>
                </div>



                {/* </>))} */}
                {/* </>) : ( */}
                {/* <div className="empty-objectives-container bg-shadow">
                        <span className="empty-objectives">No Tasks present right now. Please add a new one.</span>
                      </div> */}
                {/* )}  */}
              </div>
              <div className="prospects-container-right">
                <div className="add-tasks-box bg-shadow">
                  <p className="heading-text">Add Prospect Status</p>
                  <div className="add-new-task">
                    <p className="add-objective-heading">Status Name</p>
                    <input
                      className="objective-input bg-shadow"
                      placeholder="Status Name"
                      // value={taskName}
                      onChange={(e) => {
                        // setTaskName(e.target.value);
                        // setEmptyTaskName(false);
                      }}
                    />
                    {/* {emptyTaskName && <div className="empty-error-msg">*Please add a Task</div>} */}
                  </div>
                  <div className="mt-3 mr-3 float-right">
                    <button
                      className="next_button ml-3"
                      type="button"
                      // onClick={() => navigate("/admin/functionality-setup")}
                      >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-3 float-right">
            <button
              className="next_button ml-3"
              type="button"
              onClick={() => navigate("/admin/functionality-setup")}
            >
              Save & Exit
            </button>
          </div>
        </div>
      </div>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default ProspectStatus;

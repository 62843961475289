import { useNavigate } from "react-router-dom";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const CustomerCodeSetup = ({ sidebarCollapsed }) => {
  const navigate = useNavigate();
  const { customerTypesList }  = useSelector(
    (state) => state.adminReducer
  );
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [codeAbbreviation, setCodeAbbreviation] = useState('');
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    setCodeAbbreviation(event.target.value);
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setIsChecked(value === 'systemGenerated');
  };
const handleSaveAndExit = () => {
  // dispatch
  navigate("/admin/functionality-setup");
}
  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container bg-shadow">
          <p className="heading pt-2 pl-2">Customer Code Setup</p>
          <div className="objective-body mt-5">
            <div className="cusotmer-code-left ml-2">
              <div className="customer-code-table bg-shadow">
                {/* {setupStatus?.customerMasterSetup ? (<> */}
                <table className="table">
                  <thead className="connections-table-head">
                    <tr className="no-bottom-border">
                      <th className="connection-table-child-parent">
                        Customer Name
                      </th>
                      <th className="connection-table-child-parent">
                        System generated
                      </th>
                      <th className="connection-table-edit-action">
                        Code Abbreviation
                      </th>
                      <th
                        className="connection-table-edit-action"
                        style={{ textAlign: "center" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {attributes?.map((attribute, index) => ( */}
                    <tr
                      //   key={index}
                      className="no-bottom-border"
                      //    style={{ backgroundColor: index === editedIndex ? 'orange' : ''}}
                    >
                      <td className="connections-table-row ">Direct Account</td>
                      <td className="connections-table-row text-center">Yes</td>
                      <td className="connections-table-row text-center">DA</td>
                      <td
                        className="connections-table-row"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_icon light_shadow connections-table-edit-btn"
                          // onClick={() => handleEditClick(attribute, index)}
                          // disabled={editAttributeFlag ? true : false}
                        >
                          <img
                            src={"/assets/pencil-tool.png"}
                            style={{ width: "20px", padding: "5px" }}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                    <tr
                      //   key={index}
                      className="no-bottom-border"
                      //    style={{ backgroundColor: index === editedIndex ? 'orange' : ''}}
                    >
                      <td className="connections-table-row ">
                        Indirect Account
                      </td>
                      <td className="connections-table-row text-center">No</td>
                      <td className="connections-table-row text-center">
                        ----
                      </td>
                      <td
                        className="connections-table-row"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_icon light_shadow connections-table-edit-btn"
                          // onClick={() => handleEditClick(attribute, index)}
                          // disabled={editAttributeFlag ? true : false}
                        >
                          <img
                            src={"/assets/pencil-tool.png"}
                            style={{ width: "20px", padding: "5px" }}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                    <tr
                      //   key={index}
                      className="no-bottom-border"
                      //    style={{ backgroundColor: index === editedIndex ? 'orange' : ''}}
                    >
                      <td className="connections-table-row ">Retailers</td>
                      <td className="connections-table-row text-center">Yes</td>
                      <td className="connections-table-row text-center">RET</td>
                      <td
                        className="connections-table-row"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_icon light_shadow connections-table-edit-btn"
                          // onClick={() => handleEditClick(attribute, index)}
                          // disabled={editAttributeFlag ? true : false}
                        >
                          <img
                            src={"/assets/pencil-tool.png"}
                            style={{ width: "20px", padding: "5px" }}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                    <tr
                      //   key={index}
                      className="no-bottom-border"
                      //    style={{ backgroundColor: index === editedIndex ? 'orange' : ''}}
                    >
                      <td className="connections-table-row ">WorkShop</td>
                      <td className="connections-table-row text-center">No</td>
                      <td className="connections-table-row text-center">---</td>
                      <td
                        className="connections-table-row"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_icon light_shadow connections-table-edit-btn"
                          // onClick={() => handleEditClick(attribute, index)}
                          // disabled={editAttributeFlag ? true : false}
                        >
                          <img
                            src={"/assets/pencil-tool.png"}
                            style={{ width: "20px", padding: "5px" }}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                    <tr
                      //   key={index}
                      className="no-bottom-border"
                      //    style={{ backgroundColor: index === editedIndex ? 'orange' : ''}}
                    >
                      <td className="connections-table-row ">Distributor</td>
                      <td className="connections-table-row text-center">Yes</td>
                      <td className="connections-table-row text-center">DIS</td>
                      <td
                        className="connections-table-row"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_icon light_shadow connections-table-edit-btn"
                          // onClick={() => handleEditClick(attribute, index)}
                          // disabled={editAttributeFlag ? true : false}
                        >
                          <img
                            src={"/assets/pencil-tool.png"}
                            style={{ width: "20px", padding: "5px" }}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                    {/* ))    
            } */}
                  </tbody>
                </table>
                {/* </>) : (<> */}
                {/* <div className='pending-setup'>You have not setup Customer Master.
                    <button className="link-btn" onClick={() => navigate('/admin/customer-master-setup')}>Click here</button> 
                    to setup customer master.
                </div> */}
                {/* </>)} */}
              </div>
            </div>
            <div className="customer-code-right">
              <p className="objectives-headings mb-2 ">Customer Code Setting</p>
              <select
                className="input-group-select"
                name="customerType"
                onChange={(e) => setSelectedCustomerType(e?.target?.value)}
              >
                <option value="" defaultValue="" hidden>
                  {"Select Customer Type"}
                </option>
                {customerTypesList.map((opt) => (
                  <option value={opt?.orgAttributeId}>{opt?.name}</option>
                ))}
              </select>
              <div className="customer-code-box mt-3 light_shadow">
                <div className="d-flex justify-content-around ">
                  <input
                    type="radio"
                    id="one"
                    name="radio"
                    value="systemGenerated"
                    checked={isChecked}
                    onChange={handleRadioChange}
                  />
                  <label className="customer-box-label" for="html">
                    System Generated Code
                  </label>
                  <input
                    type="radio"
                    id="two"
                    name="radio"
                    value="manual"
                    checked={!isChecked} // Manual is checked when isChecked is false
                    onChange={handleRadioChange}
                  />
                  <label className="customer-box-label" for="html">
                    Manual Code
                  </label>
                </div>
                <input
                  className="form-control mt-2"
                  placeholder="Enter Code Abbreviation"
                  value={codeAbbreviation}
                  onChange={handleInputChange}
                />
              </div>

              <div
                className="mr-3 mt-4 float-right"
                style={{ marginTop: "5.5rem" }}
              >
                <button
                  className="next_button ml-3"
                  type="button"
                  onClick={() => handleSaveAndExit()}
                >
                  Save & Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCodeSetup;

import React, { useEffect, useState, useRef } from 'react';
import TopBar from '../../../../../shared/Navigation/TopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import MandatoryAttributesSection from "./MandatoryAttributesSection";
import NonMandatoryAttributesSection from './NonMandatoryAttributesSection';
import DropTarget from './DropTarget';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateSections = ({sidebarCollapsed}) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bottomRef = useRef(null);
    const sectionBottomRef = useRef(null);

    const [sectionsList, setSectionsList] = useState([]);
    const [editedName, setEditedName] = useState(''); // State to store the edited name
    const [mandatoryAttributes, setMandatoryAttributes] = useState([]);
    const [nonMandatoryAttributes, setNonMandatoryAttributes] = useState([]);
    const [isBackNavigated, setIsBackNavigated] = useState(false);
    const [sectionName, setSectionName] = useState('');
    const [bottomRefIndex, setBottomRefIndex] = useState();
    console.log('sectionsList:',sectionsList);
  
    // console.log('sectionsList:',sectionsList);
    // const [, forceUpdate] = useState();

    // const handleChildChange = () => {
    //   forceUpdate(Math.random());
    // };

    // const [sectionData, setSectionData] = useState([{
    //   sectionName: '',
    //   attributesList: []
    // }]);
    const [sectionData, setSectionData] = useState([]);

    useEffect(() => {
      setMandatoryAttributes(state?.mandatoryAttributes);
      setNonMandatoryAttributes(state?.nonMandatoryAttributes);
    }, [state]);

    const initialValues = {
        sections: [{
            name: ''  
        }]
    }
    const handleAddClick = (index) => {
        if(isEditable) {
          const updatedSectionsList = [...sectionsList];
          updatedSectionsList[index].name = editedName;
          setSectionsList(updatedSectionsList)
          const updatedSectionData = [...sectionData];
          updatedSectionData[index].sectionName = editedName;
          setSectionData(updatedSectionData)
          setIsEditable(false);
          setEditIndex();
        } else {
          let secName = {
            name: sectionName
          }
        setSectionsList(prevList => [...prevList, secName]);
        //New code start
        let newSectionObject = {
          sectionName: sectionName,
          attributesList: []
        }
        setSectionData(prevList => [...prevList, newSectionObject]);
        setSectionName('');
        //New Code ends
        // remove(index);
        }
    }
    console.log('Section data:',sectionData);

    // const handleEditClick = (index) => {
    //     setEditableIndex(index);
    //     setIsEditable(true);
    // }
    const [isEditable, setIsEditable] = useState(false);
    const [editIndex, setEditIndex] = useState();

    // const handleEditClick = (index) => {
    //     const newName = prompt('Enter new name:');
    //     if (newName !== null) {
    //         setEditedNames({ ...editedNames, [index]: newName });
    //     }
    // };
    const handleEditClick = (index) => {
      // Get the value of the section at the specified index
      // const sectionValue = values.sections[index];
      // Push a new section field with the same value
      // push({ ...sectionValue });
      
      setIsEditable(true);
      setEditIndex(index);
      setEditedName(sectionsList[index].name); // Set the edited name to the current section name
    };

    const handleDeleteClick = (index) => {
      const updatedList = [...sectionsList];
      updatedList?.splice(index, 1);
      setSectionsList(updatedList);
      const filteredSection = sectionData?.filter((_, secIndex) => secIndex === index);
      const newManAttributes = filteredSection[0]?.attributesList?.filter((attribute) =>
      attribute?.isMandatory);
      if(newManAttributes?.length > 0) {
        const manAttributes = [...mandatoryAttributes];
        manAttributes?.splice(0, 0, ...newManAttributes);
        setMandatoryAttributes(manAttributes);
      }
      const newNonManAttributes = filteredSection[0]?.attributesList?.filter((attribute) =>
        !attribute?.isMandatory);
        if(newNonManAttributes?.length > 0) {
          const nonManAttributes = [...nonMandatoryAttributes];
          nonManAttributes?.splice(0, 0, ...newNonManAttributes);
          setNonMandatoryAttributes(nonManAttributes);
        }
      const updatedDataList = [...sectionData];
      updatedDataList?.splice(index, 1);
      setSectionData(updatedDataList);
    }

  const handleChildChange1 = (value1, value2, value3) => {
    // console.log('Index:',value1.split(" ")[1]);
    console.log('Length:',sectionData?.length);
    console.log('Section data outside:',sectionData);
    // console.log('Attribute:',value2);
    // console.log('Filtered:',value3);
    setMandatoryAttributes(value3);
    let index = value1.split(" ")[1];
    if(sectionData?.length > 0) {
      console.log("C1")
      //First check if 
      const updatedSectionsList = [...sectionData];
      updatedSectionsList[index].sectionName = sectionsList[index].name;
      updatedSectionsList[index].attributesList.push(value2);
      setSectionData(updatedSectionsList);
    } else {
      console.log("C2")
      let newSection = {
        sectionName: sectionsList[index].name,
        attributesList: [value2]
      }
      // console.log('Section:',newSection);
      sectionData.push(newSection);
      console.log('Section data:',sectionData);
    }

  }

  const handleChildChange = (value1, value2, value3) => {
    // setMandatoryAttributes(value3);
    let index = value1.split(" ")[1];
      //First check if 
      const updatedSectionsList = [...sectionData];
      updatedSectionsList[index].attributesList.push(value2);
      setSectionData(updatedSectionsList);
      // dispatch({ type: UPDATE_SECTION_DATA, payload: updatedSectionsList })
  }

  useEffect(() => {
    // for(let i = 0; i < sectionData?.length; i++) {
    //   for(let j = 0; j < sectionData[i]?.attributesList?.length; j++) {
    //     for (let k = 0; k < mandatoryAttributes?.length; k++) {
    //       if(mandatoryAttributes[k].name === sectionData[i]?.attributesList[j]?.name) {
    //         mandatoryAttributes.splice(k, 1);
    //       }
    //     }
    //   }
    // }
    if(sectionData?. length > 0) {
    const filteredMandatoryAttributes = mandatoryAttributes.filter(mandatoryAttr => {
      return !sectionData.some(section => {
        return section.attributesList.some(attr => attr.name === mandatoryAttr.name);
      })
    });
    // console.log('filteredMandatoryAttributes:',filteredMandatoryAttributes);
    setMandatoryAttributes(filteredMandatoryAttributes);
    if(nonMandatoryAttributes?.length > 0) {
      const filteredNonMandatoryAttributes = nonMandatoryAttributes.filter(nonMandatoryAttr => {
        return !sectionData.some(section => {
          return section.attributesList.some(attr => attr.name === nonMandatoryAttr.name);
        })
      });
      // console.log('NonfilteredMandatoryAttributes:',filteredNonMandatoryAttributes);
      setNonMandatoryAttributes(filteredNonMandatoryAttributes);
    }
    }
  }, [sectionData]);

  //Back Flow Logic Start
  useEffect(() => {
    if(state?.isBack != null) {
      setIsBackNavigated(state?.isBack);
    }
  }, [state?.isBack]);

  useEffect(() => {
    if(isBackNavigated) {
      setMandatoryAttributes(state?.mandatoryAttributes);
      setNonMandatoryAttributes(state?.nonMandatoryAttributes);
      setSectionsList(state?.sectionsList);
      setSectionData(state?.sectionData);
      // setIsBackNavigated(false);
    }
  }, [isBackNavigated, state, state?.mandatoryAttributes, state?.nonMandatoryAttributes, state?.sectionData]);
  //Back Flow Logic ends

  const handleSectionDrag= (index, attribute) => {

  }
  // console.log('Mandatory attri:',mandatoryAttributes);

  // const [{ canDropMandatory, isOverMandatory }, dropSection] = useDrop(() => ({
  //   accept: "attribute",
  //   drop: () => ({ name: `section` }),
  //   collect: (monitor) => ({
  //       isOver: !!monitor.isOver(),
  //       canDrop: !!monitor.canDrop(),
  //   })
  // }), []);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [sectionsList?.length]);

  useEffect(() => {
    const options = {
      behavior: 'smooth',
      block: 'nearest', // 'start', 'center', 'end', or 'nearest'
    };
    sectionBottomRef?.current?.scrollIntoView(options);
  }, [sectionData[bottomRefIndex]?.attributesList?.length]);

  return (
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
      <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>Create Sections</p>
      <div className="d-flex flex-direction-row justify-content-around">
        <div className='create_section_wrapper'>
          <div className='create_section_heading'>
            <div className='create_section_input'>
              <input 
              placeholder='Add New Section here...' 
              className='bg-shadow'
              value={sectionName}
              onChange={(e) => setSectionName(e?.target?.value)}
               />
            </div>
            <div className='create_section_btn'>
              <button 
              onClick={() => { sectionName !== '' && handleAddClick() }}
              >
              <img 
              src={'/assets/add.png'} 
              alt='add-btn'
              style={{ height: '20px' }}
              />
              </button>
            </div>
          </div>
          {sectionsList?.length > 0 && <div className="section_box_container bg-shadow" id="style-3">
            {sectionsList?.map((item, index) => (
              <>
              <div className='section_box bg-shadow'>
                <div className='section_name_heading'>
                  {/* <div className='section_name'> */}
                    {index === editIndex ? (<>
                      <div className='section_name' style={{ width: '69%' }}>
                        <input value={editedName} onChange={(e) => setEditedName(e?.target?.value)} className='edit-input' />
                      </div>
                      <div className='section-edit-del' style={{ width: '29%' }}>
                      <button
                       className='next_button'
                       style={{ width: '35px' }}
                       onClick={() => editedName !== '' && handleAddClick(index)}>
                        ✓
                      </button>
                      <button 
                      className='next_button'
                      style={{ width: '35px' }}
                      // className='section-edit-btn'
                      onClick={() => {
                        setIsEditable(false);
                        setEditIndex();
                      }}>
                       X
                      </button>
                      </div>
                    </>) : (<>
                      <div className='section_name'>
                      {`Section ${index + 1}:${item?.name}`}
                      </div>
                      <div className='section-edit-del'>
                  <button
                  className='section-edit-btn'
                  onClick={() => handleEditClick(index)}
                  >
                    <EditIcon style={{ width: '20px' }}/>
                  </button>
                  <button 
                  className='section-edit-btn' 
                  onClick={() => handleDeleteClick(index)}
                  >
                    <DeleteIcon style={{ width: '20px' }}/>
                  </button>
                  </div>
                  </>)}
                </div>
                <div className='section_attributes_box'>
                  <DropTarget
                    key={new Date().getTime() + Math.floor(Math.random * 1000000)}
                    sectionIndex={index}
                    sectionData={sectionData}
                    func={handleSectionDrag}
                    setSectionData={setSectionData}
                    setBottomRefIndex={setBottomRefIndex}
                  />
                  {/* {index === bottomRefIndex && <div ref={sectionBottomRef}></div>} */}
                </div>
                {index === bottomRefIndex && <div ref={sectionBottomRef}></div>}
                </div>
              </>
            ))}
            <div ref={bottomRef}></div>
          </div>}
        </div>
        
        {/* <div className="usermaster_box bg-shadow" style={{ marginLeft: "30px", marginRight: "0" }} id="style-3"> */}
        <div className="usermaster_box bg-shadow" style={{ marginLeft: "20px", marginRight: "20px", width: '28%', gap: '5px' }}>
          <div className='attribute-heading'>
          <p className="module_head mb-2">Mandatory Attributes</p>
          </div>
          <div className='attributes-container'>
          {/* {state?.mandatoryAttributes?.map((attribute) =>  */}
          {mandatoryAttributes?.map((attribute) => 
            <MandatoryAttributesSection
            key={new Date().getTime() + Math.floor(Math.random * 1000000)}
            attribute={attribute}
            mandatoryAttributes={mandatoryAttributes}
            nonMandatoryAttributes={nonMandatoryAttributes}
            onChange={handleChildChange}          
            />
          )}
          </div>
        </div>
        
        <div className="usermaster_box bg-shadow" style={{ marginLeft: "0", marginRight: "30px", width: '28%', gap: '5px' }}>
          <div className='attribute-heading'>
            <p className="module_head mb-2">Non-Mandatory Attributes</p>
          </div>
          <div className='attributes-container'>
          {/* {state?.nonMandatoryAttributes?.map(attribute =>  */}
          {nonMandatoryAttributes?.map(attribute => 
            <NonMandatoryAttributesSection
            key={new Date().getTime() + Math.floor(Math.random * 100000)}
            attribute={attribute}
            mandatoryAttributes={mandatoryAttributes}
            nonMandatoryAttributes={nonMandatoryAttributes}
            onChange={handleChildChange}
            />
          )}
          </div>
        </div>

      </div>
      <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
          //   setShowTab(false);
          //   navigateToFormStep(3);
          navigate("/admin/product-master-setup-attributes", {
            state: {
              mandatoryAttributes: state?.mandatoryAttributes,
              nonMandatoryAttributes: state?.nonMandatoryAttributes,
              isBack: true,
            }
          });
        //  navigate(-1); 
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            // setShowTab(false);
            // navigateToFormStep(3);
            // setCreateSectionFlag(true);
            if(mandatoryAttributes?.length > 0 || nonMandatoryAttributes?.length > 0) {
              toast.error('Please create sections for all attributes')
            } else {
            navigate(`/admin/review-sections-product`, {
              state: {
                sectionData: sectionData,
                sectionsList: sectionsList,
                mandatoryAttributes: mandatoryAttributes,
                nonMandatoryAttributes: nonMandatoryAttributes
              }
            });
            setIsBackNavigated(false);
          }
          }}
        >
          Save & Next
        </button>
      </div>
      </div>
  )
}

export default CreateSections
import React, { useState, useEffect } from "react";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { attributesRequest } from "../../../../../Redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../shared/Loader/Loader";
import { editParentChildConnection } from "../../../../../Redux/actions";

const ParentChildEdit = ({ sidebarCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginObject = JSON.parse(sessionStorage.user);

  const { orgId } = useSelector((state) => state.loginReducer);
  const { loading, attributes, setupStatus } = useSelector(
    (state) => state.adminReducer
  );
  const [editAttributeFlag, setEditAttributeFlag] = useState(false);
  const [editedIndex, setEditedIndex] = useState();
  const [editAttribute, setEditAttribute] = useState();
  const [newParent, setNewParent] = useState("default");
  const [emptyParent, setEmptyParent] = useState(false);
  const [parentChildRelation, setParentChildRelation] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token));
  }, []);

  useEffect(() => {
    if (attributes?.length > 0) {
      setParentChildRelation([]);
      attributes?.map((attribute) =>
        setParentChildRelation((parentChildRelation) => [
          ...parentChildRelation,
          {
            parentId:
              attribute?.parentOrgAttributeRes !== null
                ? attribute?.parentOrgAttributeRes?.orgAttributeId
                : null,
            parent:
              attribute?.parentOrgAttributeRes !== null
                ? attribute?.parentOrgAttributeRes?.name
                : null,
            childId: attribute?.orgAttributeId,
            child: attribute?.name,
          },
        ])
      );
    }
  }, [attributes]);

  const handleEditClick = (attribute, index) => {
    setEditAttributeFlag(true);
    setEditedIndex(index);
    setEditAttribute(attribute);
    let childId = null;
    for (let i = 0; i < parentChildRelation?.length; i++) {
      if (parentChildRelation[i]?.parentId === attribute?.orgAttributeId) {
        childId = parentChildRelation[i]?.childId;
      }
    }
    const options = attributes?.filter(
      (attri) =>
        attri.orgAttributeId !== attribute?.orgAttributeId &&
        attri?.orgAttributeId !== childId
    );
    setDropDownOptions(options);
  };

  const handleSubmit = () => {
    if (newParent === "default") {
      setEmptyParent(true);
    } else {
      const newConnection = [
        {
          attributeId: editAttribute?.orgAttributeId,
          parentCustomerTypeOrgAttributeId: parseInt(newParent),
        },
      ];
      dispatch(
        editParentChildConnection(
          orgId,
          "CUST_TYPE",
          newConnection,
          loginObject?.token,
          false
        )
      );
      setEditedIndex();
      setEditAttributeFlag(false);
    }
  };

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container bg-shadow">
          <p className="heading pt-2 pl-2">Edit Parent & Child Connection</p>
          <div className="parent-child-wrapper">
            <p className="objectives-headings mb-2">Connections</p>
            <div className="view-edit-wrapper">
              <div className="connections-table bg-shadow">
                {setupStatus?.customerMasterSetup ? (
                  <>
                    <table className="table">
                      <thead className="connections-table-head">
                        <tr className="no-bottom-border">
                          <th className="connection-table-child-parent">
                            Child
                          </th>
                          <th className="connection-table-child-parent">
                            Parent
                          </th>
                          <th className="connection-table-edit-action">
                            Last Edited On
                          </th>
                          <th
                            className="connection-table-edit-action"
                            style={{ textAlign: "center" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attributes?.map((attribute, index) => (
                          <tr
                            key={index}
                            className="no-bottom-border"
                            style={{
                              backgroundColor:
                                index === editedIndex ? "#00a82d" : "",
                              color: index === editedIndex ? "#fff" : "",
                            }}
                          >
                            <td className="connections-table-row">
                              {attribute?.name}
                            </td>
                            <td className="connections-table-row">
                              {attribute?.parentOrgAttributeRes?.name}
                            </td>
                            <td className="connections-table-row">24/04/28</td>
                            <td
                              className="connections-table-row"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                className="edit_icon light_shadow connections-table-edit-btn"
                                onClick={() =>
                                  handleEditClick(attribute, index)
                                }
                                // disabled={editAttributeFlag ? true : false}
                              >
                                <img
                                  src={"/assets/pencil-tool.png"}
                                  style={{ width: "20px", padding: "5px" }}
                                  alt=""
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="pending-setup">
                      You have not setup Customer Master.
                      {/* <p> */}
                      <button
                        className="link-btn"
                        onClick={() => navigate("/admin/customer-master-setup")}
                      >
                        Click here
                      </button>
                      to setup customer master.
                      {/* </p> */}
                    </div>
                  </>
                )}
              </div>
              {setupStatus?.customerMasterSetup && editAttributeFlag && (
                <div className="edit-connection bg-shadow">
                  <p className="heading-text edit-connection-heading">
                    Edit Connection
                  </p>
                  <div className="edit-connection-modal">
                    <p className="add-objective-heading">Child</p>
                    <input
                      className="objective-input bg-shadow"
                      placeholder="Add Objective"
                      value={editAttribute?.name}
                      disabled
                    />
                  </div>
                  <div className="edit-connection-modal">
                    <p className="add-objective-heading">Select Parent</p>
                    <select
                      className="objective-input bg-shadow"
                      name="attributeType"
                      value={newParent}
                      onChange={(e) => {
                        setNewParent(e.target.value);
                        setEmptyParent(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Parent
                      </option>
                      {dropDownOptions?.map((option, index) => (
                        <option key={index} value={option?.orgAttributeId}>
                          {option?.name}
                        </option>
                      ))}
                    </select>
                    {emptyParent && (
                      <div className="empty-error-msg">
                        *Please select parent
                      </div>
                    )}
                  </div>
                  <div className="mt-3 mr-3 float-left">
                    <button
                      className="next_button ml-3"
                      type="button"
                      onClick={() => {
                        setEditAttributeFlag(false);
                        setEditAttribute();
                        setEditedIndex();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="mt-3 mr-3 float-right">
                    <button
                      className="next_button ml-3"
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mr-3 mt-4 float-right">
              <button
                className="next_button ml-3"
                type="button"
                onClick={() => navigate("/admin/functionality-setup")}
              >
                Save & Exit
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ParentChildEdit;

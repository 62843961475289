import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetupList,
  addNewUserSetupOption,
  deleteUserSetupOption,
  editUserSetupOption,
  getListChildOptions
} from "../../../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../shared/Loader/Loader";
import EditOption from "../UserSetup/EditOption";
import { Constants } from "../../../SuperAdmin/Credentials";

const ProductSetup = ({ sidebarCollapsed }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginObject = JSON.parse(sessionStorage.user);

  const { loading, userSetup, productListChildOptions } = useSelector((state) => state.adminReducer);

  const [roleName, setRoleName] = useState("");
  const [emptyRoleName, setEmptyRoleName] = useState(false);
  const [parent, setParent] = useState("default");
  const [emptyParent, setEmptyParent] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [optionToEdit, setOptionToEdit] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (state !== null) {
      dispatch(
        getUserSetupList(state?.attribute?.orgAttributeId, loginObject?.token)
      );
    }
  }, [state]);

  useEffect(() => {
    if(state !== null) {
      if(state?.attribute?.inputType === "LIST_LINK" && state?.index > 0) {
        const parent = state?.attributes[state?.index - 1];
        console.log('Parent:', parent);
        dispatch(getParentOptions(parent?.orgAttributeId, loginObject?.token));
      }
    }
  }, [state]);

  const getParentOptions = (orgAttributeId, token) => async (dispatch) => {
      await fetch(
         `${Constants.api}/admin/list/${orgAttributeId}`,
         {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          })
         }
       ).then((response) => response.json())
       .then(async (responseData) => {
         if (responseData.success) {
          setOptions(responseData.response)
         }
       })
       .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    if (state?.attribute?.inputType === "LIST_LINK" && roleName === "" && parent === "default") {
      setEmptyRoleName(true);
      setEmptyParent(true);
    } else if((state?.attribute?.inputType === "LIST_LINK" || state?.attribute?.inputType === "LIST") && roleName === "") {
      setEmptyRoleName(true);
    } else if(state?.attribute?.inputType === "LIST_LINK" && state?.index > 0 && parent === "default") {
      setEmptyParent(true);
    } else {
      setRoleName("");
      setParent("default");
      const taskObj = {
        option: roleName,
        parentOptionId:state?.attribute?.inputType === "LIST" ? null :
         state?.attribute?.inputType === "LIST_LINK" &&
         state?.index === 0 ?
         null : parent
      };
      dispatch(
        addNewUserSetupOption(
          taskObj,
          state?.attribute?.orgAttributeId,
          loginObject?.token
        )
      );
    }
  };

  // const handleEditClick = (option) => {
  //   setEditRole(true);
  //   setOptionToEdit(option);
  // };

  const handleDeleteClick = (listOptionId) => {
    dispatch(
      deleteUserSetupOption(
        listOptionId,
        state?.attribute?.orgAttributeId,
        loginObject?.token
      )
    );
  };

  const handleEditClick = (option) => {
    setEditRole(true);
    setOptionToEdit(option);
  };

  const editTheOption = (updatedOption, listOptionId) => {
    setEditRole(false);
    const optionObj = {
      option: updatedOption,
    };
    dispatch(
      editUserSetupOption(
        listOptionId,
        optionObj,
        state?.attribute?.orgAttributeId,
        loginObject?.token
      )
    );
  };

  console.log('options:',options);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="user-setup-container bg-shadow">
          <p className="heading pt-2 pl-2">{state?.attribute?.inputType === 'LIST_LINK' && `${state?.index + 1}. `}{state?.attribute?.name}</p>
          <div className="add-user-container">
            <p className="objectives-headings">Options</p>
            <div className="user-body-container">
              <div
                className="user-container-left"
                style={{
                  height: userSetup?.length > 0 ? "fit-content" : "100%",
                }}
              >
                {userSetup?.length > 0 ? (
                  <>
                    {userSetup?.map((option) => (
                      <>
                        <div className="user-wrapper bg-shadow">
                          <div className="user-content">{option?.name}</div>
                          <div className="mr-1 float-right task-edit-delete-btn">
                            <button
                              className="edit-delete-btn"
                              onClick={() => handleEditClick(option)}
                            >
                              <img
                                src={"/assets/pencil-tool.png"}
                                alt=""
                                className="edit-delete-icon"
                              />
                            </button>
                            <button
                              className="edit-delete-btn"
                              onClick={() => handleDeleteClick(option?.id)}
                            >
                              <img
                                src={"/assets/bin.png"}
                                alt=""
                                className="edit-delete-icon"
                              />
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div className="empty-options-container bg-shadow">
                    <span className="empty-options">
                      No Options present right now. Please add a new one.
                    </span>
                  </div>
                )}
              </div>
              <div className="user-container-right">
                <div className="add-option-box bg-shadow" 
                style={{ height: ((state?.attribute?.inputType === "LIST_LINK" && state?.index === 0) || (state?.attribute?.inputType === "LIST")) ? '60%' : '85%' }}
                >
                  <p className="heading-text">Add New Option</p>
                  <div className="add-new-option">
                    <p className="add-option-heading">Option</p>
                    <input
                      className="option-input bg-shadow"
                      placeholder="Option Name"
                      value={roleName}
                      onChange={(e) => {
                        setRoleName(e.target.value);
                        setEmptyRoleName(false);
                      }}
                    />
                    {emptyRoleName && (
                      <div className="empty-error-msg">
                        *Please add an option name
                      </div>
                    )}
                  </div>
                  {(state?.attribute?.inputType === "LIST_LINK" && state?.index > 0) && 
                  <div className="add-new-option">
                    <p className="add-option-heading">Parent</p>
                    <select
                      className="objective-input bg-shadow"
                      name="attributeType"
                      value={parent}
                      onChange={(e) => {
                        setParent(e.target.value);
                        setEmptyParent(false);
                      }}
                    >
                      <option value="default" hidden>
                        Select Parent
                      </option>
                      {options?.map((option, index) => (
                        <option key={index} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </select>
                    {emptyParent && (
                      <div className="empty-error-msg">
                        *Please select parent
                      </div>
                    )}
                  </div>}
                  <div className="mt-3 mr-3 float-right">
                    <button
                      className="next_button ml-3"
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-3 float-right">
            <button
              className="next_button ml-3"
              type="button"
              onClick={() => navigate("/admin/functionality-setup")}
            >
              Save & Exit
            </button>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {editRole && (
        <EditOption
          option={optionToEdit}
          setEditRole={setEditRole}
          func={editTheOption}
        />
      )}
    </>
  );
};

export default ProductSetup;

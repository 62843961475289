// src/App.js
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import {useState} from 'react';
// Components
import Sidebar from "./shared/Navigation/Sidebar";
import AppRoutes from "./Routes/AppRoutes";
import { AuthProvider } from "./shared/Context/AuthContext";
import { Provider } from 'react-redux';
import { store, persistor } from "./Redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const userString = sessionStorage.getItem('user');
  const loginObject = userString ? JSON.parse(userString) : null;

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
  <DndProvider backend={HTML5Backend}>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Router>
      <AuthProvider>
      <div className="App">
      {/* {loginObject?.token &&(

        )} */}
        {(
        <Sidebar toggleSidebar={toggleSidebar} sidebarCollapsed={sidebarCollapsed} />
        )}
        <AppRoutes sidebarCollapsed={sidebarCollapsed} />
        <ToastContainer draggable={false} autoClose={3000} pauseOnHover={true} />
      </div>
      </AuthProvider>
    </Router>
    </PersistGate>
  </Provider>
  </DndProvider>
  );
}

export default App;

import * as actionTypes from "./actionTypes";
import { Constants } from "../../components/mainPages/SuperAdmin/Credentials";
import { toast } from "react-toastify";

export const onSignIn = (credentials, updateToken) => async (dispatch) => {
  dispatch({ type: actionTypes.USER_LOGIN_REQ }); //set Loader to true
  await fetch(`${Constants.api}/user/login`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email: credentials.email,
      password: credentials.password,
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        sessionStorage.setItem("user", JSON.stringify(responseData.response)); //set to session storage
        dispatch({
          type: actionTypes.USER_LOGIN_SUCCESS,
          payload: responseData.response,
        });
        updateToken(responseData.response.token);
        dispatch({ type: actionTypes.EMPTY_SETUP_STATUS });
        //  dispatch({ type: actionTypes.LOGIN_STATUS_TRUE })
      } else if (!responseData.validationResult) {
        dispatch({ type: actionTypes.USER_LOGIN_FAIL });
        toast.error("Invalid Credentials");
        //  alert("Invalid credentials :(");
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.USER_LOGIN_FAIL });
      toast.error("Internal Server Error");
    });
};

export const updateSetupStatus =
  (orgId, key, module, navigate, token) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_SETUP_STATUS_REQUEST });
    await fetch(`${Constants.api}/admin/${orgId}/setup/${key}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.UPDATE_SETUP_STATUS_SUCCESS });
          toast.success(`${module} setup done successfully`);
          navigate("/admin/organization-setup");
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.UPDATE_SETUP_STATUS_FAIL });
        toast.error("Something went wrong");
      });
  };

export const setupStatusRequest = (orgId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.SETUP_STATUS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/${orgId}/setup/status`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.SETUP_STATUS_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.SETUP_STATUS_FAIL });
    });
};

export const attributesLibraryRequest =
  (orgId, key, token) => async (dispatch) => {
    dispatch({ type: actionTypes.ATTRIBUTES_LIBRARY_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/library`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({
            type: actionTypes.ATTRIBUTES_LIBRARY_SUCCESS,
            payload: responseData.response,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ATTRIBUTES_LIBRARY_FAIL });
      });
  };

export const createCustomAttribute =
  (orgId, key, customAttribute, token) => async (dispatch) => {
    dispatch({ type: actionTypes.CUSTOM_ATTRIBUTES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/attribute/custom/add`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(customAttribute),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.CUSTOM_ATTRIBUTES_SUCCESS });
          toast.success("Custom attribute added successfully");
          dispatch(attributesLibraryRequest(orgId, key, token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.CUSTOM_ATTRIBUTES_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const deleteCustomAttribute =
  (link, orgId, key, token) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ATTRIBUTE_REQUEST }); //set Loader to true
    await fetch(
      //  `${Constants.api}/admin/${orgId}/${key}/attribute/custom/${atttributeId}`,
      `${Constants.api}/${link}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.DELETE_ATTRIBUTE_SUCCESS });
          toast.success("Custom attribute deleted successfully");
          dispatch(attributesLibraryRequest(orgId, key, token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.DELETE_ATTRIBUTE_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const setAttributesList =
  (orgId, key, masterKey, toastKey, attributes, token, navigate) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: attributes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_SUCCESS });
          //  dispatch(updateSetupStatus(orgId, "USER_MASTER_SETUP", "User Master", navigate, token));
          dispatch(
            updateSetupStatus(orgId, masterKey, toastKey, navigate, token)
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const attributesRequest = (orgId, key, token) => async (dispatch) => {
  dispatch({ type: actionTypes.ATTRIBUTES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/${orgId}/${key}/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.ATTRIBUTES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.ATTRIBUTES_FAIL });
    });
};

export const setCustomerType =
  (orgId, key, selectedCustomerTypes, token, isSetup, navigate) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: selectedCustomerTypes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_SUCCESS });
          {
            isSetup &&
              toast.success(
                "Customer Type setup & Parent Child Connection established successfully"
              );
          }
          //  dispatch(attributesRequest(orgId, key, token));
          //  navigate('/admin/organization-setup');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const setCustomerTypes =
  (
    orgId,
    key,
    selectedCustomerTypes,
    customerTypes,
    customerTypesLibrary,
    token,
    navigate
  ) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: selectedCustomerTypes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_SUCCESS });
          //  {isSetup && toast.success('Customer Type setup & Parent Child Connection established successfully')}
          navigate("/admin/customer-master-parent-child-connection", {
            state: {
              selectedCustomerTypes: customerTypes,
              customerTypesLibrary: customerTypesLibrary,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const setParentChildConnection =
  (orgId, key, selectedCustomerTypes, token, isSetup) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes/ct`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: selectedCustomerTypes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_SUCCESS });
          {
            isSetup &&
              toast.success(
                "Customer Type setup & Parent Child Connection established successfully"
              );
          }
          //  dispatch(attributesRequest(orgId, key, token));
          //  navigate('/admin/organization-setup');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const setAttributesListCustomer =
  (orgId, key, attributes, token, navigate, state) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: attributes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_SUCCESS });
          toast.success(`Attributes Setup for ${state?.customer?.name}`);
          navigate("/admin/customer-master-attributes", {
            state: {
              customer: state?.customer,
              count: state?.count,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_ATTRIBUTES_LIST_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const getCustomerTypeAttributes =
  (orgId, key, customerTypeId, token) => async (dispatch) => {
    // dispatch({ type: actionTypes.GET_CUSTOMER_TYPE_ATTRIBUTES_REQUEST }); //set Loader to true
    console.log(
      "URL:",
      `${Constants.api}/admin/${orgId}/${key}/attributes/${customerTypeId}`
    );
    await fetch(
      // `${Constants.api}/admin/${orgId}/${key}/attributes/${customerTypeId}`,
      `${Constants.api}/v1/customer/attributes/${customerTypeId}`,//temporary fix, change later
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({
            type: actionTypes.GET_CUSTOMER_TYPE_ATTRIBUTES_SUCCESS,
            payload: responseData.response,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.GET_CUSTOMER_TYPE_ATTRIBUTES_FAIL });
      });
  };

export const getGeographicNodes = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_GEOGRAPHIC_NODES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/geo/nodes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_GEOGRAPHIC_NODES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_GEOGRAPHIC_NODES_FAIL });
    });
};

export const setGeographicNodes = (token, node) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_GEOGRAPHIC_NODES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/geo/node`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(node),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({ type: actionTypes.SET_GEOGRAPHIC_NODES_SUCCESS });
        dispatch(getGeographicNodes(token));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.SET_GEOGRAPHIC_NODES_FAIL });
    });
};

export const editGeographicNodes =
  (token, node, nodeId) => async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_GEOGRAPHIC_NODES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/geo/node/${nodeId}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(node),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_GEOGRAPHIC_NODES_SUCCESS });
          dispatch(getGeographicNodes(token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.EDIT_GEOGRAPHIC_NODES_FAIL });
      });
  };

export const deleteGeographicNodes = (token, nodeId) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_GEOGRAPHIC_NODES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/geo/node/${nodeId}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({ type: actionTypes.DELETE_GEOGRAPHIC_NODES_SUCCESS });
        dispatch(getGeographicNodes(token));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.DELETE_GEOGRAPHIC_NODES_FAIL });
    });
};

export const getUserAttributes = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_ATTRIBUTES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/org_user/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_USER_ATTRIBUTES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_USER_ATTRIBUTES_FAIL });
    });
};

export const getUsersList = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USERS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/org_user/list/0`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_USERS_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_USERS_FAIL });
    });
};

export const getUserById = (token, userId) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_BY_ID }); //set Loader to true
  await fetch(`${Constants.api}/org_user/${userId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      console.log("get user by id res:", responseData.response.attributeValues);
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_USER_BY_ID_SUCCESS,
          payload: responseData.response.attributeValues,
        });
        return responseData.response.attributeValues;
      }
    })
    .catch((error) => {
      console.log("GET_USER_BY_ID_FAIL", error);
      dispatch({ type: actionTypes.GET_USER_BY_ID_FAIL });
    });
};

export const SetCombinedList = (allCustomers) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_COMBINED_LIST,
    payload: allCustomers,
  });
};

export const clearUserDetails = () => async (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_USER_DETAILS,
    // payload: userId,
  });
};

export const addNewUser =
  (user, setAddUserModal, token) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_NEW_USER_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/org_user/add`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributeValues: user }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.ADD_NEW_USER_SUCCESS });
          setAddUserModal(false);
          toast.success("User added successfully");
          dispatch(getUserAttributes(token));
          dispatch(getUsersList(token));
        } else {
          dispatch({ type: actionTypes.ADD_NEW_USER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_USER_FAIL });
        toast.error("Something went wrong");
      });
  };

export const editUserById =
  (user, setIsEdit, token, userId) => async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_NEW_USER_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/org_user/${userId}/edit`, //org_user/{userId}/edit
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ attributeValues: user }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_NEW_USER_SUCCESS });
          setIsEdit(false);
          //  toast.success('User added successfully');
          dispatch(getUserAttributes(token));
          dispatch(getUsersList(token));
        } else {
          dispatch({ type: actionTypes.EDIT_NEW_USER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.EDIT_NEW_USER_FAIL });
        toast.error("Something went wrong");
      });
  };

export const toggleUserStatus = (token, toggleObj) => async (dispatch) => {
  // dispatch({ type: actionTypes.TOGGLE_USER_STATUS_REQUEST }); //set Loader to true
  await fetch(
    `${Constants.api}/org_user/active/${toggleObj.userId}/${toggleObj.active}`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(toggleObj),
    }
  )
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({ type: actionTypes.TOGGLE_USER_STATUS_SUCCESS });
        dispatch(getUsersList(token));
      }
    })
    .catch((error) => {
      dispatch({ type: actionTypes.TOGGLE_USER_STATUS_FAIL });
      console.log("TOGGLE_USER_STATUS_FAIL", error);
    });
};

export const getObjectivesList = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_OBJECTIVES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/visit/objectives`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_OBJECTIVES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_OBJECTIVES_FAIL });
    });
};

export const addNewObjective = (objective, token) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_OBJECTIVES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/visit/objective`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(objective),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({ type: actionTypes.SET_OBJECTIVES_SUCCESS });
        toast.success("Objective added successfully");
        dispatch(getObjectivesList(token));
      }
    })
    .catch((error) => {
      dispatch({ type: actionTypes.SET_OBJECTIVES_FAIL });
      toast.error("Something went wrong");
    });
};

export const editObjectiveOption =
  (objective, visitObjectiveId, setEditObjectiveModal, token) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_OBJECTIVE_OPTION_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/admin/visit/objective/${visitObjectiveId}/option`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(objective),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_OBJECTIVE_OPTION_SUCCESS });
          toast.success("Objective modified successfully");
          setEditObjectiveModal(false);
          dispatch(getObjectivesList(token));
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.EDIT_OBJECTIVE_OPTION_FAIL });
        toast.error("Something went wrong");
      });
  };

export const deleteObjective = (objectiveId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_OBJECTIVES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/visit/objective/${objectiveId}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        toast.success("Objective deleted successfully");
        dispatch({ type: actionTypes.DELETE_OBJECTIVES_SUCCESS });
        dispatch(getObjectivesList(token));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.DELETE_OBJECTIVES_FAIL });
    });
};

export const deleteObjectiveOption =
  (visitObjectiveId, vistObjOptionId, token) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_OBJECTIVE_OPTION_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/admin/visit/objective/${visitObjectiveId}/option/${vistObjOptionId}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          toast.success("Option deleted successfully");
          dispatch({ type: actionTypes.DELETE_OBJECTIVE_OPTION_SUCCESS });
          dispatch(getObjectivesList(token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.DELETE_OBJECTIVE_OPTION_FAIL });
      });
  };

export const getTasksList = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TASKS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/visit/tasks`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_TASKS_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_TASKS_FAIL });
    });
};


export const addNewTask = (objective, token) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_TASKS_REQUEST }); //set Loader to true
  await  fetch(`${Constants.api}/admin/visit/task`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(objective),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({ type: actionTypes.SET_TASKS_SUCCESS });
        toast.success("Task added successfully");
        dispatch(getTasksList(token));
      }
    })
    .catch((error) => {
      dispatch({ type: actionTypes.SET_TASKS_FAIL });
      toast.error("Something went wrong");
    });
};

export const deleteTask = (taskId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_TASKS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/visit/task/${taskId}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        toast.success("Task deleted successfully");
        dispatch({ type: actionTypes.DELETE_TASKS_SUCCESS });
        dispatch(getTasksList(token));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.DELETE_TASKS_FAIL });
    });
};

export const getSchemesList = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SCHEMES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/scheme/list`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_SCHEMES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_SCHEMES_FAIL });
    });
};

export const addNewScheme = (schemeObj, token, isEdit, schemeId,setShowProducts,setAcceptedFile) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_SCHEMES_REQUEST }); //set Loader to true
  await  fetch(isEdit ? `${Constants.api}/admin/scheme/${schemeId}` :`${Constants.api}/admin/scheme`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(schemeObj),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch(getSchemesList(token));
        dispatch({ type: actionTypes.SET_SCHEMES_SUCCESS });
        toast.success(isEdit? "Scheme edited successfully":"Scheme added successfully");
        setShowProducts(false);
        setAcceptedFile(null)
      }
    })
    .catch((error) => {
      console.log("add scheme error",error)
      dispatch({ type: actionTypes.SET_SCHEMES_FAIL });
      toast.error("Something went wrong");
    });
};

export const deleteScheme = (schemeId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_SCHEME_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/scheme/${schemeId}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        toast.success("Scheme deleted successfully");
        dispatch({ type: actionTypes.DELETE_SCHEME_SUCCESS });
        dispatch(getSchemesList(token));
      }
    })
    .catch((error) => {
      console.log("error deleting scheme",error);
      dispatch({ type: actionTypes.DELETE_SCHEME_FAIL });
    });
};

export const getUserSetupList = (orgAttributeId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_SETUP_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/list/${orgAttributeId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_USER_SETUP_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_USER_SETUP_FAIL });
    });
};

export const addNewUserSetupOption =
  (option, orgAttributeId, token) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_USER_SETUP_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/list/${orgAttributeId}/child`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(option),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_USER_SETUP_SUCCESS });
          toast.success("Option added successfully");
          dispatch(getUserSetupList(orgAttributeId, token));
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.SET_USER_SETUP_FAIL });
        toast.error("Something went wrong");
      });
  };

export const editUserSetupOption =
  (listOptionId, option, orgAttributeId, token) => async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_USER_SETUP_LIST_OPTION_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/list/${listOptionId}/edit`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(option),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_USER_SETUP_LIST_OPTION_SUCCESS });
          toast.success("Option updated successfully");
          dispatch(getUserSetupList(orgAttributeId, token));
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.EDIT_USER_SETUP_LIST_OPTION_FAIL });
        toast.error("Something went wrong");
      });
  };

export const deleteUserSetupOption =
  (listOptionId, orgAttributeId, token) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_USER_SETUP_LIST_OPTION_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/list/${listOptionId}`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          toast.success("Option deleted successfully");
          dispatch({ type: actionTypes.DELETE_USER_SETUP_LIST_OPTION_SUCCESS });
          dispatch(getUserSetupList(orgAttributeId, token));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.DELETE_USER_SETUP_LIST_OPTION_FAIL });
      });
  };

export const editParentChildConnection =
  (orgId, key, selectedCustomerTypes, token) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/${orgId}/${key}/set/attributes/ct`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributes: selectedCustomerTypes }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_SUCCESS });
          toast.success("Parent Child connection updated successfully");
          dispatch(attributesRequest(orgId, key, token));
          //  navigate('/admin/organization-setup');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_CUSTOMER_TYPES_FAIL });
        toast.error("Something went wrong");
        // dispatch(attributesLibraryRequest(orgId, key, token));
      });
  };

export const getAdminProfile = (orgId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ADMIN_PROFILE_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/org/${orgId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_ADMIN_PROFILE_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_ADMIN_PROFILE_FAIL });
    });
};

export const productCategoryRequest = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PRODUCT_CATEGORIES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/product/category`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_PRODUCT_CATEGORIES_FAIL });
    });
};

export const setProductHierarchy =
  (levels, navigate, token) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/product/category/set`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(levels),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_SUCCESS });
          toast.success("Product hierarchy set successfully");
          navigate("/admin/product-master-setup-attributes");
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_FAIL });
        navigate("/admin/product-master-setup-attributes");
      });
  };

export const editProductHierarchy =
  (orgAttributeId, name, disable, token) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/admin/product/category/${orgAttributeId}/edit`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ name: name }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_SUCCESS });
          disable(true);
          dispatch(productCategoryRequest(token));
          toast.success("Product hierarchy updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.SET_PRODUCT_HIERARCHY_FAIL });
      });
  };

export const getProductAttributes = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PRODUCT_ATTRIBUTES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/product/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_PRODUCT_ATTRIBUTES_FAIL });
    });
};

export const getListChildOptions = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LIST_CHILD_OPTIONS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/product/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_LIST_CHILD_OPTIONS_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_LIST_CHILD_OPTIONS_FAIL });
    });
};

export const addNewProduct =
  (user, imageUrls, setAddProductModal, token) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_NEW_PRODUCT_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/product/add`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributeValues: user, attachments: imageUrls }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.ADD_NEW_PRODUCT_SUCCESS });
          setAddProductModal(false);
          toast.success("Product added successfully");
          dispatch(getProductsList(token));
        } else {
          dispatch({ type: actionTypes.ADD_NEW_PRODUCT_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_PRODUCT_FAIL });
        toast.error("Something went wrong");
      });
  };

export const getProductsList = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PRODUCTS_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/product/list/0`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_PRODUCTS_SUCCESS,
          payload: responseData.response.result,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_PRODUCTS_FAIL });
    });
};

export const setFocusProduct =
  (productId, productFocus, token) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_NEW_PRODUCT_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/product/${productId}/focused/${productFocus}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.ADD_NEW_PRODUCT_SUCCESS });
          dispatch(getProductsList(token));
        } else {
          dispatch({ type: actionTypes.ADD_NEW_USER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_PRODUCT_FAIL });
        toast.error("Something went wrong");
      });
  };

export const setPopularProduct =
  (productId, productPopular, token) => async (dispatch) => {
    dispatch({ type: actionTypes.ADD_NEW_PRODUCT_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/product/${productId}/popular/${productPopular}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.ADD_NEW_PRODUCT_SUCCESS });
          dispatch(getProductsList(token));
        } else {
          dispatch({ type: actionTypes.ADD_NEW_USER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_PRODUCT_FAIL });
        toast.error("Something went wrong");
      });
  };

export const getCustomerTypes = (orgId, token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CUSTOMER_TYPES_LIST_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/${orgId}/CUST_TYPE/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_CUSTOMER_TYPES_LIST_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_CUSTOMER_TYPES_LIST_FAIL });
    });
};

export const addNewCustomer =
  (customer, customerTypeId, setAddCustomerModal, token) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.ADD_NEW_CUSTOMER_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/v1/customer/${customerTypeId}/add`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ attributeValues: customer }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.ADD_NEW_CUSTOMER_SUCCESS });
          setAddCustomerModal(false);
          toast.success("Customer added successfully");
          //  dispatch(getUsersList(token));
        } else {
          dispatch({ type: actionTypes.ADD_NEW_USER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_CUSTOMER_FAIL });
        toast.error("Something went wrong");
      });
  };

  export const editCustomerById =
  (token,customer, setAddCustomerModal,setIsEdit, customerTypeId, customerId,) => async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_CUSTOMER_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/v1/customer/${customerTypeId}/${customerId}/edit`, 
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ attributeValues: customer }),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_CUSTOMER_SUCCESS });
          setAddCustomerModal(false);
          setIsEdit(false);
          //  toast.success('User added successfully');
          // dispatch(getUserAttributes(token));
          dispatch(getCustomersList(token,customerTypeId));
        } else {
          dispatch({ type: actionTypes.EDIT_CUSTOMER_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.EDIT_CUSTOMER_FAIL });
        toast.error("Something went wrong");
      });
  };

  export const toggleCustomerStatus = (token, toggleObj,customerTypeId) => async (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_CUSTOMER_STATUS_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/v1/customer/${toggleObj.customerId}/${toggleObj.active}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(toggleObj),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.TOGGLE_CUSTOMER_STATUS_SUCCESS });
          dispatch(getCustomersList(token,customerTypeId));
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.TOGGLE_CUSTOMER_STATUS_FAIL });
        console.log("TOGGLE_CUSTOMER_STATUS_FAIL", error);
      });
  };

  export const getCustomersList = (token, customerTypeId) => async (dispatch) => {
    // dispatch({ type: actionTypes.GET_CUSTOMERS_LIST_REQUEST }); // Set Loader to true
    return fetch(`${Constants.api}/v1/customer/list/${customerTypeId}/1`, {
        method: "GET",
        headers: new Headers({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        }),
    })
    .then((response) => response.json())
    .then((responseData) => {
        if (responseData.success) {
            console.log("response customerslist:", responseData.response?.result);
            dispatch({
                type: actionTypes.GET_CUSTOMERS_LIST_SUCCESS,
                payload: responseData.response?.result,
            });
            return responseData.response?.result; // return the result here
        } else {
            throw new Error("Failed to fetch customers");
        }
    })
    .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.GET_CUSTOMERS_LIST_FAIL });
        return []; // Return an empty array in case of error
    });
};

  export const getCustomerById = (token, customerId) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_CUSTOMER_BY_ID_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/v1/customer/${customerId}/profile`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        console.log("get customer by id res:", responseData.response.attributeValues);
        if (responseData.success) {
          dispatch({
            type: actionTypes.GET_CUSTOMER_BY_ID_SUCCESS,
            payload: responseData.response,
          });
        }
      })
      .catch((error) => {
        console.log("GET_CUSTOMER_BY_ID_FAIL", error);
        dispatch({ type: actionTypes.GET_CUSTOMER_BY_ID_FAIL });
      });
  };

  export const getCustomerTypeId = (token, customerId) => async (dispatch) => {
    // dispatch({ type: actionTypes.GET_CUSTOMER_TYPE_ID_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/v1/customer/${customerId}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        console.log("get customer type id res:", responseData.response);
        if (responseData.success) {
          dispatch({
            type: actionTypes.GET_CUSTOMER_TYPE_ID_SUCCESS,
            payload: responseData.response,
          });
        }
      })
      .catch((error) => {
        console.log("GET_CUSTOMER_TYPE_ID_FAIL", error);
        dispatch({ type: actionTypes.GET_CUSTOMER_TYPE_ID_FAIL });
      });
  };

export const getListAttributes = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LIST_ATTRIBUTES_REQUEST }); //set Loader to true
  await fetch(`${Constants.api}/admin/list/attributes`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then(async (responseData) => {
      if (responseData.success) {
        dispatch({
          type: actionTypes.GET_LIST_ATTRIBUTES_SUCCESS,
          payload: responseData.response,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.GET_LIST_ATTRIBUTES_FAIL });
    });
};

export const editExistingProduct =
  (productId, editedProduct, setEditProductModal, token) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.EDIT_PRODUCT_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/product/${productId}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(editedProduct),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.EDIT_PRODUCT_SUCCESS });
          setEditProductModal(false);
          toast.success("Product edited successfully");
          dispatch(getProductsList(token));
        } else {
          dispatch({ type: actionTypes.EDIT_PRODUCT_FAIL });
          toast.error(`${responseData?.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.ADD_NEW_PRODUCT_FAIL });
        toast.error("Something went wrong");
      });
  };

  export const createKpi = (kpiObj, token, isEdit, kpiId,) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_KPI_REQUEST }); //set Loader to true
    await  fetch(isEdit ? `${Constants.api}/admin/kpi/${kpiId}/edit` :`${Constants.api}/admin/kpi/create`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(kpiObj),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch(getKpiList(token));
          dispatch({ type: actionTypes.CREATE_KPI_SUCCESS });
          toast.success(isEdit? "Kpi edited successfully":"Kpi added successfully");
        }
      })
      .catch((error) => {
        console.log("add scheme error",error)
        dispatch({ type: actionTypes.CREATE_KPI_FAIL });
        toast.error("Something went wrong");
      });
  };

  export const getKpiList = (token) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_KPI_REQUEST }); //set Loader to true
    await fetch(`${Constants.api}/admin/kpi/list`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({
            type: actionTypes.GET_KPI_SUCCESS,
            payload: responseData.response,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.GET_KPI_FAIL });
      });
  };

  export const toggleKpiStatus = (token, toggleObj) => async (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_KPI_REQUEST }); //set Loader to true
    await fetch(
      `${Constants.api}/admin/kpi/${toggleObj.kpiId}/${toggleObj.enable}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(toggleObj),
      }
    )
      .then((response) => response.json())
      .then(async (responseData) => {
        if (responseData.success) {
          dispatch({ type: actionTypes.TOGGLE_KPI_SUCCESS });
          dispatch(getKpiList(token));
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.TOGGLE_KPI_FAIL });
        console.log("TOGGLE_KPI_FAIL", error);
      });
  };

import React, { useState, useEffect } from 'react';
import TopBar from '../../../../../shared/Navigation/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { getTasksList, addNewTask, deleteTask } from "../../../../../Redux/actions";
import Loader from "../../../../../shared/Loader/Loader";
import { useNavigate } from 'react-router-dom';

const Tasks = ({ sidebarCollapsed }) => {
    const loginObject = JSON.parse(sessionStorage.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, tasks } = useSelector((state) => state.adminReducer);

    const [taskName, setTaskName] = useState('');
    const [emptyTaskName, setEmptyTaskName] = useState(false);

    useEffect(() => {
        dispatch(getTasksList(loginObject?.token));
    }, []);
    
    const handleSubmit = () => {
    if(taskName === '') {
        setEmptyTaskName(true);
    } else {
        setTaskName('');
        const taskObj = {
            task: taskName,
        }
        dispatch(addNewTask(taskObj, loginObject?.token));
    }
    }

    const handleDeleteClick = (taskId) => {
        dispatch(deleteTask(taskId, loginObject?.token));
      }
    
  return (
    <>
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
      <div className="objective-container bg-shadow">
        <p className="heading pt-2 pl-2">Add Task</p>
        <div className="add-tasks-container">
            <p className="objectives-headings">Tasks</p>
            <div className="tasks-body-container">
                <div className='tasks-container-left' style={{ height: tasks?.length > 0 ? 'fit-content' : '100%' }}>
                    {tasks?.length > 0 ? (
                    <> 
                    {tasks?.map((task) => (<>
                    <div className='task-wrapper bg-shadow'>
                        <div className='task-content'>
                            {task?.task}
                        </div>
                        <div className='mr-1 float-right task-edit-delete-btn'>
                            {/* <button className="edit-delete-btn"><img src={"/assets/pencil-tool.png"} alt="" className="edit-delete-icon" /></button> */}
                            <button className="edit-delete-btn" onClick={() => handleDeleteClick(task?.id)}><img src={"/assets/bin.png"} alt="" className="edit-delete-icon" /></button>
                        </div>
                    </div>
                    </>))}
                    </>) : (
                    <div className="empty-objectives-container bg-shadow">
                        <span className="empty-objectives">No Tasks present right now. Please add a new one.</span>
                      </div>
                    )} 
                </div>
                <div className='tasks-container-right'>
                    <div className='add-tasks-box bg-shadow'>
                    <p className="heading-text">Add Task</p>
                    <div className="add-new-task">
                        <p className="add-objective-heading">Task Name</p>
                        <input
                            className="objective-input bg-shadow"
                            placeholder="Add Task Name"
                            value={taskName}
                            onChange={(e) => {
                            setTaskName(e.target.value);
                            setEmptyTaskName(false);
                            }}
                        />
                        {emptyTaskName && <div className="empty-error-msg">*Please add a Task</div>}
                    </div>
                    <div className="mt-3 mr-3 float-right">
                    <button 
                    className="next_button ml-3" 
                    type="button"
                    onClick={() => handleSubmit()}
                    >
                    Add
                    </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mr-3 float-right">
            <button className="next_button ml-3" type="button" onClick={() => navigate("/admin/functionality-setup")}>
                Save & Exit
            </button>
        </div>
      </div>
    </div>
    {loading && <Loader />}
    </>
  )
}

export default Tasks
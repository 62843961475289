import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from 'moment';

const TravelPlanSetup = ({ SetTravelPlanSetup }) => {
    const [period, setPeriod] = useState('default');
    const [emptyPeriod, setEmptyPeriod] = useState(false);
    const [submissionDate, setSubmissionDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [approvalDate, setApprovalDate] = useState(moment(new Date()).format('DD-MM-YYYY'));

    const handleSubmit = () => {
      if(period === 'default') {
        setEmptyPeriod(true);
      }
    }

  return (
    <>
    <div className='custom-attribute-modal-wrapper'>
        <div className='travel-plan-box bg-shadow'>
        <p className="heading pt-2 pl-2">Travel Plan Setup</p>
          <div className='travel-plan-setup-wrapper'>
            <select
            className="travel-plan-select bg-shadow"
            name="attributeType"
            value={period}
            onChange={(e) => {
                setPeriod(e.target.value);
                setEmptyPeriod(false);
            }}
            style={{ marginBottom: emptyPeriod ? '3px' : '15px'}}
            >
                <option value="default" hidden>
                    Select Period
                </option>
                <option value="CURRENT_MONTH">Current Month</option>
                <option value="NEXT_MONTH">Next Month</option>
            </select>
            {emptyPeriod && <div className="empty-error-msg">*Required</div>}
            </div>
            <div className='travel-plan-setup-wrapper'>
            <p className="add-objective-heading pt-2 pl-2" style={{ opacity: '1'}}>Submission Cut off Date for Every Month</p>
            <div className="datepicker-icon datepicker-select">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className='travel-plan-select travel-date-picker bg-shadow'
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
              value={submissionDate}
              onChange={(date) => setSubmissionDate(moment(date).format('DD-MM-YYYY'))}
            />
            </div>
            <div className='travel-plan-setup-wrapper'>
            <p className="add-objective-heading pt-2 pl-2" style={{ opacity: '1'}}>Approval Cut off Date for Every Month</p>
            <div className="datepicker-icon datepicker-select">
              <CalendarMonthIcon />
            </div>
            <DatePicker
              className='travel-plan-select travel-date-picker bg-shadow'
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
              value={approvalDate}
              onChange={(date) => setApprovalDate(moment(date).format('DD-MM-YYYY'))}
            />
            </div>
            <div className='visit-restrictions-btn-wrapper float-right' 
            // style={{ marginTop: emptyPeriod ? '' : '1rem' }}
            >
        <button 
          className="next_button ml-3" 
          type="button"
          onClick={() => SetTravelPlanSetup(false)}
          >
            Cancel
        </button>
        <button 
          className="next_button ml-3" 
          type="button"
          onClick={() => handleSubmit()}
          >
            Submit
        </button>
        </div>
        </div>
    </div>
    </>
  )
}

export default TravelPlanSetup
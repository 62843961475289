import React, { useEffect, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector, useDispatch } from "react-redux";
// import BulkUploadModal from "./BulkUploadModal";
// import AddUserSectionModal from "./AddUserSectionModal";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { getProductAttributes, getProductsList, productCategoryRequest, setFocusProduct, setPopularProduct } from "../../../../../Redux/actions";
import Loader from "../../../../../shared/Loader/Loader";

const FocusedProducts = ({ sidebarCollapsed }) => {
  const navigate = useNavigate();
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const { orgId } = useSelector((state) => state.loginReducer);
  const { productAttributes, loading, setupStatus, products, productCategory } = useSelector(
    (state) => state.adminReducer
  );
  const [count, setCount] = useState(0);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  const [sectionData, setSectionData] = useState([]);
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [level3, setLevel3] = useState("");
  const [editableProduct, setEditableProduct] = useState();
  const [productLinkList, setProductLinkList] = useState([]);
 
  useEffect(() => {
    dispatch(getProductAttributes(loginObject?.token));
    dispatch(getProductsList(loginObject?.token));
    dispatch(productCategoryRequest(loginObject?.token));
  }, []);

  useEffect(() => {
    if (!loading && productAttributes?.length > 0) {
      const uniqueSections = [
        ...new Set(
          productAttributes.map((item) => item.section !== null && item.section)
        ),
      ];
      if (uniqueSections[0]) {
        const newData = uniqueSections?.map((section) => {
          return {
            sectionName: section,
            attributesList: productAttributes?.filter(
              (attr) => attr.section === section
            ),
          };
        });
        setSectionData(newData);
      } else {
        setSectionData(null);
      }
    }
  }, [loading, productAttributes]);

  useEffect(() => {
    if(productAttributes) {
      let categoryList = productAttributes?.filter((product) => product.inputType === "LIST_LINK");
      setProductLinkList(categoryList);
    }
  }, [productAttributes]);

  const increment = () => {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 5);
    });
  };

  const decrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 5);
      } else {
        return (prevCount = 0);
      }
    });
  };


  useEffect(() => {
    if (
      !loading &&
      setupStatus?.productMasterSetup &&
      productAttributes?.length > 0
    ) {
      let latestAttributes = [];
      for(let i = 3; i >= 1; i--) {
        let attribute = productCategory?.filter((_, index) => index === productCategory?.length - i);
        latestAttributes.push(attribute[0]);
      }
      // var listLinkAttributes = productAttributes?.filter(
      //   (attribute) => attribute?.inputType === "LIST_LINK"
      // );
      setLevel1(latestAttributes[0]?.name);
      setLevel2(latestAttributes[1]?.name);
      setLevel3(latestAttributes[2]?.name);
    }
  }, [loading, setupStatus, productCategory]);

  const handleCheckboxChange = (type, productId, param) => {
    if(type === 'focus') {
      dispatch(setFocusProduct(productId, !param, loginObject?.token))
    } else {
      dispatch(setPopularProduct(productId, !param, loginObject?.token))
    }
  }

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="add-user user-table">
          <div className="p-4">
            <div className="d-flex gap-3">
              <div className="ml-auto">
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <TuneIcon />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    height: "30px",
                  }}
                  //   onClick={handleOpen}
                >
                  <img
                    src={"/assets/download-file.png"}
                    alt="download"
                    style={{ width: "25px", padding: "2px" }}
                  />
                </button>
                <button
                  style={{
                    border: "none",
                    padding: "2px 8px",
                    borderRadius: "5px",
                    marginRight: "20px",
                    height: "30px",
                  }}
                  onClick={() => setBulkUploadModal(true)}
                >
                  <UploadFileIcon />
                </button>
              </div>
            </div>

            <div className="pl-3">
              <p className="d-flex align-items-center mt-3">
                Show
                <p className="count light_shadow ml-2">{count}</p>
                <div className="counters mr-2">
                  <button onClick={increment}>
                    {" "}
                    <KeyboardArrowUpIcon fontSize="5px" />
                  </button>
                  <button onClick={decrement}>
                    <KeyboardArrowDownIcon fontSize="5px" />
                  </button>
                </div>
                entries
              </p>
            </div>
            {setupStatus?.productMasterSetup ? (
              <>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className="table mt-3">
                    <thead style={{ padding: 20 }}>
                      <tr>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>{level1}</th>
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>{level2}</th>
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>{level3}</th>
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>Base Unit</th>
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>Billing Unit</th>
                        {/* <th className="table-cell-data" style={{ maxWidth: '80px' }}>Popular Product</th> */}
                        <th className="table-cell-data" style={{ maxWidth: '80px' }}>Focused Product</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="add-user-body">
                      {!loading && products?.map((product) => (
                        <tr key={product.id}>
                          <td
                            // style={{ opacity: org.isActive ? 1 : 0.5 }}
                            // className={org.isActive ? "" : "deleted_organizations"}
                            // style={{ maxWidth: "50px" }}
                            className="table-cell-data"
                          >
                            {product.productCode}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.productName}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.level1}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.level2}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.level3}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.baseUnit}
                          </td>
                          <td
                          // style={{ opacity: org.isActive ? 1 : 0.5 }}
                          // className={org.isActive ? "" : "deleted_organizations"}
                          className="table-cell-data"
                          >
                            {product.billingUnit}
                          </td>
                          {/* <td>
                          <input 
                          id="popular"
                          type="checkbox"
                          checked={product.popular}
                          onChange={() => handleCheckboxChange("popular", product?.id, product?.popular)}
                          className="custom-checkbox product-checkbox"
                          />
                          </td> */}
                          <td>
                          <input 
                          id="focused"
                          type="checkbox"
                          checked={product.focused}
                          onChange={() => handleCheckboxChange("focus", product?.id, product?.focused)}
                          className="custom-checkbox product-checkbox"
                          />
                          </td>
                          <td
                            // className="d-flex"
                            style={{ gap: '5px', width: 'auto', textAlign: "left", position: "relative" }}
                          >

                            <label
                              className="table_switch"
                              style={{ left: '50%' }}
                            >
                              <input
                                type="checkbox"
                                defaultChecked={false}
                                // onChange={() => handleCheckboxChange(org)}
                              />
                              <span className="table_slider round"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <span className="add-user-span" style={{ left: "70%" }}>
                  Please setup Product Master to add a new product.
                </span>
                <p className="add-user-span product-font">
                  <button
                    className="link-btn"
                    onClick={() => navigate("/admin/product-master-setup")}
                  >
                    Click here
                  </button>
                  to setup product master
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default FocusedProducts;

import React, { useEffect, useState } from 'react';
import TopBar from '../../../../../shared/Navigation/TopBar';
import { useNavigate, useLocation } from "react-router-dom";
import { setAttributesListCustomer } from '../../../../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../shared/Loader/Loader';

const AttributesListReview = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { orgId } = useSelector((state) => state.loginReducer); 
  const { loading } = useSelector((state) => state.adminReducer); 
  const [attributes, setAttributes] = useState([]);
  const [dispatchAction, setdispatchAction] = useState(false);
  console.log('state in review:',state);

  const handleNext = () => {
    for( let i = 0; i < state?.mandatoryAttributes?.length; i++) {
      const obj = {
        attributeId : state?.mandatoryAttributes[i]?.id != undefined ? 
        state?.mandatoryAttributes[i]?.id : state?.mandatoryAttributes[i]?.baseAttributeId,
        section: null,
        isMandatory: true,
        sequence: null,
        customerTypeId: state?.customerTypeId?.orgAttributeId
    }
    setAttributes(prevAttributes => [...prevAttributes, obj]);
    }
    if(state?.nonMandatoryAttributes?.length > 0) {
      for( let i = 0; i < state?.nonMandatoryAttributes?.length; i++) {
        const obj = {
          attributeId : state?.nonMandatoryAttributes[i]?.id != undefined ? 
          state?.nonMandatoryAttributes[i]?.id : state?.nonMandatoryAttributes[i]?.baseAttributeId,
          section: null,
          isMandatory: false,
          sequence: null,
          customerTypeId: state?.customerTypeId?.orgAttributeId
      }
      setAttributes(prevAttributes => [...prevAttributes, obj]);
      }
    }
    setdispatchAction(true);
  }

  useEffect(() => {
    if(dispatchAction && attributes?.length > 0) {
      // dispatch(setAttributesList(orgId, "CUSTOMER", attributes, loginObject.token, navigate));
      console.log('attributes in final review:',attributes);
      dispatch(setAttributesListCustomer(orgId, "CUSTOMER", attributes, loginObject.token, navigate, state));
    }
    // if(dispatchAction) {
    //   navigate('/admin/customer-master-attributes', {
    //       state: {
    //         customer: state?.customer,
    //         count: state?.count
    //       }
    //   });
    // }
  }, [dispatchAction])

  return (
    <>
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
      <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>{`Review Final List & Create Master for ${state?.customer?.name}`}</p>
      <div className="d-flex flex-direction-row justify-content-around">
        
        <div className="usermaster_box bg-shadow" style={{ marginLeft: '15%' }}>
          <div className='attribute-heading'>
          <p className="module_head mb-2">Mandatory Attributes</p>
          </div>
          <div className='attributes-container'>
          {state?.mandatoryAttributes?.map((attribute) => 
            <input 
            value={attribute.name} 
            disabled
            className="attribute"
            style={{ border: attribute.owner === "BLOOMR" ? "2px solid rgb(63, 228, 63)" : "", 
            boxShadow: !attribute.owner === "BLOOMR" ? "1px 0.5px 2px 0px" : "", minHeight: '40px' }}
            />
          )}
          </div>
        </div>
        
        <div className="usermaster_box bg-shadow" style={{ marginRight: '15%' }}>
          <div className='attribute-heading'>
          <p className="module_head mb-2">Non-Mandatory Attributes</p>
          </div>
          <div className='attributes-container'>
          {state?.nonMandatoryAttributes?.map((attribute) => 
            <input 
            value={attribute.name} 
            disabled
            className="attribute"
            style={{ border: attribute.owner === "BLOOMR" ? "2px solid rgb(63, 228, 63)" : "", 
            boxShadow: !attribute.owner === "BLOOMR" ? "1px 0.5px 2px 0px" : "", minHeight: '40px' }}
            />
          )}
          </div>
        </div>

      </div>
      <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
          //   setShowTab(false);
          //   navigateToFormStep(3);
          // navigate("/admin/organization-setup");
          navigate("/admin/customer-master-attributes", {
            state: {
              count: state?.count - 1,
              mandatoryAttributes: state?.mandatoryAttributes,
              nonMandatoryAttributes: state?.nonMandatoryAttributes,
              isBack: true,
            }
          });
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={handleNext}
        >
          Save & Create
        </button>
      </div>
      </div>
      {loading && <Loader />}
    </> 
  )
}

export default AttributesListReview
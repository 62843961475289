// src/components/LoginWithEmail.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginTopBar from "./LoginTopBar";
import { Constants } from "../mainPages/SuperAdmin/Credentials";
import { useAuth } from "../../shared/Context/AuthContext";
import * as actionTypes from '../../Redux/actions/actionTypes';
import { useDispatch, useSelector } from "react-redux";
import { onSignIn } from "../../Redux/actions";


const LoginWithEmail = () => {
  const { updateToken } = useAuth();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');
  const Api= Constants.api; 
  const { loading, role } = useSelector((state) => state.loginReducer); 

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address format"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit:  (values) => {
      dispatch(onSignIn(values, updateToken));
//       dispatch({ type: actionTypes.USER_LOGIN_REQ });   //set Loader to true
//       fetch(
//          `${Api}/user/login`,
//          {
//           method: "POST",
//           headers: new Headers({
//             // Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           }),
//           body: JSON.stringify({
//             email: values.email,
//             password: values.password,
//           }),
//          }
//        ) .then((response) => response.json())
//        .then(async (responseData) => {
//         //  console.log("login validation:", responseData);
//         console.log("api:", Api);
//          if (responseData.success) {
//            setToken(responseData.response.token);
//            sessionStorage.setItem("user", JSON.stringify(responseData.response));//set to session storage
//            dispatch({ type: actionTypes.USER_LOGIN_SUCCESS, payload: responseData.response})
// updateToken(responseData.response.token);

//              navigate("/home");
//          } else if (!responseData.validationResult) {
//            alert("Invalid credentials :(");
//          }
//        })
//        .catch((error) => {
//         console.log(error);
//         dispatch({ type: actionTypes.USER_LOGIN_FAIL })
//       });
   },
  });

  return (
    <div className="login-container">
      <form className="bg-shadow login-box" onSubmit={formik.handleSubmit}>
        <LoginTopBar />
        <div className="login-form">
          <label>Let's Get Started !</label>
          <p className="small-text">Enter your registered Email Id</p>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email/Mobile"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="form-error text-danger">
                {formik.errors.email}
              </div>
            )}
          </div>

          <div className="search-wrapper form-group">
              <img className="eye-icon" src={'/assets/show.png'} />
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
          {formik.touched.password && formik.errors.password && (
            <div className="form-error text-danger">
              {formik.errors.password}
            </div>
          )}
          </div>
          <div className="d-flex flex-direction-row">
            <a style={{ color: "#00a82d", fontSize: "14px" }} href="/verifyotp">
              Get OTP
            </a>
            <a className="ml-auto text-secondary small-text" href="/forgotpassword">
              Forgot password ?
            </a>
          </div>
          <div className="d-flex">
            <button
              type="submit"
              disabled={formik.errors.passcode || formik.errors.mobileNumber}
              className="button mx-auto"
            >
              Sign in
            </button>
          </div>
          <span className="line">
            <p>
              {" "}
              <span className="">or</span>{" "}
            </p>
          </span>
          <div className="d-flex">
            <p className="mx-auto mt-2 mb-3">Continue with</p>
          </div>
          <div className="d-flex justify-content-center">
            <a href="#" className="login_icon_wrapper ">
              <img
                src={'/assets/icons8-google-48.png'}
                className="login_icon light_shadow"
              />
            </a>
            <a href="#" className="login_icon_wrapper ">
              <img
                src={'/assets/icons8-outlook-48.png'}
                className="login_icon light_shadow"
              />
            </a>
            <a href="#" className="login_icon_wrapper ">
              <img
                src={'/assets/icons8-linkedin-48.png'}
                className="login_icon light_shadow"
              />
            </a>
            <a href="#" className="login_icon_wrapper ">
              <img
                src={'/../assets/icons8-apple-50.png'}
                className="login_icon light_shadow"
              />
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginWithEmail;

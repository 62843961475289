import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTasksList,
  addNewTask,
  deleteTask,
  getSchemesList,
  getProductsList,
  addNewScheme,
  deleteScheme,
} from "../../../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../../../shared/Navigation/TopBar";
import Loader from "../../../../../shared/Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Constants } from "../../../SuperAdmin/Credentials";
import Select from "react-select";

const SchemesAndPromos = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const Api = Constants.api;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, schemes, products } = useSelector(
    (state) => state.adminReducer
  );

  const [schemeName, setSchemeName] = useState("");
  const [emptySchemeName, setEmptySchemeName] = useState(false);
  const [schemeDescription, setSchemeDescription] = useState("");
  const [emptyDescription, setEmptyDescription] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [showProducts, setShowProducts] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const onDrop = useCallback((files) => {
    setAcceptedFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [editMode, setEditMode] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [editSchemeId,setEditSchemeId] = useState(0);
  const [schemeslist,setSchemesList] = useState(schemes)


  useEffect(() => {
    dispatch(getProductsList(loginObject?.token));
  }, []);
  console.log("schemeslist:", schemeslist);
  console.log("accepted file:",acceptedFile)
  const handleChange = (options) => {
    console.log(options);
    setSelectedOptions(options);
  };
  const options = products.map((product) => ({
    value: product.id,
    label: product.productName,
    productCode: product.productCode,
    description: product.description,
    price: product.price,
    imageUrls: product.imageUrls,
  }));
  useEffect(() => {
    dispatch(getSchemesList(loginObject?.token));
  }, []);

  const getUrl = async (productIds) => {
    const formData = new FormData();
    formData.append("file", acceptedFile);
    try {
      const response = await fetch(
        `${Api}/file/upload-url/${acceptedFile.name}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
            "Content-Type": "application/json",
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setFileUrl(data.response.documentUrl);
        uploadFile(
          data.response.fileUploadUrl,
          data.response.documentUrl,
          productIds
        );
        // editMode ? updateBasicDetails(values) : onBasicDetailsChange(values);
      }
    } catch (error) {
      console.error("Error getting file url:", error);
    }
  };
  console.log("file url:", fileUrl);
  const uploadFile = async (fileUploadUrl, documentUrl, productIds) => {
    // upload file to generated URL
    try {
      const response = await fetch(fileUploadUrl, {
        method: "PUT",
        headers: new Headers({}),
        body: acceptedFile,
      });
      if (response?.status === 200) {
        console.log(response);
        const schemeObj = {
          name: schemeName,
          description: schemeDescription,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          image: documentUrl,
          ...(productIds.length > 0 && { productIds: productIds })
        };
        dispatch(addNewScheme(schemeObj, loginObject?.token, isEdit, editSchemeId,setShowProducts,setAcceptedFile));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSubmit = () => {
    const productIds = selectedOptions.map((option) => option.value);
    if (schemeName === "") {
      setEmptySchemeName(true);
    }
    if (schemeDescription === "") {
      setEmptyDescription(true);
    } else {
      setSchemeName("");
      setSchemeDescription("");
      const schemeObj = {
        name: schemeName,
        description: schemeDescription,
        startDate: startDate,
        endDate: endDate,
        image: "",
        ...(productIds.length > 0 && { productIds: productIds }),
      };
      if (acceptedFile) {
        getUrl(productIds);
      } else dispatch(addNewScheme(schemeObj, loginObject?.token, isEdit,editSchemeId,setShowProducts,setAcceptedFile));
    }
  };
  const handleEditScheme = (scheme) => {
    setEditSchemeId(scheme?.id)
    setSchemeName(scheme?.name);
    setSchemeDescription(scheme?.description);
    setStartDate(scheme?.startDate);
    setEndDate(scheme?.endDate);
    setAcceptedFile(scheme?.image);
    setisEdit(true);
    const { products } = scheme;
      const options = products.map((product) => ({
    value: product.id,
    label: product.productName,
    productCode: product.productCode,
    description: product.description,
    price: product.price,
    imageUrls: product.imageUrls,
  }));

  setSelectedOptions(options);

    // Populate selected products
    // const selectedProducts = products
    //   .filter(product => scheme.productIds.includes(product.id))
    //   .map(product => ({
    //     value: product.id,
    //     label: product.productName,
    //     productCode: product.productCode,
    //     description: product.description,
    //     price: product.price,
    //     imageUrls: product.imageUrls,
    //   }));
  
    // setSelectedOptions(selectedProducts);
    // setEditMode(true);
    setShowProducts(true);
  };
  
  const handleDeleteProduct = (value) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== value
    );
    setSelectedOptions(updatedOptions);
  };
  const handleDeleteScheme = (taskId) => {
    dispatch(deleteScheme(taskId, loginObject?.token));
  }
  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="objective-container ">
          <p className="heading pt-2 pl-2">Schemes & Promos</p>
          <div className="add-schemes-container">
            <div className="schemes-body-container">
              <div
                className="schemes-container-left"
                style={{ height: schemeslist?.length > 0 ? "fit-content" : "100%" }}
              >
                <p className="objectives-headings">Active Schemes & Promos</p>
                {showProducts ? (
                  <div className="scheme-product-wrapper">
                    <Select
                      isMulti
                      options={options} // change to products
                      onChange={handleChange}
                      placeholder="Search and add product"
                    />
                    <div className="mt-2">
                      <div className="d-flex">
                        <p className="font-weight-bold">
                          Assigned Product List
                        </p>
                        <p className="font-weight-bold ml-auto">Action</p>
                      </div>
                      <div className="mt-1">
                        {selectedOptions &&
                          selectedOptions.map((option) => (
                            <div className="d-flex my-2">
                              <p key={option.value}>{option.label}</p>
                              <button
                                className="edit-delete-btn ml-auto"
                                onClick={() => handleDeleteProduct(option.value)}
                              >
                                <img
                                  src={"/assets/bin.png"}
                                  alt=""
                                  className="edit-delete-icon"
                                />
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : schemeslist?.length > 0 ? (
                  <>
                    {schemeslist?.map((scheme) => (
                      <>
                        <div className="scheme-wrapper light_shadow p-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ borderBottom: "1px solid grey" }}
                          >
                            <div className="font-weight-bold">
                              {scheme?.name}
                            </div>
                            <div className="ml-auto d-flex">
                              <p>{scheme?.startDate}</p>
                              <p>{scheme?.endDate}</p>
                            </div>
                          </div>
                          <div className="scheme-content">
                            {scheme?.description}
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="assign_button"
                              type="button"
                              onClick={() => setShowProducts(true)}
                            >
                              + Assign Product
                            </button>
                            <div className="ml-auto">
                              <button className="edit-delete-btn"
                              onClick={() => handleEditScheme(scheme)}
                              >
                                <img
                                  src={"/assets/pencil-tool.png"}
                                  alt=""
                                  className="edit-delete-icon"
                                />
                              </button>
                              <button
                                className="edit-delete-btn"
                                onClick={() => handleDeleteScheme(scheme?.id)}
                              >
                                <img
                                  src={"/assets/bin.png"}
                                  alt=""
                                  className="edit-delete-icon"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div className="empty-objectives-container light_shadow">
                    <span className="empty-objectives">
                      No Tasks present right now. Please add a new one.
                    </span>
                  </div>
                )}
              </div>
              <div className="schemes-container-right">
                <div className="add-schemes-box p-1">
                  {" "}
                  {/* shadow */}
                  <p className="objectives-headings">Create New Scheme</p>
                  <div className="add-new-scheme">
                    <p className="add-objective-heading">Scheme Name</p>
                    <input
                      className="objective-input light_shadow"
                      placeholder="Add Scheme Name"
                      value={schemeName}
                      onChange={(e) => {
                        setSchemeName(e.target.value);
                        setEmptySchemeName(false);
                      }}
                    />
                    {emptySchemeName && (
                      <div className="empty-error-msg">
                        *Scheme name is required
                      </div>
                    )}
                    <p className="add-objective-heading">Scheme Description</p>
                    <textarea
                      className="objective-input light_shadow"
                      placeholder="Add Scheme Description"
                      value={schemeDescription}
                      onChange={(e) => {
                        setSchemeDescription(e.target.value);
                        setEmptyDescription(false);
                      }}
                      rows={4} // Adjust the number of rows as needed
                    />
                    {emptyDescription && (
                      <div className="empty-error-msg">
                        *Scheme Description is required
                      </div>
                    )}
                    <p
                      className="add-objective-heading pt-2 pl-2"
                      style={{ opacity: "1" }}
                    >
                      Scheme Duration
                    </p>
                    <div className="d-flex flex-direction-row align-items-center">
                      <div className="travel-plan-setup-wrapper">
                        <div className="datepicker-icon schemes-datepicker-select">
                          <CalendarMonthIcon />
                        </div>
                        <DatePicker
                          className="travel-plan-select travel-date-picker light_shadow"
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          value={startDate}
                          onChange={(date) =>
                            setStartDate(moment(date).format("DD-MM-YYYY"))
                          }
                        />
                      </div>
                      <p>To</p>
                      <div className="travel-plan-setup-wrapper">
                        <div className="datepicker-icon schemes-datepicker-select">
                          <CalendarMonthIcon />
                        </div>
                        <DatePicker
                          className="travel-plan-select travel-date-picker light_shadow"
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          value={endDate}
                          onChange={(date) =>
                            setEndDate(moment(date).format("DD-MM-YYYY"))
                          }
                        />
                      </div>
                    </div>
                    <div
                      {...getRootProps()}
                      style={{
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      className="form-control"
                    >
                      <input {...getInputProps()} />
                      {acceptedFile || editMode ? (
                        <div className="d-flex justify-content-center">
                          <img
                            className=""
                            src={acceptedFile ? URL.createObjectURL(acceptedFile) : null}
                            alt="logo"
                            style={{ maxWidth: "80%", maxHeight: "100px" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center"
                          style={{ flexDirection: "column" }}
                        >
                          <img
                            src={"/assets/add-photo.png"}
                            style={{ width: "30px" }}
                          />
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#00a82d",
                            }}
                          >
                            Upload photo/ File
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-3 float-right position-relative">
            <button
              className="next_button ml-3 "
              type="button"
              style={{ zIndex: 1 }}
              onClick={() => handleSubmit()}
            >
              {isEdit ? `Edit`:`Add`}
            </button>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default SchemesAndPromos;

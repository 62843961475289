import React, { useEffect, useState, memo, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { addNewCustomer, editCustomerById, getCustomerById, getCustomerTypeId } from "../../../../Redux/actions";
import Loader from "../../../../shared/Loader/Loader";
import { getCustomerTypeAttributes } from "../../../../Redux/actions";
import { Constants } from "../../SuperAdmin/Credentials";
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';

const AddCustomerModal = ({
  setAddCustomerModal,
  customerTypesList,
  isEdit,
  setIsEdit,
  editcustomer
}) => {
  const Api = Constants.api;
  const dispatch = useDispatch();
  const loginObject = JSON.parse(sessionStorage.user);
  const { orgId } = useSelector((state) => state.loginReducer);
  const { loading, customerTypesAttributes, customerById, customerData } = useSelector(
    (state) => state.adminReducer
  );
  const [pincodeLoader, setpincodeLoader] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [businesstypes, setBusinessTypes] = useState([]);
  const [businessSegments, setBusinessSegments] = useState([]);
  const [addressField, setAddressField] = useState({});
  const [contactField, setContactField] = useState({});
  const [initialValues, setInitValues] = useState({});
  const [checked,setChecked] = useState(false);
 
  console.log("ADDRESS:", addressField);
  console.log("contact:", contactField);

  console.log("selectedCustomerType:", selectedCustomerType);
  console.log("customerbyid:", customerById);

  useEffect(() => {
    if (isEdit) {
      dispatch(getCustomerById(loginObject?.token,editcustomer?.id));
    } else {
      setInitValues({});
    }
  }, [isEdit,]);
  
  useEffect(() => {
    setInitValues(getInitialValues(customerById));
  }, [isEdit, customerById]);
  const handleModalClick = (e) => {
    // Prevent click propagation to children
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    // Close modal when clicking outside of it
    setAddCustomerModal(false);
  };

  // useEffect(() => {
  //     if(attributes?.length > 0) {
  //         const object = {};
  //         attributes?.map((attribute) => (
  //             object.push({ attribute.name : ''})
  //         ))
  //     }
  // }, [attributes]);

  const handleCheckboxChange = (e, setFieldValue,attribute,values) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    const index = 0;
    if (isChecked) {
      setFieldValue(`addressListValue.${index}.line1`, values[`line1${attribute?.orgAttributeId}`]);
      setFieldValue(`addressListValue.${index}.line2`, values[`line2${attribute?.orgAttributeId}`]);
      setFieldValue(`addressListValue.${index}.pinCode`, values[`pincode${attribute?.orgAttributeId}`]);
      setFieldValue(`addressListValue.${index}.city`, values[`city${attribute?.orgAttributeId}`]);
      setFieldValue(`addressListValue.${index}.state`, values[`state${attribute?.orgAttributeId}`]);
      setFieldValue(`addressListValue.${index}.country`, values[`country${attribute?.orgAttributeId}`]);
    } else {
      setFieldValue(`addressListValue.${index}.line1`, '');
      setFieldValue(`addressListValue.${index}.line2`, '');
      setFieldValue(`addressListValue.${index}.city`, '');
      setFieldValue(`addressListValue.${index}.state`, '');
      setFieldValue(`addressListValue.${index}.country`, '');
      setFieldValue(`addressListValue.${index}.pinCode`, '');
    }
  };


  useEffect(() => {
    if (isEdit && customerById?.id) {
        // Dispatch the action to get the customer type ID
        dispatch(getCustomerTypeId(loginObject?.token, customerById?.id))
            .then(() => {
                // Ensure customerData is updated after the action is dispatched
                if (customerData?.customerTypeId) {
                    console.log("cusDat:", customerData.customerTypeId);
                    setSelectedCustomerType(customerData.customerTypeId);
                }
            });
    }
}, [customerById?.id, customerData?.customerTypeId]); 


  const AdditionalField = memo(
    ({
      fieldName,
      label,
      attribute,
      value,
      onChangeText,
      keyboardType = "text",
      item,
      addListObj,
      addressIndex,
    }) => {
      return (
        <div
          className="form-group"
          style={{ marginBottom: "10px", padding: "5px" }}
        >
          <label
            htmlFor={`${fieldName}${attribute?.orgAttributeId}`}
            className={attribute?.isMandatory ? "required-field" : ""}
          >
            {label}
            {/* {attribute?.isMandatory && (
              <span style={{ color: 'red' }}>{" *"}</span>
            )} */}
          </label>
          <div className="box-shadow">
            <Field
              type={keyboardType}
              className="form-control"
              name={`${fieldName}${attribute?.orgAttributeId}`}
              autoComplete="off"
              style={{ height: "30px" }}
              value={addListObj?.item?.line1}
              onChangeText={(text) =>
                onChangeAddressText(item, text, addressIndex, fieldName)
              }
              // value={value || ""}
              // onChange={(text) => onChangeAddressText(item, text, addListObj?.index, "line2",setFieldValue)}
            />
          </div>
          <ErrorMessage
            name={`${fieldName}${attribute?.orgAttributeId}`}
            component="div"
            style={{ color: "red" }}
          />
        </div>
      );
    }
  );

  useEffect(() => {
    if (customerTypesAttributes) {
      //disable while editing
      const finalAddressField = customerTypesAttributes
        ?.filter(
          (i) => i?.inputType === "ADDRESS" || i?.inputType === "ADDRESS_LIST"
        )
        ?.map((item) => {
          return {
            ...item,
            addressList: [
              {
                line1: "",
                line2: "",
                pincode: "",
                country: "",
                state: "",
                city: "",
              },
            ],
          };
        });
      const finalContactField = customerTypesAttributes
        ?.filter((i) => i?.inputType == "CONTACT_LIST")
        ?.map((item) => {
          return {
            ...item,
            contactList: [
              {
                keyContactName: "",
                designation: "",
                mobileNo: "",
                emailId: "",
              },
            ],
          };
        });
      console.log("FINAL CONTACT:", finalContactField[0]?.contactList);
      setAddressField(finalAddressField);
      setContactField(finalContactField);
    }
  }, []);

  const onPressAddAddress = useCallback(
    (data) => {
      let newAddress = addressField?.map((i) => {
        if (data?.orgAttributeId == i?.orgAttributeId) {
          let abc = i?.addressList?.concat([
            {
              line1: "",
              line2: "",
              pincode: "",
              country: "",
              state: "",
              city: "",
            },
          ]);
          return { ...i, addressList: abc };
        } else {
          return i;
        }
      });

      setAddressField(newAddress);
    },
    [addressField]
  );

  const onChangeAddressText = useCallback(
    async (data, text, index, key, setFieldValue) => {
      console.log("change address text:", data, text, index, key);
      if (key == "pincode" && text?.length == 6) {
        const addressData = await getAddressDataByPinCode(text);
        setAddressField(
          addressField?.map((item) => {
            if (item?.baseAttributeId == data?.baseAttributeId) {
              return {
                ...item,
                addressList: item.addressList?.map((i, indexAdd) => {
                  if (indexAdd == index) {
                    return {
                      ...i,
                      [key]: text,
                      city: addressData?.[0]?.PostOffice?.[0]?.District || "",
                      state: addressData?.[0]?.PostOffice?.[0]?.State || "",
                      country: addressData?.[0]?.PostOffice?.[0]?.Country || "",
                    };
                  } else {
                    return i;
                  }
                }),
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setAddressField(
          addressField?.map((item) => {
            if (item?.baseAttributeId == data?.baseAttributeId) {
              return {
                ...item,
                addressList: item.addressList?.map((i, indexAdd) => {
                  if (indexAdd == index) {
                    // setFieldValue(`${key}${data.baseAttributeId}`, text);
                    console.log("key", data?.baseAttributeId);
                    return {
                      ...i,
                      [key]: text,
                    };
                  } else {
                    return i;
                  }
                }),
              };
            } else {
              return item;
            }
          })
        );
      }
    },
    [addressField]
  );

  useEffect(() => {
    if (selectedCustomerType !== null) {
      dispatch(
        getCustomerTypeAttributes(
          orgId,
          "CUSTOMER",
          selectedCustomerType,
          loginObject?.token
        )
      );
    }
  }, [selectedCustomerType]);

  const mapValueToOptionId = (customerDetail, options) => {
    const option = options.find((opt) => opt.id === customerDetail?.valueId); // Adjust as needed
    console.log("found option", option?.id?.toString());
    return option ? option.id : ""; // Default to empty string if not found
  };

  const getInitialValues = (customerById = null) => {
    const initialValues = {};
    initialValues.addressListValue = [];
    initialValues.listContactValue = [];
    customerTypesAttributes?.forEach((attribute) => {
      const customerDetail = customerById?.attributeValues?.find(
        (detail) => detail.orgAttributeId === attribute.orgAttributeId
      );
      console.log("matched:", customerDetail);
      if (isEdit) {
        // Handle dropdowns with LIST or GEO_NODE input types
        if (
          attribute.inputType === "LIST" ||
          attribute.inputType === "GEO_NODE" ||
          attribute?.inputType === "BUSINESS_SEG" ||
          attribute?.inputType === "BUSINESS_TYPE"
        ) {
          const mappedValue = mapValueToOptionId(
            customerDetail,
            attribute.options
          );
          initialValues[attribute.name] = mappedValue?.toString();
        } else if (attribute?.name === "Name") {
          initialValues[attribute.name] = customerById?.name;
        }
        else if (attribute.inputType === "ADDRESS") {
          // console.log("customeraddressdetail", customerDetail);
          initialValues[`line1${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.line1 || "";
          initialValues[`line2${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.line2 || "";
          initialValues[`city${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.city || "";
          initialValues[`state${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.state || "";
          initialValues[`country${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.country || "";
          initialValues[`pincode${attribute.orgAttributeId}`] =
            customerDetail?.addressValue?.pinCode || "";
        } else if (attribute.inputType === "ADDRESS_LIST") {
          console.log("customeraddresslistdetail", customerDetail);
          initialValues.addressListValue = customerDetail?.addressListValue?.map((address) => ({
            id: address.id || 0,
            line1: address?.line1 || "",
            line2: address?.line2 || "",
            city: address?.city || "",
            state: address?.state || "",
            country: address?.country || "",
            pinCode: address?.pinCode || "",
          })) || [];
        } else if (attribute.inputType === "CONTACT") {
          console.log("customercontactdetail", customerDetail);
          initialValues[`keyContactName${attribute.orgAttributeId}`] =
            customerDetail?.contactValue?.firstName || "";
          initialValues[`mobileNo${attribute.orgAttributeId}`] =
            customerDetail?.contactValue?.phone || "";
          initialValues[`email${attribute.orgAttributeId}`] =
            customerDetail?.contactValue?.email || "";
          initialValues[`designation${attribute.orgAttributeId}`] =
            customerDetail?.contactValue?.designation || "";
        } else if (attribute?.inputType === "CONTACT_LIST"){
          initialValues.listContactValue = customerDetail?.contactValue 
          ? [{
              id: customerDetail.contactValue.id || 0,
              keyContactName: customerDetail.contactValue.firstName || "",
              mobileNo: customerDetail.contactValue.phone || "",
              email: customerDetail.contactValue.email || "",
              designation: customerDetail.contactValue.designation || ""
            }]
          : [];        
        }
        else {
          initialValues[attribute.name] = attribute?.systemGenerated ? attribute?.systemGenCode : customerDetail?.value || "";
        }
      } else {
        initialValues[attribute.name] = attribute?.systemGenerated ? attribute?.systemGenCode : ""; // Default to empty string if not in edit mode
        if (attribute.inputType === "ADDRESS") {
          console.log("customeraddressdetail", customerDetail);
          initialValues[`line1${attribute.orgAttributeId}`] = "";
          initialValues[`line2${attribute.orgAttributeId}`] = "";
          initialValues[`city${attribute.orgAttributeId}`] = "";
          initialValues[`state${attribute.orgAttributeId}`] = "";
          initialValues[`country${attribute.orgAttributeId}`] = "";
          initialValues[`pincode${attribute.orgAttributeId}`] = "";
        } else if (attribute.inputType === "ADDRESS_LIST") {
          console.log("customeraddresslistdetail", customerDetail);
          // // customerDetail?.addressListValue?.forEach((address, index) => {
          // initialValues[`line1${attribute.orgAttributeId}`] = '';
          // initialValues[`line2${attribute.orgAttributeId}`] = '';
          // initialValues[`city${attribute.orgAttributeId}`] ='';
          // initialValues[`state${attribute.orgAttributeId}`] ='';
          // initialValues[`country${attribute.orgAttributeId}`] = '';
          // initialValues[`pincode${attribute.orgAttributeId}`] = '';
          // // });
          initialValues.addressListValue = [
            // addressListValue: [
            {
              id: 0,
              line1: "",
              line2: "",
              city: "",
              state: "",
              country: "",
              pinCode: "",
            },
          ];
        } else if (attribute.inputType === "CONTACT") {
          console.log("customercontactdetail", customerDetail);
          initialValues[`keyContactName${attribute.orgAttributeId}`] = "";
          initialValues[`mobileNo${attribute.orgAttributeId}`] = "";
          initialValues[`email${attribute.orgAttributeId}`] = "";
          initialValues[`designation${attribute.orgAttributeId}`] = "";
        }
        else if (attribute.inputType === "CONTACT_LIST") {
          console.log("customercontactdetail", customerDetail);
          // initialValues[`keyContactName${attribute.orgAttributeId}`] = "";
          // initialValues[`mobileNo${attribute.orgAttributeId}`] = "";
          // initialValues[`email${attribute.orgAttributeId}`] = "";
          // initialValues[`designation${attribute.orgAttributeId}`] = "";
          initialValues.listContactValue = [
            {
              id: 0,
              keyContactName: "",
              mobileNo: "",
              email: "",
              designation: "",
            },
          ];
        }
      }
    });
    console.log("init values;", initialValues);
    return initialValues;
  };
  const validateAddressList = Yup.object().shape({
    line1: Yup.string().required('Line 1 is required'),
    line2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    pinCode: Yup.string()
      .matches(/^\d{6}$/, 'Pin Code must be exactly 6 digits')
      .required('Pin Code is required'),
  });

  const validate = (values, attributes) => {
    console.log("values validating:", values);
    const errors = {};
    attributes.forEach((attribute) => {
      if (
        attribute?.inputType !== "ADDRESS" &&
        attribute?.inputType !== "CONTACT" &&
        attribute.inputType !== "ADDRESS_LIST" &&
        attribute.inputType !== "CONTACT_LIST" &&
        attribute.isMandatory &&
        !values[attribute.name]
      ) {
        errors[attribute.name] = `*${attribute.name} is mandatory`;
        console.log("unknown validation error occured", attribute);
      }
      if (
        (attribute?.isMandatory && attribute.inputType === "ADDRESS") 
      ) {
        // if (!values["line1"]) {
        //   errors["line1"] = `*Address Line 1 is mandatory`;
        // }
        // // if (!values["line2"]) {
        // //   errors["line2"] = `*Address Line 2 is mandatory`;
        // // }
        // if (!values["city"]) {
        //   errors["city"] = `*City is mandatory`;
        // }
        // if (!values["state"]) {
        //   errors["state"] = `*State is mandatory`;
        // }
        // if (!values["country"]) {
        //   errors["country"] = `*Country is mandatory`;
        // }
        // if (!values["pincode"]) {
        //   errors["pincode"] = `*Pincode is mandatory`;
        // }
        if (!values[`line1${attribute?.orgAttributeId}`]) {
          errors[
            `line1${attribute?.orgAttributeId}`
          ] = `*Address Line 1 is mandatory`;
        }
        if (!values[`line2${attribute?.orgAttributeId}`]) {
          errors[
            `line2${attribute?.orgAttributeId}`
          ] = `*Address Line 2 is mandatory`;
        }
        if (!values[`city${attribute?.orgAttributeId}`]) {
          errors[`city${attribute?.orgAttributeId}`] = `*City is mandatory`;
        }
        if (!values[`state${attribute?.orgAttributeId}`]) {
          errors[`state${attribute?.orgAttributeId}`] = `*State is mandatory`;
        }
        if (!values[`country${attribute?.orgAttributeId}`]) {
          errors[
            `country${attribute?.orgAttributeId}`
          ] = `*Country is mandatory`;
        }
        if (!values[`pincode${attribute?.orgAttributeId}`]) {
          errors[
            `pincode${attribute?.orgAttributeId}`
          ] = `*Pincode is mandatory`;
        }
      }

      if (attribute.inputType === "ADDRESS_LIST") {
        const addressErrors = [];
  
        values.addressListValue.forEach((address, index) => {
          const addressError = {};
  
          if (!address.line1) {
            addressError.line1 = `*Address Line 1 is mandatory`;
          }
          if (!address.line2) {
            addressError.line2 = `*Address Line 2 is mandatory`;
          }
          if (!address.city) {
            addressError.city = `*City is mandatory`;
          }
          if (!address.state) {
            addressError.state = `*State is mandatory`;
          }
          if (!address.country) {
            addressError.country = `*Country is mandatory`;
          }
          if (!address.pinCode) {
            addressError.pinCode = `*Pincode is mandatory`;
          }
  
          if (Object.keys(addressError).length > 0) {
            addressErrors[index] = addressError;
          }
        });
  
        if (addressErrors.length > 0) {
          errors.addressListValue = addressErrors;
        }
      }
      if (
        (attribute?.isMandatory && attribute.inputType === "CONTACT") 
      ) {
        if (!values[`keyContactName${attribute?.orgAttributeId}`]) {
          errors[
            `keyContactName${attribute?.orgAttributeId}`
          ] = `*Key Contact Name is mandatory`;
        }
        if (!values[`mobileNo${attribute?.orgAttributeId}`]) {
          errors[
            `mobileNo${attribute?.orgAttributeId}`
          ] = `*Phone Number is mandatory`;
        }
        // if (!values[`email${attribute?.orgAttributeId}`]) {
        //   errors[`email${attribute?.orgAttributeId}`] = `*Email is mandatory`;
        // }
        if (!values[`designation${attribute?.orgAttributeId}`]) {
          errors[
            `designation${attribute?.orgAttributeId}`
          ] = `*Designation is mandatory`;
        }
      }
      if(attribute.inputType === "CONTACT_LIST"){
        const contactErrors = [];
  
        values.listContactValue.forEach((contact, index) => {
          const contactError = {};
  
          if (!contact.keyContactName) {
            contactError.keyContactName = `*Key Contact Name is mandatory`;
          }
          if (!contact.mobileNo) {
            contactError.mobileNo = `*Phone Number is mandatory`;
          }
          // if (!contact.email) {
          //   contactError.email = `*City is mandatory`;
          // }
          if (!contact.designation) {
            contactError.designation = `*Designation is mandatory`;
          }
          if (Object.keys(contactError).length > 0) {
            contactErrors[index] = contactError;
          }
        });
        if (contactErrors.length > 0) {
          errors.listContactValue = contactErrors;
        }
      }
    });

    //Email Validation
    // attributes.forEach(attribute => {
    //     if (attribute.isMandatory && !values[attribute.name]) {
    //       errors[attribute.name] = `*${attribute.name} is mandatory`;
    //     } else if (attribute.name === 'Email' && values[attribute.name]) {
    //       // Additional validation for email format
    //       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //       if (!emailRegex.test(values[attribute.name])) {
    //         errors[attribute.name] = 'Invalid email format';
    //       }
    //     }
    //   });
    return errors;
  };

  const handleAddCustomer = (values) => {
    console.log("Values:", values);
    const data = [];
    customerTypesAttributes?.map((attribute) => {
      const obj = {
        orgAttributeId: attribute?.orgAttributeId,
        [attribute?.inputType !== "ADDRESS" &&
        attribute?.inputType !== "CONTACT" &&
        attribute.inputType !== "ADDRESS_LIST" &&
        attribute.inputType !== "CONTACT_LIST"
          ? "value"
          : attribute?.inputType === "ADDRESS"
          ? "addressValue"
          : attribute.inputType === "ADDRESS_LIST"
          ? "listAddressValue"
          : attribute.inputType === "CONTACT_LIST"
          ? "listContactValue"
          : "contactValue"]:
          attribute?.inputType !== "ADDRESS" &&
          attribute?.inputType !== "CONTACT" &&
          attribute.inputType !== "ADDRESS_LIST" &&
          attribute.inputType !== "CONTACT_LIST"
            ? values[attribute.name] !== ""
              ? values[attribute.name]
              : null
            : attribute?.inputType === "ADDRESS"
            ? {
                line1: values[`line1${attribute?.orgAttributeId}`],
                line2: values[`line2${attribute?.orgAttributeId}`],
                city: values[`city${attribute?.orgAttributeId}`],
                state: values[`state${attribute?.orgAttributeId}`],
                country: values[`country${attribute?.orgAttributeId}`],
                pincode: values[`pincode${attribute?.orgAttributeId}`],
              }
            :  attribute?.inputType === "ADDRESS_LIST"
            ? values?.addressListValue?.map((address) => ({
                id: address?.id || 0, 
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                state: address.state,
                country: address.country,
                pinCode: address.pinCode,
              }))
            : attribute?.inputType === "CONTACT" ? [
                {
                  firstName:
                    values[`keyContactName${attribute?.orgAttributeId}`],
                  lastName: "",
                  phone:
                    values[`mobileNo${attribute?.orgAttributeId}`]?.toString(),
                  email: values[`email${attribute?.orgAttributeId}`],
                  designation:
                    values[`designation${attribute?.orgAttributeId}`],
                },
              ] : values?.listContactValue?.map((contact) => ({
                  id: contact?.id || 0, 
                  firstName: contact.keyContactName,
                  lastName:"",
                  phone: contact.mobileNo?.toString(),
                  email: contact.email,
                  designation: contact.designation,
                }))
              
      };
      data.push(obj);
    });

    const finalData = data?.filter((d) => d.value !== null);
    console.log("Filtered data:", finalData);
    isEdit
      ? dispatch(
          editCustomerById(
            loginObject?.token,
            finalData,
            setAddCustomerModal,
            setIsEdit,
            selectedCustomerType,
            customerById?.id
          )
        )
      : dispatch(
          addNewCustomer(
            finalData,
            selectedCustomerType,
            setAddCustomerModal,
            loginObject?.token
          )
        );
  };

  const getAddressDataByPinCode = async (text) => {
    setpincodeLoader(true);
    const resp = await fetch(`https://api.postalpincode.in/pincode/${text}`, {
      method: "GET",
      headers: new Headers({}),
    }).catch((error) => console.log(error));
    const respJson = await resp.json();
    console.log("respJson", respJson);
    // Update form fields with fetched data
    // if (respJson && respJson.length > 0) {
    //   setpincodeLoader(false);
    //   const { State, Country, PostOffice } = respJson[0];
    //   if (PostOffice && PostOffice.length > 0) {
    //     const { State, Country, Region } = PostOffice[0];
    //     setFieldValue(`country${orgAttributeId}`, Country);
    //     setFieldValue(`state${orgAttributeId}`, State);
    //     setFieldValue(`city${orgAttributeId}`, Region);
    //   }
    // }
  };

  const getLocation = async (pincode, setFieldValue, orgAttributeId, index ,attribute,) => {
    console.log("index;",attribute)
    if (pincode?.length === 6) {
      setpincodeLoader(true);
      const resp = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
        {
          method: "GET",
          headers: new Headers({}),
        }
      ).catch((error) => console.log(error));
      const respJson = await resp.json();
      // Update form fields with fetched data
      if (respJson && respJson.length > 0) {
        setpincodeLoader(false);
        const { State, Country, PostOffice } = respJson[0];
        if (PostOffice && PostOffice.length > 0) {
          const { State, Country, Region } = PostOffice[0];
          // formik.setFieldValue("communicationAddress.country", Country);
          // formik.setFieldValue("communicationAddress.state", State);
          // formik.setFieldValue("communicationAddress.city", Region);
          if(attribute?.inputType === "ADDRESS"){
          setFieldValue(`country${orgAttributeId}`, Country);
          setFieldValue(`state${orgAttributeId}`, State);
          setFieldValue(`city${orgAttributeId}`, Region);
          } else {
            setFieldValue(`addressListValue.${index}.country`, Country);
            setFieldValue(`addressListValue.${index}.state`, State);
            setFieldValue(`addressListValue.${index}.city`, Region);
          }
          // formik.setFieldValue("billingAddress.country", Country);
          // formik.setFieldValue("billingAddress.state", State);
          // formik.setFieldValue("billingAddress.city", Name);
        }
      }
    }
  };

  useEffect(() => {
    const getDropdowns = async () => {
      const resp = await fetch(`${Api}/data/business_type/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const responseData = await resp.json();
      if (responseData.success) {
        setBusinessTypes(responseData.response);
      } else {
        console.log("failed to fetch business types");
      }

      const res = await fetch(`${Api}/data/business_segment/list`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${loginObject.token}`,
        }),
      });
      const respJson = await res.json();
      if (respJson.success) {
        setBusinessSegments(respJson.response);
      } else {
        console.log("failed to fetch business segments");
      }
    };
    getDropdowns();

    // if (editMode) {
    //   formik.setValues({
    //     ...formik.values,
    //     name: organizationDetails.name || "",
    //     businessTypeId: organizationDetails.businessTypeId || "",
    //     channelPartner: organizationDetails.channelPartner || "",
    //     logoUrl: organizationDetails.logoUrl || "",
    //     businessSegmentId: organizationDetails.businessSegmentId || "",
    //     gst: organizationDetails.gst || "",
    //     mobileCode: organizationDetails.mobileCode || "+91",
    //     mobileNumber: organizationDetails.mobileNumber || "",
    //     accountManager: organizationDetails.accountManager || ""
    //   });
    // }
  }, []);

  return (
    <>
      <div className="custom-attribute-modal-wrapper">
        <div className="add-user-modal">
          <div>
            <h4>Add New Customer</h4>
          </div>
          {/* <div className='add-user-details'> */}
          {/* <label htmlFor='customerType' className='required-field'>Customer Type</label>
            <select
              className="input-group-select"
              name='customerType'
              onChange={(e) => setSelectedCustomerType(e?.target?.value)}
            >
              <option value="" defaultValue="" hidden>{'Select Customer Type'}</option>
              {customerTypesList.map((opt) => (
              <option value={opt?.orgAttributeId}>{opt?.name}</option>
              ))}
            </select> */}
          <Formik
            initialValues={initialValues}
            validateOnChange={true}
            enableReinitialize={true}
            validate={(values) => validate(values, customerTypesAttributes)}
            onSubmit={(values, { setSubmitting }) => {
              console.log("Values submitted:", values);
              handleAddCustomer(values);
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, setFieldValue, touched, errors, values }) => (
              <>
                {/* {console.log('Values:',values)} */}
                {/* {console.log('errors:',errors)} */}
                <Form>
                  <div className="form-fields">
                  {!isEdit && (
                    <>
                    <label htmlFor="customerType" className="required-field">
                      Customer Type
                    </label>
                    <select
                      className="input-group-select"
                      name="customerType"
                      onChange={(e) =>
                        setSelectedCustomerType(e?.target?.value)
                      }
                    >
                      <option value="" defaultValue="" hidden>
                        {"Select Customer Type"}
                      </option>
                      {customerTypesList.map((opt) => (
                        <option value={opt?.orgAttributeId}>{opt?.name}</option>
                      ))}
                    </select>
                    </>
                    )}
                    {(selectedCustomerType !== null ) &&
                      customerTypesAttributes?.map((attribute, index) => (
                        <>
                          <div
                            key={index}
                            className="form-group"
                            style={{ marginBottom: "10px", padding: "5px" }}
                          >
                            <label
                              htmlFor={attribute?.name}
                              className={
                                attribute?.inputType !== "ADDRESS" &&
                                attribute?.inputType !== "CONTACT" &&
                                attribute.inputType !== "ADDRESS_LIST" &&
                                attribute.inputType !== "CONTACT_LIST" &&
                                attribute?.isMandatory
                                  ? "required-field"
                                  : ""
                              }
                            >
                              {attribute?.inputType !== "ADDRESS" &&
                                attribute?.inputType !== "CONTACT" &&
                                attribute.inputType !== "ADDRESS_LIST" &&
                                attribute.inputType !== "CONTACT_LIST" &&
                                attribute?.name}
                            </label>
                            {attribute?.inputType !== "ADDRESS" &&
                            attribute?.inputType !== "LIST" &&
                            attribute?.inputType !== "GEO_NODE" &&
                            attribute?.inputType !== "CONTACT" &&
                            attribute?.inputType !== "BUSINESS_SEG" &&
                            attribute?.inputType !== "BUSINESS_TYPE" &&
                            attribute.inputType !== "ADDRESS_LIST" &&
                            attribute.inputType !== "CONTACT_LIST" ? (
                              <>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={attribute?.name}
                                  // value={attribute?.systemGenerated && attribute?.systemGenCode}
                                  autoComplete="off"
                                  style={{ height: "30px" }}
                                  disabled={attribute?.systemGenerated}
                                />
                                <ErrorMessage
                                  name={attribute.name}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            ) : attribute?.inputType === "ADDRESS_LIST" ? (
                              <>
                              <label className={attribute?.isMandatory ? 'required-field' : ''}>{attribute?.name}</label>
                              <FieldArray name="addressListValue">
  {({ insert, remove, push }) => (
    <div>
      {values?.addressListValue?.map((address, index) => (
        <div key={index} style={{marginTop:"5px"}}>
          {/* Fields for Line 1, Line 2, City, State, Country, Pin Code */}
          <label>{`Address ${index+1}`}</label>

          <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
          <label htmlFor={`addressListValue.${index}.line1`}>Address Line 1</label>
            <Field name={`addressListValue.${index}.line1`} className="form-control" 
                              style={{ height: '30px' }}

 />
            <ErrorMessage name={`addressListValue.${index}.line1`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`addressListValue.${index}.line2`}>Address Line 2</label>
            <Field name={`addressListValue.${index}.line2`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`addressListValue.${index}.line2`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`addressListValue.${index}.pinCode`}>Pin Code</label>
            <Field name={`addressListValue.${index}.pinCode`} className="form-control" 
                              style={{ height: '30px' }}
                              onChange={(e) => {
                                const inputValue = e?.target?.value;
                                const trimmedValue = inputValue.slice(0, 6);
                                getLocation(trimmedValue, setFieldValue,attribute?.orgAttributeId, index, attribute, );
                                setFieldValue(`addressListValue.${index}.pinCode`, trimmedValue);
                              }}
            />
            <ErrorMessage name={`addressListValue.${index}.pinCode`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`addressListValue.${index}.city`}>City</label>
            <Field name={`addressListValue.${index}.city`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`addressListValue.${index}.city`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`addressListValue.${index}.state`}>State</label>
            <Field name={`addressListValue.${index}.state`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`addressListValue.${index}.state`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`addressListValue.${index}.country`}>Country</label>
            <Field name={`addressListValue.${index}.country`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`addressListValue.${index}.country`} component="div" />
          </div>
        {values.addressListValue.length > 1 && (
          <button
            type="button"
            style={{float:'right',border:0,backgroundColor:'#fff',marginTop:'5px'}}
            onClick={(event) =>{event.preventDefault(); remove(index)}}
            disabled={values.addressListValue.length === 1}
          >
          <DeleteIcon style={{ width: '20px', }}/>
          </button>
          )}
        </div>
      ))}

      {/* Button to add a new address */}
      <button
      style={{border:'none',backgroundColor:"#00a82d",borderRadius:'5px',marginTop:'5px'}}
        type="button"  // Ensure this type is set to "button"
        onClick={(event) => {
          event.preventDefault();  // Prevent default submission behavior
          push({
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "",
            pinCode: "",
          });
        }}
      >
        <p style={{color:'#fff',padding:'3px'}}>Add</p>
      </button>
    </div>
  )}
</FieldArray>
</>

                            ) :
                            attribute?.inputType === "ADDRESS" ? (<>
                            <label className={attribute?.isMandatory ? 'required-field' : ''}>{attribute?.name}</label>
                              <br />
                              <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                              <label htmlFor={`line1${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                                {`Address Line 1`}
                              </label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="line1"
                              name={`line1${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                            />
                            </div>
                            {/* <ErrorMessage name={"line1"} component="div" style={{ color: 'red' }} /> */}
                            <ErrorMessage name={`line1${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                            <label htmlFor={`line2${attribute?.orgAttributeId}`}>{"Address Line 2"}</label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="line2"
                              name={`line2${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                            />
                            </div>
                            <ErrorMessage name={`line2${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                            <label htmlFor={`pincode${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                              {"Pincode"}
                            </label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="pincode"
                              name={`pincode${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                              onChange={(e) => {
                                const inputValue = e?.target?.value;
                                // Check if the input value has more than 6 digits, if yes, trim it to 6 digits
                                const trimmedValue = inputValue.slice(0, 6);
                                const index = undefined;
                                getLocation(trimmedValue, setFieldValue, attribute?.orgAttributeId,index,attribute);
                                setFieldValue(`pincode${attribute?.orgAttributeId}`, trimmedValue);
                                // getLocation(e?.target?.value, setFieldValue)
                                // setFieldValue(`communicationAddress.pincode`, e?.target?.value);
                              }}
                            />
                            <ErrorMessage name={`pincode${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                            <label htmlFor={`city${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                              {"City"}
                            </label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="city"
                              name={`city${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                            />
                            <ErrorMessage name={`city${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
                            <label htmlFor={`state${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                              {"State"}
                            </label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="state"
                              name={`state${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                            />
                            <ErrorMessage name={`state${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            </div>
                            <div style={{ marginBottom: '10px', padding: '5px' }}>
                            <label htmlFor={`country${attribute?.orgAttributeId}`} className={attribute?.isMandatory ? 'required-field' : ''}>
                              {"Country"}</label>
                              <Field
                              type="text"
                              className="form-control"
                              // name="country"
                              name={`country${attribute?.orgAttributeId}`}
                              autoComplete="off"
                              style={{ height: '30px' }}
                            />
                            <ErrorMessage name={`country${attribute?.orgAttributeId}`} component="div" style={{ color: 'red' }} />
                            </div>
                            <div style={{display:'flex',flexDirection:"row",marginLeft:'8px'}}>
                            <input type="checkbox" checked={checked} onChange={(e) => handleCheckboxChange(e, setFieldValue,attribute,values)} style={{marginRight:"2px",color:'#000'}}/>
                            <p style={{color:"#000"}}>Shipping address same as billing address</p>
                            </div>
                            </>)

                            // :
                            //                   attribute?.inputType === 'ADDRESS_LIST'?
                            //                   (<>
                            //                         {addressField?.map((item, itemIndex) => {
                            //         console.log("LIST ITEM1:", item);
                            //         if (item?.inputType === "ADDRESS_LIST") {
                            //           return (
                            //             <div key={itemIndex}>
                            //               <div className="section-container">
                            //                 <p className="section-text">{item?.name || ""}</p>
                            //               </div>
                            //               {item?.addressList?.map((addListObj, addressIndex) => {
                            //                 console.log("address list:", addListObj);
                            //                 return (
                            //                   <React.Fragment key={addressIndex}>
                            //                     <AdditionalField
                            //   fieldName="line1"
                            //   label="Address Line 1"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.line1}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "line1")}
                            // />

                            // <AdditionalField
                            //   fieldName="line2"
                            //   label="Address Line 2"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.line2}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "line2")}
                            // />

                            // <AdditionalField
                            //   fieldName="pincode"
                            //   label="Pin Code"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.pincode}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "pincode")}
                            //   keyboardType="number"
                            // />

                            // <AdditionalField
                            //   fieldName="country"
                            //   label="Country"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.country}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "country")}
                            // />

                            // <AdditionalField
                            //   fieldName="state"
                            //   label="State"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.state}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "state")}
                            // />

                            // <AdditionalField
                            //   fieldName="city"
                            //   label="City"
                            //   attribute={attribute} // Pass the relevant attribute object here
                            //   value={addListObj?.item?.city}
                            //   onChangeText={(text) => onChangeAddressText(item, text, addListObj?.index, "city")}
                            // />
                            // </React.Fragment>
                            //                 );
                            //               })}
                            //                <button
                            //       onClick={() => onPressAddAddress(item)}
                            //       type='button'
                            //       className="bottom-button-container"
                            //       style={{ marginTop: '2%' }} // Adjust the marginTop to your needs
                            //     >
                            //       <p className="button-btn-text">Add</p>
                            //     </button>
                            //             </div>
                            //           );
                            //         }
                            //       })}

                            //                   </>)
                            : attribute?.inputType === "CONTACT_LIST" ? (
                              <>
                              <label className={attribute?.isMandatory ? 'required-field' : ''}>{attribute?.name}</label>
                              <FieldArray name="listContactValue">
  {({ insert, remove, push }) => (
    <div>
      {values?.listContactValue?.map((contact, index) => (
        <div key={index}>
          {/* Fields for Line 1, Line 2, City, State, Country, Pin Code */}
          <label>{`Contact ${index+1}`}</label>

            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label className={attribute?.isMandatory ? 'required-field' : ''} htmlFor={`listContactValue.${index}.keyContactName`}>Key Contact Name</label>
            <Field name={`listContactValue.${index}.keyContactName`} className="form-control" 
                              style={{ height: '30px' }}
 />
            <ErrorMessage name={`listContactValue.${index}.keyContactName`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label className={attribute?.isMandatory ? 'required-field' : ''} htmlFor={`listContactValue.${index}.mobileNo`}>Phone Number</label>
            <Field name={`listContactValue.${index}.mobileNo`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`listContactValue.${index}.mobileNo`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label htmlFor={`listContactValue.${index}.email`}>Email</label>
            <Field name={`listContactValue.${index}.email`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`listContactValue.${index}.email`} component="div" />
          </div>
            <div className="form-group" style={{ marginBottom: '10px', padding: '5px' }}>
            <label className={attribute?.isMandatory ? 'required-field' : ''} htmlFor={`listContactValue.${index}.designation`}>Designation</label>
            <Field name={`listContactValue.${index}.designation`} className="form-control" 
                              style={{ height: '30px' }}
            />
            <ErrorMessage name={`listContactValue.${index}.designation`} component="div" />
          </div>
        {values.listContactValue.length > 1 && (
          <button
            type="button"
            style={{float:'right',border:0,backgroundColor:'#fff',marginTop:'5px'}}
            onClick={(event) =>{event.preventDefault(); remove(index)}}
            disabled={values.listContactValue.length === 1}
          >
          <DeleteIcon style={{ width: '20px', }}/>
          </button>
          )}
        </div>
      ))}

      {/* Button to add a new address */}
      <button
      style={{border:'none',backgroundColor:"#00a82d",borderRadius:'5px',marginTop:'5px'}}
        type="button"  // Ensure this type is set to "button"
        onClick={(event) => {
          event.preventDefault();  // Prevent default submission behavior
          push({
            // id: 0,
            keyContactName: "",
            mobileNo: "",
            email: "",
            designation: "",
          });
        }}
      >
        <p style={{color:'#fff',padding:'3px'}}>Add</p>
      </button>
    </div>
  )}
</FieldArray>
</>

                            )
                          :  attribute?.inputType === "CONTACT" ? (
                              <>
                                <label
                                  className={
                                    attribute?.isMandatory
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  {attribute?.name}
                                </label>
                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    padding: "5px",
                                  }}
                                >
                                  <label
                                    htmlFor={`keyContactName${attribute?.orgAttributeId}`}
                                    className={
                                      attribute?.isMandatory
                                        ? "required-field"
                                        : ""
                                    }
                                  >
                                    {`Key Contact Name`}
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name={`keyContactName${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: "30px" }}
                                  />
                                </div>
                                <ErrorMessage
                                  name={`keyContactName${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />

                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    padding: "5px",
                                  }}
                                >
                                  <label
                                    htmlFor={`mobileNo${attribute?.orgAttributeId}`}
                                    className={
                                      attribute?.isMandatory
                                        ? "required-field"
                                        : ""
                                    }
                                  >
                                    {`Phone Number`}
                                  </label>
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name={`mobileNo${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: "30px" }}
                                  />
                                </div>
                                <ErrorMessage
                                  name={`mobileNo${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />

                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    padding: "5px",
                                  }}
                                >
                                  <label
                                    htmlFor={`email${attribute?.orgAttributeId}`}
                                    // className={attribute?.isMandatory ? 'required-field' : ''}
                                  >
                                    {`Email`}
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name={`email${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: "30px" }}
                                  />
                                </div>
                                <ErrorMessage
                                  name={`email${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />

                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: "10px",
                                    padding: "5px",
                                  }}
                                >
                                  <label
                                    htmlFor={`designation${attribute?.orgAttributeId}`}
                                    className={
                                      attribute?.isMandatory
                                        ? "required-field"
                                        : ""
                                    }
                                  >
                                    {`Designation`}
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name={`designation${attribute?.orgAttributeId}`}
                                    autoComplete="off"
                                    style={{ height: "30px" }}
                                  />
                                </div>
                                <ErrorMessage
                                  name={`designation${attribute?.orgAttributeId}`}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            ) : attribute?.inputType === "BUSINESS_SEG" ? (
                              <>
                                <select
                                  className="input-group-select"
                                  value={values[attribute?.name]}  
                                  name={attribute?.name}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `${attribute?.name}`,
                                      e?.target?.value
                                    )
                                  }
                                >
                                    <option
                                      value=""
                                      defaultValue=""
                                    >{`Select ${attribute?.name}`}</option>
                                  {businessSegments?.map((opt) => (
                                    <option value={opt?.id}>{opt?.name}</option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  name={attribute.name}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            ) : attribute?.inputType === "BUSINESS_TYPE" ? (
                              <>
                                <select
                                  className="input-group-select"
                                  value={values[attribute?.name]}  
                                  name={attribute?.name}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `${attribute?.name}`,
                                      e?.target?.value
                                    )
                                  }
                                >
                                    <option
                                      value=""
                                      defaultValue=""
                                    >{`Select ${attribute?.name}`}</option>
                                  {businesstypes?.map((opt) => (
                                    <option value={opt?.id}>{opt?.name}</option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  name={attribute.name}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            ) : (
                              <>
                                <select
                                  className="input-group-select"
                                  name={attribute?.name}
                                  value={values[attribute?.name]}  
                                  onChange={(e) =>
                                    setFieldValue(
                                      `${attribute?.name}`,
                                      e?.target?.value
                                    )
                                  }
                                >
                                  {/* {!isEdit && ( */}
                                    <option
                                      value=""
                                      defaultValue=""
                                    >{`Select ${attribute?.name}`}</option>
                                  {/* // )} */}
                                  {attribute?.options?.map((opt) => (
                                    <option value={opt?.id}>{opt?.name}</option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  name={attribute.name}
                                  component="div"
                                  style={{ color: "red" }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                  <div
                    className="mr-3 float-left"
                    style={{ paddingTop: "2rem", marginBottom: "5px" }}
                  >
                    <button
                      type="submit"
                      // className="button"
                      style={{
                        backgroundColor: "#00a82d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        width: "90px",
                        height: "30px",
                        padding: "2px 8px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        setAddCustomerModal(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  {(selectedCustomerType !== null ) && (
                    <div
                      className="ml-3 float-right"
                      style={{ paddingTop: "2rem", marginBottom: "5px" }}
                    >
                      <button
                        type="submit"
                        // className="button"
                        style={{
                          backgroundColor: "#00a82d",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          width: "90px",
                          height: "30px",
                          padding: "2px 8px",
                          marginRight: "10px",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
      {/*</div> */}
      {(loading || pincodeLoader) && <Loader />}
    </>
  );
};

export default AddCustomerModal;

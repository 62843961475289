import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TopBar from "../../../shared/Navigation/TopBar";

const Reminder = ({ sidebarCollapsed }) => {
  const [value, setValue] = useState(new Date());
  function onChange(nextValue) {
    setValue(nextValue);
  }
  return (
    <div
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      className="main"
    >
      <TopBar />
      <div className="create-organization">
      <label className="pt-3 pl-3">Reminder</label>
      <div className="row">
        <div className="col-5">
          <Calendar
          className="ml-3"
            onChange={onChange}
            value={value}
            showNeighboringDecade={false}
            prev2Label={null}
            next2Label={null}
            formatShortWeekday={(locale, date) =>
              [`S`, `M`, `T`, `W`, `T`, `F`, `S`][date.getDay()]
            }
            calendarType="gregory"
            tileClassName="class1 class2"
            nextLabel={
              <EastIcon
              // style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius:'50' }}
              />
            }
            prevLabel={<WestIcon />}
          />
        </div>
        <div className="col-5">
          <div className="form-group">
            <label>Reminder</label>
            <input className="form-control" />
          <button className="button float-right mt-3 px-3">Send Now</button>
          </div>
          <div className="pt-2">
            <label>Date</label>
            <div className="d-flex">
              <input className="form-control" />
              <label className="mx-4">To</label>
              <input className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <label>Time</label>
            <input className="form-control w-50" />
          </div>
          <button className="button float-right">Set Reminder</button>
        </div>
      </div>
      <div className="px-5 py-3">
        <label>Reminder List</label>
        <div
          className="light_shadow d-flex align-items-center"
          style={{ borderRadius: "10px", width: "90%", padding:'12px 24px' }}
        >
          <p className="font-weight- d-flex">
            Due date crossed by 4 days  <p style={{opacity:0.5, marginLeft:'10px' }}>Tuesday, 31/02/2024, 1:30pm</p>
          </p>
          <div className="ml-auto ">
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <AccessTimeIcon />
            </button>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
        <div
          className="light_shadow mt-3 d-flex align-items-center"
          style={{ borderRadius: "10px", width: "90%",padding:'12px 24px' }}
        >
          <p className="font-weight- d-flex" style={{opacity:0.5}}>
            Bloomr renewal due in next 10 days <p style={{marginLeft:'10px'}}> Wednesday, 06/02/2024, 12:30pm </p>
          </p>
          <div className="ml-auto ">
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
              }}
              className="edit_icon light_shadow"
            >
              <ArrowUpwardIcon />
            </button>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                opacity:0.5
              }}
              className="edit_icon light_shadow"
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
            <button
              style={{
                padding: "2px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                opacity:0.5
              }}
              className="edit_icon light_shadow"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
      </div>

    </div>
    
  );
};

export default Reminder;

import React, { useState, useEffect, useCallback } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { getGeographicNodes, setGeographicNodes, editGeographicNodes, deleteGeographicNodes, updateSetupStatus } from "../../../../Redux/actions";
import AddNodeModal from "../../../../shared/AddNodeModal/AddNodeModal";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  Panel,
  Background,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from '@mui/material/styles';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useNavigate  } from "react-router-dom";
import Loader from "../../../../shared/Loader/Loader";
import EditNodeModalNew from "../../../../shared/AddNodeModal/EditNodeModalNew";
import { EMPTY_GEOGRAPHIC_NODES } from "../../../../Redux/actions/actionTypes";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Flow = ({ sidebarCollapsed }) => {
  const loginObject = JSON.parse(sessionStorage.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { geographicNodes, loading } = useSelector((state) => state.adminReducer);
  const { orgId } = useSelector((state) => state.loginReducer); 
  // console.log('geographicNodes:',geographicNodes);

  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const [addNodeModal, setAddNodeModal] = useState(false);
  const [nodesId, setNodesId] = useState([]);
  const [editNodeModal, setEditNodeModal] = useState(false);
  const [editNode, setEditNode] = useState();
  const [nodeDetails, setNodeDetails] = useState();

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;
  const nodeHeight = 36;

  const { fitView } = useReactFlow();

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    console.log("Layout Program Called");
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "top";
      node.sourcePosition = "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    // window.requestAnimationFrame(() => {
    fitView();
    // });

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges,
    "TB"
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const position = { x: 0, y: 0 };

  useEffect(() => {
    dispatch(getGeographicNodes(loginObject?.token));
  }, []);

  const retrieve_node_name = (value) => {
    // dispatch({ type: EMPTY_GEOGRAPHIC_NODES })
    setNodeDetails(value);
    // setInitialNodes(prevNodes => {
    //     const newNode = {
    //         id: (prevNodes.length + 1)?.toString(),
    //         data: { label: value.node },
    //         position,
    //     };
    //     const updatedNodes = [...prevNodes, newNode];
    //     return updatedNodes;
    // })
    // setNodes(prevNodes => {
    //     const newNode = {
    //         id: (prevNodes.length + 1)?.toString(),
    //         data: { label: value.node },
    //         position,
    //     };
    //     const updatedNodes = [...prevNodes, newNode];
    //     console.log('updatedNodes:',updatedNodes);
    //     const nodeVariable = {
    //         name: value?.node,
    //         parents: (updatedNodes?.length > 0 && updatedNodes?.length === 1) ? [] : [{
    //             nodeId: value?.parent,
    //             relation: 'WRITE'
    //         }]
    //     }
    //     dispatch(setGeographicNodes(loginObject?.token, nodeVariable));
    //     return updatedNodes;
    // });
    // const newNode = {
    //     id: (nodes.length + 1)?.toString(),
    //     data: { label: value.node },
    //     position,
    //   };
    // setInitialNodes([...initialNodes, newNode]);
    // setNodes([...nodes, newNode]);
    // if (value?.parent !== null) {
    //   const newEdge = {
    //     id: `e${value?.parent}${nodes?.length + 1}`,
    //     source: value?.parent,
    //     target: (nodes?.length + 1)?.toString(),
    //     animated: true,
    //   };
    //   setInitialEdges([...initialEdges, newEdge]);
    //   setEdges([...edges, newEdge]);
    // }
    // setTimeout(() => {
    //   document?.getElementById("vertical-button")?.click();
    // }, [10]);
  };

  const filterDuplicates = (arr) => {
    const uniqueIds = new Set();
    return arr.filter(item => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    // if(nodes?.length > 0 & nodeDetails !== undefined) {
    if(nodeDetails !== undefined) {
    const nodeVariable = {
        name: nodeDetails?.node,
        parents: nodeDetails?.parent === null ? [] : [{
            nodeId: nodeDetails?.parent,
            relation: 'WRITE'
        }]
    }
     dispatch(setGeographicNodes(loginObject?.token, nodeVariable));
     setNodeDetails(undefined);
   }
  }, [nodeDetails]);

  const createNodes = (geographicNodes) => {
    for(let i = 0; i < geographicNodes?.child?.length; i++) {
        const newNodeId = (geographicNodes?.child[i]?.id)?.toString();

        // Check if newNodeId already exists in nodes
        const isNodeExists = nodes.some(node => (node.id === newNodeId));
        if (!isNodeExists) {
        const newNode = {
            id: (geographicNodes?.child[i]?.id)?.toString(),
            data: { label: geographicNodes?.child[i]?.name },
            position
        }
        console.log('C1');
        // setNodes(prevNodes => [...prevNodes, newNode]);
        // setInitialNodes(prevNodes => [...prevNodes, newNode]);
        setNodes(prevNodes => {
          // Filter out duplicates
          const filteredNodes = filterDuplicates(prevNodes);
          const isNewNodeExists = prevNodes?.filter((node) => node.id === newNode.id);
          if(isNewNodeExists?.length === 0) {
          const updatedNodes = [...filteredNodes, newNode];
          return updatedNodes;
          }
          else {
            return filteredNodes;
          }
        });
        setInitialNodes(prevNodes => {
          // Filter out duplicates
          const filteredNodes = filterDuplicates(prevNodes);
          const isNewNodeExists = prevNodes?.filter((node) => node.id === newNode.id);
          if(isNewNodeExists?.length === 0) {
          const updatedNodes = [...filteredNodes, newNode];
          return updatedNodes;
          }
          else {
            return filteredNodes;
          }
        });
        }
        if(geographicNodes?.child[i]?.child?.length > 0) {
            createNodes(geographicNodes?.child[i]);
        }
    }
  };

  const createEdges = (geographicNodes) => {
    for(let i = 0; i < geographicNodes?.child?.length; i++) {
        const isEdgeExists = edges.some(edge => (edge.id === `e${geographicNodes?.id}${geographicNodes?.child[i]?.id}`));
        if(!isEdgeExists) {
            const newEdge = {
            id: `e${geographicNodes?.id}${geographicNodes?.child[i]?.id}`,
            source: (geographicNodes?.id)?.toString(),
            target: (geographicNodes?.child[i]?.id)?.toString(),
            animated: true,
            };
            setInitialEdges(prevEdges => [...prevEdges, newEdge]);
            setEdges(prevEdges => [...prevEdges, newEdge]);
        }
        if(geographicNodes?.child[i]?.child?.length > 0) {
            createEdges(geographicNodes?.child[i]);
        }
    }
  }

  useEffect(() => {
    if(!loading && geographicNodes !== null) {
        const newNodeId = (geographicNodes?.id)?.toString();

        // Check if newNodeId already exists in nodes
        const isNodeExists = nodes?.length > 0 && nodes.some(node => (node.id === newNodeId));
        if (!isNodeExists) {
        const newNode = {
            id: (geographicNodes?.id)?.toString(),
            data: { label: geographicNodes?.name },
            position
        }
        // setNodes([...nodes, newNode]);
        // setInitialNodes([...initialNodes, newNode]);
        console.log('C2');
        setNodes(prevNodes => [...prevNodes, newNode]);
        setInitialNodes(prevNodes => [...prevNodes, newNode]);
        // }
        if(geographicNodes?.child?.length > 0) {
            // for(let i = 0; i < geographicNodes?.child?.length; i++) {
            //     const newNodeId = (geographicNodes?.child[i]?.id)?.toString();

            //     // Check if newNodeId already exists in nodes
            //     const isNodeExists = nodes.some(node => (node.id === newNodeId));
            //     if (!isNodeExists) {
            //     const newNode = {
            //         id: (geographicNodes?.child[i]?.id)?.toString(),
            //         data: { label: geographicNodes?.child[i]?.name },
            //         position
            //     }
            //     setNodes([...nodes, newNode]);
            //     setInitialNodes([...initialNodes, newNode]);
            //     }
            // }
            createNodes(geographicNodes);
        }
        //Edges Logic
        if(geographicNodes?.child?.length > 0) {
            // for(let i = 0; i < geographicNodes?.child?.length; i++) {
            //     const isEdgeExists = edges.some(edge => (edge.id === `e${geographicNodes?.id}${geographicNodes?.child[i]?.id}`));
            //     if(!isEdgeExists) {
            //         const newEdge = {
            //         id: `e${geographicNodes?.id}${geographicNodes?.child[i]?.id}`,
            //         source: (geographicNodes?.id)?.toString(),
            //         target: (geographicNodes?.child[i]?.id)?.toString(),
            //         animated: true,
            //         };
            //         setInitialEdges([...initialEdges, newEdge]);
            //         setEdges([...edges, newEdge]);
            //     }
            // }
            createEdges(geographicNodes);
        }
        setTimeout(() => {
            document?.getElementById("vertical-button")?.click();
        }, [10]);
       }
    }
  }, [loading, geographicNodes]);

  useEffect(() => {
    if(geographicNodes === null) {
      console.log('C3');
      setNodes([]);
      setInitialNodes([]);
      setEdges([]);
      setInitialEdges([]);
    }
  }, [geographicNodes]);

  const edit_node = (value1, value2) => {
    dispatch(editGeographicNodes(loginObject?.token, value1, value2));
  }

  const handleDelete = (selectedNode) => {
    dispatch({ type: EMPTY_GEOGRAPHIC_NODES });
    dispatch(deleteGeographicNodes(loginObject?.token, selectedNode?.id));
  }

  // console.log('Nodes:',nodes);
  // console.log('Initial Nodes:',initialNodes);
  // console.log('Edges:',edges);
//   console.log('nodesList:',nodesList);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onLayout = useCallback(
    (direction) => {
      console.log("Layout called");
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges]
  );

  const handleEdit = (node) => {
    setEditNode(node);
  }

  const renderTreeItems = (node) => {
    return (
      <>
        {/* <TreeItem itemId={node?.id * Math.floor(Math.random() * 54239)} label={node?.data?.label}> */}
        <TreeItem
          // itemId={Math.floor(Date.now() * Math.random())}
          itemId={node?.id}
          label={node?.name}
        >
          <button className="btn-edit" 
          onClick={() => {
            setEditNodeModal(true);
            handleEdit(node)
            }}
          >
            <img src={"/assets/pencil-tool.png"} alt="" style={{ width: "20px"}}/>
          </button>
          {node?.child?.length === 0 && <button className="btn-delete" onClick={() => handleDelete(node)}>
          <img src={"/assets/delete.png"} alt="" style={{ width: "20px"}}/>
          </button>}
          {node?.child?.length > 0 &&
            node?.child?.map((childNode) => renderTreeItems(childNode))}
        </TreeItem>
      </>
    );
  };

  const getAllIds = (node, idSet) => {
    if (!node) return;
    
    idSet.add(node.id);
    
    if (Array.isArray(node.child)) {
      node.child.forEach(child => {
        getAllIds(child, idSet);
      });
    }
  };

  useEffect(() => {
    const idSet = new Set();
    getAllIds(geographicNodes, idSet);

    const uniqueIdsArray = Array.from(idSet);
      setNodesId(uniqueIdsArray);
  }, [geographicNodes]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setNodesId(itemIds);
  };

  function ExpandIcon(props) {
    return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
  }
  
  function CollapseIcon(props) {
    return <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
  }
  
  function EndIcon(props) {
    return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
  }

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <div className="org-structure-container">
          <div
            style={{
              height: "500px",
              width: "600px",
              padding: "10px",
              margin: "5px",
              border: "1px solid black",
            }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              // onConnect={onConnect}
              fitView
            >
              <Background />
              <Controls />
              <Panel position="top-right">
                <button id="vertical-button" onClick={() => onLayout("TB")}>
                  Re-order
                </button>
                {/* <button onClick={() => onLayout('LR')}>horizontal layout</button> */}
              </Panel>
            </ReactFlow>
          </div>
          <div className="nodes">
            <div className="org-structure-child child-2">
              <p className="heading pt-1">Current Nodes</p>
              <button
                style={{
                  backgroundColor: "#00a82d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  width: "90px",
                  height: "30px",
                  padding: "2px 8px",
                  marginRight: "10px",
                }}
                onClick={() => setAddNodeModal(true)}
              >
                + Add Node
              </button>
            </div>
            <div className="nodes-list-tree">
              {geographicNodes !== null && (
              <SimpleTreeView
              // expandedItems={nodesId}
              // onExpandedItemsChange={handleExpandedItemsChange}
              aria-label="customized"
              sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}
              >
                {/* <TreeItem itemId={nodesList?.id} label={nodesList?.data?.label}> */}
                {/* {nodesList?.child?.length > 0 && nodesList?.child?.map((node, index) => (
                <TreeItem itemId={node?.id} label={node?.data?.label} />
              ))} */}
                {renderTreeItems(geographicNodes)}
                {/* </TreeItem> */}
              </SimpleTreeView>)}
            </div>
          </div>
        </div>
        <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
          //   setShowTab(false);
          //   navigateToFormStep(3);
          navigate('/admin/organization-setup');
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            dispatch(updateSetupStatus(orgId, "ORG_STRUCTURE", "Organization Structure", navigate, loginObject?.token));
          }}
        >
          Save & Create
        </button>
      </div>
      </div>
      {addNodeModal && (
        <AddNodeModal
          setAddNodeModal={setAddNodeModal}
          initialNodes={nodes}
          func={retrieve_node_name}
          dispatch={dispatch}
        />
      )}
      {editNodeModal && (
        <EditNodeModalNew
        setEditNodeModal={setEditNodeModal}
        initialNodes={nodes}
        node={editNode}
        func={edit_node}
        edges={edges}
        dispatch={dispatch}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

function OrganizationStructureNew({ sidebarCollapsed }) {
  return (
    <ReactFlowProvider>
      <Flow sidebarCollapsed={sidebarCollapsed} />
    </ReactFlowProvider>
  );
}

export default OrganizationStructureNew;

import React, { useEffect, useState } from "react";
import TopBar from "../../../../../shared/Navigation/TopBar";
import { useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import MandatoryAttributes from "./MandatoryAttributes";
import NonMandatoryAttributes from "./NonMandatoryAttributes";
import AttributesLibrary from "./AttributesLibrary";
import { useDrop } from 'react-dnd';
import CustomAttributeModal from "../../../../../shared/CustomAttribute/CustomAttributeModal";
import { attributesLibraryRequest, createCustomAttribute, deleteCustomAttribute, attributesRequest, getCustomerTypeAttributes } from "../../../../../Redux/actions/userLoginAction";
import { useSelector, useDispatch } from 'react-redux';
import Loader from "../../../../../shared/Loader/Loader";
import QuickGuide from "../../../../../shared/QuickGuide/QuickGuide";
import CreateSectionModalCustomer from "../../../../../shared/CreateSectionModal/CreateSectionModalCustomer";
import { EMPTY_ATTRIBUTES } from "../../../../../Redux/actions/actionTypes";

export default function UserMaster({ customer, sidebarCollapsed, count, custTypeAttributes }) {
    const loginObject = JSON.parse(sessionStorage.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    // console.log('state:',state);
    // console.log('customer:',customer);
    const { loading, attributesLibraryList, setupStatus, attributes, customerTypesAttributes } = useSelector((state) => state.adminReducer);
    const { orgId } = useSelector((state) => state.loginReducer); 
    const [quickFlagGuide, setQuickFlagGuide] = useState(false);
    const [createSectionFlag, setCreateSectionFlag] = useState(false);
    const [customAttributeFlag, setCustomAttributeFlag] = useState(false);
    const [isBackNavigated, setIsBackNavigated] = useState(false);
    const [customerStateFlow, setCustomerStateFlow] = useState([]);
    // console.log(`attributes for ${customer.name}:`,attributes);
    // console.log('customerTypesAttributes:',customerTypesAttributes);
    console.log('ATTR1:',customerTypesAttributes);
    console.log('ATTR2:',customerTypesAttributes[0]?.section);

    const [mandatoryAttributes, setMandatoryAttributes] = useState([{
      name: 'Employee name',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'Employee code',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'Designation',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'Email ID',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'Phone Number',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'Base Location',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }, {
      name: 'User role in Bloomr',
      isMandatory: true,
      isDraggable: false,
      preExists: true
  }]);
    const [nonMandatoryAttributes, setNonMandatoryAttributes] = useState([]);
    const [attributesLibrary, setAttributesLibrary] = useState([{
      name: 'Business',
      isMandatory: true,
      isDraggable: true,
      preExists: true
  }, {
      name: 'Alternate No',
      isMandatory: true,
      isDraggable: true,
      preExists: true
  }, {
      name: 'Residence address',
      isMandatory: true,
      isDraggable: true,
      preExists: true
  }, {
      name: 'Blood Group',
      isMandatory: true,
      isDraggable: true,
      preExists: true
}]);

  // console.log(`Mandatory for ${customer.name}:`,mandatoryAttributes);
  // console.log(`Non mandatory for ${customer.name}:`, nonMandatoryAttributes);

  const [, forceUpdate] = useState();
  const [customAttributeModal, setCustomAttributeModal] = useState(false);
  const [searchTerm ,setSearchTerm] = useState('');
  const [attributesLibraryCopy, setAttributesLibraryCopy] = useState([]);
  const [customerTypeId, setCustomerTypeId] = useState(null);

  // console.log('customerTypeId:',customerTypeId);
  // console.log('attributes:',attributes);
  // console.log('Customer:',customer);

  useEffect(() => {
    setTimeout(() => {
      if(!loading && custTypeAttributes?.length > 0 && customer !== null) {
        console.log('Attributes inside:',custTypeAttributes);
        console.log('customerSelected inside:',customer);
        let customerType = custTypeAttributes?.filter((attribute) => (attribute?.baseAttributeId === customer?.baseAttributeId));
        setCustomerTypeId(customerType[0]);
        console.log('customerTypeId inside:',customerTypeId);
      }
    }, [100]);
  }, [loading, custTypeAttributes, customer]);

  console.log('customerTypeId outside:',customerTypeId);

  useEffect(() => {
    // setTimeout(() => {
      if(customerTypeId !== null) {
        dispatch(getCustomerTypeAttributes(orgId, "CUSTOMER", customerTypeId?.orgAttributeId, loginObject?.token))
      }
    // }, [100]);
    // if(customer?.orgAttributeId) {
    //   dispatch(getCustomerTypeAttributes(orgId, "CUSTOMER", customer?.orgAttributeId, loginObject?.token))
    // }
  }, [customerTypeId]);

  //Back Flow Logic Start
  useEffect(() => {
    // if(state != null) {
    if(state?.mandatoryAttributes !== undefined && state?.nonMandatoryAttributes !== undefined) {
      setIsBackNavigated(true);
    }
  }, [state]);

  useEffect(() => {
    if(isBackNavigated) {
      console.log('1');
      setMandatoryAttributes(state?.mandatoryAttributes);
      setNonMandatoryAttributes(state?.nonMandatoryAttributes);
      const filtered = attributesLibraryList.filter(cItem => {
        return !mandatoryAttributes?.some(aItem => aItem.name === cItem.name) && !nonMandatoryAttributes?.some(bItem => bItem.name === cItem.name);
      });
      setAttributesLibrary(filtered);
      setAttributesLibraryCopy(filtered);
      // setIsBackNavigated(false);
    }
  }, [isBackNavigated, state, state?.isBack, attributesLibraryList, state?.mandatoryAttributes, state?.nonMandatoryAttributes]);
  //Back Flow Logic ends

  const handleChildChange = (newValue1, newValue2) => {
    //New added
    if(newValue2 === 'mandatory attributes') {
      console.log('2')
      setMandatoryAttributes(newValue1)
    } else if(newValue2 === 'non mandatory attributes') {
      setNonMandatoryAttributes(newValue1)
    } else {
      setAttributesLibrary(newValue1);
      setAttributesLibraryCopy(newValue1);
    }
    //Ends
    // setAttributesLibrary(filtered);
    forceUpdate(Math.random());
  };

  const [{ canDropMandatory, isOverMandatory }, dropMandatory] = useDrop(() => ({
    accept: "attribute",
    drop: () => ({ name: "mandatory attributes" }),
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
    })
  }), []);

  const [{ canDropNonMandatory, isOverNonMandatory }, dropNonMandatory] = useDrop(() => ({
    accept: "attribute",
    drop: () => ({ name: "non mandatory attributes" }),
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
    })
  }), []);

  const [{ canDropAttributesLibrary, isOverAttributesLibrary }, dropAttributesLibrary] = useDrop(() => ({
    accept: "attribute",
    drop: () => ({ name: "attributes library" }),
    collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
    })
  }), []);

  const handleAddClick = () => {
    setCustomAttributeModal(true);
  }

  useEffect(() => {
    if(orgId != null) {
      dispatch(attributesLibraryRequest(orgId, "CUSTOMER", loginObject.token));
    }
  }, []);

  useEffect(() => {
    if(customAttributeFlag) {
      const filtered = attributesLibraryList.filter(cItem => {
        return !mandatoryAttributes?.some(aItem => aItem.name === cItem.name) && !nonMandatoryAttributes?.some(bItem => bItem.name === cItem.name);
      });
      setAttributesLibrary(filtered);
      setAttributesLibraryCopy(filtered);
    // } else if(attributesLibraryList?.length > 0) {
    } else if(!isBackNavigated && !setupStatus?.customerMasterSetup && attributesLibraryList?.length > 0) {
      const mandatoryList = attributesLibraryList?.filter((item) => {
        return item.owner === "BLOOMR" && item.isMandatory
      });
      console.log('3')
      setMandatoryAttributes(mandatoryList);
      const attributesList = attributesLibraryList?.filter((item) => {
        return !item.isMandatory
      });
      setAttributesLibrary(attributesList);
      setAttributesLibraryCopy(attributesList);

      // const filtered = attributesLibraryList.filter(cItem => {
      //   return !mandatoryAttributes.some(aItem => aItem.name === cItem.name) && !nonMandatoryAttributes.some(bItem => bItem.name === cItem.name);
      // });
      // setAttributesLibrary(filtered);
      // setAttributesLibraryCopy(filtered);
      // console.log('attributesList:',attributesList);
    }
  }, [attributesLibraryList, customAttributeFlag]);
  // console.log('non mandatory attr:',nonMandatoryAttributes);

  //Edit Screen Code Starts

  useEffect(() => {
    if(setupStatus?.customerMasterSetup) {
      dispatch(attributesRequest(orgId, "CUSTOMER", loginObject.token));
    } else {
      dispatch({ type: EMPTY_ATTRIBUTES });
    }
  }, [setupStatus?.customerMasterSetup]);

  useEffect(() => {
    // if(setupStatus?.customerMasterSetup && attributes?.length > 0) {
    if(setupStatus?.customerMasterSetup && attributes?.length > 0 && !isBackNavigated && customerTypesAttributes?.length > 0) {
      const filteredManAttributes = customerTypesAttributes?.filter((item) => item.isMandatory);
      console.log('4')
      setMandatoryAttributes(filteredManAttributes);
      const filteredNonManAttributes = customerTypesAttributes?.filter((item) => !item.isMandatory);
      setNonMandatoryAttributes(filteredNonManAttributes);
    }
  // },[attributes, mandatoryAttributes, nonMandatoryAttributes]);
// },[attributes, setupStatus?.customerMasterSetup]);
},[attributes, setupStatus?.customerMasterSetup, isBackNavigated, customerTypesAttributes]);

useEffect(() => {
  const filtered = attributesLibraryList.filter(cItem => {
    return !mandatoryAttributes?.some(aItem => aItem.name === cItem.name) && !nonMandatoryAttributes?.some(bItem => bItem.name === cItem.name);
  });
  setAttributesLibrary(filtered);
  setAttributesLibraryCopy(filtered);
}, [attributes, setupStatus?.customerMasterSetup, mandatoryAttributes, nonMandatoryAttributes]);

// console.log('Man attri:',mandatoryAttributes);
  //Edit screen code ends

  const retrieve_custom_attribute = (value) => {
    dispatch(createCustomAttribute(orgId, "CUSTOMER", value, loginObject?.token));
    setCustomAttributeFlag(true);
  }

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    if(searchTerm != "") {
      let filter =  attributesLibrary?.filter((attribute) => {
        return attribute.name.toLowerCase().includes(searchTerm?.toLowerCase());
      });
      // setAttributesLibrary(filter);
      setAttributesLibraryCopy(filter);
    } else if(searchTerm == "") {
      setAttributesLibraryCopy(attributesLibrary);
    }
  }, [searchTerm]);

  useEffect(() => {
    if(!loading) {
      if(setupStatus.customerMasterSetup) {
        setQuickFlagGuide(false);
      } else {
        setQuickFlagGuide(true);
      }
    }
  }, [setupStatus, loading]);

  const pullOrgAttributeId = (attributeId, name, link) => {
    if(!setupStatus?.customerMasterSetup) {
      if(name === 'mandatoryAttributes') {
        let list = mandatoryAttributes.filter(item => item.id !== attributeId);
        console.log('5')
        setMandatoryAttributes(list);
      } else if(name === 'nonMandatoryAttributes') {
        let list = nonMandatoryAttributes.filter(item => item.id !== attributeId);
        setNonMandatoryAttributes(list);
      } else {
        let list = attributesLibrary.filter(item => item.id !== attributeId);
        setAttributesLibrary(list);
        setAttributesLibraryCopy(list);
      }
      const link = `admin/${orgId}/CUSTOMER/attribute/custom/${attributeId}`;
      // dispatch(deleteCustomAttribute(orgId, "CUSTOMER", attributeId, loginObject.token));
      dispatch(deleteCustomAttribute(link, loginObject.token));
    } else {
      if(name === 'mandatoryAttributes') {
        let link;
        if(link === 'link2') {
        link = `admin/${orgId}/CUSTOMER/attributes/${attributeId}`;
        let list = mandatoryAttributes.filter(item => item.orgAttributeId !== attributeId);
        console.log('6')
        setMandatoryAttributes(list);
        } else {
          console.log("attributeId:",attributeId);
        link = `admin/${orgId}/CUSTOMER/attribute/custom/${attributeId}`
        let list = mandatoryAttributes.filter(item => item.id !== attributeId);
        console.log('7')
        setMandatoryAttributes(list);
        }
        dispatch(deleteCustomAttribute(link, orgId, "CUSTOMER", loginObject.token));
        // let list = mandatoryAttributes.filter(item => item.orgAttributeId !== attributeId);
        // setMandatoryAttributes(list);
      } else if(name === 'nonMandatoryAttributes') {
        let link;
        if(link === 'link2') {
        link = `admin/${orgId}/CUSTOMER/attributes/${attributeId}`;
        let list = nonMandatoryAttributes.filter(item => item.orgAttributeId !== attributeId);
        setNonMandatoryAttributes(list);
        } else {
        link = `admin/${orgId}/CUSTOMER/attribute/custom/${attributeId}`;
        let list = nonMandatoryAttributes.filter(item => item.id !== attributeId);
        setNonMandatoryAttributes(list);
        }
        // const link = `admin/${orgId}/CUSTOMER/attributes/${attributeId}`;
        dispatch(deleteCustomAttribute(link, orgId, "CUSTOMER", loginObject.token));
        // let list = nonMandatoryAttributes.filter(item => item.orgAttributeId !== attributeId);
        // setNonMandatoryAttributes(list);
      } else {
        const link = `admin/${orgId}/CUSTOMER/attribute/custom/${attributeId}`;
        dispatch(deleteCustomAttribute(link, orgId, "CUSTOMER", loginObject.token));
        let list = attributesLibrary.filter(item => item.id !== attributeId);
        setAttributesLibrary(list);
        setAttributesLibraryCopy(list);
      }
      
    }
  }
  
//   useEffect(() => {
//     const index = customerState?.selectedCustomerTypes?.findIndex(c => c?.name === customer?.name);
//     console.log('Index:',index);
//     const spliced = customerState?.selectedCustomerTypes?.splice(index, 1);
//     console.log('spliced:',spliced);
//     // const updatedState = customerState?.selectedCustomerTypes?.filter((state) => state !== customer);
//     // console.log('updatedState:',updatedState);
//     setCustomerStateFlow(spliced);
//   }, [customerState]);

  return (
    <>
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
      <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>{`Create Attributes List for Mandatory, Non-Mandatory for ${customer.name}`}</p>
      <div className="d-flex flex-direction-row justify-content-around">
        
        <div className="usermaster_box bg-shadow" style={{ marginLeft: "30px", marginRight: "0" }} id="style-3" ref={dropMandatory}>
         <div className='attribute-heading'>
          <p className="module_head mb-2">Mandatory Attributes</p>
          </div>
          {/* {mandatoryAttributes?.map((attribute) => (
              // <div className="attribute" 
              // style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
              // boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}> 
              // {attribute.name}
              // </div>
            
              <div>
                <input 
                value={attribute.name} 
                disabled={attribute.isMandatory ? true : false}
                className="attribute"
                style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
                boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}
                />
              </div>
          ))} */}
          <div className='attributes-container'>
          {mandatoryAttributes?.filter((item) => item.owner === 'BLOOMR' && item.isMandatory).map((attribute) => 
            <input 
            value={attribute.name} 
            disabled
            className="attribute"
            // style={{ border: attribute.preExists === true ? "2px solid rgb(63, 228, 63)" : "", 
            // boxShadow: !attribute.preExists ? "1px 0.5px 2px 0px" : "" }}
            style={{ border: "2px solid rgb(63, 228, 63)", minHeight: '40px' }}
            />
          )}
          {/* {mandatoryAttributes?.filter((item) => (item.owner === 'ORG' || item.owner === 'BLOOMR') && !item.isMandatory).map((attribute) =>  */}
          {!setupStatus?.customerMasterSetup ? (
            <>
              {mandatoryAttributes?.filter((item) => (item.owner === 'ORG' || item.owner === 'BLOOMR') && !item.isMandatory).map((attribute) => 
            <MandatoryAttributes
            key={new Date().getTime() + Math.floor(Math.random * 1000000)}
            attribute={attribute}
            isMandatory={attribute.preExists}
            mandatoryAttributes={mandatoryAttributes}
            attributesLibrary={attributesLibrary}
            nonMandatoryAttributes={nonMandatoryAttributes}
            onChange={handleChildChange}
            func={pullOrgAttributeId}
            setupStatus={setupStatus}
            />
            )}
            </>
          ) : (<>
            {mandatoryAttributes?.filter((item) => (item.owner === 'ORG' && item.isMandatory) || (item.owner === 'ORG' && !item.isMandatory) || (item.owner === 'BLOOMR' && !item.isMandatory)).map((attribute) => 
            <MandatoryAttributes
            key={new Date().getTime() + Math.floor(Math.random * 1000000)}
            attribute={attribute}
            isMandatory={attribute.preExists}
            mandatoryAttributes={mandatoryAttributes}
            attributesLibrary={attributesLibrary}
            nonMandatoryAttributes={nonMandatoryAttributes}
            onChange={handleChildChange}
            func={pullOrgAttributeId}
            setupStatus={setupStatus}
            />
            )}
          </>)}
          </div>
        </div>

        {/* <MandatoryAttributes 
        // key={new Date().getTime() + Math.floor(Math.random * 1000)}
        attributes={mandatoryAttributes}
          /> */}
        
        <div className="usermaster_box bg-shadow" style={{ marginLeft: "20px", marginRight: "20px" }} ref={dropNonMandatory}>
          <div className='attribute-heading'>
          <p className="module_head mb-2">Non-Mandatory Attributes</p>
          </div>
          <div className='attributes-container'>
          {nonMandatoryAttributes?.map(attribute => 
            <NonMandatoryAttributes
            key={new Date().getTime() + Math.floor(Math.random * 100000)}
            attribute={attribute}
            isMandatory={attribute.preExists}
            mandatoryAttributes={mandatoryAttributes}
            attributesLibrary={attributesLibrary}
            nonMandatoryAttributes={nonMandatoryAttributes}
            onChange={handleChildChange}
            length={mandatoryAttributes?.length}
            func={pullOrgAttributeId}
            setupStatus={setupStatus}
            />
          )}
          </div>
        </div>
        {/* <NonMandatoryAttributes
        attributes={nonMandatoryAttributes} /> */}

        {/* <AttributesLibrary
        attributes={attributesLibrary} /> */}

      <div className="usermaster_box bg-shadow" style={{ height: '70vh', marginLeft: "0", marginRight: "30px", padding: '20px 20px 10px 20px' }} ref={dropAttributesLibrary}>
        <p className="module_head mb-2">Attributes Library</p>
          <div className="search-wrapper-icon">
            <div className="search-icon-attributes">
              <SearchIcon />
            </div>
            <input
              className="search-input form-control pl-5"
              id="search-library"
              placeholder="Search"
              type="text"
              onChange={(e) => handleSearchTerm(e)}
            />
          </div>
          <div className="attributes-library">
          {/* {attributesLibrary?.map(attribute =>  */}
          {attributesLibraryCopy?.map(attribute => 
            <AttributesLibrary
            key={new Date().getTime() + Math.floor(Math.random * 10000)}
            attribute={attribute}
            isMandatory={attribute.preExists}
            mandatoryAttributes={mandatoryAttributes}
            attributesLibrary={attributesLibrary}
            onChange={handleChildChange}
            nonMandatoryAttributes={nonMandatoryAttributes}
            func={pullOrgAttributeId}
            />
          )}
          </div>
          {/* <div className="add-custom-attribute">
              <div className="custom-attribute-wrapper">
                <input type="button" className="custom-attribute"  value="Add Custom Attribute" onClick={handleAddClick} />
              </div>
              <button 
              className="add-icon"
              // onClick={() => handleAddClick}
              onClick={handleAddClick}
              >
                <img 
                src={'/assets/add.png'} 
                />
              </button>  
          </div> */}
          <div className="add-custom-attribute">
              <button
              className="custom-attribute1"
              onClick={handleAddClick}
              >
              Add Custom Attribute
              <img 
                src={'/assets/add.png'} 
                />
              </button>
          </div>
      </div>
      
      {/* <div className="usermaster_box bg-shadow" style={{ height: '70vh', marginLeft: "0", marginRight: "30px" }}>
          <p className="module_head mb-2">Attributes Library</p>
            <div className="search-wrapper">
              <div className="search-icon-attributes">
                <SearchIcon />
              </div>
              <input
                className="search-input form-control pl-5"
                id="search-library"
                placeholder="Search"
                type="text"
              />
            </div>
            {attributesLibrary?.map((attribute) => (
            <>
              <div>
                <input 
                value={attribute.name} 
                disabled={attribute.isMandatory ? true : false}
                className="attribute"
                style={{ border: attribute.isMandatory === true ? "2px solid rgb(63, 228, 63)" : "", 
                boxShadow: !attribute.isMandatory ? "1px 0.5px 2px 0px" : "" }}
                />
              </div>
            </>
          ))}
        </div> */}

      </div>
      <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
          //   setShowTab(false);
          //   navigateToFormStep(3);
          navigate("/admin/organization-setup");
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            // setShowTab(false);
            // navigateToFormStep(3);
            // navigate(`/admin/attributes-list-review`, {
            //   state: {
            //     mandatoryAttributes: mandatoryAttributes,
            //     nonMandatoryAttributes: nonMandatoryAttributes
            //   }
            // });
            if(setupStatus?.customerMasterSetup && customerTypesAttributes[0]?.section != null) {
              navigate('/admin/view-created-sections-customer', {
                // navigate('/admin/create-sections', {
                state: {
                  mandatoryAttributes: mandatoryAttributes,
                  nonMandatoryAttributes: nonMandatoryAttributes,
                  attributes: attributes,
                  setupStatus: setupStatus,
                  customer: customer,
                  count: count,
                  customerTypeId: customerTypeId
                }
              })
            } else {
            setCreateSectionFlag(true);
            // setIsBackNavigated(false);
            }
          }}
        >
          Save & Next
        </button>
      </div>
      </div>
      {customAttributeModal && 
      <CustomAttributeModal 
      setCustomAttributeModal={setCustomAttributeModal} 
      func={retrieve_custom_attribute}
      comesFrom={"userMaster"}
       />}
      {loading && <Loader />}
      {quickFlagGuide && <QuickGuide setQuickFlagGuide={setQuickFlagGuide} />}
      {createSectionFlag && 
      <CreateSectionModalCustomer 
      setCreateSectionFlag={setCreateSectionFlag} 
      mandatoryAttributes={mandatoryAttributes}
      nonMandatoryAttributes={nonMandatoryAttributes}
      navigate={navigate}
      customer={customer}
      count={count}
      customerTypeId={customerTypeId}
      />}
    </>  
  );
}
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../shared/Loader/Loader';
import { deleteObjectiveOption } from '../../../../../Redux/actions';

const EditObjective = ({ editObjectiveDetails, setEditObjectiveModal, func, deleteVisitOption }) => {
    const dispatch = useDispatch();
    const loginObject = JSON.parse(sessionStorage.user);
    const { loading, objectives } = useSelector((state) => state.adminReducer);
    const [achievementName, setAchievementName] = useState([]);
    const [isAchievementEmpty, setIsAchievementEmpty] = useState(false);
    const [addAchievementFlag, setAddAchievementFlag] = useState(true);

    console.log('objectives:',objectives);

    useEffect(() => {
        if(editObjectiveDetails !== undefined && objectives?.length > 0) {
            // const achievements = editObjectiveDetails?.options?.map((option) => {
            //     const list = {
            //         id: option?.id,
            //         option: option?.option,
            //         isEmpty: false
            //     }
            //     return list;
            // });
            // setAchievementName(achievements);
            const objectiveOptions = objectives?.filter((objective) => objective?.id === editObjectiveDetails?.id);
            setAchievementName(objectiveOptions[0]?.options);
        }
    }, [editObjectiveDetails, objectives]);

    console.log('achievementName:',achievementName);

    const handleAchievementDelete = (achievement, index) => {
        console.log('achievement to be deleted:',achievement);
        console.log('index delete:',index);
        if(achievement?.id === null) {
            setAchievementName(prevAchievements => prevAchievements.filter((_, i) => i !== index));
        } else {
            // deleteVisitOption(editObjectiveDetails?.id, achievement?.id);
            dispatch(deleteObjectiveOption(editObjectiveDetails?.id, achievement?.id, loginObject?.token));
        }
    }

    const handleSubmit = () => {
        let isEmptyAchievementFound = false;
        for(let i = 0; i < achievementName?.length; i++) {
            isEmptyAchievementFound = true;
            if(achievementName[i]?.option === '' && achievementName[i]?.isEmpty !== undefined) {
                const dummy = [...achievementName];
                dummy[i].isEmpty = true;
                setAchievementName(dummy);
                setIsAchievementEmpty(true);
                isEmptyAchievementFound = false;
            }
        }
        console.log('isEmptyAchievementFound:',isEmptyAchievementFound);
        console.log('isAchievementEmpty:',isAchievementEmpty);
        if(isEmptyAchievementFound && isAchievementEmpty !== undefined && !isAchievementEmpty) {
            func(achievementName, editObjectiveDetails?.id);
        }
    }

    useEffect(() => {
        const newAchievement = achievementName?.filter((achievement) => achievement?.id === null);
        if(newAchievement?.length === 1) {
            setAddAchievementFlag(false);
        } else {
            setAddAchievementFlag(true);
        }
    }, [achievementName?.length]);

  return (
    <>
    <div className='custom-attribute-modal-wrapper' style={{ zIndex: '10' }}>
        <div className='edit-attribute-wrapper bg-shadow'>
        <p className="heading pt-2 pl-2">Edit Objectives Achievements</p>
            <div className="edit-objective">
                <p className="add-objective-heading" style={{ fontWeight: 'bold' }}>Objective</p>
                <input
                className="objective-input bg-shadow"
                placeholder="Add Objective"
                value={editObjectiveDetails?.objective}
                disabled
                />
            </div>
            <div className="edit-objective">
                <p className="add-objective-heading" style={{ fontWeight: 'bold' }}>Achievement Field Type</p>
                <input
                className="objective-input bg-shadow"
                placeholder="Add Objective"
                value={editObjectiveDetails?.achievementFieldType === 'NUMERIC' ? 'Numeric' :
                editObjectiveDetails?.achievementFieldType === 'OPTIONS_MULTI_SELECT' ? 'Multiple choice, multi-select' :
                editObjectiveDetails?.achievementFieldType === 'OPTIONS_SINGLE_SELECT' ? 'Multiple choice, single-select' : 'Free Text'}
                disabled
                />
            </div>
            <div className="add-new-objective multi-select add-achievement">
            <div className="achievement-line">
            <p className="add-objective-heading" style={{ width: '45%' }}>Add Achievements</p>
            {addAchievementFlag && <button className="add-btn" onClick={() => setAchievementName([...achievementName, { id: null, option: '', isEmpty: false }])}>
              <img src={'/assets/add.png'} alt="" className="edit-delete-icon" />
            </button>}
            </div>
            <div className="extra-acheievements bg-shadow" style={{ maxHeight: '250px' }}>
              {achievementName?.map((achievement, index) => (
                <>
                <div className="extra-achievements-wrapper">
                <div className="delete-achievement">
                  <button className="edit-delete-btn" onClick={() => handleAchievementDelete(achievement, index)}>
                    <img src={"/assets/bin.png"} alt="" className="edit-delete-icon" />
                  </button>
                </div>
                <input
                key={index}
                className="objective-input bg-shadow"
                placeholder="Add Achievement"
                value={achievement?.option}
                disabled={achievement?.id !== null ? true : false}
                onChange={(e) => {
                  const updatedAchievementName = [...achievementName]; 
                  updatedAchievementName[index].option = e.target.value; 
                  updatedAchievementName[index].isEmpty = false; 
                  setAchievementName(updatedAchievementName); 
                  if(isAchievementEmpty) {
                  setIsAchievementEmpty(false);
                  }
                }}
              />
              {(achievement?.id === null && achievement?.isEmpty) && <div className="empty-error-msg" style={{ marginBottom: '5px' }}>*Required</div>}
              </div>
              </>
              ))}
            </div>
            </div>
            <div className="mt-3 mr-3 float-left">
              <button 
              className="next_button ml-3" 
              type="button"
              onClick={() => setEditObjectiveModal(false)}
              >
                Cancel
              </button>
            </div>
            <div className="mt-3 mr-3 float-right">
              <button 
              className="next_button ml-3" 
              type="button"
              onClick={() => handleSubmit()}
              >
                Save
              </button>
            </div>
        </div>
    </div>
    {loading && <Loader />}
    </>
  )
}

export default EditObjective
import * as actionType from "../actions/actionTypes";

const initialState = {
    userId: null,
    firstName: "",
    secondName: "",
    email: "",
    phoneNumber: "",
    role: "",
    orgId: null,
    loading: false,
};

const loginReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionType.USER_LOGIN_REQ: 
            return {
                ...state,
                loading: true
            }
        case actionType.USER_LOGIN_SUCCESS:
            return {
                userId: action.payload.userId,
                firstName: action.payload.firstName,
                secondName: action.payload.secondName,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                role: action.payload.role,
                orgId: action.payload.orgId,
                loading: false,
            }
        case actionType.USER_LOGIN_FAIL: 
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
};

export default loginReducer;
  
import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form, Field } from 'formik';
import { useDropzone } from "react-dropzone";
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../SuperAdmin/Credentials';
import * as Yup from "yup";

const CompanyDetails = ({ profile }) => {
    const navigate = useNavigate();
    const Api = Constants.api;
    const loginObject = JSON.parse(sessionStorage.user);
    const [acceptedFile, setAcceptedFile] = useState(null);
    const [businesstypes, setBusinessTypes] = useState([]);
    const [fileSizeFlag, setfileSizeFlag] = useState(false);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, "Organization name must be at least 2 characters")
        .required("*Required"),
      mobileNumber: Yup.number()
        .required("*Required")
        .test("len", "Phone number should be 10 digits", (val) => {
          if (val) return val.toString().length === 10;
        }),
      businessTypeId: Yup.number().required("*Required"),
      address: Yup.string().required("*Required"),
      email: Yup.string().email("Invalid Email").required("*Required"),
      location: Yup.string().required("*Required"),
      adminName: Yup.string().required("*Required"),
      employeeId: Yup.number().required("*Required"),
      designation: Yup.string().required("*Required"),
      division: Yup.string().required("*Required"),
    });

    const onDrop = useCallback((files) => {
        setAcceptedFile(files[0]);
      }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, 
      accept: {
        'image/jpeg': [],
        'image/jpg': []
      }
     });

    console.log('acceptedFile:',acceptedFile);

    useEffect(() => {
      const getDropdowns = async () => {
        const resp = await fetch(`${Api}/data/business_type/list`, {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${loginObject.token}`,
          }),
        });
        const responseData = await resp.json();
        if (responseData.success) {
          setBusinessTypes(responseData.response);
        } else {
          console.log("failed to fetch business types");
        }
      };
      getDropdowns();
    }, []);

    useEffect(() => {
      if(acceptedFile?.size > 5242880) {
        setAcceptedFile(null);
        setfileSizeFlag(true);
      }
    }, [acceptedFile?.size]);

    useEffect(() => {
      if(fileSizeFlag && acceptedFile !== null && acceptedFile?.size <= 5242880) {
        setfileSizeFlag(false);
        }
    }, [acceptedFile]);

  return (
    <Formik
    initialValues={{
      name: profile.name,
      address: profile?.communicationAddress?.line1 + ', ' + profile?.communicationAddress?.line2
      + ', ' + profile?.communicationAddress?.city + ', ' + profile?.communicationAddress?.state 
      + ', ' + profile?.communicationAddress?.country + ', ' + profile?.communicationAddress?.pinCode,
      businessTypeId: profile?.businessTypeId,
      email: profile?.keyContacts[0]?.email,
      mobileCode: '+91',
      mobileNumber: profile?.keyContacts[0]?.mobile,
      location: '',
      adminName: profile?.keyContacts[0]?.firstName + ' ' + profile?.keyContacts[0]?.lastName,
      employeeId: profile?.keyContacts[0]?.id,
      designation: profile?.keyContacts[0]?.designation,
      division: ''
    }}
    validationSchema={validationSchema}
    enableReinitialize={true}
    validateOnChange={true}
    onSubmit={(values) => {
      console.log("Values submitted:", values);
    //   handleSubmit(values);
    }}
  >
    {({ handleSubmit, touched, errors, values }) => (
      <>
      <Form
        // className="row mr-5"
        // className="row d-flex justify-content-center"
        style={{ height: '100%' }}
        onSubmit={handleSubmit}
      >
        <div className='company-details-container'>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="name">Company Name</label>
            <Field
                type="text"
                className="form-control"
                name="name"
                placeholder="Company Name"
                autoComplete="off"
            />
            {touched.name && errors.name && (
                <span className="error-msg">{errors.name}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '38%' }}>
            <label htmlFor="address">Company Address</label>
            <Field
                as='textarea'
                className="form-control company-address"
                name="address"
                placeholder="Company Address"
                autoComplete="off"
                rows='5'
                cols='5'
            />
            {touched.address && errors.address && (
                <span className="error-msg">{errors.address}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '33%' }}>
                <label>Logo</label>

                <>
                  <div
                    {...getRootProps()}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    className="form-control"
                  >
                    <input {...getInputProps()} />
                    {acceptedFile ? (
                      <div className="d-flex justify-content-center">
                        <img
                          className=""
                          src={URL.createObjectURL(acceptedFile)}
                          alt="logo"
                          style={{ maxWidth: "100%", maxHeight: "140px" }}
                        />
                      </div>
                    ) : (
                      // <a
                      //   style={{
                      //     textDecoration: "underline",
                      //     color: "#00a82d",
                      //   }}
                      // >
                      //   Drag and Drop logo of Organization <br /> or Click here
                      //   to select
                      // </a>
                      <div className="d-flex justify-content-center">
                        <img
                          className=""
                          src={profile?.logoUrl}
                          alt="logo"
                          style={{ maxWidth: "100%", maxHeight: "140px" }}
                        />
                      </div>
                    )}
                  </div>
                  {acceptedFile !== undefined && acceptedFile?.size <= 5242880 && <p className="small-text mt-2">
                    * Logo should be 250px * 250px and less than 5 mb
                  </p>}
                  {acceptedFile === undefined && <span className="error-msg">*Invalid File Type</span>}
                  {fileSizeFlag && <div className="error-msg">*Image size limit exceeded</div>}
                </>
              </div>
              <div className="field-width" style={{ height: '20%' }}>
              <label htmlFor="businessTypeId">Business</label>
            <Field
                as="select"
                className="form-control"
                id="businesstype"
                name="businessTypeId"
            >
                <option
                value="default"
                selected
                hidden
                style={{ fontSize: "16px !important" }}
                >
                Select Business
                </option>
                {!businesstypes.length !== 0 &&
                businesstypes?.map((type) => {
                    return (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                    );
                })}
            </Field>
            {touched.businessTypeId && errors.businessTypeId && (
              <span className="error-msg">{errors.businessTypeId}</span>
            )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="email">Email Id</label>
            <Field
                type="text"
                className="form-control"
                name="email"
                placeholder="Email Id"
                autoComplete="off"
            />
            {touched.email && errors.email && (
                <span className="error-msg">{errors.email}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="mobileNumber">Phone Number</label>
            <div className="d-flex">
                  <input
                    type="text"
                    value="+91 "
                    id="subdomain"
                    disabled
                    className="form-control"
                  />
                  <Field
                    type="number"
                    inputMode="numeric"
                    id="subdomaintwo"
                    placeholder="Enter Phone Number"
                    className="form-control"
                    autoComplete="off"
                    name="mobileNumber"
                  />
                </div>
                {touched.mobileNumber && errors.mobileNumber && (
                  <span className="error-msg">{errors.mobileNumber}</span>
                )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="location">Company Location</label>
            <Field
                type="text"
                className="form-control"
                name="location"
                placeholder="Company Location"
                autoComplete="off"
            />
            {touched.location && errors.location && (
                <span className="error-msg">{errors.location}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="adminName">Admin Name</label>
            <Field
                type="text"
                className="form-control"
                name="adminName"
                placeholder="Admin Name"
                autoComplete="off"
            />
            {touched.adminName && errors.adminName && (
                <span className="error-msg">{errors.adminName}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="employeeId">Employee Id</label>
            <Field
                type="text"
                className="form-control"
                name="employeeId"
                placeholder="Auto Generated"
                autoComplete="off"
            />
            {touched.employeeId && errors.employeeId && (
                <span className="error-msg">{errors.employeeId}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="designation">Designation / Role</label>
            <Field
                type="text"
                className="form-control"
                name="designation"
                placeholder="Admin Designation / Role"
                autoComplete="off"
            />
            {touched.designation && errors.designation && (
                <span className="error-msg">{errors.designation}</span>
              )}
            </div>
            <div className="field-width" style={{ height: '20%' }}>
            <label htmlFor="division">Division</label>
            <Field
                type="text"
                className="form-control"
                name="division"
                placeholder="Division"
                autoComplete="off"
            />
            {touched.division && errors.division && (
                <span className="error-msg">{errors.division}</span>
              )}
            </div>
        </div>
        <div className="ml-auto mt-3 mr-3 float-right">
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  onClick={() => navigate('/admin/company-profile')}
                >
                  Back
                </button>
                <button
                  style={{
                    backgroundColor: "#00a82d",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    width: "90px",
                    height: "32px",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                  type="submit"
                //   onClick={() => handleUpdateButtonClick()}
                >
                  Update
                </button>
              </div>
      </Form>
      </>
    )}
  </Formik>
  )
}

export default CompanyDetails
import React from "react";

const FilterModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "#fff",
          height: "90%",
          width: "60%",
          margin: "auto",
          padding: "2%",
          borderRadius: "10px",
          boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
          overflowY:'auto'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterModal;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "./Credentials";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Loader from "../../../shared/Loader/Loader";

export default function EditOrganization({ sidebarCollapsed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const orgId = location?.state?.id;
  const Api = Constants.api;
  const loginObject = JSON.parse(sessionStorage.user);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [loading, setLoading] = useState(false);

  function getOrganizationDetails() {
    setLoading(true);
    fetch(`${Api}/org/${orgId}`, {
      method: "GET",
      //Request Type
      headers: {
        Authorization: `Bearer ${loginObject.token}`,
      },
    })
      .then((response) => response.json())
      .then((respJson) => {
        if (respJson.success) {
          setLoading(false);
          setOrganizationDetails(respJson.response);
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        // return error;
        setLoading(false);
      });
  }
  console.log("org details:", organizationDetails);
  useEffect(() => {
    getOrganizationDetails();
  }, []);

  return (
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <button
        className="d-flex align-items-center"
        style={{ backgroundColor: "#fff", border: "none" }}
        onClick={() => navigate("/home")}
      >
        <KeyboardBackspaceIcon />
        <p>Back</p>
      </button>
      <div className="edit_organization">
        <div
          className="row light_shadow p-2 mx-1"
          style={{ borderRadius: "5px" }}
        >
          <div className="col-12 p-0 position-relative">
            <h6 className="text-center font-weight-bold">Basic Details</h6>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                right: 0,
                top: 0,
              }}
              className="edit_icon light_shadow position-absolute"
              // onClick={() => navigate('/organizationprofile')}
              onClick={() =>
                navigate(`/organizationprofile/${orgId}`, {
                  state: { id: orgId, tab: "nav-basicdetails" },
                })
              }
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
            <div className="d-flex align-items-center mb-3">
              <img
                src={organizationDetails.logoUrl}
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              />
              <div className="ml-3 ">
                <p className="heading">{organizationDetails?.name}</p>
                <p>
                  {organizationDetails?.communicationAddress?.line1 || "----"+
                    " " +
                    organizationDetails?.communicationAddress?.line2 || "----"}
                </p>
                <p>
                  {organizationDetails?.communicationAddress?.pinCode || "----"  +
                    " " +
                    organizationDetails?.communicationAddress?.city|| "----" +
                    " " +
                    organizationDetails?.communicationAddress?.state || "----"+
                    " " +
                    organizationDetails?.communicationAddress?.country|| "----"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex editorg-label">
              {/* <label
              >
                Organization Name
              </label>
              <p>{organizationDetails.name}</p> */}
            </div>
            <div className="d-flex editorg-label">
              <label>Business Segment</label>
              <p>{organizationDetails.businessSegmentName}</p>
            </div>
            <div className="d-flex editorg-label">
              <label>Business Type</label>
              <p>{organizationDetails.businessTypeName}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex editorg-label">
              <label>GST/Tax Number</label>
              <p>{organizationDetails?.gst || "----"}</p>
            </div>
            <div className="d-flex editorg-label">
              <label>Channnel Partner</label>
              <p>{organizationDetails.channelPartner}</p>
            </div>
          </div>

          <div className="col-4">
            <div className="d-flex editorg-label">
              <label>Phone No.</label>
              <p>{organizationDetails.mobileNumber}</p>
            </div>
            <div className="d-flex editorg-label">
              <label>Bloomr Account Manager</label>
              <p>{organizationDetails?.accountManager}</p>
            </div>
          </div>
        </div>

        <div
          className="row light_shadow mt-3 p-2 mx-1"
          style={{ borderRadius: "5px" }}
        >
          <div className="col-12 px-0 position-relative mb-3">
            <h6 className="text-center font-weight-bold">
              Key Contact Details
            </h6>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                right: 0,
                top: 0,
              }}
              className="edit_icon light_shadow position-absolute"
              onClick={() =>
                navigate(`/organizationprofile/${orgId}`, {
                  state: { id: orgId, tab: "nav-keycontacts" },
                })
              }
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
          </div>
          {typeof organizationDetails?.keyContacts !== "undefined" &&
            organizationDetails?.keyContacts.length > 0 && (
              <>
                {organizationDetails.keyContacts.find(
                  (contact) => contact.isAdmin
                ) && (
                  <>
                    <div className="col-4">
                      <div className="d-flex keycontact-label">
                        <label>Key Contact Name</label>
                        <p>
                          {
                            organizationDetails.keyContacts.find(
                              (contact) => contact.isAdmin
                            ).firstName
                          }
                        </p>
                      </div>
                      <div className="d-flex keycontact-label">
                        <label>Designation</label>
                        <p>
                          {
                            organizationDetails.keyContacts.find(
                              (contact) => contact.isAdmin
                            )?.designation || "----"
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex keycontact-label">
                        <label>Email Id</label>
                        <p>
                          {
                            organizationDetails.keyContacts.find(
                              (contact) => contact.isAdmin
                            ).email
                          }
                        </p>
                      </div>
                      <div className="d-flex keycontact-label">
                        <label>Admin Name</label>
                        <p>
                          {
                            organizationDetails.keyContacts.find(
                              (contact) => contact.isAdmin
                            ).firstName
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex keycontact-label">
                        <label>Mobile No.</label>
                        <p>
                          {
                            organizationDetails.keyContacts.find(
                              (contact) => contact.isAdmin
                            ).mobile
                          }
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
        </div>
        <div className="d-flex justify-content-around mt-3 w-100">
          <div
            className="light_shadow py-3"
            style={{ borderRadius: "5px", width: "32%" }}
          >
            <div className="">
              <p className="text-center font-weight-bold mb-3">
                Default Modules
              </p>
            </div>
            <div className="d-flex ">
              <div className="px-4">
                <p>My Team</p>
                <p>My Customers</p>
                <p>My Calendar</p>
              </div>
              <div className="px-4">
                <p>My Dashboard</p>
                <p>MY Visits</p>
              </div>
            </div>
          </div>

          <div
            className="light_shadow  py-1 mx-2"
            style={{ borderRadius: "5px", width: "32%" }}
          >
            <div className="">
              <p className="text-center font-weight-bold mb-1">
                Module Selection Details
              </p>
              <p className="text-center font-weight-bold mb-3">
                Optional Modules
              </p>
            </div>
            <div className="d-flex ">
              <div className="px-4">
                <p>My Opportunities</p>
                <p>Market Intelligence</p>
                <p>Compliants</p>
              </div>
              <div className="px-4">
                <p>My Orders</p>
                <p>MY Expenses</p>
              </div>
            </div>
          </div>
          <div
            className=" light_shadow py-2"
            style={{ borderRadius: "5px", width: "32%" }}
          >
            <div className="d-flex align-items-center justify-content-center mb-3 position-relative">
              <p className="text-center font-weight-bold">Optional Modules</p>
              <button
                style={{
                  padding: "1px",
                  margin: "0px 3px",
                  border: "none",
                  backgroundColor: "#fff",
                  right: 5,
                  top: 1,
                }}
                className="edit_icon light_shadow float-right position-absolute"
              >
                <img
                  src={"/assets/pencil-tool.png"}
                  style={{ width: "25px", padding: "5px" }}
                />
              </button>
            </div>
            <div className="d-flex ">
              <div className="px-4">
                <p>Skilltiger</p>
                <p>Mail Chimp</p>
                <p>My Operator</p>
              </div>
              <div className="px-4">
                <p>Survey Monkey</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" row light_shadow mt-3 p-2 mx-1"
          style={{ borderRadius: "5px" }}
        >
          <div className="col-12 px-0 position-relative">
            <h6 className="text-center font-weight-bold mb-3">
              Package Details
            </h6>
            <button
              style={{
                padding: "1px",
                margin: "0px 3px",
                border: "none",
                backgroundColor: "#fff",
                right: 0,
                top: 0,
              }}
              className="edit_icon light_shadow position-absolute"
              onClick={() =>
                navigate(`/organizationprofile/${orgId}`, {
                  state: { id: orgId, tab: "nav-packageselection" },
                })
              }
            >
              <img
                src={"/assets/pencil-tool.png"}
                style={{ width: "25px", padding: "5px" }}
              />
            </button>
          </div>
          <div className="col-4">
            <div className="d-flex editorg-label">
              <label>Package Name</label>
              <p>{organizationDetails?.license?.packageName}</p>
            </div>
            <div className="d-flex editorg-label">
              <label>Valid From</label>
              <p>{organizationDetails?.license?.validFrom}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex editorg-label">
              <label>valid To</label>
              <p>{organizationDetails?.license?.validTo}</p>
            </div>
            <div className="d-flex editorg-label">
              <label>Billing Cycle</label>
              <p>{organizationDetails?.license?.billingCycle}</p>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}

import React from "react";
import "./QuickGuide.css";
import { useLocation } from "react-router-dom";

const QuickGuide = ({
  setQuickFlagGuide,
  setQuickFlagGuideUser,
  quickFlagGuide,
}) => {
  const location = useLocation();
  console.log("path:", location.pathname);
  return (
    <>
      <div className="body">
        <div className="container-quick-guide">
          {location.pathname === "/admin/organization-setup" ? (
            <>
              <div className="quick-tip">
                <span className="text">Hi! User</span>
                <span className="text">Welcome to Bloomr</span>
                <div className="para-body">
                    Our goal is to make managing your customer relationships as smooth and effective as possible. Let's personalize your settings.
                  </div>
              </div>
            </>
          ) : location.pathname === "/admin/module-selection" ? (
            <>
              <div className="quick-tip1">
                <p className="para-text">
                  <span>Hi!</span>
                  <span>Let's start by selecting relevant modules !</span>
                  <div className="para-body">
                    In this step the admin can see all the modules bought by
                    them. Here the Admin can also activate and deactivate any
                    module.
                  </div>
                </p>
              </div>
            </>
          ) : location.pathname === "/admin/user-master-setup" ? (
            <>
              <div className="quick-tip1">
                <p className="para-text">
                  <span>Hi!</span>
                  <span>Let's start forming your User Master !</span>
                  <div className="para-body">
                    To make it simple, we have already listed some "Must have"
                    attributes under "Mandatory Attributes", highlighted in
                    green.
                  </div>
                  <div className="para-body">
                    "Good to have" attributes are populated in Library.
                  </div>
                  <div className="para-body">
                    Search for your desired attributes in library and simply
                    drag them to Mandatory or Non Mandatory column.
                  </div>
                  <div className="para-body">
                    Don't worry if your desired attribute is not there , you can
                    always add it in library. But make sure that you don't
                    exceed more than 10 custom attributes.
                  </div>
                </p>
              </div>
            </>
          ) : location.pathname === "/admin/customer-master-setup" ? (
            <>
              <div className="quick-tip1">
                <p className="para-text">
                  <span>Hi!</span>
                  <span>Let's start forming your Customer Master !</span>
                  <div className="para-body">
                    In the first step user has to select relevant customer types
                    list for their organization.
                  </div>
                  <div className="para-body">
                    To make it simple, we have already listed some "Must have"
                    attributes under "Mandatory Attributes", highlighted in
                    green.
                  </div>
                  <div className="para-body">
                    "Good to have" attributes are populated in Library.
                  </div>
                  <div className="para-body">
                    Search for your desired attributes in library and simply
                    drag them to Mandatory or Non Mandatory column.
                  </div>
                  <div className="para-body">
                    Don't worry if your desired attribute is not there , you can
                    always add it in library. But make sure that you don't
                    exceed more than 10 custom attributes.
                  </div>
                </p>
              </div>
            </>
          ) :
           location.pathname === "/admin/product-master-setup" ? (
            <>
              <div className="quick-tip1">
                <p className="para-text">
                  <span>Hi!</span>
                  <span>Let's start forming your Products Master !</span>
                  <div className="para-body">
                    In the first step user has to select relevant customer types
                    list for their organization.
                  </div>
                  <div className="para-body">
                    To make it simple, we have already listed some "Must have"
                    attributes under "Mandatory Attributes", highlighted in
                    green.
                  </div>
                  <div className="para-body">
                    "Good to have" attributes are populated in Library.
                  </div>
                  <div className="para-body">
                    Search for your desired attributes in library and simply
                    drag them to Mandatory or Non Mandatory column.
                  </div>
                  <div className="para-body">
                    Don't worry if your desired attribute is not there , you can
                    always add it in library. But make sure that you don't
                    exceed more than 10 custom attributes.
                  </div>
                </p>
              </div>
            </>
          ) :
          (
            <>
              <div className="quick-tip1">
                <p className="para-text"></p>
              </div>
            </>
          )}
          <div className="ok-btn1">
            <button
              onClick={() => {
                if (setQuickFlagGuide !== undefined) {
                  setQuickFlagGuide(false);
                }
                if (setQuickFlagGuideUser !== undefined) {
                  setQuickFlagGuideUser(false);
                }
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickGuide;

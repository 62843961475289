import React, { useState } from 'react'

const CustomAttributeModal = ({ 
  setCustomAttributeModal, 
  func, 
  comesFrom, 
  parentChildValue,
  dropdownOptions
 }) => {
  const [attributeName, setAttributeName] = useState('');
  const [inputType, setInputType] = useState('');
  const [emptyName, setemptyName] = useState(false);
  const [emptyType, setEmptyType] = useState(false);

  const handleChange = (e) => {
    if(e.target.name === 'attributeName') {
      setAttributeName(e.target.value);
      setemptyName(false);
    }
    if(e.target.name === 'attributeType') {
      setInputType(e.target.value);
      setEmptyType(false);
    }
  }

  const handleSave = () => {
    if (comesFrom !== 'customerParentChild' && attributeName === "" && inputType === "") {
      console.log('C1')
      setemptyName(true);
      setEmptyType(true);
    } else if(comesFrom !== 'customerParentChild' && attributeName === "") {
      console.log('C2')
      setemptyName(true);
    } else if (inputType === "") {
      setEmptyType(true);
    } else {
    const customAttribute = {
      name: attributeName,
      inputType: inputType
    }
    if(comesFrom === 'customerParentChild') {
      func(inputType);
    } else {
    func(customAttribute);
    }
    setCustomAttributeModal(false)
  }
  }

  return (
    <div className='custom-attribute-modal-wrapper'>
        <div className='custom-modal-popup' style={{ height: emptyType ? '45%' : '40%' }}>
            <h3>{comesFrom === 'userMaster' ? `Add Custom Attribute` : comesFrom === 'customerMaster' ? `Add Custom Customer Type` : `Select Parent`}</h3>
            <div className='input-group'>
                <input 
                placeholder={comesFrom === 'userMaster' ? 'Attribute Name' : 'Customer Type Name'}
                name="attributeName" 
                style={{ marginBottom: emptyName ? "0" : "20px" }} 
                value={comesFrom === 'customerParentChild' ? parentChildValue?.name : attributeName}
                disabled={comesFrom === 'customerParentChild' ? true : false}
                onChange={(e) => handleChange(e)} 
                />
                {emptyName && <p style={{ color: 'red', fontSize: '5px', marginTop: "5px", marginBottom: '5px' }}>*Required</p>}
                <select
                  className="input-group-select"
                  name="attributeType"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="default" hidden>
                    <span style={{ fontWeight: '100'}}>{comesFrom === 'customerParentChild' ? `Parent` : `Type of attribute`}</span>
                  </option>
                  {comesFrom === 'customerMaster' ? (<>
                  {/* <option value="STRING">STRING</option>
                  <option value="NUMBER">NUMBER</option>
                  <option value="BOOLEAN">BOOLEAN</option>
                  <option value="DATE">DATE</option>
                  <option value="DATE_TIME">DATE_TIME</option>
                  <option value="TIME">TIME</option>
                  <option value="LONG_TEXT">LONG_TEXT</option>
                  <option value="ADDRESS">ADDRESS</option> */}
                  <option value="SEQ">SEQUENCE</option>
                  {/* <option value="BUSINESS_TYPE">BUSINESS_TYPE</option>
                  <option value="BUSINESS_SEG">BUSINESS_SEG</option>
                  <option value="CONTACT">CONTACT</option> */}
                  </>) : comesFrom === 'userMaster' ? (<>
                  <option value="STRING">STRING</option>
                  <option value="NUMBER">NUMBER</option>
                  <option value="BOOLEAN">BOOLEAN</option>
                  <option value="LIST">LIST</option>
                  <option value="DATE">DATE</option>
                  <option value="DATE_TIME">DATE_TIME</option>
                  <option value="TIME">TIME</option>
                  <option value="LONG_TEXT">LONG_TEXT</option>
                  <option value="ADDRESS">ADDRESS</option>
                  <option value="SEQ">SEQUENCE</option>
                  <option value="BUSINESS_TYPE">BUSINESS_TYPE</option>
                  <option value="BUSINESS_SEG">BUSINESS_SEG</option>
                  <option value="CONTACT">CONTACT</option>
                  </>) : (<>
                  {dropdownOptions?.map((type, index) => (
                    <option key={index} value={`${type?.orgAttributeId},${type?.name}`}>{type.name}</option>
                  ))}
                  </>)}
                </select>
                {emptyType && <p style={{ color: 'red', fontSize: '5px', marginTop: "5px", marginBottom: '5px' }}>*Required</p>}
            </div>
            <div className='mt-3 float-right'>
                <button
                 className="cancel_button"
                 onClick={() => setCustomAttributeModal(false)}
                 >
                  Cancel
                 </button>
                <button 
                className='next_button ml-3'
                onClick={handleSave}
                >Save</button>
            </div>
        </div>
    </div>
  )
}

export default CustomAttributeModal
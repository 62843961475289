import React from 'react';
import { useDrop, useDrag } from 'react-dnd';

// const NonMandatoryAttributes = ({ attributes }) => {
  const NonMandatoryAttributes = ({ attribute, isMandatory, mandatoryAttributes, attributesLibrary, nonMandatoryAttributes, onChange, length, func, setupStatus }) => {

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "attribute",
    item: { name: attribute },
    end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if(item.name.name === attribute.name && dropResult) {
            // alert(`You threw ${item.name.name} into ${dropResult.name}`);
            // let addedObj = {name: item.name.name, isMandatory: false, isEditable: true}
            if(dropResult.name === 'mandatory attributes') {
              // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: true, inputType: item.name.inputType, owner: item.name.owner}
              // mandatoryAttributes.push(addedObj);
              mandatoryAttributes.push(item.name);
            } else if(dropResult.name === 'attributes library') {
              // let addedObj = {id: item.name.id, name: item.name.name, isMandatory: false, inputType: item.name.inputType, owner: item.name.owner}
              // attributesLibrary.push(addedObj);
              attributesLibrary.push(item.name);
            }
            const filtered = nonMandatoryAttributes.filter(cItem => {
                return !mandatoryAttributes.some(aItem => aItem.name === cItem.name) && !attributesLibrary.some(bItem => bItem.name === cItem.name);
              });
            onChange(filtered, "non mandatory attributes")
        }
    },
    collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
    })
}), [attribute, attributesLibrary, mandatoryAttributes, nonMandatoryAttributes]);


  return (
    <>
    <div className='wrapper' ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='logo'>
        <img src={"/assets/drag.png"} />
      </div>
    <div> 
      <input 
      // ref={drag}
      value={attribute.name} 
      // disabled={isMandatory ? true : false}
      disabled
      className="attribute"
      style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
      boxShadow: attribute.owner === 'ORG' ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "", cursor: "pointer", minHeight: '40px' }}
      />
    </div>
    {attribute.owner !== "BLOOMR" && (
        <button 
        className='logo-delete'
        // onClick={() => handleDeleteClick(attribute.id)}
        // onClick={() => func(attribute?.id, "nonMandatoryAttributes")}
        onClick={() => {
          if(setupStatus?.userMasterSetup) {
            if(attribute?.orgAttributeId != undefined) {
              func(attribute?.orgAttributeId, "nonMandatoryAttributes", "link2")
              } else {
                func(attribute?.id, "nonMandatoryAttributes", "link1")
              }
          } else {
            func(attribute?.id, "nonMandatoryAttributes", "link1")
          }
        }}
        >
            <img src={"/assets/delete.png"} />
        </button>
    )}
    </div>
        {/* <div className="usermaster_box bg-shadow" style={{ marginLeft: "20px", marginRight: "20px" }} ref={drop}>
            <p className="module_head mb-2">Non-Mandatory Attributes</p>
            {attributes?.map((attribute) => (
                <div>
                  <input 
                  value={attribute.name} 
                  disabled={attribute.isMandatory ? true : false}
                  className="attribute"
                  style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
                  boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}
                  />
                </div>
            ))}
        </div> */}
    </>
  )
  // return (
  //   <>
  //       <div className="usermaster_box bg-shadow" style={{ marginLeft: "20px", marginRight: "20px" }} ref={drop}>
  //           <p className="module_head mb-2">Non-Mandatory Attributes</p>
  //           {attributes?.map((attribute) => (
  //               <div>
  //                 <input 
  //                 value={attribute.name} 
  //                 disabled={attribute.isMandatory ? true : false}
  //                 className="attribute"
  //                 style={{ border: attribute.isMandatory ? "2px solid rgb(63, 228, 63)" : "2px solid none", 
  //                 boxShadow: !attribute.isMandatory ? "0 1px 10px rgba(0,0,0,0.3)" : "" }}
  //                 />
  //               </div>
  //           ))}
  //       </div>
  //   </>
  // )
}

export default NonMandatoryAttributes
const LoginTopBar = () => {
  return (
    <div
      className="w-100 pb-3"
      style={{ backgroundColor: "#00a82d",borderRadius:'5px', }}
    >
        <div className="d-flex justify-content-center pt-3" >
      <img
        src={'assets/Bloomr.png'}
        style={{ width: "110px", }}
      />
      </div>
      <div className="text-center">
      <p style={{color:'#fff', fontSize:'13px'}}>Accelerate your sales</p>
      </div>
    </div>
  );
};

export default LoginTopBar;

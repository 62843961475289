import React, { useState, useEffect } from "react";
import TopBar from "../../../../shared/Navigation/TopBar";
import { useNavigate } from 'react-router-dom';
import VisitRestrictions from "./VisitsSetup/VisitRestrictions";
import TravelPlanSetup from "./VisitsSetup/TravelPlanSetup";
import { useSelector, useDispatch } from 'react-redux';
import { getListAttributes, getProductAttributes } from "../../../../Redux/actions/userLoginAction";
import Loader from "../../../../shared/Loader/Loader";
import OrderEmail from "./ProductSetup/OrderEmail";

const FunctionalitySetupHome = ({ sidebarCollapsed }) => {
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const loginObject = JSON.parse(sessionStorage.user);
  const { orgId } = useSelector((state) => state.loginReducer); 
  const { loading, attributes, allAttributes, productAttributes } = useSelector((state) => state.adminReducer);

  const [expandUserBox, setExpandUserBox] = useState(true);
  const [expandCustomerBox, setExpandCustomerBox] = useState(true);
  const [expandVisitBox, setExpandVisitBox] = useState(true);
  const [expandHierarchyBox, setExpandHierarchyBox] = useState(true);
  const [expandProductBox, setExpandProductBox] = useState(true);
  const [expandOrganizationBox, setExpandOrganizationBox] = useState(true);
  const [visitRestrictions, SetVisitRestrictions] = useState(false);
  const [travelPlanSetup, SetTravelPlanSetup] = useState(false);
  const [listAttributes, setListAttributes] = useState([]);
  const [productLinkListAttributes, setProductLinkListAttributes] = useState([]);
  const [orderEmailModal, setOrderEmailModal] = useState(false);

  useEffect(() => {
    if(orgId != null) {
      dispatch(getListAttributes(loginObject.token));
    }
  }, []);

  useEffect(() => {
    dispatch(getProductAttributes(loginObject?.token));
  }, []);

  // useEffect(() => 
  //   if(!loading && allAttributes?.length > 0) {
  //     setListAttributes(allAttributes);
  //   }
  // }, [loading, allAttributes]);

  useEffect(() => {
    if(!loading && productAttributes?.length > 0) {
      const linkListAttributes = productAttributes?.filter((attribute) => attribute?.inputType === "LIST_LINK");
      let latestAttributes = [];
      for(let i = 3; i >= 1; i--) {
        let attribute = linkListAttributes?.filter((_, index) => index === linkListAttributes?.length - i);
        latestAttributes.push(attribute[0]);
      }
      setProductLinkListAttributes(latestAttributes);
    }
  }, [loading, productAttributes]);

  return (
    <>
      <div
        className="main"
        style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
      >
        <TopBar />
        <p className="heading pt-2 pl-5 m-2">Functionality Setup</p>
        <div className="setup-container mb-1">
          <div className="setup-boxes bg-shadow" style={{ height: expandUserBox ? "fit-content" : "65px" }}>
            <div className="setup-boxes-heading-container">
            <div className="setup-boxes-logo">
              <img src={"/assets/user-master.png"} alt="" />
            </div>
            <div className="setup-boxes-heading" style={{ height: '50px' }}>
              <p>List Attributes Setup</p>
              <p>Manage list attributes related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandUserBox(!expandUserBox)}>
                {expandUserBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
            </div>
            {expandUserBox &&
            (<>
             <div className="setup-options-container setup-options-container-user">
              {allAttributes?.length > 0 ?
              (<>
              {allAttributes?.filter((attribute) => attribute.name !== "Manager")?.map((attribute, index) => (
                <>
                <div key={index} className="setup-options bg-shadow">
                  <button 
                  className="ftn-btn"
                  onClick={() => navigate(`/admin/functionality-setup/user-functionality/setup/${attribute?.baseAttributeId}`, {
                    state: {
                      attribute: attribute
                    }
                  })}
                  >
                    <div>{attribute?.name}</div>
                    <p className="list-category">Category: {attribute?.category}</p>
                  </button>
                </div>
                </>
              ))}
              </>) : (<>
                <div style={{ textAlign: 'center' }}>List Attributes Not Available.
                  {/* <p>
                    <button className="link-btn" onClick={() => navigate('/admin/user-master-setup')}>Click here</button> 
                    to setup user master
                  </p> */}
                </div>
              </>)
            } 
            </div>
            </>)
          }
          </div>
          <div className="setup-boxes bg-shadow customer-setup" style={{ height: expandCustomerBox ? "315px" : "70px" }}>
          <div className="setup-boxes-heading-container">
          <div className="setup-boxes-logo">
              <img src={"/assets/verified-customer.png"} alt="" />
            </div>
            <div className="setup-boxes-heading">
              <p>Customer Functionality Setup</p>
              <p>Manage specific customer related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandCustomerBox(!expandCustomerBox)}>
                {expandCustomerBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
          </div>
          {expandCustomerBox && <div className="setup-options-container setup-options-container-customer">
              <div className="setup-options bg-shadow">
                <button onClick={() => navigate("/admin/functionality-setup/customer-setup/customer-code-setup")}
                >Customer Code Setup</button>
              </div>
              {/* <div className="setup-options bg-shadow">
                <button onClick={() => navigate("/admin/functionality-setup/customer-setup/prospect-status")}>Prospect Status</button>
              </div> */}
              <div className="setup-options bg-shadow">
                <button onClick={() => navigate("/admin/functionality-setup/customer-setup/edit-parent-child-connection")}>
                  Parent & Child Connection
                </button>
              </div>
              <div className="setup-options bg-shadow">
                <button>Business Sub-Segment</button>
              </div>
              <div className="setup-options bg-shadow">
                <button>Business Potential</button>
              </div>
              {/* <div className="setup-options bg-shadow">
                <button>Class</button>
              </div> */}
          </div>}
          </div>
          <div className="setup-boxes bg-shadow" style={{ height: expandVisitBox ? "305px" : "65px" }}>
          <div className="setup-boxes-heading-container">
          <div className="setup-boxes-logo">
              <img src={"/assets/visits.png"} alt="" />
            </div>
            <div className="setup-boxes-heading">
              <p>Visit Functionality Setup</p>
              <p>Manage specific visit related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandVisitBox(!expandVisitBox)}>
                {expandVisitBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
            </div>
            {expandVisitBox && <div className="setup-options-container">
              <div className="setup-options bg-shadow">
                <button onClick={() => navigate('/admin/functionality-setup/objective-and-achievements')}>
                  Objective & Achievement
                </button>
              </div>
              <div className="setup-options bg-shadow">
                <button onClick={() => navigate('/admin/functionality-setup/add-tasks')}>Add Tasks</button>
              </div>
              <div className="setup-options bg-shadow" onClick={() => SetVisitRestrictions(true)}>
                <button>Visit Restrictions</button>
              </div>
              <div className="setup-options bg-shadow" onClick={() => SetTravelPlanSetup(true)}>
                <button>Travel Plan Setup</button>
              </div>
            </div>}
          </div>
          {/* <div className="second-row"> */}
          <div className="setup-boxes bg-shadow setup-boxes-product" style={{ height: expandHierarchyBox ? "fit-content" : "65px" }}>
            <div className="setup-boxes-heading-container">
            <div className="setup-boxes-logo">
              <img src={"/assets/hierarchy.png"} alt="" />
            </div>
            <div className="setup-boxes-heading">
              <p>Product Hierarchy Functionality Setup</p>
              <p>Manage product hierarchy related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandHierarchyBox(!expandHierarchyBox)}>
                {expandHierarchyBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
            </div>
            {expandHierarchyBox && <div className="setup-options-container setup-options-container-user">
              {productAttributes?.length > 0 ?
              (<>
              {productLinkListAttributes?.filter((_, index) => index < 3)?.map((attribute, index) => (
                <>
                <div key={index} className="setup-options bg-shadow">
                  <button 
                  onClick={() => navigate(`/admin/functionality-setup/product-functionality/setup/${attribute?.baseAttributeId}`, {
                    state: {
                      attributes: productLinkListAttributes,
                      attribute: attribute,
                      index: index
                    }
                  })}
                  >
                    {`${index + 1}. `}{attribute?.name}
                  </button>
                </div>
                </>
              ))}
              </>) : (<>
                <div style={{ textAlign: 'center' }}>You have not setup Product Master
                  <p>
                    <button className="link-btn" onClick={() => navigate('/admin/product-master-setup')}>Click here</button> 
                    to setup product master
                  </p>
                </div>
              </>)
            } 
            </div>}
            </div>
            <div className="setup-boxes bg-shadow setup-boxes-product" style={{ height: expandProductBox ? "fit-content" : "65px" }}>
            <div className="setup-boxes-heading-container">
            <div className="setup-boxes-logo">
              <img src={"/assets/verified-cube.png"} alt="" />
            </div>
            <div className="setup-boxes-heading">
              <p>Product & Order Functionality Setup</p>
              <p>Manage specific order related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandProductBox(!expandProductBox)}>
                {expandProductBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
            </div>
            {expandProductBox && <div className="setup-options-container setup-options-container-product">
              <div className="setup-options bg-shadow">
                <button onClick={()=> setOrderEmailModal(true)}>Order Email</button>
              </div>
              {/* <div className="setup-options bg-shadow">
                <button
                onClick={() => navigate("/admin/functionality-setup/product-functionality/focused-products")}
                >Focused Products</button>
              </div> */}
              <div className="setup-options bg-shadow">
                <button 
                onClick={() => navigate('/admin/functionality-setup/product-functionality/set-delivery')}
                >
                  Set Delivery
                </button>
              </div>
              <div className="setup-options bg-shadow">
                <button 
                onClick={() => navigate("/admin/functionality-setup/product-functionality/schemes-and-promos")}
                >
                  Schemes
                </button>
              </div>
              <div className="setup-options bg-shadow">
                <button>Product Unit</button>
              </div>
            </div>}
            </div>
            <div className="setup-boxes bg-shadow customer-setup" style={{ height: expandOrganizationBox ? "400px" : "70px" }}>
            <div className="setup-boxes-heading-container">
            <div className="setup-boxes-logo">
              <img src={"/assets/diagram.png"} alt="" />
            </div>
            <div className="setup-boxes-heading">
              <p>Organization Functionality Setup</p>
              <p>Manage general organization related settings here</p>
            </div>
            <div className="dropdown-caret">
              <button className="caret-button" onClick={() => setExpandOrganizationBox(!expandOrganizationBox)}>
                {expandOrganizationBox ? <img src={"/assets/caret-up.png"} alt="" /> : <img src={"/assets/caret-down.png"} alt="" />}
              </button>
            </div>
            </div>
            {expandOrganizationBox && <div className="setup-options-container setup-options-container-customer">
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">Week Off &Holiday Settings</button>
              </div>
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">Download Permissions for Users</button>
              </div>
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">Start & End my Day</button>
              </div>
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">Resources Libaray Management</button>
              </div>
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">Geo Tagging Settings</button>
              </div>
              <div className="setup-options bg-shadow">
                <button className="ftn-btn">KPI, Target & Achievement Settings</button>
              </div>
            </div>}
            </div>
          {/* </div> */}
          {/* <div className="mt-3 mr-3 float-left">
            <button
              className="next_button"
              type="button"
              onClick={() => {
                navigate('/admin/organization-setup')
              }}
            >
              Back
            </button>
          </div> */}
        </div>
        <div className="mt-3 mr-3 float-left" style={{ padding: '0 20px 20px 40px' }}>
            <button
              className="next_button"
              type="button"
              onClick={() => {
                navigate('/admin/organization-setup')
              }}
            >
              Back
            </button>
          </div>
      </div>
      {visitRestrictions && <VisitRestrictions SetVisitRestrictions={SetVisitRestrictions} />}
      {travelPlanSetup && <TravelPlanSetup SetTravelPlanSetup={SetTravelPlanSetup} />}
      {loading && <Loader />}
      {orderEmailModal && 
    <OrderEmail 
    setOrderEmailModal={setOrderEmailModal} 
    />}
    </>
  );
};

export default FunctionalitySetupHome;

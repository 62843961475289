import React, { useEffect, useState } from 'react';
import TopBar from '../../../../shared/Navigation/TopBar';
import { useDrop } from 'react-dnd';
import SearchIcon from "@mui/icons-material/Search";
import { attributesLibraryRequest, deleteCustomAttribute, setCustomerType, setCustomerTypes } from '../../../../Redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../shared/Loader/Loader';
import CustomerTypesLibrary from './CustomerTypesLibrary';
import CustomAttributeModal from '../../../../shared/CustomAttribute/CustomAttributeModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCustomAttribute, attributesRequest } from '../../../../Redux/actions';
import { DragDropContext } from '@hello-pangea/dnd';
import CustomerMasterChild from './CustomerMasterChild';
import { SET_CUSTOMER_TYPES, EMPTY_ATTRIBUTES } from '../../../../Redux/actions/actionTypes';
import QuickGuide from '../../../../shared/QuickGuide/QuickGuide';

const CustomerMasterNew = ({ sidebarCollapsed }) => {
    const loginObject = JSON.parse(sessionStorage.user);
    const dispatch = useDispatch();
    const { loading, attributesLibraryList, deleteAttribute, attributes, setupStatus } = useSelector((state) => state.adminReducer); 
    const { orgId } = useSelector((state) => state.loginReducer); 
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log('setUpstatus:',setupStatus);

    const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([]);
    const [customerTypesLibrary, setCustomerTypesLibrary] = useState([]);
    const [customerTypesLibraryCopy, setCustomerTypesLibraryCopy] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [customAttributeModal, setCustomAttributeModal] = useState(false);
    const [parentChildRelation, setParentChildRelation] = useState([]);
    const [parentChildModal, setParentChildModal] = useState(false);
    const [parentChildValue, setParentChildValue] = useState('');
    let quicktipFlagUser = localStorage.getItem("quickFlagGuideUser");
    const [quickFlagGuideUser, setQuickFlagGuideUser] = useState();


    // console.log('selectedCustomerTypes 1st:',selectedCustomerTypes);
    // console.log('customerTypesLibrary:',customerTypesLibrary);
    // console.log('customerTypesLibraryCopy:',customerTypesLibraryCopy);
    // console.log('attributes:',attributes);

    useEffect(() => {
      if(!loading) {
        setCustomerTypesLibrary(attributesLibraryList);
      }
    }, [attributesLibraryList, loading]);

    useEffect(() => {
      if(!loading) {
        if(setupStatus.customerMasterSetup) {
          setQuickFlagGuideUser(false);
        } else if(quicktipFlagUser !== null && quicktipFlagUser) {
          setQuickFlagGuideUser(false);
        } else {
          setQuickFlagGuideUser(true);
        }
      }
    }, [setupStatus, loading, quicktipFlagUser]);
  
    useEffect(() => {
      if(quickFlagGuideUser != undefined && !quickFlagGuideUser) {
        localStorage.setItem("quickFlagGuideUser", true);
      }
    }, [quickFlagGuideUser]);

    useEffect(() => {
      // Save original customerTypesLibrary when it's first loaded
      if (searchTerm === '' && customerTypesLibrary.length > 0 && customerTypesLibraryCopy.length === 0) {
        console.log('U1');
        setCustomerTypesLibraryCopy(customerTypesLibrary);
      }
    }, [customerTypesLibrary, customerTypesLibraryCopy]);

      useEffect(() => {
        dispatch(attributesLibraryRequest(orgId, "CUST_TYPE", loginObject.token));
        // dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token))
      }, []);

      useEffect(() => {
        // if(setupStatus?.customerMasterSetup) {
          dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token));
        // }
      }, [setupStatus?.customerMasterSetup]);

      const handleSearchTerm = (value) => {
        // setSearchTerm(e.target.value);
        setSearchTerm(value);
      }
    
      useEffect(() => {
        if(searchTerm !== "") {
            const filteredCustomerTypes = customerTypesLibrary?.filter(type => {
                return !selectedCustomerTypes?.some(customer => customer.name === type.name)
              });
          let filter =  filteredCustomerTypes?.filter((attribute) => {
            return attribute.name.toLowerCase().includes(searchTerm?.toLowerCase());
          });
          setCustomerTypesLibraryCopy(filter);
        //   setCustomerTypesLibrary(filter);
        } else if(searchTerm === "") {
            const filteredCustomerTypes = customerTypesLibrary?.filter(type => {
                return !selectedCustomerTypes?.some(customer => customer.name === type.name)
              });
              setCustomerTypesLibraryCopy(filteredCustomerTypes);
        //   setCustomerTypesLibraryCopy(customerTypesLibrary);
          // setCustomerTypesLibrary(customerTypesLibraryCopy);
        }
    //   }, [searchTerm, customerTypesLibrary, customerTypesLibraryCopy]);
        }, [searchTerm, customerTypesLibrary]);

        // console.log('customerTypesLibrary:',customerTypesLibrary);

      const handleAddClick = () => {
        setCustomAttributeModal(true);
      }

      const retrieve_custom_attribute = (value) => {
        dispatch(createCustomAttribute(orgId, "CUST_TYPE", value, loginObject?.token));
        // setCustomAttributeFlag(true);
      }

      useEffect(() => {
        if(selectedCustomerTypes?.length > 0) {
          const filteredCustomerTypes = attributesLibraryList?.filter(type => {
            return !selectedCustomerTypes?.some(customer => customer.name === type.name)
          });
          setCustomerTypesLibrary(filteredCustomerTypes);
        }
      }, [attributesLibraryList]);

      useEffect(() => {
        if(deleteAttribute !== undefined) {
            if(deleteAttribute.table === 'selectedCustomerType') {
                let filter = selectedCustomerTypes.filter(customer => customer.id !== deleteAttribute.attributeId);
                setSelectedCustomerTypes(filter);
            }
            console.log('Delete attribute:',deleteAttribute);
            if(attributes?.length > 0) {
              console.log('1st delete')
              if(deleteAttribute.table === 'selectedCustomerType') {
              const link = `admin/${orgId}/CUST_TYPE/attribute/custom/${deleteAttribute.orgAttributeId}`;
              dispatch(deleteCustomAttribute(link, orgId, 'CUST_TYPE', loginObject.token));
              const customerTypeList = selectedCustomerTypes?.filter(customer => customer.id !== deleteAttribute.attributeId)?.map((customer, index) => {
                return {
                  attributeId: customer.id,
                  sequence: index + 1,
                }
              });
              console.log('customerTypeList 2st:',customerTypeList);
              dispatch(setCustomerType(orgId, 'CUST_TYPE', customerTypeList, loginObject.token, false, navigate));
              // dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token))
              } else {
              const link = `admin/${orgId}/CUST_TYPE/attribute/custom/${deleteAttribute.attributeId}`;
              dispatch(deleteCustomAttribute(link, orgId, 'CUST_TYPE', loginObject.token));
              dispatch(attributesRequest(orgId, "CUST_TYPE", loginObject.token))
              }
            } else {
              console.log('2nd delete')
              const link = `admin/${orgId}/CUST_TYPE/attributes/${deleteAttribute.attributeId}`;
              dispatch(deleteCustomAttribute(link, orgId, 'CUST_TYPE', loginObject.token));
            }
        }
      }, [deleteAttribute])

      // useEffect(() => {
      //   if(state !== null) {
      //     setSelectedCustomerTypes(state?.selectedCustomerTypes);
      //     setCustomerTypesLibrary(state?.customerTypesLibrary);
      //   }
      // }, [state]);

      const onDragEnd = (result) => {
        console.log('Result:',result);
        if(searchTerm !== '') {
            setSearchTerm('');

        }
        const { source, destination } = result;

        if(!destination) {
            return;
        } 

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
          ) {
            return;
        }

        let add;
        let active = [...customerTypesLibraryCopy];
        let complete = [...selectedCustomerTypes];

        // Source Logic
    if (source.droppableId === "customerTypesLibrary") {
        console.log('C1')
        add = active[source.index];
        active.splice(source.index, 1);
      } else {
        console.log('C2')
        add = complete[source.index];
        complete.splice(source.index, 1);
      }
  
      // Destination Logic
      if (destination.droppableId === "customerTypesLibrary") {
        console.log('C3')
        active.splice(destination.index, 0, add);
      } else {
        console.log('C4')
        complete.splice(destination.index, 0, add);
      }
  
      setSelectedCustomerTypes(complete);
      setCustomerTypesLibraryCopy(active);
      if(searchTerm === '') {
        setCustomerTypesLibrary(active);
      }
      }

      const handleSubmit = () => {
        const customerTypeList = selectedCustomerTypes?.map((customer, index) => {
          return {
            attributeId: customer.id,
            // sequence: index + 1,
          }
        });
        // console.log('customerTypeList 1st:',customerTypeList);
        dispatch({ type: EMPTY_ATTRIBUTES })
        // dispatch(setCustomerType(orgId, 'CUST_TYPE', customerTypeList, loginObject.token, false, navigate));
        dispatch(setCustomerTypes(orgId, 'CUST_TYPE', customerTypeList, selectedCustomerTypes, customerTypesLibrary, loginObject?.token, navigate));
        dispatch({ type: SET_CUSTOMER_TYPES, payload: selectedCustomerTypes })
      }

      //Edit Screen Logic - If Sequence Setup is already done
      //Then attributes state from redux will not be null

      useEffect(() => {
        if(attributes?.length > 0) {
          const sortedArray = [...attributes];
          const newArray = sortedArray.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence)).map(obj => ({ 
            id: obj.baseAttributeId, 
            inputType: obj.inputType,
            isMandatory: obj.isMandatory,
            name: obj.name,
            orgAttributeId: obj.orgAttributeId,
            owner: obj.owner,
            section: obj.section,
            sequence: obj.sequence,
          }));
          // console.log('New sorted array:',newArray);
          setTimeout(() => {
            setSelectedCustomerTypes(newArray);
          }, [0]);
        }
      }, [attributes?.length]);

    //   const retrieve_attribute_id = (value1) => {
    //     // if(value2 === 'link') {
    //         setParentChildModal(true);
    //         setParentChildValue(value1);
    //     // }
    //   }

    //   const retrieve_parent_Child = (value) => {
    //     console.log('Value in PC:',value);
    //     setParentChildModal(false);
    //     let obj = {
    //         parent: value?.inputType,
    //         child: parentChildValue.name
    //     }
    //     console.log('PC OBJ:',obj);
    //     setParentChildRelation(prevState => [...prevState, obj]);
    // }
      // console.log('PC Table:',parentChildRelation);

  return (
    <>
    <DragDropContext onDragEnd={onDragEnd}>
    <div
    className="main"
    style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
  >
    <TopBar />
    <button onClick={() => {if (!quickFlagGuideUser) {setQuickFlagGuideUser(true)}}} style={{float:'right', marginRight:'40px',backgroundColor:'#00a82d',padding:'0px 7px',borderRadius:'50%',border:'none'}}>
        <p style={{color:'#fff'}}>?</p>
        </button>
    <p className="heading pt-2 pl-2 m-2" style={{ textAlign: 'center' }}>Selection of Customer Types</p>
      <div className="d-flex flex-direction-row justify-content-around">
        
        {/* <div className="usermaster_box bg-shadow" style={{ height: '70vh', marginLeft: '10%', padding: '20px 20px 10px 20px' }} 
        >
          <p className="module_head mb-2">Selected Customer Types</p>
        </div>

        <div className="usermaster_box bg-shadow" style={{ height: '70vh', marginRight: "10%", padding: '20px 20px 10px 20px' }}>
          <p className="module_head mb-2">Customer Types Library</p>
          <div className="search-wrapper">
            <div className="search-icon-attributes">
              <SearchIcon />
            </div>
            <input
              className="search-input form-control pl-5"
              id="search-library"
              placeholder="Search"
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearchTerm(e)}
            />
          </div>
          <div className="attributes-library">
          </div>
          <div className="add-custom-attribute">
              <button
              className="custom-attribute1"
              onClick={handleAddClick}
              >
              Add Customer Type
              <img 
                src={'/assets/add.png'} 
                />
              </button>
          </div>
        </div> */}
        <CustomerMasterChild
        selectedCustomerTypes={selectedCustomerTypes}
        setSelectedCustomerTypes={setSelectedCustomerTypes}
        customerTypesLibrary={customerTypesLibrary}
        setCustomerTypesLibrary={setCustomerTypesLibrary}
        customerTypesLibraryCopy={customerTypesLibraryCopy}
        setCustomerTypesLibraryCopy={setCustomerTypesLibraryCopy}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleAddClick={handleAddClick}
         />
      </div>
      <div className="mt-3 mr-3 float-left">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            navigate('/admin/organization-setup')
          }}
        >
          Back
        </button>
      </div>
      <div className="mt-3 mr-3 float-right">
        <button
          className="next_button ml-3"
          type="button"
          onClick={() => {
            handleSubmit();
            // {!loading && navigate('/admin/customer-master-attributes', {
            //   // state: {
            //   //   selectedCustomerTypes: selectedCustomerTypes,
            //   //   customerTypesLibrary: customerTypesLibrary
            //   // }
            // })}
            {!loading && navigate('/admin/customer-master-parent-child-connection', {
              state: {
                selectedCustomerTypes: selectedCustomerTypes,
                customerTypesLibrary: customerTypesLibrary
              }
            })}
            // navigate('/admin/customer-master-parent-child-connection',  {
            //     state: {
            //       selectedCustomerTypes: selectedCustomerTypes,
            //       customerTypesLibrary: customerTypesLibrary
            //     }
            //   })
          }}
        >
          Save & Next
        </button>
      </div>
    </div>
    {loading && <Loader />}
    {quickFlagGuideUser && <QuickGuide setQuickFlagGuideUser={setQuickFlagGuideUser} />}
    {customAttributeModal && 
    <CustomAttributeModal 
    setCustomAttributeModal={setCustomAttributeModal} 
    func={retrieve_custom_attribute} 
    comesFrom={"customerMaster"}
    />}
    </DragDropContext>
    {loading && <Loader />}
    {/* {parentChildRelation?.length > 0 && 
    <ConnectionsBox
    parentChildRelation={parentChildRelation}
    />}
    {parentChildModal && 
    <CustomAttributeModal 
    setCustomAttributeModal={setParentChildModal} 
    func={retrieve_parent_Child} 
    comesFrom={"customerParentChild"}
    parentChildValue={parentChildValue}
    selectedCustomerTypes={selectedCustomerTypes}
    />
    } */}
    </>
  )
}

export default CustomerMasterNew
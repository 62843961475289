import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DraggableElement from "./DraggableElement";
import { Droppable } from "@hello-pangea/dnd";
import { useSelector, useDispatch } from 'react-redux';

const CustomerMasterChild = ({
  selectedCustomerTypes,
  setSelectedCustomerTypes,
  customerTypesLibrary,
  setCustomerTypesLibrary,
  customerTypesLibraryCopy,
  setCustomerTypesLibraryCopy,
  searchTerm,
  setSearchTerm,
  handleAddClick
}) => {
  const { loading, attributesLibraryList } = useSelector((state) => state.adminReducer); 
  const { orgId } = useSelector((state) => state.loginReducer); 
  const [, forceUpdate] = useState();

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if(customerTypesLibrary?.length > 0) {
      forceUpdate(Math.random());
    }
  }, [customerTypesLibrary?.length])

  return (
    <>
    <div
    className="usermaster_box bg-shadow"
    style={{
        height: "70vh",
        marginLeft: "10%",
        padding: "20px 20px 10px 20px",
    }}>
        <p className="module_head mb-2">Selected Customer Types</p>
      <Droppable droppableId="selectedCustomerTypes">
        {(provided, snapshot) => (
          <div
            style={{
              height: "70vh",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {selectedCustomerTypes?.map((attribute, index) => (
              <DraggableElement
               index={index}
               attribute={attribute} 
               tableName="selectedCustomerType"
               />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      </div>

          <div
            className="usermaster_box bg-shadow"
            style={{
              height: "70vh",
              marginRight: "10%",
              padding: "20px 20px 10px 20px",
            }}
            // ref={provided.innerRef}
            // {...provided.droppableProps}
          >
            <p className="module_head mb-2">Customer Types Library</p>
            <div className="search-wrapper-icon">
              <div className="search-icon-attributes">
                <SearchIcon />
              </div>
              <input
                className="search-input form-control pl-5"
                id="search-library"
                placeholder="Search"
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearchTerm(e)}
              />
            </div>
            <Droppable droppableId="customerTypesLibrary">
            {(provided, snapshot) => (
            <div 
            className="attributes-library" 
            ref={provided.innerRef}
            {...provided.droppableProps}
            >
              {customerTypesLibraryCopy?.map((attribute, index) => (
                <DraggableElement
                 index={index}
                 attribute={attribute}
                 tableName="customerTypeLibrary"
                  />
              ))}
                {provided.placeholder}
            </div>
            )}
            </Droppable>
            <div className="add-custom-attribute">
              <button className="custom-attribute1" onClick={handleAddClick}>
                Add Customer Type
                <img src={"/assets/add.png"} />
              </button>
            </div>
          </div>
    </>
  );
};

export default CustomerMasterChild;

import React from 'react';
import { useDrop } from 'react-dnd';
import SectionDrag from './SectionDrag';

const DropTarget = ({ sectionIndex, sectionData, setSectionData, setBottomRefIndex }) => {
  // console.log('Section data in DT:',sectionData);
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: "attribute",
        drop: (item, monitor) => {
        // Define drop behavior here
        // You can access the section index using sectionIndex prop
        // You can access dropped item properties using item.name, item.type, etc.
        setBottomRefIndex(sectionIndex);
        return { name: `section ${sectionIndex}` }
        },
        // drop: () => ({ name: `section ${sectionIndex}` }),
        collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
        })
    }), []);

  return (
    <div 
    // style={{ minHeight: '200px', overflowY: 'auto',
    style={{ minHeight: '150px', height: 'fit-content', display: 'flex', flexDirection: 'column', gap: '10px',
    //  border: '1px solid green'
     }}
    ref={drop}>
    {sectionData?.filter((item, index) => index === sectionIndex).map((section, index) => (
      <>
        {section.attributesList.map((attribute, attrIndex) => (
          // <div className='wrapper' key={attrIndex}>
          //   <div className='logo' key={index}>
          //     <img src={"/assets/drag.png"} />
          //   </div>
          //   <div> 
          //     <input 
          //     // ref={drag}
          //     value={attribute.name} 
          //     disabled
          //     className="attribute"
          //     style={{ border: attribute.owner === 'BLOOMR' ? "2px solid rgb(63, 228, 63)" : "", 
          //     boxShadow: attribute.owner === 'ORG' ? "1px 0.5px 2px 0px" : "", cursor: "pointer" }}
          //     />
          //   </div>
          // </div>
          <SectionDrag 
            key={new Date().getTime() + Math.floor(Math.random * 1000000)}
            attribute={attribute}
            attrIndex={attrIndex}
            index={index}
            sectionData={sectionData}
            setSectionData={setSectionData}
            sectionIndex={sectionIndex}
          />
        ))}
      </>
    ))}
    </div>
  )
}

export default DropTarget
import React, { useState } from "react";
import TopBar from "../../../shared/Navigation/TopBar";
import { useNavigate } from "react-router-dom";
import Loader from "../../../shared/Loader/Loader";
import { useSelector, useDispatch } from 'react-redux';
import { updateSetupStatus } from "../../../Redux/actions";
import { Constants } from "../SuperAdmin/Credentials";
import QuickGuide from "../../../shared/QuickGuide/QuickGuide";

const ModuleSelection = ({setShowTab,navigateToFormStep,sidebarCollapsed}) => {
  const loginObject = JSON.parse(sessionStorage.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.adminReducer);
    const { orgId } = useSelector((state) => state.loginReducer);
    const [quickFlagGuide, setQuickFlagGuide] = useState(true);
    const Api = Constants.api;

    const handleSetup = () => {
      dispatch(updateSetupStatus(orgId, "MODULE_SELECTION", "Module Selection", navigate, loginObject.token));
    }

    return (
      <>
      <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
        <button onClick={() => {if (!quickFlagGuide) {setQuickFlagGuide(true)}}} style={{float:'right', marginRight:'40px',backgroundColor:'#00a82d',padding:'0px 7px',borderRadius:'50%',border:'none'}}>
        <p style={{color:'#fff'}}>?</p>
        </button>
      <div className="organization_setup" style={{ height: 'auto' }}>
        <p className="heading pt-2 pl-2">Module Selection</p>
        <div id="multi-step-form-container">
        <ul className="form-stepper form-stepper-horizontal-admin text-center mx-auto pl-0">
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "active"} text-center form-stepper-list`}
              step="1"
            >
              <a
                className="mx-2"
                // onClick={(e) => {
                //   e.preventDefault();
                //   if (currentStep > 1) {
                //     navigateToFormStep(1);
                //   }
                // }}
              >
                <div className="label">Module Selection</div>
                <span className="form-stepper-circle">
                    <p className="tick">✓</p>
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "unfinished"} text-center form-stepper-list`}
              step="2"
            >
              <a
                className="mx-2"
                // onClick={(e) => {
                //   e.preventDefault();
                //   if (currentStep > 2) {
                //     navigateToFormStep(2);
                //   }
                // }}
              >
                <div className="label">Organization Structure</div>
                <span className="form-stepper-circle">
                    <span></span>
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "unfinished"} text-center form-stepper-list`}
              step="3"
            >
              <a
                className="mx-2"
                // onClick={(e) => {
                //   e.preventDefault();
                //   if (currentStep > 3) {
                //     navigateToFormStep(3);
                //   }
                // }}
              >
                <div className="label">User Setup</div>
                <span className="form-stepper-circle">
                    <span></span>
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "unfinished"} text-center form-stepper-list`}
              step="3"
            >
              <a
                className="mx-2"
                // onClick={(e) => {
                //   e.preventDefault();
                //   if (currentStep > 4) {
                //     navigateToFormStep(4);
                //   }
                // }}
              >
                <div className="label">Customer Setup </div>
                <span className="form-stepper-circle">
                  <span></span>
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "unfinished"} text-center form-stepper-list`}
              step="3"
            >
              <a className="mx-2">
                <div className="label">Product Setup</div>
                <span className="form-stepper-circle">
                  <span></span>
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${quickFlagGuide ? "" : "unfinished"} text-center form-stepper-list`}
              step="3"
            >
              <a className="mx-2">
                <div className="label">Functionality Setup</div>
                <span className="form-stepper-circle">
                  <span></span>
                </span>
              </a>
            </li>
          </ul>
      <div className="d-flex flex-direction-row justify-content-around flex-wrap">
        <div className="module  bg-shadow">
          <p className="module_head text-center mb-4">Default Modules</p>
          <div className="my-2">
            <label>My Dashboard</label>
            <label className="switch">
              <input type="checkbox" disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Visits</label>
            <label className="switch">
              <input type="checkbox" disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Calendar</label>
            <label className="switch">
              <input type="checkbox" disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Customers</label>
            <label className="switch">
              <input type="checkbox" disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Team</label>
            <label className="switch">
              <input type="checkbox" disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
  
        <div className="module bg-shadow">
          <p className="module_head text-center mb-4">Optional Modules</p>
          <div className="my-2">
            <label>My Opportunities</label>
            <label className="switch">
              <input type="checkbox"/>
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Orders</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Expenses</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Plan</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Resourses</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>Market Intelligence</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>Complaints</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="module bg-shadow">
          <p className="module_head text-center mb-4">
            External App Integrations
          </p>
          <div className="my-2">
            <label>Skilltiger</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>Mail Chimp</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>My Operator</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="my-2">
            <label>Survey Monkey</label>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="mt-3 mr-3 float-right">
                <button
                  className="next_button ml-3"
                  type="button"
                  onClick={() => {
                    // setShowTab(false);
                    // navigateToFormStep(3);
                    // navigate("/admin/organization-setup");
                    handleSetup();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
            </div>
        </div>
        {loading && <Loader />}
        {quickFlagGuide && <QuickGuide setQuickFlagGuide={setQuickFlagGuide} />}
      </>
    );
  };
  export default ModuleSelection;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModuleSelection from "./ModuleSelection";
import UserMaster from "./UserMasterSetup/UserMaster";
import TopBar from "../../../shared/Navigation/TopBar";
import { useDispatch, useSelector } from 'react-redux';
import { setupStatusRequest } from "../../../Redux/actions";
import Loader from "../../../shared/Loader/Loader";
import QuickGuide from "../../../shared/QuickGuide/QuickGuide";
import { EMPTY_DELETE_ATTRIBUTE, EMPTY_ATTRIBUTES, DELETE_CUSTOMER_TYPES, EMPTY_GEOGRAPHIC_NODES, EMPTY_SETUP_STATUS, EMPTY_PROFILE_DETAILS } 
from "../../../Redux/actions/actionTypes";

const OrganizationSetup = ({ sidebarCollapsed }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const loginObject = JSON.parse(sessionStorage.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTab, setShowTab] = useState(false);
  const [quickFlagGuide, setQuickFlagGuide] = useState();
  const navigateToFormStep = (stepNumber) => {
    setCurrentStep(stepNumber);
  };
  const { loading, setupStatus } = useSelector((state) => state.adminReducer);
  const { orgId } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    dispatch(setupStatusRequest(orgId, loginObject.token));
    // let quicktipFlag = localStorage.getItem("quickFlagGuide");
    // console.log('quicktipFlag:',quicktipFlag);
    // if(quicktipFlag === null && setupStatus?.moduleSelection === false
    //   && setupStatus?.orgStructure === false
    //   && setupStatus?.userMasterSetup === false
    //   && setupStatus?.customerMasterSetup === false
    //   && setupStatus?.productMasterSetup === false
    //   && setupStatus?.functionalitySetup === false) {
    //   console.log('C1G');
    //   setQuickFlagGuide(true);
    // }
    // if(quicktipFlag !== null && quicktipFlag) {
    //   console.log('C2')
    //   setQuickFlagGuide(false);
    // }
    // if(setupStatus?.moduleSelection === true
    //   || setupStatus?.orgStructure === true
    //   || setupStatus?.userMasterSetup === true
    //   || setupStatus?.customerMasterSetup === true
    //   || setupStatus?.productMasterSetup === true
    //   || setupStatus?.functionalitySetup === true) {
    //     console.log('C3')
    //     setQuickFlagGuide(false);
    //   }
  }, []);

  let quicktipFlag = localStorage.getItem("quickFlagGuide");

  useEffect(() => {
    // let quicktipFlag = localStorage.getItem("quickFlagGuide");
    // setTimeout(() => {
    if(setupStatus?.moduleSelection !== undefined
      && setupStatus?.orgStructure !== undefined
      && setupStatus?.userMasterSetup !== undefined
      && setupStatus?.customerMasterSetup !== undefined
      && setupStatus?.productMasterSetup !== undefined
      && setupStatus?.functionalitySetup !== undefined) {
    if(quicktipFlag === null && setupStatus?.moduleSelection === false
      && setupStatus?.orgStructure === false
      && setupStatus?.userMasterSetup === false
      && setupStatus?.customerMasterSetup === false
      && setupStatus?.productMasterSetup === false
      && setupStatus?.functionalitySetup === false) {
      console.log('C1G');
      setQuickFlagGuide(true);
    }
    if((quicktipFlag !== null && quicktipFlag && (setupStatus?.moduleSelection === true
      || setupStatus?.orgStructure === true
      || setupStatus?.userMasterSetup === true
      || setupStatus?.customerMasterSetup === true
      || setupStatus?.productMasterSetup === true
      || setupStatus?.functionalitySetup === true))) {
      console.log('C2')
      setQuickFlagGuide(false);
    }
    if(setupStatus?.moduleSelection === true
      || setupStatus?.orgStructure === true
      || setupStatus?.userMasterSetup === true
      || setupStatus?.customerMasterSetup === true
      || setupStatus?.productMasterSetup === true
      || setupStatus?.functionalitySetup === true) {
        console.log('C3')
        setQuickFlagGuide(false);
      }
    }
    // },[100]);
  }, [setupStatus, quicktipFlag])

  useEffect(() => {
    if(quickFlagGuide !== undefined && quickFlagGuide === false) {
      console.log('C4')
      localStorage.setItem("quickFlagGuide", true);
    }
  }, [quickFlagGuide]);

  useEffect(() => {
    dispatch({ type: EMPTY_DELETE_ATTRIBUTE });
    dispatch({ type: EMPTY_ATTRIBUTES });
    dispatch({ type: DELETE_CUSTOMER_TYPES });
    dispatch({ type: EMPTY_GEOGRAPHIC_NODES });
    dispatch({ type: EMPTY_SETUP_STATUS });
    dispatch({ type: EMPTY_PROFILE_DETAILS })
  }, []);

  return (
    <>
    <div
      className="main"
      style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}
    >
      <TopBar />
      <button onClick={() => {if (!quickFlagGuide) {setQuickFlagGuide(true)}}} style={{float:'right', marginRight:'40px',backgroundColor:'#00a82d',padding:'0px 7px',borderRadius:'50%',border:'none'}}>
        <p style={{color:'#fff'}}>?</p>
        </button>
      <div className="organization_setup">
        <p className="heading pt-4 pl-4">Organization Setup</p>
        <div id="multi-step-form-container">
          <ul className="form-stepper form-stepper-horizontal-admin text-center mx-auto pl-0" 
          >
            <li
              className={`form-stepper-${
                // currentStep === 1
                //   ? "active"
                //   : currentStep > 1
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.moduleSelection ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="1"
            >
              <a
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  if (currentStep > 1) {
                    navigateToFormStep(1);
                  }
                }}
              >
                <div className="label">Module Selection</div>
                <span className="form-stepper-circle">
                  {setupStatus?.moduleSelection && !quickFlagGuide ? (
                    <p className="tick">✓</p>
                  ) : (
                    <span>{/* 1 */}</span>
                  )}
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${
                // currentStep === 2
                //   ? "active"
                //   : currentStep > 2
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.orgStructure ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="2"
            >
              <a
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  if (currentStep > 2) {
                    navigateToFormStep(2);
                  }
                }}
              >
                <div className="label">Organization Structure</div>
                <span className="form-stepper-circle">
                  {/* {currentStep > 2 ? ( */}
                  {setupStatus?.orgStructure && !quickFlagGuide ? (
                    //   <i className="fas fa-check"></i>
                    <p className="tick">✓</p>
                  ) : (
                    <span></span>
                  )}
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${
                // currentStep === 3
                //   ? "active"
                //   : currentStep > 3
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.userMasterSetup ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="3"
            >
              <a
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  if (currentStep > 3) {
                    navigateToFormStep(3);
                  }
                }}
              >
                <div className="label">User Setup</div>
                <span className="form-stepper-circle">
                  {setupStatus?.userMasterSetup && !quickFlagGuide ? <p className="tick">✓</p> : <span></span>}
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${
                // currentStep === 4
                //   ? "active"
                //   : currentStep > 4
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.customerMasterSetup ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="3"
            >
              <a
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  if (currentStep > 4) {
                    navigateToFormStep(4);
                  }
                }}
              >
                <div className="label">Customer Setup </div>
                <span className="form-stepper-circle">
                  {setupStatus?.customerMasterSetup && !quickFlagGuide ? <p className="tick">✓</p> : <span></span>}
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${
                // currentStep === 5
                //   ? "active"
                //   : currentStep > 5
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.productMasterSetup ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="3"
            >
              <a className="mx-2">
                <div className="label">Product Setup</div>
                <span className="form-stepper-circle">
                  {setupStatus?.productMasterSetup && !quickFlagGuide ? <p className="tick">✓</p> : <span></span>}
                </span>
              </a>
            </li>
            <li
              className={`form-stepper-${
                // currentStep === 6
                //   ? "active"
                //   : currentStep > 6
                //   ? "completed"
                //   : "unfinished"
                quickFlagGuide ? "" :
                setupStatus?.functionalitySetup ? 
                  "active"
                  : "unfinished"
              } text-center form-stepper-list`}
              step="3"
            >
              <a className="mx-2">
                <div className="label">Functionality Setup</div>
                <span className="form-stepper-circle">
                  {setupStatus?.functionalitySetup && !quickFlagGuide ? <p className="tick">✓</p> : <span></span>}
                </span>
              </a>
            </li>
          </ul>
          {!showTab && (
            <div className="d-flex justify-content-around">
              <button
                className="org_setup_button"
                onClick={ () => navigate('/admin/module-selection')}
                // onClick={() => setShowTab(true)}
                style={{
                  border: currentStep === 1 ? "1px solid #00a82d" : "none",
                }}
              >
                <div className="d-flex mb-3">
                  <img
                    src={"/assets/sugar-cubes.png"}
                    style={{ width: "20px", color: "#000" }}
                    className="sidebar-icon"
                  />
                </div>
                <p className="font-weight-bold text-left">Module Selection</p>
                <p className="text-left">
                  Select the right modules suiting your business requirements
                </p>
                {/* <p className="font-weight-bold text-left mt-2">Step 1</p> */}
              </button>
              <button
                className="org_setup_button"
                style={{
                  border: currentStep === 2 ? "1px solid #00a82d" : "none",
                }}
                onClick={() => navigate("/admin/organization-structure")}
              >
                <div className="d-flex">
                  <img
                    src={"/assets/diagram.png"}
                    style={{ width: "20px", color: "#000"}}
                    className="sidebar-icon org-structure"
                  />
                </div>
                <p className="font-weight-bold text-left">
                  Organization Structure
                </p>
                <p className="text-left">
                  Create the structure of your Organization here
                </p>
                {/* <p className="font-weight-bold text-left mt-2 steps-para">Step 2</p> */}
              </button>
              <button
                className="org_setup_button"
                style={{
                  border: currentStep === 3 ? "1px solid #00a82d" : "none",
                }}
                // onClick={() => {setShowTab(true); navigateToFormStep(3)} }
                onClick={ () => navigate('/admin/user-master-setup')}
              >
                <div className="d-flex mb-3">
                  <img
                    src={"/assets/user-master.png"}
                    style={{ width: "20px", color: "#000" }}
                    className="sidebar-icon"
                  />
                </div>
                <p className="font-weight-bold text-left">User Master setup</p>
                <p className="text-left">
                  Choose or define the attributes for creating users
                </p>
                {/* <p className="font-weight-bold text-left mt-2">Step 3</p> */}
              </button>
              <button
                className="org_setup_button"
                style={{
                  border: currentStep === 4 ? "1px solid #00a82d" : "none",
                }}
                onClick={() => navigate('/admin/customer-master-setup')}
              >
                <div className="d-flex mb-3">
                  <img
                    src={"/assets/customer-master.png"}
                    style={{ width: "20px", color: "#000" }}
                    className="sidebar-icon"
                  />
                </div>
                <p className="font-weight-bold text-left">
                  Customer Master setup
                </p>
                <p className="text-left">
                  Customize the master suiting different customer types
                </p>
                {/* <p className="font-weight-bold text-left mt-2">Step 4</p> */}
              </button>
              <button
                className="org_setup_button"
                style={{
                  border: currentStep === 5 ? "1px solid #00a82d" : "none",
                }}
                onClick={() => navigate("/admin/product-master-setup")}
              >
                <div className="d-flex mb-3">
                  <img
                    src={"/assets/verified-cube.png"}
                    style={{ width: "20px", color: "#000" }}
                    className="sidebar-icon"
                  />
                </div>
                <p className="font-weight-bold text-left">
                  Product Master setup
                </p>
                <p className="text-left">
                  Decide the product information you want to upload
                </p>
                {/* <p className="font-weight-bold text-left mt-2">Step 5</p> */}
              </button>
              <button
                className="org_setup_button"
                style={{
                  border: currentStep === 6 ? "1px solid #00a82d" : "none",
                }}
                onClick={() => navigate('/admin/functionality-setup')}
              >
                <div className="d-flex">
                  <img
                    src={"/assets/technology.png"}
                    style={{ width: "20px", color: "#000" }}
                    className="sidebar-icon org-structure"
                  />
                </div>
                <p className="font-weight-bold text-left">
                  Functionality setup
                </p>
                <p className="text-left">
                  Actions & Settings Admin needs to do
                </p>
                {/* <p className="font-weight-bold text-left mt-2 steps-para">Step 6</p> */}
              </button>
            </div>
          )}
          {showTab && (
            <>
              <section
                id="step-1"
                className={`form-step ${currentStep === 1 ? "" : "d-none"}`}
              >
                <div className="mt-3">
                  <ModuleSelection setShowTab={setShowTab} navigateToFormStep={navigateToFormStep} />
                </div>
              </section>
              {/* <section
              id="step-3"
              className={`form-step ${currentStep === 2 ? "" : "d-none"}`}
            >
              <div className="mt-3">
                <KeyContacts
                  onKeyContactsChange={handleKeyContacts}
                  navigateToFormStep={navigateToFormStep}
                  navigateToBackStep={navigateToBackStep}
                />
              </div>
      </section> */}
          <section
            id="step-2"
            className={`form-step ${currentStep === 3 ? "" : "d-none"}`}
          >
            <div className="mt-3">
              <UserMaster setShowTab={setShowTab} navigateToFormStep={navigateToFormStep} />
            </div>
          </section>
            </>
          )}
          {/* 
            <section
              id="step-4"
              className={`form-step ${currentStep === 4 ? "" : "d-none"}`}
            >
              <div className="mt-3">
                <Modules />
              </div>
              <div className="mt-3 float-left">
                <button
                  className="back_button"
                  type="button"
                  onClick={() => navigateToBackStep(3)}
                >
                  Back
                </button>
              </div>
              <div className="mt-3 float-right">
                <button
                  className="cancel_button"
                  type="button"
                  onClick={() => navigate("/home")}
                >
                  Cancel
                </button>
              </div>
            </section>
            <section
              id="step-5"
              className={`form-step ${currentStep === 5 ? "" : "d-none"}`}
            >
              <div className="mt-3">
                <Package
                  onPackageChange={handlePackage}
                  orgName={orgName}
                  name={formData?.basicDetails?.name}
                  navigateToBackStep={navigateToBackStep}
                />
              </div>
            </section> */}
        </div>
      </div>
      {loading && <Loader />}
    </div>
    {quickFlagGuide && <QuickGuide setQuickFlagGuide={setQuickFlagGuide} quickFlagGuide={quickFlagGuide} />}
    </>
  );
};
export default OrganizationSetup;
import React from "react";
import { Draggable } from "@hello-pangea/dnd";
import { useSelector, useDispatch } from 'react-redux';
import { DELETE_ATTRIBUTE } from "../../../../Redux/actions/actionTypes";

const DraggableElement = ({ attribute, index, tableName }) => {
    const dispatch = useDispatch();

    const handleDeleteClick = (attributeId, orgAttributeId) => {
        const attributePayload = {
            attributeId: attributeId,
            table: tableName,
            orgAttributeId: orgAttributeId
        }
        dispatch({ type: DELETE_ATTRIBUTE, payload: attributePayload })
    }
  return (
    <Draggable 
    key={attribute.id}
    draggableId={attribute.id.toString()}
    index={index}
    >
      {(provided, snapshot) => (
        <div
          className="wrapper"
            // key={index}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
        >
          <div className="logo">
            <img src={"/assets/drag.png"} />
          </div>
          <div>
            <input
              // ref={ref}
            //   ref={provided.innerRef}
            //   {...provided.dragHandleProps}
            //   {...provided.draggableProps}
              value={attribute?.name}
              disabled
              className={`attribute ${attribute?.owner=== "BLOOMR" ? "attribute-bloomr" : "attribute-org"}`}
            />
          </div>
          {attribute?.owner !== "BLOOMR" && (
            <button
              className="logo-delete"
              onClick={() => handleDeleteClick(attribute.id, attribute.orgAttributeId)}
            //   onClick={() => func(attribute?.id, tableName)}
            >
              <img src={"/assets/delete.png"} alt="delete" />
            </button>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableElement;

import React from 'react';

const PromoCode = ({sidebarCollapsed}) => {
  return (
    <div className='d-flex my-3 main' style={{ marginLeft: sidebarCollapsed ? "55px" : "180px" }}>
      <h3 className='mx-auto'>Promo Code</h3>
    </div>
  );
};

export default PromoCode;

import React from 'react'

const ConnectionsBox = ({ parentChildRelation }) => {
  return (
    <div className='connections'> 
        <h4>Connections</h4>
            <div className='connections-container'>
                <div className='connections-container-green'>Child</div>
                <div className='connections-container-white bg-shadow'>
                    {parentChildRelation?.map((child) => (
                        <div>{child?.child}</div>
                    ))}
                </div>
            </div>
            <div className='connections-container'>
                <div className='connections-container-green parent-box'>Parent</div>
                <div className='connections-container-white bg-shadow parent-box'>
                {parentChildRelation?.map((child) => (
                        <div>{child?.parent}</div>
                    ))}
                </div>
            </div>
    </div>
  )
}

export default ConnectionsBox
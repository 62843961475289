// Sidebar.js

import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from '../../Redux/actions/actionTypes';

const Sidebar = ({ toggleSidebar, sidebarCollapsed }) => {
  const { updateToken } = useAuth();
  const dispatch = useDispatch();
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.loginReducer);

  // Only render the sidebar on the home page
  if (
    location.pathname === "/" ||
    location.pathname === "/loginwithemail" ||
    location.pathname === "/loginwithmobilenumber" ||
    location.pathname === "/verifyotp" ||
    location.pathname === "/forgotpassword" ||
    !token
  ) {
    return null;
  }

  return (
    <>
      {role === "SUPER_ADMIN" ? (
        <>
          {/* These are Super admin dashboard options */}
          <div
            className="sidebar"
            style={{ width: sidebarCollapsed ? "55px" : "180px" }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={
                  sidebarCollapsed
                    ? "/assets/Bloomr-shrinked.png"
                    : "/assets/Bloomr.png"
                }
                id="logo"
                style={{
                  width: sidebarCollapsed ? "18px" : "110px",
                  marginTop: "10px",
                  marginBottom: "60px",
                }}
              />
            </div>
            {/* <hr className="solid" color="#fff" /> */}
            <div
              className={`sidebar-option ${
                location.pathname === "/dashboard" ? "selected" : ""
              }`}
            >
              <Link to="/dashboard" className="sidebar-link">
                <img
                  src={"/assets/dashboard-interface.png"}
                  style={{ width: "16px", marginTop: '5px' }}
                  className="sidebar-icon"
                />
                Dashboard
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/home" ||
                location.pathname === "/createorganization" ||
                location.pathname === "/organizationprofile/:id" ||
                location.pathname === "/editorganization/:id" ||
                location.pathname === "/reminder"
                  ? "selected"
                  : ""
              }`}
            >
              <Link to="/home" className="sidebar-link">
                <img
                  src={"/assets/house.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Home
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/package" ? "selected" : ""
              }`}
            >
              <Link to="/package" className="sidebar-link">
                <img
                  src={"/assets/rupee-indian.png"}
                  style={{ width: "20px", marginTop: '2px' }}
                  className="sidebar-icon"
                />
                Package
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/promocode" ? "selected" : ""
              }`}
            >
              <Link to="/promocode" className="sidebar-link">
                <img
                  src={"/assets/tag.png"}
                  style={{ width: "20px", marginTop: '4px' }}
                  className="sidebar-icon"
                />
                Promo Code
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/settings" ? "selected" : ""
              }`}
            >
              <Link to="/settings" className="sidebar-link">
                <SettingsIcon className="sidebar-icon" />
                Settings
              </Link>
            </div>
            <div className="sidebar-bottom">
              <button
                onClick={toggleSidebar}
                style={{
                  width: "16px",
                  backgroundColor: "#000",
                  border: "none",
                  height: "30px",
                  marginLeft: "20px",
                  padding: "2px",
                }}
              >
                <img
                  src={"/assets/expand.png"}
                  style={{
                    width: "16px",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                />
              </button>

              {/* <div className="sidebar-option">
          <HelpOutlineIcon fontSize="medium" />
          <Link to="/needhelp" className="sidebar-link">
            Need Help
          </Link>
        </div> */}
              {/* <hr className="solid" color="#fff" /> */}
              <div className="sidebar-option logout">
                <button
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  to="/"
                  className="sidebar-link"
                  onClick={() => {
                    sessionStorage.clear();
                    updateToken("");
                    navigate("/");
                  }}
                >
                  <img
                    src={"/assets/out.png"}
                    style={{ width: "20px" }}
                    className="sidebar-icon"
                  />
                  Logout
                </button>
              </div>
            </div>
          </div>
        </>
      ) : role === 'ORG_ADMIN' ? (
        <>
          {/* These are Admin dashboard options */}
          <div
            className="sidebar"
            style={{ width: sidebarCollapsed ? "55px" : "180px" }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={
                  sidebarCollapsed
                    ? "/assets/Bloomr-shrinked.png"
                    : "/assets/Bloomr.png"
                }
                id="logo"
                style={{
                  width: sidebarCollapsed ? "18px" : "110px",
                  marginTop: "10px",
                  marginBottom: "60px",
                }}
              />
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/dashboard" ? "selected" : ""
              }`}
            >
              <Link 
              // to="dashboard" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/dashboard-interface.png"}
                  style={{ width: "20px", marginTop: '5px' }}
                  className="sidebar-icon"
                />
                Dashboard
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/organization-setup" ||
                location.pathname === "/admin/module-selection" ||
                location.pathname === "/admin/user-master-setup" ||
                location.pathname === "/admin/attributes-list-review" ||
                location.pathname === "/admin/create-sections" ||
                location.pathname === "/admin/review-sections" ||
                location.pathname === "/admin/customer-master-setup" ||
                location.pathname === "/admin/customer-master-attributes" ||
                location.pathname === "/admin/customer-master-attributes" ||
                location.pathname === "/admin/attributes-list-review-customer" ||
                location.pathname === "/admin/create-sections-customer" ||
                location.pathname === "/admin/review-sections-customer" ||
                location.pathname === "/admin/view-created-sections-customer" ||
                location.pathname === "/admin/customer-master-parent-child-connection" ||
                location.pathname === "/admin/product-master-setup" ||
                location.pathname === "/admin/product-master-setup-attributes" ||
                location.pathname === "/admin/create-sections-product" ||
                location.pathname === "/admin/review-sections-product"
                 ? "selected" : ""
              }`}
            >
              <Link to="/admin/organization-setup" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/organization.png"}
                  style={{ width: "20px", marginTop: '10px' }}
                  className="sidebar-icon"
                />
                Organization Setup
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/organization-structure" ? "selected" : ""
              }`}
            >
              <Link to="admin/organization-structure" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/organize-structure.png"}
                  style={{ width: "20px", marginTop: '10px' }}
                  className="sidebar-icon"
                />
                Organization Structure
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/users" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/users" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/user.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Users
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/customers" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/customers" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/customer.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Customers
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/products" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/products" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/products.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Products
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/products" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/kpi-setup" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/products.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                KPI Setup
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "admin/home" ? "selected" : ""
              }`}
            >
              <Link 
              // to="admin/home" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <SettingsIcon className="sidebar-icon"  style={{ width: "20px" }} />
                Admin Actions
              </Link>
            </div>

            <div className="sidebar-bottom">
              <button
                onClick={toggleSidebar}
                style={{
                  width: "16px",
                  backgroundColor: "#000",
                  border: "none",
                  height: "30px",
                  marginLeft: "10px",
                  padding: "2px",
                }}
              >
                <img
                  src={"/assets/expand.png"}
                  style={{
                    width: "16px",
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: '5px',
                    marginLeft: '5px'
                  }}
                />
              </button>
              <div className="sidebar-option logout" style={{ marginLeft: '0px' }}>
                <button
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  // className="sidebar-link"
                  className="sidebar-link-collapsed"
                >
                  <img
                    src={"/assets/tooltip.png"}
                    style={{ width: "20px", marginLeft: '5px' }}
                    className="sidebar-icon"
                  />
                  Need Help
                </button>
              </div>
              <div className="bar"></div>
              <div className="sidebar-option logout" style={{ marginLeft: '0px' }}>
                <button
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  to="/"
                  className="sidebar-link"
                  onClick={() => {
                    sessionStorage.clear();
                    updateToken("");
                    navigate("/");
                    localStorage.clear();
                    dispatch({ type: actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS, payload: [] });
                  }}
                >
                  <img
                    src={"/assets/out.png"}
                    style={{ width: "20px", marginLeft: '5px' }}
                    className="sidebar-icon"
                  />
                  Logout
                </button>
              </div>
            </div>
          </div>
        </>
      ) : role === 'ORG_USER' ? (
        <>
          {/* These are Admin dashboard options */}
          <div
            className="sidebar"
            style={{ width: sidebarCollapsed ? "55px" : "180px" }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={
                  sidebarCollapsed
                    ? "/assets/Bloomr-shrinked.png"
                    : "/assets/Bloomr.png"
                }
                id="logo"
                style={{
                  width: sidebarCollapsed ? "18px" : "110px",
                  marginTop: "10px",
                  marginBottom: "60px",
                }}
              />
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/dashboard" ? "selected" : ""
              }`}
            >
              <Link 
              // to="dashboard" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/dashboard-interface.png"}
                  style={{ width: "20px", marginTop: '5px' }}
                  className="sidebar-icon"
                />
                Dashboard
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/organization-setup" ||
                location.pathname === "/admin/module-selection" ||
                location.pathname === "/admin/user-master-setup" ||
                location.pathname === "/admin/attributes-list-review" ||
                location.pathname === "/admin/create-sections" ||
                location.pathname === "/admin/review-sections" ||
                location.pathname === "/admin/customer-master-setup" ||
                location.pathname === "/admin/customer-master-attributes" ||
                location.pathname === "/admin/customer-master-attributes" ||
                location.pathname === "/admin/attributes-list-review-customer" ||
                location.pathname === "/admin/create-sections-customer" ||
                location.pathname === "/admin/review-sections-customer" ||
                location.pathname === "/admin/view-created-sections-customer" ||
                location.pathname === "/admin/customer-master-parent-child-connection" ||
                location.pathname === "/admin/product-master-setup" ||
                location.pathname === "/admin/product-master-setup-attributes" ||
                location.pathname === "/admin/create-sections-product" ||
                location.pathname === "/admin/review-sections-product"
                 ? "selected" : ""
              }`}
            >
              <Link to="/admin/organization-setup" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/organization.png"}
                  style={{ width: "20px", marginTop: '10px' }}
                  className="sidebar-icon"
                />
                Organization Setup
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/organization-structure" ? "selected" : ""
              }`}
            >
              <Link to="admin/organization-structure" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/organize-structure.png"}
                  style={{ width: "20px", marginTop: '10px' }}
                  className="sidebar-icon"
                />
                Organization Structure
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/users" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/users" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/user.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Users
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/customers" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/customers" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/customer.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Customers
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "/admin/products" ? "selected" : ""
              }`}
            >
              <Link 
              to="admin/products" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <img
                  src={"/assets/products.png"}
                  style={{ width: "20px" }}
                  className="sidebar-icon"
                />
                Products
              </Link>
            </div>
            <div
              className={`sidebar-option ${
                location.pathname === "admin/home" ? "selected" : ""
              }`}
            >
              <Link 
              // to="admin/home" 
              // className="sidebar-link"
              className={`${sidebarCollapsed ? "sidebar-link-collapsed" : "sidebar-link"}`}
              >
                <SettingsIcon className="sidebar-icon"  style={{ width: "20px" }} />
                Admin Actions
              </Link>
            </div>

            <div className="sidebar-bottom">
              <button
                onClick={toggleSidebar}
                style={{
                  width: "16px",
                  backgroundColor: "#000",
                  border: "none",
                  height: "30px",
                  marginLeft: "10px",
                  padding: "2px",
                }}
              >
                <img
                  src={"/assets/expand.png"}
                  style={{
                    width: "16px",
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: '5px',
                    marginLeft: '5px'
                  }}
                />
              </button>
              <div className="sidebar-option logout" style={{ marginLeft: '0px' }}>
                <button
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  // className="sidebar-link"
                  className="sidebar-link-collapsed"
                >
                  <img
                    src={"/assets/tooltip.png"}
                    style={{ width: "20px", marginLeft: '5px' }}
                    className="sidebar-icon"
                  />
                  Need Help
                </button>
              </div>
              <div className="bar"></div>
              <div className="sidebar-option logout" style={{ marginLeft: '0px' }}>
                <button
                  style={{
                    backgroundColor: "#000",
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  to="/"
                  className="sidebar-link"
                  onClick={() => {
                    sessionStorage.clear();
                    updateToken("");
                    navigate("/");
                    localStorage.clear();
                    dispatch({ type: actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS, payload: [] });
                  }}
                >
                  <img
                    src={"/assets/out.png"}
                    style={{ width: "20px", marginLeft: '5px' }}
                    className="sidebar-icon"
                  />
                  Logout
                </button>
              </div>
            </div>
          </div>
        </>
      ) : ''}
    </>
  );
};

export default Sidebar;

import React, { useState } from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';

export default function UploadTargetModal({showtargetModal, setShowTargetModal}) {
    const [kpiName, setKpiName] = useState('Opportunities Converted');

    if (!showtargetModal) return null;

  return (
    <div className="custom-attribute-modal-wrapper" style={{zIndex:10}}>
      <div className="upload_target_modal">
        <div className="d-flex align-items-center">
          <button className="close-button p-0" onClick={() => setShowTargetModal(false)}>
            <KeyboardArrowLeft />
          </button>
          <p className='order_email_heading'>Upload Target</p>
        </div>
        <div className="">
         
        <label htmlFor="kpiName" className="block text-sm font-medium text-gray-700 mb-1">
          KPI Name
        </label>
        <input
          type="text"
          id="kpiName"
          value={kpiName}
          onChange={(e) => setKpiName(e.target.value)}
          className="w-100 p-2 light_shadow border-0 rounded-md"
        />

      <div className="my-2 light_shadow p-1 rounded-lg" style={{width:'fit-content'}}>
        <label htmlFor="targetTemplate" className="block text-sm font-medium text-gray-700 mb-1">
          Target Template
        </label>
        <button className="border-0 rounded-md bg-light ">
          <img
            style={{ width: "20px" }}
            src={"/assets/download.png"}
          />
        </button>
      </div>

      <div className="mb-6">
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
          {/* <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" /> */}
          <p className="text-sm text-gray-500">Upload Template</p>
        </div>
      </div>

      <button className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-300">
        Save
      </button>
    </div>
     </div>
    </div>
  );
}
import { useNavigate } from "react-router-dom";
const LoginWithMobileNumber = () => {
  const navigate = useNavigate();
  return (
    <div className="login-container">
      <div className="login-form w-25">
        <h4>Let's Get Started !</h4>
        <label>Enter your registered mobile no.</label>
        <input
          className="form-control w-100 login-input"
          placeholder="Mobile Number"
        />
        <button
          className="button mt-2"
          onClick={() => navigate("/otpverification")}
        >
          Get OTP
        </button>
      </div>
    </div>
  );
};

export default LoginWithMobileNumber;
